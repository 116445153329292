import {
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
  InvalidDateError,
  DateRangePickerInput,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  DateRangePickerField,
  Dialog,
  TextInputField,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React, { useEffect, useState } from "react";
import {
  CloneCreateNewProjectDialogState,
  CloneCreateNewProjectRPCErrors,
  ProjectNameValidationErrors,
  ProjectsRootStore,
} from "../../store/ProjectsRootStore";
import { Stack, Typography } from "@mui/material";
import { HelpCircle, InfoIcon } from "lucide-react";
import {
  ICON_SIZE,
  PROJECT_SURVEY_NAME_VALIDATIONS,
  SINGLE_SPACING,
  getIconProps,
} from "@pulse/shared-components";
import { NavigateToFunctions } from "../../../../routes/RoutesHelper";
import { DialogErrorContent } from "../../../../components/DialogErrorContent";
import { NestedTypographyComponent } from "../NestedTypographyComponent";
import { getDefaultProjectDetailsTab } from "../../utils/ProjectDetailsTabUtils";

interface CloneProjectDialogProps {
  projectName: string;
  projectId: string;
}

export interface CloneCreateNewProjectDialogProps {
  isCloneCreateNewProjectDialogOpen: boolean;
  t: TFunction;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  setIsCloneCreateNewProjectDialogOpen:
    | ((isCloneCreateNewProjectDialogOpen: boolean) => void)
    | React.Dispatch<React.SetStateAction<boolean>>;
  projectsRootStore: Instance<typeof ProjectsRootStore>;
  navigateTo: NavigateToFunctions;
  cloneProjectDialogProps: CloneProjectDialogProps | undefined;
}

export const CloneCreateNewProjectDialog = observer(
  ({
    isCloneCreateNewProjectDialogOpen,
    t,
    spacing,
    tokens,
    typography,
    setIsCloneCreateNewProjectDialogOpen,
    projectsRootStore,
    navigateTo,
    cloneProjectDialogProps,
  }: CloneCreateNewProjectDialogProps): React.ReactElement => {
    const [isDateRangeInputInvalid, setIsDateRangeInputInvalid] =
      useState(false);
    const projectDetailsStore = projectsRootStore.projectDetailsStore;

    useEffect(() => {
      if (cloneProjectDialogProps) {
        projectDetailsStore.setProjectName(
          `${cloneProjectDialogProps.projectName}${SINGLE_SPACING}${t("projects.cloneProjectDialog.cloneProjectSuffix")}`,
        );
        projectDetailsStore.setCloneProjectId(
          cloneProjectDialogProps.projectId,
        );
      }
    }, [cloneProjectDialogProps]);

    const getProjectNameTextFieldHelperText = (): string | undefined => {
      switch (projectsRootStore.rpcErrors) {
        case CloneCreateNewProjectRPCErrors.InvalidProjectName:
          return t("projects.invalidProjectNameErrorText", {
            allowedSpecialCharacters:
              PROJECT_SURVEY_NAME_VALIDATIONS.allowedSpecialCharacters,
          });
        case CloneCreateNewProjectRPCErrors.ProjectNameNotUnique:
          return t("projects.projectNameNotUniqueErrorText");
      }
      switch (projectsRootStore.projectNameValidationErrors) {
        case ProjectNameValidationErrors.InvalidProjectName: {
          return t("projects.invalidProjectNameErrorText", {
            allowedSpecialCharacters:
              PROJECT_SURVEY_NAME_VALIDATIONS.allowedSpecialCharacters,
          });
        }
        case ProjectNameValidationErrors.InvalidProjectNameLength: {
          return t("projects.invalidProjectNameLengthErrorText", {
            minLength: PROJECT_SURVEY_NAME_VALIDATIONS.minLength,
            maxLength: PROJECT_SURVEY_NAME_VALIDATIONS.maxLength,
          });
        }
      }
    };

    const getProjectDateFieldHelperText = (): string | undefined => {
      switch (projectsRootStore.rpcErrors) {
        case CloneCreateNewProjectRPCErrors.InvalidProjectDates:
          return t("projects.invalidProjectDatesErrorText");
      }
    };

    const DialogInfo = (): React.ReactElement => {
      if (cloneProjectDialogProps) {
        return (
          <Stack direction="row" gap={spacing.spaceXS}>
            <HelpCircle
              {...getIconProps(tokens.iconSubtle, ICON_SIZE.default)}
            />
            <NestedTypographyComponent
              textColor={tokens.labelSubtle}
              startText={t("projects.cloneProjectDialog.startInfoText")}
              middleBoldText={cloneProjectDialogProps.projectName}
              endText={t("projects.cloneProjectDialog.endInfoText")}
              startAndEndTextTypography={typography.b2}
              boldTextTypography={typography.s2}
              isSpan={true}
            />
          </Stack>
        );
      } else {
        return (
          <Stack flexDirection="row" gap={spacing.spaceXS}>
            <InfoIcon
              style={getIconProps(tokens.iconSubtle, ICON_SIZE.default, true)}
            />
            <Typography {...typography.b2} color={tokens.labelSubtle}>
              {t("projects.createNewProjectDialog.infoText")}
            </Typography>
          </Stack>
        );
      }
    };

    const getDialogChild = (): React.ReactElement => {
      switch (projectsRootStore.cloneCreateNewProjectDialogState) {
        case CloneCreateNewProjectDialogState.CloneCreateNewProject: {
          return (
            <Stack gap={spacing.spaceLG}>
              <DialogInfo />
              <TextInputField
                name="projectName"
                value={projectDetailsStore.projectName}
                onTextChange={(value) => {
                  projectsRootStore.resetProjectNameValidationErrors();
                  projectsRootStore.clearRpcErrors();
                  projectDetailsStore.setProjectName(value);
                }}
                label={t("projects.projectNameLabel")}
                type={"text"}
                placeholder={t("projects.enterProjectNamePlaceholder")}
                helperText={getProjectNameTextFieldHelperText()}
                error={
                  projectsRootStore.doesProjectTextFieldContainErrors ||
                  projectsRootStore.doesStoreContainValidationErrors
                }
                disabled={projectsRootStore.isRPCLoading}
                required={true}
              />
              <Stack gap={spacing.spaceXXS}>
                <DateRangePickerField
                  required={true}
                  value={projectDetailsStore.getDateRangePickerInput}
                  startDateLabel={t("projects.startDateLabel")}
                  endDateLabel={t("projects.endDateLabel")}
                  onChange={(newDateRangePickerInput: DateRangePickerInput) => {
                    projectsRootStore.clearRpcErrors();
                    projectDetailsStore.setProjectDateRangeValue(
                      newDateRangePickerInput,
                    );
                  }}
                  minDate={new Date()}
                  startDatePlaceholder={t("common.dateRangePlaceholderText")}
                  endDatePlaceholder={t("common.dateRangePlaceholderText")}
                  format="dd MMM, yyyy"
                  onError={(
                    startDateError: InvalidDateError | null,
                    endDateError: InvalidDateError | null,
                  ): void => {
                    if (startDateError !== null) {
                      setIsDateRangeInputInvalid(true);
                      console.error(startDateError);
                    }
                    if (endDateError !== null) {
                      setIsDateRangeInputInvalid(true);
                      console.error(endDateError);
                    }
                    if (endDateError === null && startDateError === null) {
                      setIsDateRangeInputInvalid(false);
                    }
                  }}
                  startDateHelperText={getProjectDateFieldHelperText()}
                  startDateError={getProjectDateFieldHelperText() !== undefined}
                  endDateError={getProjectDateFieldHelperText() !== undefined}
                />
              </Stack>
            </Stack>
          );
        }
        case CloneCreateNewProjectDialogState.Error: {
          return (
            <DialogErrorContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              errorText={
                cloneProjectDialogProps
                  ? t("projects.cloneProjectDialog.unexpectedErrorText")
                  : t("projects.createNewProjectDialog.unexpectedErrorText")
              }
            />
          );
        }
      }
    };

    const getPrimaryButtonText = (): string | undefined => {
      if (!projectsRootStore.isCreateNewProjectDialogErrorState) {
        if (cloneProjectDialogProps) {
          return t("projects.cloneProjectDialog.cloneProject");
        } else {
          return t("projects.createNewProjectDialog.createProjectButtonText");
        }
      }
    };

    return (
      <Dialog
        open={isCloneCreateNewProjectDialogOpen}
        width="560px"
        title={
          cloneProjectDialogProps
            ? t("projects.cloneProjectDialog.cloneProject")
            : t("projects.createNewProject")
        }
        contentPadding={spacing.spaceLG}
        primaryButtonText={getPrimaryButtonText()}
        onPrimaryButtonClick={async (): Promise<void> => {
          if (
            projectsRootStore.isProjectNameValid() &&
            !projectsRootStore.doesStoreContainCloneCreateProjectErrors
          ) {
            cloneProjectDialogProps
              ? await projectsRootStore.cloneProject()
              : await projectsRootStore.createNewProject();
            if (
              projectsRootStore.projectId &&
              projectDetailsStore.projectName &&
              !projectsRootStore.doesStoreContainCloneCreateProjectErrors
            ) {
              setIsCloneCreateNewProjectDialogOpen(false);
              projectDetailsStore.respondentStore.setProjectId(
                projectsRootStore.projectId,
              );
              navigateTo.projectDetails(
                projectsRootStore.projectId,
                getDefaultProjectDetailsTab(t, navigateTo),
              );
            }
          }
        }}
        isPrimaryButtonDisabled={
          isDateRangeInputInvalid ||
          projectsRootStore.isCreateProjectPrimaryButtonDisabled ||
          projectsRootStore.doesStoreContainValidationErrors ||
          projectsRootStore.doesStoreContainCloneCreateProjectErrors
        }
        secondaryButtonText={
          projectsRootStore.isCreateNewProjectDialogErrorState
            ? t("common.close")
            : t("common.cancel")
        }
        onSecondaryButtonClick={(): void => {
          setIsCloneCreateNewProjectDialogOpen(false);
          projectsRootStore.resetProjectNameValidationErrors();
          projectsRootStore.clearProjectCreationDetailsAndRpcErrors();
        }}
        isSecondaryButtonDisabled={
          projectsRootStore.isCreateProjectSecondaryButtonDisabled
        }
        disableBackdropClick={false}
      >
        {/*
        getDialogChild() is not called like <DialogChild />.
        This is done to avoid giving it its own lifecycle which will result in loss of focus from <TextInputField /> on store updation.
        */}
        {getDialogChild()}
      </Dialog>
    );
  },
);
