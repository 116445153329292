import {
  GroupChildGraphTypeV1,
  QuestionViewGraphTypeV1,
  QuestionViewTypeV1,
  RankingGraphTypeV1,
  RankingResultV1,
} from "@pulse/pulse-rpcs";
import { Instance, types } from "mobx-state-tree";
import {
  createSampleBreakdownModel,
  SampleBreakdownModel,
} from "./SampleBreakdownModel";
import { QuestionTypeGraphs } from "../components/ChartSelection";

const RankingResultV1Model = types.model("RankingResultV1Model", {
  answer: types.string,
  code: types.string,
  count: types.array(types.number),
});

const createRankingResultV1Model = (
  rankingResultV1: RankingResultV1,
): Instance<typeof RankingResultV1Model> => {
  return RankingResultV1Model.create({
    answer: rankingResultV1.answer.answer,
    code: rankingResultV1.answer.code.code,
    count: rankingResultV1.count.map((count) => count.count),
  });
};

export const RankingQuestionViewModelV1 = types
  .model("RankingQuestionViewModelV1", {
    sampleBreakdown: SampleBreakdownModel,
    rankingGraphType: types.enumeration(
      "RankingGraphTypeV1",
      Object.values(RankingGraphTypeV1.RankingGraphTypeV1),
    ),
    updatedRankingGraphType: types.enumeration(
      "RankingGraphTypeV1",
      Object.values(RankingGraphTypeV1.RankingGraphTypeV1),
    ),
    question: types.maybeNull(types.string),
    rankingResults: types.array(RankingResultV1Model),
    surveyQuestionViewId: types.string,
    groupChildQuestionCode: types.string,
  })
  .views((store) => ({
    get isGraphSelectionUpdated(): boolean {
      return store.updatedRankingGraphType !== store.rankingGraphType;
    },
    get questionViewGraphType(): QuestionViewGraphTypeV1.Ranking {
      return new QuestionViewGraphTypeV1.Ranking(store.updatedRankingGraphType);
    },
    get groupQuestionViewGraphtype(): GroupChildGraphTypeV1.GroupChildGraphTypeV1 {
      return new GroupChildGraphTypeV1.Ranking(store.updatedRankingGraphType);
    },
  }))
  .actions((store) => ({
    updateSelectedGraphType: (
      updatedRankingGraphType: QuestionTypeGraphs,
    ): void => {
      store.updatedRankingGraphType =
        RankingGraphTypeV1.RankingGraphTypeV1[
          updatedRankingGraphType as keyof typeof RankingGraphTypeV1.RankingGraphTypeV1
        ];
    },
    updateSelectedGraphTypeWithUpdatedGraph: (): void => {
      store.rankingGraphType = store.updatedRankingGraphType;
    },
  }));

export const createRankingQuestionViewModelV1 = (
  rankingQuestionView: QuestionViewTypeV1.Ranking,
  surveyQuestionViewId: string,
  groupChildQuestionCode: string,
  question: string | null = null,
): Instance<typeof RankingQuestionViewModelV1> => {
  return RankingQuestionViewModelV1.create({
    sampleBreakdown: createSampleBreakdownModel(
      rankingQuestionView.sampleBreakdown,
    ),
    rankingGraphType: rankingQuestionView.rankingGraphType,
    updatedRankingGraphType: rankingQuestionView.rankingGraphType,
    rankingResults: rankingQuestionView.rankingResults.map((rankingResult) =>
      createRankingResultV1Model(rankingResult),
    ),
    question,
    surveyQuestionViewId,
    groupChildQuestionCode,
  });
};
