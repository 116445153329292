import { Divider } from "@mui/material";
import React from "react";

export const DividerComponent = ({
  orientation,
  height,
  width,
  padding,
}: {
  orientation: "horizontal" | "vertical";
  height?: string;
  width?: string;
  padding?: string;
}): React.ReactElement => {
  return (
    <Divider
      sx={{
        height,
        width,
        alignSelf: "center",
        padding,
      }}
      orientation={orientation}
      flexItem
    />
  );
};
