import { Instance } from "mobx-state-tree";
import React, { ReactElement } from "react";
import { Stack } from "@mui/material";
import {
  CornerRadius,
  FoundationColorTokens,
  IconButton,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  QuestionOrderElement,
  QuestionTextElement,
} from "../../../surveys/components/QuestionnaireListItem";
import {
  BorderStyle,
  EMPTY_LIST_LENGTH,
  getIconProps,
  ICON_SIZE,
} from "@pulse/shared-components";
import { QuestionnaireItemHeader } from "../../../surveys/components/questionItemComponents/QuestionnaireItemHeader";
import { TFunction } from "i18next";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DraggableAttributes,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Eye, EyeOff, GripVertical } from "lucide-react";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { observer } from "mobx-react";
import {
  GroupChildQuestionModel,
  SurveyQuestionViewsModel,
} from "../../models/SurveyQuestionViewsModel";
import { QuestionType } from "@pulse/pulse-rpcs";

interface QuestionComponentProps {
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  t: TFunction;
  questionText: string | undefined;
  questionCode: string;
  questionType: QuestionType.QuestionType;
  isViewVisible: boolean;
}

const QuestionComponent = ({
  questionCode,
  questionType,
  questionText,
  tokens,
  typography,
  t,
  spacing,
  isViewVisible,
}: QuestionComponentProps): ReactElement => {
  return (
    <Stack spacing={spacing.spaceXS}>
      <QuestionnaireItemHeader
        isRuleApplied={false}
        questionCode={questionCode}
        questionType={questionType}
        isVisibleByDefault={false}
        tokens={tokens}
        typography={typography}
        t={t}
        spacing={spacing}
        isRuleAppliedIconVisible={false}
        isVisiblityIconVisible={false}
      />
      <QuestionTextElement
        questionText={questionText}
        typography={typography}
        textColor={isViewVisible ? tokens.label : tokens.labelLowEmphasis}
      />
    </Stack>
  );
};

interface SortableChildQuestionListItemProps {
  index: number;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  childQuestion: Instance<typeof GroupChildQuestionModel>;
  t: TFunction;
  isViewIconVisible: boolean;
  cornerRadius: CornerRadius;
}

const SortableChildQuestionListItem = observer(
  ({
    index,
    spacing,
    tokens,
    typography,
    childQuestion,
    t,
    isViewIconVisible,
    cornerRadius,
  }: SortableChildQuestionListItemProps): ReactElement => {
    const { attributes, listeners, setNodeRef, transform } = useSortable({
      id: childQuestion.questionCode,
    });
    return (
      <Stack
        ref={setNodeRef}
        key={index}
        direction="row"
        width="100%"
        gap={spacing.spaceSM}
        justifyContent="space-between"
        minHeight="56px"
        sx={{
          transform: CSS.Translate.toString(transform),
        }}
        bgcolor={
          childQuestion.isViewVisible ? undefined : tokens.backgroundSubtle
        }
        paddingLeft="56px"
        borderRadius={cornerRadius.radiusXS}
      >
        <QuestionComponent
          questionCode={childQuestion.questionCode}
          questionType={childQuestion.questionType}
          questionText={childQuestion.questionText}
          tokens={tokens}
          typography={typography}
          t={t}
          spacing={spacing}
          isViewVisible={childQuestion.isViewVisible}
        />
        <Stack
          gap={spacing.spaceXS}
          direction="row"
          alignItems="center"
          justifyContent="center"
          paddingY={spacing.spaceXS}
        >
          {isViewIconVisible && (
            <IconButton
              name="viewVisibility"
              size="medium"
              icon={childQuestion.isViewVisible ? <EyeOff /> : <Eye />}
              variant="outlined-neutral"
              onClick={childQuestion.updateIsViewVisible}
            />
          )}
          <Stack
            {...attributes}
            {...listeners}
            sx={{ cursor: "grab" }}
            padding={spacing.spaceXS}
          >
            <GripVertical {...getIconProps(tokens.icon, ICON_SIZE.default)} />
          </Stack>
        </Stack>
      </Stack>
    );
  },
);

interface QuestionListItemProps {
  question: Instance<typeof SurveyQuestionViewsModel>;
  spacing: Spacing;
  cornerRadius: CornerRadius;
  border: BorderStyle;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  t: TFunction;
  attributes: DraggableAttributes;
  listeners: SyntheticListenerMap | undefined;
  index: number;
}

export const QuestionListItem = observer(
  ({
    question,
    spacing,
    border,
    cornerRadius,
    tokens,
    typography,
    t,
    attributes,
    listeners,
    index,
  }: QuestionListItemProps): ReactElement => {
    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      }),
    );
    const handleDragEnd = (event: DragEndEvent): void => {
      const { active, over } = event;

      if (over && active.id !== over.id) {
        question.reorderChildQuestions(`${active.id}`, `${over.id}`);
      }
    };

    return (
      <Stack
        direction="column"
        padding={spacing.spaceLG}
        gap={spacing.spaceLG}
        width="100%"
        bgcolor={question.isViewVisible ? undefined : tokens.backgroundSubtle}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          gap={spacing.spaceXS}
          width="100%"
        >
          <QuestionOrderElement
            border={border}
            cornerRadius={cornerRadius}
            tokens={tokens}
            typography={typography}
            order={index + 1}
            spacing={spacing}
            showDisabledColor={!question.isViewVisible}
          />
          <Stack
            direction="row"
            gap={spacing.spaceSM}
            width="100%"
            justifyContent="space-between"
          >
            <QuestionComponent
              questionCode={question.questionCode}
              questionType={question.questionType}
              questionText={question.questionText}
              tokens={tokens}
              typography={typography}
              t={t}
              spacing={spacing}
              isViewVisible={question.isViewVisible}
            />
          </Stack>
          <Stack
            gap={spacing.spaceXS}
            direction="row"
            alignItems="center"
            justifyContent="center"
            paddingY={spacing.spaceXS}
          >
            <IconButton
              name="viewVisibility"
              size="medium"
              icon={question.isViewVisible ? <EyeOff /> : <Eye />}
              variant="outlined-neutral"
              onClick={question.updateIsViewVisible}
            />
            <Stack
              {...attributes}
              {...listeners}
              sx={{ cursor: "grab" }}
              padding={spacing.spaceXS}
            >
              <GripVertical {...getIconProps(tokens.icon, ICON_SIZE.default)} />
            </Stack>
          </Stack>
        </Stack>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={question.groupChildQuestions.map(
              (groupChildQuestion) => groupChildQuestion.questionCode,
            )}
            strategy={verticalListSortingStrategy}
          >
            {question.groupChildQuestions.length > EMPTY_LIST_LENGTH && (
              <Stack gap={spacing.spaceLG} width="100%">
                {question.groupChildQuestions.map(
                  (childQuestion, childQuestionIndex) => {
                    return (
                      <SortableChildQuestionListItem
                        key={childQuestionIndex}
                        childQuestion={childQuestion}
                        index={childQuestionIndex}
                        spacing={spacing}
                        tokens={tokens}
                        typography={typography}
                        t={t}
                        isViewIconVisible={question.isViewVisible}
                        cornerRadius={cornerRadius}
                      />
                    );
                  },
                )}
              </Stack>
            )}
          </SortableContext>
        </DndContext>
      </Stack>
    );
  },
);
