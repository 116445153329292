import {
  GroupChildGraphTypeV1,
  QuestionViewGraphTypeV1,
  QuestionViewTypeV1,
  SingleSelectNpsGraphTypeV1,
  SingleSelectNpsResultV1,
} from "@pulse/pulse-rpcs";
import { Instance, types } from "mobx-state-tree";
import {
  createSampleBreakdownModel,
  SampleBreakdownModel,
} from "./SampleBreakdownModel";
import { QuestionTypeGraphs } from "../components/ChartSelection";
import { getHundredthDecimalRoundedPercentageString } from "@pulse/shared-components";
import {
  SingleSelectResultsModelV1,
  createSingleSelectResultsModelV1,
} from "./SingleSelectResultsModelV1";

export const SingleSelectNPSResultsModelV1 = types.model(
  "SingleSelectNPSResultsModelV1",
  {
    answer: types.string,
    percentage: types.string,
    count: types.number,
    rangeStart: types.number,
    rangeEnd: types.number,
  },
);

export const createSingleSelectNPSResultsModelV1 = (
  singleSelectResultV1: SingleSelectNpsResultV1,
): Instance<typeof SingleSelectNPSResultsModelV1> => {
  return SingleSelectNPSResultsModelV1.create({
    answer: singleSelectResultV1.answer,
    percentage: getHundredthDecimalRoundedPercentageString(
      singleSelectResultV1.commonResultBreakdownDetails.percentage,
    ),
    count: singleSelectResultV1.commonResultBreakdownDetails.count.count,
    rangeEnd: singleSelectResultV1.rangeEnd,
    rangeStart: singleSelectResultV1.rangeStart,
  });
};

export const NPSSingleSelectQuestionModelV1 = types
  .model("NPSSingleSelectQuestionModelV1", {
    question: types.maybeNull(types.string),
    detractors: SingleSelectNPSResultsModelV1,
    passives: SingleSelectNPSResultsModelV1,
    promoters: SingleSelectNPSResultsModelV1,
    sampleBreakdown: SampleBreakdownModel,
    singleSelectNPSGraphType: types.enumeration(
      "SingleSelectNpsGraphTypeV1",
      Object.values(SingleSelectNpsGraphTypeV1.SingleSelectNpsGraphTypeV1),
    ),
    updatedSingleSelectNPSGraphType: types.enumeration(
      "SingleSelectNpsGraphTypeV1",
      Object.values(SingleSelectNpsGraphTypeV1.SingleSelectNpsGraphTypeV1),
    ),
    surveyQuestionViewId: types.string,
    groupChildQuestionCode: types.string,
    singleSelectNpsResultsBreakdownV1: types.array(SingleSelectResultsModelV1),
  })
  .views((store) => ({
    get isGraphSelectionUpdated(): boolean {
      return (
        store.updatedSingleSelectNPSGraphType !== store.singleSelectNPSGraphType
      );
    },
    get questionViewGraphType(): QuestionViewGraphTypeV1.SingleSelectNps {
      return new QuestionViewGraphTypeV1.SingleSelectNps(
        store.updatedSingleSelectNPSGraphType,
      );
    },
    get groupQuestionViewGraphtype(): GroupChildGraphTypeV1.GroupChildGraphTypeV1 {
      return new GroupChildGraphTypeV1.SingleSelectNps(
        store.updatedSingleSelectNPSGraphType,
      );
    },
  }))
  .actions((store) => ({
    updateSelectedGraphType: (
      updatedSingleSelectNPSGraphType: QuestionTypeGraphs,
    ): void => {
      store.updatedSingleSelectNPSGraphType =
        SingleSelectNpsGraphTypeV1.SingleSelectNpsGraphTypeV1[
          updatedSingleSelectNPSGraphType as keyof typeof SingleSelectNpsGraphTypeV1.SingleSelectNpsGraphTypeV1
        ];
    },
    updateSelectedGraphTypeWithUpdatedGraph: (): void => {
      store.singleSelectNPSGraphType = store.updatedSingleSelectNPSGraphType;
    },
  }));

export const createNPSSingleSelectQuestionModelV1 = (
  singleSelectNPSQuestion: QuestionViewTypeV1.SingleSelectNps,
  surveyQuestionViewId: string,
  groupChildQuestionCode: string,
  question: string | null = null,
): Instance<typeof NPSSingleSelectQuestionModelV1> => {
  const singleSelectNPSGraphType =
    singleSelectNPSQuestion.singleSelectNpsGraphType ===
    SingleSelectNpsGraphTypeV1.SingleSelectNpsGraphTypeV1.GAUGE
      ? SingleSelectNpsGraphTypeV1.SingleSelectNpsGraphTypeV1
          .PROPORTION_WITH_VERTICAL_BAR
      : singleSelectNPSQuestion.singleSelectNpsGraphType;
  return NPSSingleSelectQuestionModelV1.create({
    detractors: createSingleSelectNPSResultsModelV1(
      singleSelectNPSQuestion.detractors,
    ),
    passives: createSingleSelectNPSResultsModelV1(
      singleSelectNPSQuestion.passives,
    ),
    promoters: createSingleSelectNPSResultsModelV1(
      singleSelectNPSQuestion.promoters,
    ),
    sampleBreakdown: createSampleBreakdownModel(
      singleSelectNPSQuestion.sampleBreakdown,
    ),
    singleSelectNPSGraphType,
    updatedSingleSelectNPSGraphType: singleSelectNPSGraphType,
    question,
    surveyQuestionViewId,
    groupChildQuestionCode,
    singleSelectNpsResultsBreakdownV1: createSingleSelectResultsModelV1(
      singleSelectNPSQuestion.singleSelectNpsResultsBreakdownV1,
    ),
  });
};
