import React from "react";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../routes/RoutesHelper";
import { UploadFileDialog } from "../../../components/fileUpload/UploadFileDialog";
import {
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import { RespondentUploadPane } from "./RespondentUploadPane";
import { NetworkingError } from "@pulse/shared-components";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import { RespondentUploadStore } from "../store/RespondentUploadStore";
import { ProjectDetailsStore } from "../store/ProjectDetailsStore";

export interface RespondentFileUploadDialogProps {
  t: TFunction;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  navigateTo: NavigateToFunctions;
  respondentUploadStore: Instance<typeof RespondentUploadStore> | null;
  projectStore: Instance<typeof ProjectDetailsStore>;
}

export const RespondentFileUploadComponent = observer(
  ({
    t,
    spacing,
    tokens,
    typography,
    navigateTo,
    respondentUploadStore,
    projectStore,
  }: RespondentFileUploadDialogProps): React.ReactElement => {
    if (respondentUploadStore?.rpcErrors) {
      switch (respondentUploadStore.rpcErrors) {
        case NetworkingError.InternalError:
        case NetworkingError.InternalServerError: {
          navigateTo.internalServerError();
        }
      }
    }

    const getErrorBannerText = (): string | undefined => {
      if (respondentUploadStore?.isFileInvalid) {
        return t("common.uploadDialog.invalidFileDetailsBannerText");
      } else {
        return undefined;
      }
    };

    const downloadButtonOnClick = (): Promise<void> => {
      return processProjectParams(async (projectId) => {
        await respondentUploadStore?.downloadRespondentTemplate(
          projectId,
          projectStore.projectName,
        );
      }, navigateTo);
    };

    const dialogButtonOnClick = async (): Promise<void> => {
      respondentUploadStore?.setIsRespondentUploadDialogVisible(false);
      if (respondentUploadStore?.isUploadTypeFirst) {
        respondentUploadStore?.setIsDuplicateValidationDialogVisible(true);
      } else {
        if (projectStore.respondentStore.projectId !== null) {
          await respondentUploadStore?.setRespectUniqueColumn(true);
          await respondentUploadStore?.setIsDuplicateValidationDialogVisible(
            true,
          );
          await respondentUploadStore?.validateUniqueColumnAndUpdateRespondent(
            projectStore.respondentStore.projectId,
            projectStore.respondentStore.isLiveRespondentsSelected,
          );
        } else {
          console.error(
            "Project id was found to be null in the respondent store.",
          );
        }
      }
      return Promise.resolve();
    };

    return respondentUploadStore ? (
      <UploadFileDialog
        errorBannerText={getErrorBannerText()}
        isUploadFileDialogVisible={
          respondentUploadStore?.isRespondentUploadDialogVisible
        }
        title={t("projects.respondentList.uploadRespondentCSVTitle")}
        description={t("projects.respondentList.downloadTemplateDescription")}
        note={t("common.uploadDialog.uploadNoteText")}
        primaryDownloadButtonText={t("common.downloadTemplate")}
        primaryDownloadButtonOnClick={downloadButtonOnClick}
        primaryDialogButtonText={t("common.proceed")}
        primaryDialogButtonOnClick={dialogButtonOnClick}
        secondaryDialogButtonText={t("common.cancel")}
        isPrimaryDialogButtonDisabled={
          !respondentUploadStore?.hasFileUploaded ||
          respondentUploadStore.doesStoreContainErrors
        }
        fileUploadPane={
          <RespondentUploadPane
            respondentUploadStore={respondentUploadStore}
            navigateTo={navigateTo}
            t={t}
            spacing={spacing}
            tokens={tokens}
            typography={typography}
          />
        }
        isSecondaryDialogButtonDisabled={respondentUploadStore.isRPCLoading}
        secondaryDialogButtonOnClick={() => {
          respondentUploadStore.resetRPCErrors();
          respondentUploadStore.resetValidationErrors();
          respondentUploadStore.resetUploadCSVStates();
          respondentUploadStore?.setIsRespondentUploadDialogVisible(false);
        }}
      />
    ) : (
      <></>
    );
  },
);
