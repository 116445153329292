import React, { ReactElement } from "react";
import { Instance } from "mobx-state-tree";
import { SingleSelectQuestionWithProportionModelV1 } from "../models/SingleSelectQuestionWithProportionModelV1";
import {
  CornerRadius,
  Shadow,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { BorderStyle } from "@pulse/shared-components";
import { SurveyQuestionViewStoreNames } from "../stores/SurveyViewDetailsStore";
import { QuestionView } from "./QuestionView";
import { QuestionTypeGraphs } from "./ChartSelection";
import { observer } from "mobx-react";
import { getButtonProps, UpdateQuestionViewParams } from "./QuestionViews";
import { SignInStore } from "../../auth/store/SignInStore";

interface SingleSelectQuestionWithProportionViewProps {
  surveyQuestionView: Instance<
    typeof SingleSelectQuestionWithProportionModelV1
  >;
  typography: Typography;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
  border: BorderStyle;
  cornerRadius: CornerRadius;
  boxShadow: Shadow;
  updateQuestionViewParams?: UpdateQuestionViewParams;
  allowSPoCToSelectGraphType: boolean;
  signInStore: Instance<typeof SignInStore>;
}

export const SingleSelectQuestionWithProportionView = observer(
  ({
    surveyQuestionView,
    spacing,
    t,
    tokens,
    typography,
    border,
    cornerRadius,
    boxShadow,
    updateQuestionViewParams,
    allowSPoCToSelectGraphType,
    signInStore,
  }: SingleSelectQuestionWithProportionViewProps): ReactElement => {
    const optionCodes: number[] = surveyQuestionView.singleSelectResults
      .map((result) => {
        const code = Number(result.answer.code);
        return isNaN(code) ? undefined : code;
      })
      .filter((code): code is number => code !== undefined);

    return (
      <QuestionView
        t={t}
        spacing={spacing}
        typography={typography}
        tokens={tokens}
        cornerRadius={cornerRadius}
        border={border}
        questionText={surveyQuestionView.question}
        sampleBreakdown={surveyQuestionView.sampleBreakdown}
        total={surveyQuestionView.sampleBreakdown.total}
        selectedGraph={
          QuestionTypeGraphs[surveyQuestionView.updatedSingleSelectGraphType]
        }
        surveyQuestionViewType={
          SurveyQuestionViewStoreNames.SINGLE_SELECT_QUESTION_WITH_PROPORTION_MODEL
        }
        updateSelectedGraphType={surveyQuestionView.updateSelectedGraphType}
        data={surveyQuestionView.singleSelectResults.map(
          (singleSelectResult) => {
            return {
              option: singleSelectResult.answer.answer,
              count: singleSelectResult.count,
              percentage: singleSelectResult.percentage,
              id: singleSelectResult.answer.code,
              isSurveyViewOptionDisplayTextSelected: true,
            };
          },
        )}
        naOptionCode={surveyQuestionView.naOptionCode}
        buttonProps={getButtonProps(
          updateQuestionViewParams,
          surveyQuestionView.surveyQuestionViewId,
          surveyQuestionView.questionViewGraphType,
          surveyQuestionView.isGraphSelectionUpdated,
          surveyQuestionView.updateSelectedGraphTypeWithUpdatedGraph,
        )}
        meanProps={{
          mean: surveyQuestionView.mean,
          optionCodes,
          singleSelectPrimitiveQuartileColor:
            surveyQuestionView.singleSelectPrimitiveQuartileColor,
        }}
        boxShadow={boxShadow}
        isChildQuestion={updateQuestionViewParams === undefined}
        isSurveyViewOptionDisplayTextSelected={true}
        allowSPoCToSelectGraphType={allowSPoCToSelectGraphType}
        signInStore={signInStore}
      />
    );
  },
);
