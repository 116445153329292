import {
  AddNewEmbedRPC,
  AddNewEmbedRPCClientImpl,
  CreateDashboardViewRPC,
  CreateDashboardViewRPCClientImpl,
  DeleteDashboardViewRPC,
  DeleteDashboardViewRPCClientImpl,
  DeleteEmbedRPC,
  DeleteEmbedRPCClientImpl,
  DownloadDashboardViewPDFRPC,
  DownloadDashboardViewPDFRPCClientImpl,
  EditDashboardViewRPC,
  EditDashboardViewRPCClientImpl,
  GetDashboardViewAndProjectSpocsRPC,
  GetDashboardViewAndProjectSpocsRPCClientImpl,
  GetEmbedsRPC,
  GetEmbedsRPCClientImpl,
  GetPaginatedOpenEndedResponsesRPC,
  GetPaginatedOpenEndedResponsesRPCClientImpl,
  GetPaginatedSurveyViewResultsRPC,
  GetPaginatedSurveyViewResultsRPCClientImpl,
  GetSurveyDashboardPreferencesRPC,
  GetSurveyDashboardPreferencesRPCClientImpl,
  GetSurveyDashboardViewsRPC,
  GetSurveyDashboardViewsRPCClientImpl,
  GetSurveyQuestionViewsRPC,
  GetSurveyQuestionViewsRPCClientImpl,
  ManageDashboardViewRPC,
  ManageDashboardViewRPCClientImpl,
  ManageDashboardViewSpocsRPC,
  ManageDashboardViewSpocsRPCClientImpl,
  UpdateQuestionGraphTypeRPC,
  UpdateQuestionGraphTypeRPCClientImpl,
  UpdateSurveyDashboardPreferencesRPC,
  UpdateSurveyDashboardPreferencesRPCClientImpl,
} from "@pulse/pulse-rpcs";
import { MOCK } from "@pulse/shared-components";
import { APIClient } from "@surya-digital/tedwig";
import { MockAddNewEmbedRPCClientImpl } from "./MockAddNewEmbed";
import { MockGetEmbedsRPCClientImpl } from "./MockGetEmbeds";
import { MockDeleteEmbedRPCClientImpl } from "./MockDeleteEmbed";
import { MockGetSurveyDashboardViewsRPCClientImpl } from "./MockGetSurveyDashboardViewsRPCClientImpl";
import { MockCreateDashboardViewRPCClientImpl } from "./MockCreateDashboardViewRPCClientImpl";
import { MockGetPaginatedSurveyViewResultsRPCClientImpl } from "./MockGetPaginatedSurveyViewResultsRPCClientImpl";
import { MockEditDashboardViewRPCClientImpl } from "./MockEditDashboardViewRPCClientImpl";
import { MockUpdateQuestionGraphTypeRPCClientImpl } from "./MockUpdateQuestionGraphTypeRPCClientImpl";
import { MockGetSurveyQuestionViewsRPCClientImpl } from "./MockGetSurveyQuestionViewsRPCClientImpl";
import { MockManageDashboardViewRPCClientImpl } from "./MockManageDashboardViewRPCClientImpl";
import { MockGetSurveyDashboardPreferencesRPCClientImpl } from "./MockGetSurveyDashboardPreferencesRPCClientImpl";
import { MockGetDashboardViewAndProjectSpocsRPCClientImpl } from "./MockGetDashboardViewAndProjectSpocsRPCClientImpl";
import { MockManageDashboardViewSpocsRPCClientImpl } from "./MockManageDashboardViewSpocsRPCClientImpl";
import { MockUpdateSurveyDashboardPreferencesRPCClientImpl } from "./MockUpdateSurveyDashboardPreferencesRPCClientImpl";
import { MockDownloadDashboardViewPDFRPCClientImpl } from "./MockDownloadDashboardViewPDFRPCClientImpl";
import { MockGetPaginatedOpenEndedResponsesRPCClientImpl } from "./MockGetPaginatedOpenEndedResponsesRPCClientImpl";
import { MockDeleteDashboardViewRPCClientImpl } from "./MockDeleteDashboardViewRPCClientImpl";

export const useAddNewEmbedRPCClient = (
  apiClient: APIClient,
): AddNewEmbedRPC => {
  if (MOCK.reportDetails) {
    return new MockAddNewEmbedRPCClientImpl();
  } else {
    return new AddNewEmbedRPCClientImpl(apiClient);
  }
};

export const useGetEmbedsRPCClient = (apiClient: APIClient): GetEmbedsRPC => {
  if (MOCK.reportDetails) {
    return new MockGetEmbedsRPCClientImpl();
  } else {
    return new GetEmbedsRPCClientImpl(apiClient);
  }
};

export const useDeleteEmbedRPCClient = (
  apiClient: APIClient,
): DeleteEmbedRPC => {
  if (MOCK.reportDetails) {
    return new MockDeleteEmbedRPCClientImpl();
  } else {
    return new DeleteEmbedRPCClientImpl(apiClient);
  }
};

export const useGetSurveyDashboardViewsRPCClient = (
  apiClient: APIClient,
): GetSurveyDashboardViewsRPC => {
  if (MOCK.reportDetails) {
    return new MockGetSurveyDashboardViewsRPCClientImpl();
  } else {
    return new GetSurveyDashboardViewsRPCClientImpl(apiClient);
  }
};

export const useCreateDashboardViewRPCClientImpl = (
  apiClient: APIClient,
): CreateDashboardViewRPC => {
  if (MOCK.reportDetails) {
    return new MockCreateDashboardViewRPCClientImpl();
  } else {
    return new CreateDashboardViewRPCClientImpl(apiClient);
  }
};

export const useGetPaginatedSurveyViewResultsRPCClient = (
  apiClient: APIClient,
): GetPaginatedSurveyViewResultsRPC => {
  if (MOCK.reportDetails) {
    return new MockGetPaginatedSurveyViewResultsRPCClientImpl();
  } else {
    return new GetPaginatedSurveyViewResultsRPCClientImpl(apiClient);
  }
};

export const useEditDashboardViewRPCClientImpl = (
  apiClient: APIClient,
): EditDashboardViewRPC => {
  if (MOCK.reportDetails) {
    return new MockEditDashboardViewRPCClientImpl();
  } else {
    return new EditDashboardViewRPCClientImpl(apiClient);
  }
};

export const useUpdateQuestionGraphTypeRPCClientImpl = (
  apiClient: APIClient,
): UpdateQuestionGraphTypeRPC => {
  if (MOCK.reportDetails) {
    return new MockUpdateQuestionGraphTypeRPCClientImpl();
  } else {
    return new UpdateQuestionGraphTypeRPCClientImpl(apiClient);
  }
};

export const useGetSurveyQuestionViewsRPCClientImpl = (
  apiClient: APIClient,
): GetSurveyQuestionViewsRPC => {
  if (MOCK.reportDetails) {
    return new MockGetSurveyQuestionViewsRPCClientImpl();
  } else {
    return new GetSurveyQuestionViewsRPCClientImpl(apiClient);
  }
};

export const useManageDashboardViewRPCClientImpl = (
  apiClient: APIClient,
): ManageDashboardViewRPC => {
  if (MOCK.reportDetails) {
    return new MockManageDashboardViewRPCClientImpl();
  } else {
    return new ManageDashboardViewRPCClientImpl(apiClient);
  }
};

export const useGetSurveyDashboardPreferencesRPCClient = (
  apiClient: APIClient,
): GetSurveyDashboardPreferencesRPC => {
  if (MOCK.reportDetails) {
    return new MockGetSurveyDashboardPreferencesRPCClientImpl();
  } else {
    return new GetSurveyDashboardPreferencesRPCClientImpl(apiClient);
  }
};

export const useGetDashboardViewAndProjectSpocsRPCClientImpl = (
  apiClient: APIClient,
): GetDashboardViewAndProjectSpocsRPC => {
  if (MOCK.reportDetails) {
    return new MockGetDashboardViewAndProjectSpocsRPCClientImpl();
  } else {
    return new GetDashboardViewAndProjectSpocsRPCClientImpl(apiClient);
  }
};

export const useManageDashboardViewSpocsRPCClientImpl = (
  apiClient: APIClient,
): ManageDashboardViewSpocsRPC => {
  if (MOCK.reportDetails) {
    return new MockManageDashboardViewSpocsRPCClientImpl();
  } else {
    return new ManageDashboardViewSpocsRPCClientImpl(apiClient);
  }
};

export const useUpdateSurveyDashboardPreferencesRPCClient = (
  apiClient: APIClient,
): UpdateSurveyDashboardPreferencesRPC => {
  if (MOCK.reportDetails) {
    return new MockUpdateSurveyDashboardPreferencesRPCClientImpl();
  } else {
    return new UpdateSurveyDashboardPreferencesRPCClientImpl(apiClient);
  }
};

export const useDownloadDashboardViewPDFRPCClientImpl = (
  apiClient: APIClient,
): DownloadDashboardViewPDFRPC => {
  if (MOCK.reportDetails) {
    return new MockDownloadDashboardViewPDFRPCClientImpl();
  } else {
    return new DownloadDashboardViewPDFRPCClientImpl(apiClient);
  }
};

export const useGetPaginatedOpenEndedResponsesRPCClientImpl = (
  apiClient: APIClient,
): GetPaginatedOpenEndedResponsesRPC => {
  if (MOCK.reportDetails) {
    return new MockGetPaginatedOpenEndedResponsesRPCClientImpl();
  } else {
    return new GetPaginatedOpenEndedResponsesRPCClientImpl(apiClient);
  }
};

export const useDeleteDashboardViewRPCClientImpl = (
  apiClient: APIClient,
): DeleteDashboardViewRPC => {
  if (MOCK.reportDetails) {
    return new MockDeleteDashboardViewRPCClientImpl();
  } else {
    return new DeleteDashboardViewRPCClientImpl(apiClient);
  }
};
