import React, { PropsWithChildren } from "react";
import { QuestionTypeHeader } from "./QuestionTypeHeader";
import { Box, Stack } from "@mui/material";
import { BorderStyle } from "@pulse/shared-components";
import {
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { QuestionType } from "@pulse/pulse-rpcs";

interface QuestionHeaderProps {
  tokens: FoundationColorTokens<string>;
  cornerRadius: CornerRadius;
  typography: Typography;
  t: TFunction;
  questionOrder: number;
  questionType: QuestionType.QuestionType;
}

interface QuestionContainerProps extends PropsWithChildren {
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
  border: BorderStyle;
  questionHeaderProps?: QuestionHeaderProps;
  paddingBottom?: string;
}

export const QuestionContainer = ({
  children,
  spacing,
  border,
  questionHeaderProps,
  paddingBottom,
}: QuestionContainerProps): React.ReactElement => {
  return (
    <Stack
      gap={spacing.spaceSM}
      padding={spacing.spaceLG}
      borderTop={border.default}
      width="100%"
      paddingBottom={paddingBottom}
    >
      {questionHeaderProps && (
        <QuestionTypeHeader
          border={border}
          tokens={questionHeaderProps.tokens}
          cornerRadius={questionHeaderProps.cornerRadius}
          spacing={spacing}
          typography={questionHeaderProps.typography}
          t={questionHeaderProps.t}
          questionOrder={questionHeaderProps.questionOrder}
          questionType={questionHeaderProps.questionType}
        />
      )}
      <Box paddingLeft={spacing.space3XL}>{children}</Box>
    </Stack>
  );
};
