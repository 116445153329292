import { Instance, types } from "mobx-state-tree";
import { EditRespondentDialogState } from "../components/RespondentListChildComponents/EditRespondentDetailsDialog";
import { EMPTY_CHARACTER } from "@pulse/shared-components";

export enum UnassignRespondentDialogState {
  UnassignRespondent = "UNASSIGN_RESPONDENT",
  Error = "ERROR",
  Success = "SUCCESS",
}

export const RespondentUIStore = types
  .model("RespondentUIStore", {
    isDeleteRespondentDialogVisible: types.optional(types.boolean, false),
    isRespondentResponseDialogVisible: types.optional(types.boolean, false),
    isEditRespondentDetailsDialogVisible: types.optional(types.boolean, false),
    isUnassignRespondentDialogVisible: types.optional(types.boolean, false),
    isReopenSurveyLinkDialogVisible: types.optional(types.boolean, false),
    isAssignRespondentDialogVisible: types.optional(types.boolean, false),
    isDeleteColumnDialogVisible: types.optional(types.boolean, false),
    isAssignSpocsDialogVisible: types.optional(types.boolean, false),
    isViewSpocsDialogVisible: types.optional(types.boolean, false),
    searchSpocText: types.optional(types.string, EMPTY_CHARACTER),
    isApplyFiltersClicked: types.optional(types.boolean, false),
    editRespondentDialogState: types.optional(
      types.enumeration(
        "EditRespondentDialogState",
        Object.values(EditRespondentDialogState),
      ),
      EditRespondentDialogState.Edit,
    ),
    isDeletedColumnFilterErrorDialogVisible: types.optional(
      types.boolean,
      false,
    ),
    unassignRespondentDialogState: types.optional(
      types.enumeration(
        "UnassignRespondentDialogState",
        Object.values(UnassignRespondentDialogState),
      ),
      UnassignRespondentDialogState.UnassignRespondent,
    ),
  })
  .actions((store) => ({
    setIsApplyFiltersClicked: (isApplyFiltersClicked: boolean): void => {
      store.isApplyFiltersClicked = isApplyFiltersClicked;
    },
    setSearchSpocText: (inputText: string | null): void => {
      if (inputText === null) {
        store.searchSpocText = EMPTY_CHARACTER;
      } else {
        store.searchSpocText = inputText;
      }
    },
    setIsViewSpocsDialogVisible: (isDialogVisible: boolean): void => {
      store.isViewSpocsDialogVisible = isDialogVisible;
    },
    setIsAssignSpocsDialogVisible: (isDialogVisible: boolean): void => {
      store.isAssignSpocsDialogVisible = isDialogVisible;
    },
    setIsDeleteRespondentDialogVisible: (isDialogVisible: boolean): void => {
      store.isDeleteRespondentDialogVisible = isDialogVisible;
    },
    setIsEditRespondentDetailsDialogVisible: (
      isDialogVisible: boolean,
    ): void => {
      store.isEditRespondentDetailsDialogVisible = isDialogVisible;
    },
    setIsUnassignRespondentDialogVisible: (isDialogVisible: boolean): void => {
      store.isUnassignRespondentDialogVisible = isDialogVisible;
    },
    setIsAssignRespondentDialogVisible: (isDialogVisible: boolean): void => {
      store.isAssignRespondentDialogVisible = isDialogVisible;
    },
    setIsDeleteColumnDialogVisible: (isDialogVisible: boolean): void => {
      store.isDeleteColumnDialogVisible = isDialogVisible;
    },
    setEditRespondentDialogState: (
      dialogState: EditRespondentDialogState,
    ): void => {
      store.editRespondentDialogState = dialogState;
    },
    setIsDeletedColumnFilterErrorDialogVisible: (isVisible: boolean): void => {
      store.isDeletedColumnFilterErrorDialogVisible = isVisible;
    },
    setIsReopenSurveyLinkDialogVisible: (
      isReopenSurveyLinkDialogVisible: boolean,
    ): void => {
      store.isReopenSurveyLinkDialogVisible = isReopenSurveyLinkDialogVisible;
    },
    setUnassignRespondentDialogState: (
      unassignRespondentDialogState: UnassignRespondentDialogState,
    ): void => {
      store.unassignRespondentDialogState = unassignRespondentDialogState;
    },
    setIsRespondentResponseDialogVisible: (
      isRespondentResponseDialogVisible: boolean,
    ): void => {
      store.isRespondentResponseDialogVisible =
        isRespondentResponseDialogVisible;
    },
  }));

export const createRespondentUIStore = (): Instance<
  typeof RespondentUIStore
> => {
  return RespondentUIStore.create();
};
