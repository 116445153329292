import React from "react";
import { SPoCRespondentTabs } from "../../../store/SPoCRespondentStore";
import { SPoCRespondentsList } from "./SPoCRespondentsList";
import { SPoCSettings } from "./SPoCSettings";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import { NavigateToFunctions } from "../../../../../routes/RoutesHelper";
import {
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { UserAccessStore } from "../../../store/UserAccessStore";
import { BorderStyle, DeveloperErrorFlow } from "@pulse/shared-components";

interface SPoCRespondentTabElementProps {
  t: TFunction;
  userAccessStore: Instance<typeof UserAccessStore>;
  navigateTo: NavigateToFunctions;
  typography: Typography;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
  selectedTab: number;
  border: BorderStyle;
}

export const SPoCRespondentTabElement = ({
  selectedTab,
  navigateTo,
  spacing,
  t,
  tokens,
  typography,
  userAccessStore,
  border,
}: SPoCRespondentTabElementProps): React.ReactElement => {
  switch (selectedTab) {
    case SPoCRespondentTabs.Respondents: {
      return (
        <SPoCRespondentsList
          t={t}
          spocRespondentStore={userAccessStore.spocRespondentStore}
          navigateTo={navigateTo}
          typography={typography}
          tokens={tokens}
          spacing={spacing}
        />
      );
    }
    case SPoCRespondentTabs.Settings: {
      return (
        <SPoCSettings
          userAccessStore={userAccessStore}
          spacing={spacing}
          border={border}
          typography={typography}
          tokens={tokens}
          t={t}
          navigateTo={navigateTo}
        />
      );
    }
    default:
      return <DeveloperErrorFlow />;
  }
};
