import {
  Dialog,
  FoundationColorTokens,
  Spacing,
  TableReloadHandle,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import React from "react";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../../routes/RoutesHelper";
import { Instance } from "mobx-state-tree";
import {
  AssignSurveyDialogState,
  AssignSurveyError,
  RespondentStore,
} from "../../store/RespondentStore";
import { DialogLoaderContent } from "../DialogLoaderContent";
import { ProjectDetailsStore } from "../../store/ProjectDetailsStore";
import { ChooseSurveyRadioList } from "./ChooseSurveyRadioList";
import { RespondentsAlreadyAssignedErrorDialogContent } from "./RespondentsAlreadyAssignedErrorDialogContent";
import { AssignRespondentSuccess } from "./AssignRespondentSuccess";
import { ErrorSuccessDialogContent } from "../ErrorSuccessDialogContent";
import { Stack, Typography } from "@mui/material";
import { AlertCircle } from "lucide-react";
import {
  DeveloperErrorFlow,
  ICON_SIZE,
  getIconProps,
} from "@pulse/shared-components";
import { InformationDialogContent } from "../InformationDialogContent";

interface AssignRespondentDialogProps {
  isAssignRespondentDialogVisible: boolean;
  setIsAssignRespondentDialogVisible: (value: boolean) => void;
  t: TFunction;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  navigateTo: NavigateToFunctions;
  respondentStore: Instance<typeof RespondentStore>;
  projectStore: Instance<typeof ProjectDetailsStore>;
  tableRef: React.MutableRefObject<TableReloadHandle | null>;
}

export const AssignRespondentDialog = observer(
  ({
    isAssignRespondentDialogVisible,
    setIsAssignRespondentDialogVisible,
    t,
    spacing,
    tokens,
    typography,
    navigateTo,
    respondentStore,
    projectStore,
    tableRef,
  }: AssignRespondentDialogProps): React.ReactElement => {
    const getErrorSuccessText = (): string => {
      switch (respondentStore.rpcErrors) {
        case AssignSurveyError.ProjectAlreadyArchived:
          return t("projects.assignToSurvey.projectArchivedErrorText");
        case AssignSurveyError.DeletedColumnFilter:
          return t("common.deletedColumnFilterErrorText");
        case AssignSurveyError.SurveyIsClosed:
          return t("projects.assignToSurvey.surveyIsClosedErrorText");
        case AssignSurveyError.NoSurveyQuestionsPresent:
          return t("projects.assignToSurvey.noSurveyQuestionsPresentErrorText");
        default:
          return t("projects.assignToSurvey.dialogErrorText");
      }
    };

    const AssignRespondentDialogContent = observer((): React.ReactElement => {
      switch (respondentStore.assignRespondentDialogState) {
        case AssignSurveyDialogState.FetchingSurveyList:
          return (
            <DialogLoaderContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              loaderText={t("projects.commonTexts.fetchingSurveysText")}
            />
          );
        case AssignSurveyDialogState.FetchingSurveyListError:
          return (
            <ErrorSuccessDialogContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              isError={true}
              errorSuccessText={t(
                "projects.commonTexts.fetchingSurveysErrorText",
              )}
            />
          );
        case AssignSurveyDialogState.ChooseFromSurveyList:
          return (
            <ChooseSurveyRadioList
              respondentStore={respondentStore}
              projectStore={projectStore}
              typography={typography}
              tokens={tokens}
              spacing={spacing}
            />
          );
        case AssignSurveyDialogState.RespondentsAlreadyAssigned:
          if (respondentStore.isRespondentAlreadyAssigned) {
            return (
              <RespondentsAlreadyAssignedErrorDialogContent
                typography={typography}
                spacing={spacing}
                tokens={tokens}
                t={t}
                respondentsSelected={respondentStore.respondentsSelected}
                respondentsAssigned={respondentStore.respondentsAssigned}
                respondentsNotAssigned={respondentStore.respondentsNotAssigned}
              />
            );
          } else {
            console.error(
              "Respondents Selected, Assigned and Not Assigned cannot be null in this flow.",
            );
            return <></>;
          }
        case AssignSurveyDialogState.AssignRespondentSuccess:
          return respondentStore.selectedSurvey?.surveyName ? (
            <AssignRespondentSuccess
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              t={t}
              respondentCount={respondentStore.selectedRespondentsCount}
              surveyName={respondentStore.selectedSurvey.surveyName}
            />
          ) : (
            <DeveloperErrorFlow />
          );
        case AssignSurveyDialogState.AssignRespondentError:
          return (
            <ErrorSuccessDialogContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              isError={true}
              errorSuccessText={getErrorSuccessText()}
            />
          );
        case AssignSurveyDialogState.RespondentAssignmentInProgress:
          // TODO: Update the designs of respondentAssignmentInProgress dialog state once the designs are ready.
          // Ticket Link: https://feedbackinsights.atlassian.net/browse/PUL2-332
          return (
            <Stack gap={spacing.spaceXS} direction="row" width="100%">
              <AlertCircle
                {...getIconProps(tokens.iconWarning, ICON_SIZE.default)}
              />
              <Typography
                sx={{
                  ...typography.b2,
                  color: tokens.labelWarning,
                  width: "492px",
                }}
              >
                {t(
                  "projects.assignToSurvey.respondentAssignmentInProgressWarningText",
                )}
              </Typography>
            </Stack>
          );
        case AssignSurveyDialogState.NoSurveyCreated:
          // TODO: Update the designs of noSurveyCreated dialog state once the designs are ready.
          // Ticket Link: https://feedbackinsights.atlassian.net/browse/PUL2-332
          return (
            <InformationDialogContent
              spacing={spacing}
              tokens={tokens}
              informationText={t("projects.assignToSurvey.noSurveyCreatedText")}
              typography={typography}
            />
          );
        default:
          return <DeveloperErrorFlow />;
      }
    });

    const getSecondaryButtonText = (): string => {
      switch (respondentStore.assignRespondentDialogState) {
        case AssignSurveyDialogState.AssignRespondentSuccess:
          return t("common.done");
        case AssignSurveyDialogState.ChooseFromSurveyList:
        case AssignSurveyDialogState.FetchingSurveyList:
          return t("common.cancel");
        case AssignSurveyDialogState.NoSurveyCreated:
          return t("common.dismiss");
        default:
          return t("common.close");
      }
    };

    const getTitleText = (): string => {
      switch (respondentStore.assignRespondentDialogState) {
        case AssignSurveyDialogState.RespondentsAlreadyAssigned:
          return t(
            "projects.assignToSurvey.respondentAlreadyAssignedTitleText",
          );
        case AssignSurveyDialogState.RespondentAssignmentInProgress:
          return t(
            "projects.assignToSurvey.respondentAssignmentInProgressTitleText",
          );
        default:
          return t("projects.assignToSurvey.chooseSurveyDialogTitle");
      }
    };

    return (
      <Dialog
        open={isAssignRespondentDialogVisible}
        title={getTitleText()}
        primaryButtonText={
          respondentStore.isAssignRespondentDialogPrimaryButtonVisible
            ? t("projects.assignToSurvey.dialogPrimaryButtonText")
            : undefined
        }
        isPrimaryButtonDisabled={
          respondentStore.isAssignRespondentDialogPrimaryButtonDisabled
        }
        onPrimaryButtonClick={async () => {
          const selectedSurvey = respondentStore.selectedSurvey;
          if (selectedSurvey) {
            await processProjectParams((projectId) => {
              return respondentStore.assignSurvey(
                projectId,
                selectedSurvey.surveyId,
              );
            }, navigateTo);
            tableRef.current?.reload();
          } else {
            console.error("SelectedSurvey cannot be null in this flow.");
          }
        }}
        isSecondaryButtonDisabled={
          respondentStore.isRPCLoading || projectStore.isRPCLoading
        }
        secondaryButtonText={getSecondaryButtonText()}
        onSecondaryButtonClick={() => {
          setIsAssignRespondentDialogVisible(false);
          respondentStore.resetAssignRespondentDialog();
        }}
        disableBackdropClick={true}
        contentPadding={spacing.spaceLG}
      >
        <AssignRespondentDialogContent />
      </Dialog>
    );
  },
);
