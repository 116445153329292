import {
  Dialog,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import React, { ReactElement, useEffect } from "react";
import {
  NavigateToFunctions,
  processSurveyViewParams,
} from "../../../routes/RoutesHelper";
import { DialogErrorContent } from "../../../components/DialogErrorContent";
import {
  DownloadDashboardViewPDFDialogState,
  DownloadDashboardViewPDFStore,
} from "../stores/DownloadDashboardViewPDFStore";
import { Instance } from "mobx-state-tree";
import { DialogSuccessContent } from "../../../components/DialogSuccessContent";
import {
  EMPTY_CHARACTER,
  getIconProps,
  ICON_SIZE,
} from "@pulse/shared-components";
import { NestedTypographyComponent } from "../../projects/components/NestedTypographyComponent";
import { Stack } from "@mui/material";
import { HelpCircle } from "lucide-react";
import { TableFilter } from "@pulse/pulse-rpcs";

interface DownloadDashboardViewPDFDialogProps {
  t: TFunction;
  isDownloadDashboardViewPDFDialogOpen: boolean;
  setIsDownloadDashboardViewPDFDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  downloadDashboardViewPDFStore: Instance<typeof DownloadDashboardViewPDFStore>;
  navigateTo: NavigateToFunctions;
  viewName: string | null;
  tableFilters: TableFilter[];
}

export const DownloadDashboardViewPDFDialog = observer(
  ({
    t,
    isDownloadDashboardViewPDFDialogOpen,
    setIsDownloadDashboardViewPDFDialogOpen,
    spacing,
    tokens,
    typography,
    downloadDashboardViewPDFStore,
    navigateTo,
    viewName,
    tableFilters,
  }: DownloadDashboardViewPDFDialogProps): ReactElement => {
    useEffect(() => {
      return () => {
        downloadDashboardViewPDFStore.resetStoreData();
      };
    }, []);
    const getPrimaryButtonText = (): string | undefined => {
      switch (
        downloadDashboardViewPDFStore.downloadDashboardViewPDFDialogState
      ) {
        case DownloadDashboardViewPDFDialogState.DownloadDashboardViewPDF: {
          return t("surveyDashboard.downloadPDF");
        }
        case DownloadDashboardViewPDFDialogState.Error:
        case DownloadDashboardViewPDFDialogState.Success: {
          return undefined;
        }
      }
    };

    const getSecondaryButtonText = (): string => {
      switch (
        downloadDashboardViewPDFStore.downloadDashboardViewPDFDialogState
      ) {
        case DownloadDashboardViewPDFDialogState.DownloadDashboardViewPDF: {
          return t("common.cancel");
        }
        case DownloadDashboardViewPDFDialogState.Error:
        case DownloadDashboardViewPDFDialogState.Success: {
          return t("common.close");
        }
      }
    };

    const getDialogChild = (): ReactElement => {
      switch (
        downloadDashboardViewPDFStore.downloadDashboardViewPDFDialogState
      ) {
        case DownloadDashboardViewPDFDialogState.DownloadDashboardViewPDF: {
          return (
            <Stack direction="row" gap={spacing.spaceXS}>
              <HelpCircle {...getIconProps(tokens.icon, ICON_SIZE.default)} />
              <NestedTypographyComponent
                textColor={tokens.label}
                boldTextTypography={typography.s2}
                middleBoldText={viewName ?? EMPTY_CHARACTER}
                startText={t(
                  "surveyDashboard.downloadPDFDialog.startDescriptionText",
                )}
                endText={t(
                  "surveyDashboard.downloadPDFDialog.endDescriptionText",
                )}
                startAndEndTextTypography={typography.b2}
                isSpan={true}
              />
            </Stack>
          );
        }
        case DownloadDashboardViewPDFDialogState.Success: {
          return (
            <DialogSuccessContent
              description={t(
                "surveyDashboard.downloadPDFDialog.successDescription",
              )}
              spacing={spacing}
              tokens={tokens}
              typography={typography}
            />
          );
        }
        case DownloadDashboardViewPDFDialogState.Error: {
          return (
            <DialogErrorContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              errorText={t(
                "surveyDashboard.downloadPDFDialog.errorDescription",
              )}
            />
          );
        }
      }
    };

    return (
      <Dialog
        open={isDownloadDashboardViewPDFDialogOpen}
        title={t("surveyDashboard.downloadPDF")}
        primaryButtonText={getPrimaryButtonText()}
        onPrimaryButtonClick={async () => {
          await processSurveyViewParams(
            async (
              surveyViewId: string,
              surveyId: string,
              projectId: string,
            ): Promise<void> => {
              await downloadDashboardViewPDFStore.downloadDashboardViewPDF(
                surveyViewId,
                surveyId,
                projectId,
                tableFilters,
              );
            },
            navigateTo,
          );
        }}
        secondaryButtonText={getSecondaryButtonText()}
        onSecondaryButtonClick={() => {
          setIsDownloadDashboardViewPDFDialogOpen(false);
          downloadDashboardViewPDFStore.resetStoreData();
        }}
        isSecondaryButtonDisabled={downloadDashboardViewPDFStore.isRPCLoading}
        width="560px"
      >
        {/*
        getDialogChild() is not called like <DialogChild />.
        This is done to avoid giving it its own lifecycle which will result in loss of focus from <TextInputField /> on store updation.
        */}
        {getDialogChild()}
      </Dialog>
    );
  },
);
