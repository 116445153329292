import {
  Dialog,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  ManageSurveyViewSPoCsDialogState,
  ManageSurveyViewSPoCsModel,
} from "../../models/ManageSurveyViewSPoCsModel";
import { Instance } from "mobx-state-tree";
import { DialogErrorContent } from "../../../../components/DialogErrorContent";
import {
  GetDashboardViewAndProjectSpocsRPC,
  SurveyDashboardView,
} from "@pulse/pulse-rpcs";
import { DialogLoaderContent } from "../../../projects/components/DialogLoaderContent";
import { ManageSurveyViewSPoCsDialogChild } from "./ManageSurveyViewSPoCsDialogChild";
import {
  NavigateToFunctions,
  processSurveyParams,
} from "../../../../routes/RoutesHelper";
import { observer } from "mobx-react";
import { BorderStyle } from "@pulse/shared-components";

interface ManageSurveyViewSPoCsDialogProps {
  isManageSurveyViewSPoCsDialogOpen: boolean;
  setIsManageSurveyViewSPoCsDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  t: TFunction;
  manageSurveyViewSPoCs: Instance<typeof ManageSurveyViewSPoCsModel>;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  navigateTo: NavigateToFunctions;
  border: BorderStyle;
  setSurveyDashboardViews: (surveyDashboardView: SurveyDashboardView[]) => void;
}

export const ManageSurveyViewSPoCsDialog = observer(
  ({
    isManageSurveyViewSPoCsDialogOpen,
    setIsManageSurveyViewSPoCsDialogOpen,
    t,
    manageSurveyViewSPoCs,
    spacing,
    tokens,
    typography,
    navigateTo,
    border,
    setSurveyDashboardViews,
  }: ManageSurveyViewSPoCsDialogProps): ReactElement => {
    const getDashboardViewAndProjectSPoCs =
      useCallback(async (): Promise<void> => {
        await processSurveyParams(
          manageSurveyViewSPoCs.getDashboardViewAndProjectSpocs,
          navigateTo,
        );
      }, []);

    useEffect(() => {
      manageSurveyViewSPoCs.resetStore();
      getDashboardViewAndProjectSPoCs();
    }, []);

    const [isRPCLoading, setIsRPCLoading] = useState(false);
    const getPrimaryButtonText = (): string | undefined => {
      switch (manageSurveyViewSPoCs.ManageSurveyViewSPoCsDialogState) {
        case ManageSurveyViewSPoCsDialogState.Loading:
        case ManageSurveyViewSPoCsDialogState.ManageSurveyViewSPoCs: {
          return t("surveyDashboard.updateViewAccess");
        }
        default: {
          return undefined;
        }
      }
    };
    const getSecondaryButtonText = (): string | undefined => {
      switch (manageSurveyViewSPoCs.ManageSurveyViewSPoCsDialogState) {
        case ManageSurveyViewSPoCsDialogState.ManageSurveyViewSPoCs: {
          return t("common.cancel");
        }
        case ManageSurveyViewSPoCsDialogState.Loading:
        case ManageSurveyViewSPoCsDialogState.Error: {
          return t("common.close");
        }
      }
    };
    const getErrorText = (): string => {
      switch (manageSurveyViewSPoCs.rpcError) {
        case GetDashboardViewAndProjectSpocsRPC.RPCError.ProjectAlreadyArchived:
          return t("common.projectAlreadyArchivedErrorText");
        default:
          return t("surveyDashboard.unexpectedErrorWhileUpdatingViewAccess");
      }
    };

    const getDialogChild = (): React.ReactElement => {
      switch (manageSurveyViewSPoCs.ManageSurveyViewSPoCsDialogState) {
        case ManageSurveyViewSPoCsDialogState.Loading: {
          return (
            <DialogLoaderContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              loaderText={t("projects.commonTexts.fetchingSpocsText")}
            />
          );
        }
        case ManageSurveyViewSPoCsDialogState.ManageSurveyViewSPoCs: {
          return (
            <ManageSurveyViewSPoCsDialogChild
              t={t}
              manageSurveyViewSPoCs={manageSurveyViewSPoCs}
              spacing={spacing}
              typography={typography}
              tokens={tokens}
              isRPCLoading={isRPCLoading}
              border={border}
            />
          );
        }
        case ManageSurveyViewSPoCsDialogState.Error: {
          return (
            <DialogErrorContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              errorText={getErrorText()}
            />
          );
        }
      }
    };

    return (
      <Dialog
        open={isManageSurveyViewSPoCsDialogOpen}
        title={t("common.manageSPoCs")}
        width="560px"
        primaryButtonText={getPrimaryButtonText()}
        onPrimaryButtonClick={async (): Promise<void> => {
          setIsRPCLoading(true);
          await processSurveyParams(
            async (surveyId: string, projectId: string) => {
              const surveyDashboardViews =
                await manageSurveyViewSPoCs.manageDashboardViewSpocsRPC(
                  surveyId,
                  projectId,
                );
              if (surveyDashboardViews) {
                setSurveyDashboardViews(surveyDashboardViews);
                setIsManageSurveyViewSPoCsDialogOpen(false);
              }
            },
            navigateTo,
          );
          setIsRPCLoading(false);
        }}
        isPrimaryButtonDisabled={
          manageSurveyViewSPoCs.isManageSurveyViewSPoCsDialogPrimaryButtonDisabled
        }
        secondaryButtonText={getSecondaryButtonText()}
        onSecondaryButtonClick={() => {
          setIsManageSurveyViewSPoCsDialogOpen(false);
        }}
        isSecondaryButtonDisabled={isRPCLoading}
        disableBackdropClick={true}
      >
        {/*
          getDialogChild() is not called like <DialogChild />.
          This is done to avoid giving it its own lifecycle which will result in loss of focus from <TextInputField /> on store updation.
        */}
        {getDialogChild()}
      </Dialog>
    );
  },
);
