import { Instance, types } from "mobx-state-tree";
import {
  createSingleSelectQuestionWithProportionModelV1,
  SingleSelectQuestionWithProportionModelV1,
} from "./SingleSelectQuestionWithProportionModelV1";
import {
  createRegularSingleSelectQuestionModelV1,
  RegularSingleSelectQuestionModelV1,
} from "./RegularSingleSelectQuestionModelV1";
import {
  createNPSSingleSelectQuestionModelV1,
  NPSSingleSelectQuestionModelV1,
} from "./NPSSingleSelectQuestionModelV1";
import {
  createOpenEndedQuestionViewModelV1,
  OpenEndedQuestionViewModelV1,
} from "./OpenEndedQuestionViewModelV1";
import {
  createMultiSelectQuestionViewModelV1,
  MultiSelectQuestionViewModelV1,
} from "./MultiSelectQuestionViewModelV1";
import {
  createRankingQuestionViewModelV1,
  RankingQuestionViewModelV1,
} from "./RankingQuestionViewModelV1";
import {
  Code,
  GroupChildGraphDetailsV1,
  GroupElementResultV1Enums,
  QuestionViewGraphTypeV1,
  QuestionViewTypeV1,
} from "@pulse/pulse-rpcs";
import {
  createGridQuestionViewV1Model,
  GridQuestionViewV1Model,
} from "./GridQuestionV1Model";
import { EMPTY_CHARACTER } from "@pulse/shared-components";

export const GroupQuestionViewModelV1 = types
  .model("GroupQuestionViewModelV1", {
    question: types.maybeNull(types.string),
    surveyQuestionViewId: types.string,
    childSurveyQuestionViews: types.array(
      types.union(
        SingleSelectQuestionWithProportionModelV1,
        RegularSingleSelectQuestionModelV1,
        NPSSingleSelectQuestionModelV1,
        OpenEndedQuestionViewModelV1,
        MultiSelectQuestionViewModelV1,
        RankingQuestionViewModelV1,
        GridQuestionViewV1Model,
      ),
    ),
  })
  .views((store) => ({
    get isChildQuestionViewsPresent(): boolean {
      return store.childSurveyQuestionViews.length !== 0;
    },
    get isSaveButtonEnabled(): boolean {
      let isButtonEnabled = false;
      store.childSurveyQuestionViews.map((surveyQuestionView) => {
        if (!isButtonEnabled && surveyQuestionView.isGraphSelectionUpdated) {
          isButtonEnabled = true;
        }
      });
      return isButtonEnabled;
    },
    get questionViewGraphType(): QuestionViewGraphTypeV1.Group {
      return new QuestionViewGraphTypeV1.Group(
        store.childSurveyQuestionViews.map(
          (surveyQuestionView): GroupChildGraphDetailsV1 => {
            return new GroupChildGraphDetailsV1(
              surveyQuestionView.groupQuestionViewGraphtype,
              new Code(surveyQuestionView.groupChildQuestionCode),
            );
          },
        ),
      );
    },
  }))
  .actions((store) => ({
    updateSelectedGraphTypeWithUpdatedGraph: (): void => {
      store.childSurveyQuestionViews.map((surveyQuestionView) => {
        surveyQuestionView.updateSelectedGraphTypeWithUpdatedGraph();
      });
    },
  }));

const getGroupQuestionViewModelV1 = (
  groupQuestion: QuestionViewTypeV1.Group,
): Instance<
  | typeof SingleSelectQuestionWithProportionModelV1
  | typeof NPSSingleSelectQuestionModelV1
  | typeof RegularSingleSelectQuestionModelV1
  | typeof OpenEndedQuestionViewModelV1
  | typeof MultiSelectQuestionViewModelV1
  | typeof RankingQuestionViewModelV1
  | typeof GridQuestionViewV1Model
>[] => {
  const groupSurveyQuestions: Instance<
    | typeof SingleSelectQuestionWithProportionModelV1
    | typeof NPSSingleSelectQuestionModelV1
    | typeof RegularSingleSelectQuestionModelV1
    | typeof OpenEndedQuestionViewModelV1
    | typeof MultiSelectQuestionViewModelV1
    | typeof RankingQuestionViewModelV1
    | typeof GridQuestionViewV1Model
  >[] = [];
  groupQuestion.groupElementResults.map((groupElementResult) => {
    if (
      groupElementResult.questionViewType instanceof
      GroupElementResultV1Enums.QuestionViewType.SingleSelect
    ) {
      groupSurveyQuestions.push(
        createRegularSingleSelectQuestionModelV1(
          new QuestionViewTypeV1.SingleSelect(
            groupElementResult.questionViewType.sampleBreakdown,
            groupElementResult.questionViewType.singleSelectResults,
            groupElementResult.questionViewType.singleSelectGraphType,
          ),
          // Since, groupElementResult is a child of the group question, it does not have its own surveyQuestionViewId. Hence it is being set to EMPTY_CHARACTER.
          EMPTY_CHARACTER,
          groupElementResult.questionCode.code,
          groupElementResult.question?.text,
        ),
      );
    } else if (
      groupElementResult.questionViewType instanceof
      GroupElementResultV1Enums.QuestionViewType.SingleSelectNps
    ) {
      groupSurveyQuestions.push(
        createNPSSingleSelectQuestionModelV1(
          new QuestionViewTypeV1.SingleSelectNps(
            groupElementResult.questionViewType.sampleBreakdown,
            groupElementResult.questionViewType.promoters,
            groupElementResult.questionViewType.passives,
            groupElementResult.questionViewType.detractors,
            groupElementResult.questionViewType.singleSelectNpsResultsBreakdownV1,
            groupElementResult.questionViewType.singleSelectNpsGraphType,
          ),
          // Since, groupElementResult is a child of the group question, it does not have its own surveyQuestionViewId. Hence it is being set to EMPTY_CHARACTER.
          EMPTY_CHARACTER,
          groupElementResult.questionCode.code,
          groupElementResult.question?.text,
        ),
      );
    } else if (
      groupElementResult.questionViewType instanceof
      GroupElementResultV1Enums.QuestionViewType.SingleSelectWithProportion
    ) {
      groupSurveyQuestions.push(
        createSingleSelectQuestionWithProportionModelV1(
          new QuestionViewTypeV1.SingleSelectWithProportion(
            groupElementResult.questionViewType.sampleBreakdown,
            groupElementResult.questionViewType.singleSelectResults,
            groupElementResult.questionViewType.proportionChartType,
            groupElementResult.questionViewType.singleSelectWithProportionGraphType,
            groupElementResult.questionViewType.naOptionCode,
          ),
          // Since, groupElementResult is a child of the group question, it does not have its own surveyQuestionViewId. Hence it is being set to EMPTY_CHARACTER.
          EMPTY_CHARACTER,
          groupElementResult.questionCode.code,
          groupElementResult.question?.text,
        ),
      );
    } else if (
      groupElementResult.questionViewType instanceof
      GroupElementResultV1Enums.QuestionViewType.OpenEnded
    ) {
      groupSurveyQuestions.push(
        createOpenEndedQuestionViewModelV1(
          new QuestionViewTypeV1.OpenEnded(
            groupElementResult.questionViewType.sampleBreakdown,
            groupElementResult.questionViewType.openEndedResults,
            groupElementResult.questionViewType.openEndedGraphType,
          ),
          // Since, groupElementResult is a child of the group question, it does not have its own surveyQuestionViewId. Hence it is being set to EMPTY_CHARACTER.
          EMPTY_CHARACTER,
          groupElementResult.questionCode.code,
          groupElementResult.question?.text,
        ),
      );
    } else if (
      groupElementResult.questionViewType instanceof
      GroupElementResultV1Enums.QuestionViewType.Ranking
    ) {
      groupSurveyQuestions.push(
        createRankingQuestionViewModelV1(
          new QuestionViewTypeV1.Ranking(
            groupElementResult.questionViewType.sampleBreakdown,
            groupElementResult.questionViewType.rankingResults,
            groupElementResult.questionViewType.rankingGraphType,
          ),
          // Since, groupElementResult is a child of the group question, it does not have its own surveyQuestionViewId. Hence it is being set to EMPTY_CHARACTER.
          EMPTY_CHARACTER,
          groupElementResult.questionCode.code,
          groupElementResult.question?.text,
        ),
      );
    } else if (
      groupElementResult.questionViewType instanceof
      GroupElementResultV1Enums.QuestionViewType.MultiSelect
    ) {
      groupSurveyQuestions.push(
        createMultiSelectQuestionViewModelV1(
          new QuestionViewTypeV1.MultiSelect(
            groupElementResult.questionViewType.sampleBreakdown,
            groupElementResult.questionViewType.multiSelectResults,
            groupElementResult.questionViewType.multiSelectGraphType,
          ),
          // Since, groupElementResult is a child of the group question, it does not have its own surveyQuestionViewId. Hence it is being set to EMPTY_CHARACTER.
          EMPTY_CHARACTER,
          groupElementResult.questionCode.code,
          groupElementResult.question?.text,
        ),
      );
    } else if (
      groupElementResult.questionViewType instanceof
      GroupElementResultV1Enums.QuestionViewType.Grid
    ) {
      groupSurveyQuestions.push(
        createGridQuestionViewV1Model(
          new QuestionViewTypeV1.Grid(
            groupElementResult.questionViewType.sampleBreakdown,
            groupElementResult.questionViewType.gridResults,
          ),
          // Since, groupElementResult is a child of the group question, it does not have its own surveyQuestionViewId. Hence it is being set to EMPTY_CHARACTER.
          EMPTY_CHARACTER,
          groupElementResult.questionCode.code,
          groupElementResult.question?.text,
        ),
      );
    }
  });
  return groupSurveyQuestions;
};

export const createGroupQuestionViewModelV1 = (
  groupQuestion: QuestionViewTypeV1.Group,
  surveyQuestionViewId: string,
  question: string | null = null,
): Instance<typeof GroupQuestionViewModelV1> => {
  return GroupQuestionViewModelV1.create({
    childSurveyQuestionViews: getGroupQuestionViewModelV1(groupQuestion),
    surveyQuestionViewId,
    question,
  });
};
