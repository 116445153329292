import { Instance } from "mobx-state-tree";
import React from "react";
import { SingleSelectResponseV1Model } from "../../models/RespondentSubmittedResponseModels/SingleSelectResponseV1Model";
import { Stack } from "@mui/material";
import { S1TypographyLabel } from "./S1TypographyLabel";
import { TFunction } from "i18next";
import {
  FoundationColorTokens,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { B1TypographyLabel } from "./B1TypographyLabel";
import { observer } from "mobx-react";

interface SingleSelectResponseProps {
  question: string | null;
  singleSelectResponse: Instance<typeof SingleSelectResponseV1Model>;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
}

export const SingleSelectResponse = observer(
  ({
    question,
    singleSelectResponse,
    t,
    tokens,
    typography,
  }: SingleSelectResponseProps): React.ReactElement => {
    return (
      <Stack>
        {question && (
          <S1TypographyLabel
            text={t("projects.respondentResponseDialog.questionPrefix", {
              question,
            })}
            tokens={tokens}
            typography={typography}
          />
        )}
        <B1TypographyLabel
          text={t("projects.respondentResponseDialog.answerPrefix", {
            response: singleSelectResponse.singleSelectAnswer,
          })}
          tokens={tokens}
          typography={typography}
        />
      </Stack>
    );
  },
);
