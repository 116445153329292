import {
  GroupChildGraphTypeV1,
  ProportionChartType,
  QuestionViewGraphTypeV1,
  QuestionViewTypeV1,
  SingleSelectWithProportionGraphTypeV1,
} from "@pulse/pulse-rpcs";
import { Instance, types } from "mobx-state-tree";
import {
  createSampleBreakdownModel,
  SampleBreakdownModel,
} from "./SampleBreakdownModel";
import {
  createSingleSelectResultsModelV1,
  SingleSelectResultsModelV1,
} from "./SingleSelectResultsModelV1";
import { QuestionTypeGraphs } from "../components/ChartSelection";

export const SingleSelectQuestionWithProportionModelV1 = types
  .model("SingleSelectQuestionWithProportionModelV1", {
    question: types.maybeNull(types.string),
    sampleBreakdown: SampleBreakdownModel,
    singleSelectResults: types.array(SingleSelectResultsModelV1),
    singleSelectGraphType: types.enumeration(
      "SingleSelectWithProportionGraphTypeV1",
      Object.values(
        SingleSelectWithProportionGraphTypeV1.SingleSelectWithProportionGraphTypeV1,
      ),
    ),
    updatedSingleSelectGraphType: types.enumeration(
      "SingleSelectWithProportionGraphTypeV1",
      Object.values(
        SingleSelectWithProportionGraphTypeV1.SingleSelectWithProportionGraphTypeV1,
      ),
    ),
    mean: types.maybe(types.number),
    naOptionCode: types.maybeNull(types.string),
    surveyQuestionViewId: types.string,
    groupChildQuestionCode: types.string,
    singleSelectPrimitiveQuartileColor: types.maybeNull(types.string),
  })
  .views((store) => ({
    get isGraphSelectionUpdated(): boolean {
      return store.updatedSingleSelectGraphType !== store.singleSelectGraphType;
    },
    get questionViewGraphType(): QuestionViewGraphTypeV1.SingleSelectWithProportion {
      return new QuestionViewGraphTypeV1.SingleSelectWithProportion(
        store.updatedSingleSelectGraphType,
      );
    },
    get groupQuestionViewGraphtype(): GroupChildGraphTypeV1.GroupChildGraphTypeV1 {
      return new GroupChildGraphTypeV1.SingleSelectWithProportion(
        store.updatedSingleSelectGraphType,
      );
    },
  }))
  .actions((store) => ({
    updateSelectedGraphType: (
      updatedSingleSelectGraphType: QuestionTypeGraphs,
    ): void => {
      store.updatedSingleSelectGraphType =
        SingleSelectWithProportionGraphTypeV1.SingleSelectWithProportionGraphTypeV1[
          updatedSingleSelectGraphType as keyof typeof SingleSelectWithProportionGraphTypeV1.SingleSelectWithProportionGraphTypeV1
        ];
    },
    updateSelectedGraphTypeWithUpdatedGraph: (): void => {
      store.singleSelectGraphType = store.updatedSingleSelectGraphType;
    },
  }));

export const createSingleSelectQuestionWithProportionModelV1 = (
  singleSelectWithProportion: QuestionViewTypeV1.SingleSelectWithProportion,
  surveyQuestionViewId: string,
  groupChildQuestionCode: string,
  question: string | null = null,
): Instance<typeof SingleSelectQuestionWithProportionModelV1> => {
  let singleSelectPrimitiveQuartileColor: string | undefined;
  let mean: number | undefined;
  if (
    singleSelectWithProportion.proportionChartType instanceof
    ProportionChartType.Ryg
  ) {
    mean = singleSelectWithProportion.proportionChartType.mean;
    singleSelectPrimitiveQuartileColor =
      singleSelectWithProportion.proportionChartType.quartileColor
        ?.defaultSingleSelectQuartileColor?.code ??
      singleSelectWithProportion.proportionChartType.quartileColor
        ?.primitiveQuartileColor?.code;
  }

  return SingleSelectQuestionWithProportionModelV1.create({
    sampleBreakdown: createSampleBreakdownModel(
      singleSelectWithProportion.sampleBreakdown,
    ),
    singleSelectGraphType:
      singleSelectWithProportion.singleSelectWithProportionGraphType,
    updatedSingleSelectGraphType:
      singleSelectWithProportion.singleSelectWithProportionGraphType,
    singleSelectResults: createSingleSelectResultsModelV1(
      singleSelectWithProportion.singleSelectResults,
    ),
    mean,
    question,
    naOptionCode: singleSelectWithProportion.naOptionCode?.code,
    surveyQuestionViewId,
    groupChildQuestionCode,
    singleSelectPrimitiveQuartileColor,
  });
};
