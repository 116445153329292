import {
  FileAttributes,
  GetPresignedDownloadURLForQuestionOptionsTemplateRPC,
  GetPresignedUploadURLForQuestionOptionsRPC,
  SingleChoiceOption,
  ValidateCSVAndUpdateQuestionOptionsRPC,
} from "@pulse/pulse-rpcs";
import { flow, getRoot, Instance, types } from "mobx-state-tree";
import {
  FileUploadError,
  getFileSHA,
  uploadFileToS3,
} from "../../utils/FileUploadUtils";
import { getAPIClient } from "../../networking/APIClient";
import {
  useGetPresignedDownloadURLForQuestionOptionsTemplateRPCClient,
  useGetPresignedUploadURLForQuestionOptionsRPCClientImpl,
  useValidateCSVAndUpdateQuestionOptionsRPCClient,
} from "../rpcs/RPC";
import {
  downloadFile,
  insertDocumentRecord,
} from "../../utils/FileDownloadUtils";
import {
  CommonErrors,
  CSV_FILE_TYPE,
  EMPTY_CHARACTER,
  MAX_FILE_NAME,
  MAX_SINGLE_SELECT_DROPDOWN_OPTIONS_FILE_SIZE_IN_BYTES,
  MIN_FILE_NAME,
  MIN_SINGLE_SELECT_DROPDOWN_OPTIONS_FILE_SIZE_IN_BYTES,
  NetworkingError,
  QUESTION_OPTION_TEMPLATE_CSV_FILE_NAME,
  SHA_256_LENGTH,
} from "@pulse/shared-components";
import { RootStore } from "../../root/store/RootStore";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import Papa, { ParseResult } from "papaparse";

export enum SingleSelectDropdownOptionsUploadState {
  SingleSelectDropdownOptionsUpload = "SINGLE_SELECT_DROPDOWN_OPTIONS_UPLOAD",
  FileUploaded = "FILE_UPLOADED",
  Error = "ERROR",
  ValidationErrorFound = "VALIDATION_ERROR_FOUND",
}

export const SingleSelectDropdownOptionsUploadStore = types
  .model("SingleSelectDropdownOptionsUploadStore", {
    singleSelectDropdownOptionsUploadState: types.optional(
      types.enumeration(Object.values(SingleSelectDropdownOptionsUploadState)),
      SingleSelectDropdownOptionsUploadState.SingleSelectDropdownOptionsUpload,
    ),
    rpcError: types.maybeNull(
      types.union(
        types.enumeration(
          Object.values(GetPresignedUploadURLForQuestionOptionsRPC.RPCError),
        ),
        types.enumeration(
          Object.values(ValidateCSVAndUpdateQuestionOptionsRPC.RPCError),
        ),
        types.enumeration(Object.values(NetworkingError)),
      ),
    ),
    isRPCLoading: types.optional(types.boolean, false),
    validationError: types.maybeNull(
      types.enumeration(Object.values(FileUploadError)),
    ),
    isDownloadTemplateButtonLoading: types.optional(types.boolean, false),
    fileName: types.optional(types.string, EMPTY_CHARACTER),
    documentId: types.maybeNull(types.string),
    invalidFileDetails: types.maybeNull(types.string),
  })
  .views((store) => ({
    get doesStoreContainValidationError(): boolean {
      return (
        store.validationError !== null ||
        store.rpcError ===
          ValidateCSVAndUpdateQuestionOptionsRPC.RPCError.InvalidFile
      );
    },
  }))
  .actions((store) => ({
    resetStore: (): void => {
      store.documentId = null;
      store.fileName = EMPTY_CHARACTER;
      store.invalidFileDetails = null;
      store.rpcError = null;
      store.validationError = null;
      store.singleSelectDropdownOptionsUploadState =
        SingleSelectDropdownOptionsUploadState.SingleSelectDropdownOptionsUpload;
      store.isRPCLoading = false;
      store.isDownloadTemplateButtonLoading = false;
    },
    setIsRPCLoading: (isRPCLoading: boolean): void => {
      store.isRPCLoading = isRPCLoading;
    },
    getFileAttribute: flow(function* (file: File) {
      if (!CSV_FILE_TYPE.includes(file.type)) {
        store.singleSelectDropdownOptionsUploadState =
          SingleSelectDropdownOptionsUploadState.ValidationErrorFound;
        store.validationError = FileUploadError.InvalidFileFormat;
        return;
      }
      const sha256: string = yield getFileSHA(file);

      const csvText: string = yield new Response(file).text();

      const parsedData: ParseResult<{ [key: string]: string }> = Papa.parse(
        csvText,
        { header: true },
      );

      const csvBaseColumns = parsedData.meta.fields?.map((baseColumn) =>
        baseColumn.toLocaleLowerCase(),
      );
      if (csvBaseColumns === undefined) {
        store.singleSelectDropdownOptionsUploadState =
          SingleSelectDropdownOptionsUploadState.ValidationErrorFound;
        store.validationError = FileUploadError.InternalError;
        return;
      }

      if (csvBaseColumns.length > 2) {
        store.singleSelectDropdownOptionsUploadState =
          SingleSelectDropdownOptionsUploadState.ValidationErrorFound;
        store.validationError = FileUploadError.MaxColumnsExceeded;
        return;
      }

      try {
        if (sha256.length !== SHA_256_LENGTH) {
          store.singleSelectDropdownOptionsUploadState =
            SingleSelectDropdownOptionsUploadState.ValidationErrorFound;
          store.validationError = FileUploadError.InvalidFileSHA;
          return;
        } else if (
          file.name.length < MIN_FILE_NAME ||
          file.name.length > MAX_FILE_NAME
        ) {
          store.singleSelectDropdownOptionsUploadState =
            SingleSelectDropdownOptionsUploadState.ValidationErrorFound;
          store.validationError = FileUploadError.InvalidFileName;
          return;
        } else if (
          file.size > MAX_SINGLE_SELECT_DROPDOWN_OPTIONS_FILE_SIZE_IN_BYTES
        ) {
          store.singleSelectDropdownOptionsUploadState =
            SingleSelectDropdownOptionsUploadState.ValidationErrorFound;
          store.validationError = FileUploadError.MaxFileSizeReached;
          return;
        } else if (
          file.size < MIN_SINGLE_SELECT_DROPDOWN_OPTIONS_FILE_SIZE_IN_BYTES
        ) {
          store.singleSelectDropdownOptionsUploadState =
            SingleSelectDropdownOptionsUploadState.ValidationErrorFound;
          store.validationError = FileUploadError.MinFileSizeNotReached;
          return;
        }

        const fileAttribute = new FileAttributes(sha256, file.name, file.size);
        return fileAttribute;
      } catch (error) {
        console.error(error);
        store.singleSelectDropdownOptionsUploadState =
          SingleSelectDropdownOptionsUploadState.Error;
        store.validationError = FileUploadError.InternalError;
      }
    }),
  }))
  .actions((store) => ({
    setFileName: (fileName: string): void => {
      store.fileName = fileName;
    },
    resetRPCError: (): void => {
      store.rpcError = null;
      store.validationError = null;
      store.invalidFileDetails = null;
    },
    uploadCSVFile: flow(function* (
      file: File,
      surveyId: string,
      projectId: string,
      questionId: string | null,
    ) {
      const errorStore =
        getRoot<typeof RootStore>(store).networkingStore.errorStore;
      try {
        const apiClient = getAPIClient(store);

        const fileAttribute: FileAttributes | undefined =
          yield store.getFileAttribute(file);

        if (fileAttribute === undefined) {
          return;
        }
        const request = new GetPresignedUploadURLForQuestionOptionsRPC.Request(
          fileAttribute,
          new LeoUUID(surveyId),
          new LeoUUID(projectId),
          new LeoUUID(questionId),
        );

        const {
          response,
          error,
        }: {
          response?: GetPresignedUploadURLForQuestionOptionsRPC.Response;
          error?: GetPresignedUploadURLForQuestionOptionsRPC.Errors.Errors;
        } =
          yield useGetPresignedUploadURLForQuestionOptionsRPCClientImpl(
            apiClient,
          ).execute(request);
        if (response) {
          try {
            yield uploadFileToS3(response.documentURL, file);
            store.documentId = yield insertDocumentRecord(
              fileAttribute,
              apiClient,
            );
          } catch (e) {
            store.singleSelectDropdownOptionsUploadState =
              SingleSelectDropdownOptionsUploadState.Error;
            store.validationError = FileUploadError.InternalError;
          }
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              break;
            case GetPresignedUploadURLForQuestionOptionsRPC.RPCError
              .ProjectAlreadyArchived: {
              store.rpcError =
                GetPresignedUploadURLForQuestionOptionsRPC.RPCError.ProjectAlreadyArchived;
              break;
            }
            case GetPresignedUploadURLForQuestionOptionsRPC.RPCError
              .IncorrectQuestionType: {
              store.rpcError =
                GetPresignedUploadURLForQuestionOptionsRPC.RPCError.IncorrectQuestionType;
              break;
            }
            case GetPresignedUploadURLForQuestionOptionsRPC.RPCError
              .InvalidQuestionId: {
              store.rpcError =
                GetPresignedUploadURLForQuestionOptionsRPC.RPCError.InvalidQuestionId;
              break;
            }
            case GetPresignedUploadURLForQuestionOptionsRPC.RPCError
              .QuestionWasDeleted: {
              store.rpcError =
                GetPresignedUploadURLForQuestionOptionsRPC.RPCError.QuestionWasDeleted;
              break;
            }
            case GetPresignedUploadURLForQuestionOptionsRPC.RPCError
              .SurveyIsClosed: {
              store.rpcError =
                GetPresignedUploadURLForQuestionOptionsRPC.RPCError.SurveyIsClosed;
              break;
            }
            case GetPresignedUploadURLForQuestionOptionsRPC.RPCError
              .SurveyIsLive:
              {
                store.rpcError =
                  GetPresignedUploadURLForQuestionOptionsRPC.RPCError.SurveyIsLive;
              }
              break;
          }
          store.singleSelectDropdownOptionsUploadState =
            SingleSelectDropdownOptionsUploadState.Error;
        }
      } catch (e) {
        store.singleSelectDropdownOptionsUploadState =
          SingleSelectDropdownOptionsUploadState.Error;
        if (e instanceof Error) {
          errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      }
    }),
    downloadSingleSelectOptionsTemplate: flow(function* () {
      try {
        store.isDownloadTemplateButtonLoading = true;
        const apiClient = getAPIClient(store);
        const request =
          new GetPresignedDownloadURLForQuestionOptionsTemplateRPC.Request();
        const {
          response,
          error,
        }: {
          response?: GetPresignedDownloadURLForQuestionOptionsTemplateRPC.Response;
          error?: GetPresignedDownloadURLForQuestionOptionsTemplateRPC.Errors.Errors;
        } =
          yield useGetPresignedDownloadURLForQuestionOptionsTemplateRPCClient(
            apiClient,
          ).execute(request);
        if (response) {
          yield downloadFile(
            response.documentURL.href,
            QUESTION_OPTION_TEMPLATE_CSV_FILE_NAME,
          );
        }
        if (error) {
          store.rpcError = NetworkingError.InternalError;
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isDownloadTemplateButtonLoading = false;
      }
    }),
    validateCSVAndUpdateQuestionOptions: flow(function* (
      projectId: string,
      surveyId: string,
      questionId: string | null,
      updateOptions: (options: SingleChoiceOption[]) => void,
    ) {
      if (
        store.rpcError !== null ||
        store.validationError !== null ||
        store.invalidFileDetails !== null
      ) {
        return;
      }
      const errorStore =
        getRoot<typeof RootStore>(store).networkingStore.errorStore;
      try {
        const apiClient = getAPIClient(store);
        const request = new ValidateCSVAndUpdateQuestionOptionsRPC.Request(
          new LeoUUID(projectId),
          new LeoUUID(surveyId),
          new LeoUUID(questionId),
          new LeoUUID(store.documentId),
        );
        const {
          response,
          error,
        }: {
          response?: ValidateCSVAndUpdateQuestionOptionsRPC.Response;
          error?: ValidateCSVAndUpdateQuestionOptionsRPC.Errors.Errors;
        } =
          yield useValidateCSVAndUpdateQuestionOptionsRPCClient(
            apiClient,
          ).execute(request);
        if (response) {
          updateOptions(response.singleChoiceOptions);
          store.singleSelectDropdownOptionsUploadState =
            SingleSelectDropdownOptionsUploadState.FileUploaded;
        }
        if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              break;
            case ValidateCSVAndUpdateQuestionOptionsRPC.RPCError
              .InvalidQuestionId: {
              store.singleSelectDropdownOptionsUploadState =
                SingleSelectDropdownOptionsUploadState.Error;
              break;
            }
            case ValidateCSVAndUpdateQuestionOptionsRPC.RPCError
              .IncorrectQuestionType: {
              store.rpcError =
                ValidateCSVAndUpdateQuestionOptionsRPC.RPCError.IncorrectQuestionType;
              store.singleSelectDropdownOptionsUploadState =
                SingleSelectDropdownOptionsUploadState.Error;
              break;
            }
            case ValidateCSVAndUpdateQuestionOptionsRPC.RPCError
              .ProjectAlreadyArchived: {
              store.rpcError =
                ValidateCSVAndUpdateQuestionOptionsRPC.RPCError.ProjectAlreadyArchived;
              store.singleSelectDropdownOptionsUploadState =
                SingleSelectDropdownOptionsUploadState.Error;
              break;
            }
            case ValidateCSVAndUpdateQuestionOptionsRPC.RPCError.InvalidFile: {
              store.rpcError =
                ValidateCSVAndUpdateQuestionOptionsRPC.RPCError.InvalidFile;
              if (
                error instanceof
                ValidateCSVAndUpdateQuestionOptionsRPC.Errors.InvalidFile
              ) {
                store.invalidFileDetails = error.invalidFileDetails;
              }
              break;
            }
            case ValidateCSVAndUpdateQuestionOptionsRPC.RPCError
              .QuestionWasDeleted: {
              store.rpcError =
                ValidateCSVAndUpdateQuestionOptionsRPC.RPCError.QuestionWasDeleted;
              store.singleSelectDropdownOptionsUploadState =
                SingleSelectDropdownOptionsUploadState.Error;
              break;
            }
            case ValidateCSVAndUpdateQuestionOptionsRPC.RPCError
              .SurveyIsClosed: {
              store.rpcError =
                ValidateCSVAndUpdateQuestionOptionsRPC.RPCError.SurveyIsClosed;
              store.singleSelectDropdownOptionsUploadState =
                SingleSelectDropdownOptionsUploadState.Error;
              break;
            }
            case ValidateCSVAndUpdateQuestionOptionsRPC.RPCError.SurveyIsLive: {
              store.rpcError =
                ValidateCSVAndUpdateQuestionOptionsRPC.RPCError.SurveyIsLive;
              store.singleSelectDropdownOptionsUploadState =
                SingleSelectDropdownOptionsUploadState.Error;
              break;
            }
            case ValidateCSVAndUpdateQuestionOptionsRPC.RPCError.UnknownFile: {
              store.rpcError =
                ValidateCSVAndUpdateQuestionOptionsRPC.RPCError.UnknownFile;
              store.singleSelectDropdownOptionsUploadState =
                SingleSelectDropdownOptionsUploadState.Error;
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      }
    }),
  }));

export const createSingleSelectDropdownOptionsUploadStore = (): Instance<
  typeof SingleSelectDropdownOptionsUploadStore
> => {
  return SingleSelectDropdownOptionsUploadStore.create({});
};
