import React, { ReactElement } from "react";
import { Instance } from "mobx-state-tree";
import {
  CornerRadius,
  FoundationColorTokens,
  Shadow,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { BorderStyle } from "@pulse/shared-components";
import { SurveyQuestionViewStoreNames } from "../stores/SurveyViewDetailsStore";
import { QuestionView } from "./QuestionView";
import { QuestionTypeGraphs } from "./ChartSelection";
import { observer } from "mobx-react";
import { MultiSelectQuestionViewModelV1 } from "../models/MultiSelectQuestionViewModelV1";
import { getButtonProps, UpdateQuestionViewParams } from "./QuestionViews";
import { SignInStore } from "../../auth/store/SignInStore";

interface MultiSelectSelectQuestionViewProps {
  surveyQuestionView: Instance<typeof MultiSelectQuestionViewModelV1>;
  typography: Typography;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
  border: BorderStyle;
  cornerRadius: CornerRadius;
  boxShadow: Shadow;
  updateQuestionViewParams?: UpdateQuestionViewParams;
  isSurveyViewOptionDisplayTextSelected: boolean;
  allowSPoCToSelectGraphType: boolean;
  signInStore: Instance<typeof SignInStore>;
}

export const MultiSelectQuestionView = observer(
  ({
    surveyQuestionView,
    spacing,
    t,
    tokens,
    typography,
    border,
    cornerRadius,
    boxShadow,
    updateQuestionViewParams,
    isSurveyViewOptionDisplayTextSelected,
    allowSPoCToSelectGraphType,
    signInStore,
  }: MultiSelectSelectQuestionViewProps): ReactElement => {
    return (
      <QuestionView
        t={t}
        spacing={spacing}
        typography={typography}
        tokens={tokens}
        cornerRadius={cornerRadius}
        border={border}
        questionText={surveyQuestionView.question}
        sampleBreakdown={surveyQuestionView.sampleBreakdown}
        total={surveyQuestionView.sampleBreakdown.total}
        selectedGraph={
          QuestionTypeGraphs[surveyQuestionView.updatedMultiSelectGraphType]
        }
        surveyQuestionViewType={
          SurveyQuestionViewStoreNames.MULTI_SELECT_QUESTION_MODEL
        }
        updateSelectedGraphType={surveyQuestionView.updateSelectedGraphType}
        data={surveyQuestionView.multiSelectResults.map((multiSelectResult) => {
          return {
            option: isSurveyViewOptionDisplayTextSelected
              ? multiSelectResult.answer
              : multiSelectResult.code,
            count: multiSelectResult.count,
            percentage: multiSelectResult.percentage,
            id: isSurveyViewOptionDisplayTextSelected
              ? multiSelectResult.answer
              : multiSelectResult.code,
            isSurveyViewOptionDisplayTextSelected,
          };
        })}
        buttonProps={getButtonProps(
          updateQuestionViewParams,
          surveyQuestionView.surveyQuestionViewId,
          surveyQuestionView.questionViewGraphType,
          surveyQuestionView.isGraphSelectionUpdated,
          surveyQuestionView.updateSelectedGraphTypeWithUpdatedGraph,
        )}
        boxShadow={boxShadow}
        isChildQuestion={updateQuestionViewParams === undefined}
        isSurveyViewOptionDisplayTextSelected={
          isSurveyViewOptionDisplayTextSelected
        }
        allowSPoCToSelectGraphType={allowSPoCToSelectGraphType}
        signInStore={signInStore}
      />
    );
  },
);
