import {
  Button,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import React, { useState } from "react";
import { NavigateToFunctions } from "../../../../routes/RoutesHelper";
import { SingleSelectDropdownOptionsUploadStore } from "../../store/SingleSelectDropdownOptionsUploadStore";
import { Instance } from "mobx-state-tree";
import { UploadSingleSelectDropdownOptionsDialog } from "./UploadSingleSelectDropdownOptionsDialog";
import { observer } from "mobx-react";
import { SingleChoiceOption } from "@pulse/pulse-rpcs";

interface UploadDropdownOptionsCSVProps {
  t: TFunction;
  navigateTo: NavigateToFunctions;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  singleSelectDropdownOptionsUploadStore: Instance<
    typeof SingleSelectDropdownOptionsUploadStore
  >;
  questionId: string | null;
  updateOptions: (options: SingleChoiceOption[]) => void;
  isUploadCSVButtonDisabled: boolean;
}

export const UploadDropdownOptionsCSV = observer(
  ({
    t,
    navigateTo,
    spacing,
    tokens,
    typography,
    singleSelectDropdownOptionsUploadStore,
    questionId,
    updateOptions,
    isUploadCSVButtonDisabled,
  }: UploadDropdownOptionsCSVProps): React.ReactElement => {
    const [
      isUploadDropdownOptionsCSVDialogVisible,
      setIsUploadDropdownOptionsCSVDialogVisible,
    ] = useState(false);

    return (
      <>
        {isUploadDropdownOptionsCSVDialogVisible && (
          <UploadSingleSelectDropdownOptionsDialog
            isUploadSingleSelectDropdownOptionsDialogOpen={
              isUploadDropdownOptionsCSVDialogVisible
            }
            setIsUploadDropdownOptionsCSVDialogVisible={
              setIsUploadDropdownOptionsCSVDialogVisible
            }
            t={t}
            singleSelectDropdownOptionsUploadStore={
              singleSelectDropdownOptionsUploadStore
            }
            spacing={spacing}
            tokens={tokens}
            typography={typography}
            navigateTo={navigateTo}
            questionId={questionId}
            updateOptions={updateOptions}
          />
        )}
        <Button
          label={t("common.uploadCSV")}
          onClick={(): void => {
            setIsUploadDropdownOptionsCSVDialogVisible(true);
          }}
          name={t("common.uploadCSV")}
          size="small"
          variant="outlined-neutral"
          disabled={questionId === null || isUploadCSVButtonDisabled}
        />
      </>
    );
  },
);
