import { Pie } from "@nivo/pie";
import React, { ReactElement } from "react";
import {
  DASHBOARD_CHART_COLORS,
  QUESTION_VIEW_MAX_WIDTH_IN_PIXELS,
} from "../../../utils/constants";
import {
  BorderStyle,
  EMPTY_CHARACTER,
  ZERO_VALUE,
} from "@pulse/shared-components";
import { GraphData } from "../SelectedGraph";
import { DataProps } from "../DataSection";
import { Legends } from "./Legends";
import { Stack } from "@mui/material";
import {
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TooltipContent } from "./TooltipContent";

interface PieGraphProps {
  data: DataProps[];
  isDonut: boolean;
  spacing: Spacing;
  cornerRadius: CornerRadius;
  tokens: FoundationColorTokens<string>;
  border: BorderStyle;
  typography: Typography;
  isSurveyViewOptionDisplayTextSelected: boolean;
}

export const PieGraph = ({
  data,
  isDonut,
  cornerRadius,
  spacing,
  tokens,
  border,
  typography,
  isSurveyViewOptionDisplayTextSelected,
}: PieGraphProps): ReactElement => {
  const graphData: GraphData[] = data.map((item) => {
    return {
      id: item.id ?? EMPTY_CHARACTER,
      label: item.option ?? EMPTY_CHARACTER,
      value: item.count,
      percentage: item.percentage,
    };
  });
  return (
    <Stack width={`${QUESTION_VIEW_MAX_WIDTH_IN_PIXELS}px`}>
      <Pie
        data={graphData}
        animate={true}
        height={240}
        width={QUESTION_VIEW_MAX_WIDTH_IN_PIXELS}
        innerRadius={isDonut ? 0.6 : ZERO_VALUE}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        margin={{
          bottom: 20,
          left: 20,
          right: 20,
          top: 0,
        }}
        colors={DASHBOARD_CHART_COLORS}
        tooltip={(tooltipData) => {
          return (
            <TooltipContent
              spacing={spacing}
              cornerRadius={cornerRadius}
              tokens={tokens}
              count={`${tooltipData.datum.value}`}
              percentage={`${tooltipData.datum.data.percentage}`}
              label={`${tooltipData.datum.label}`}
              border={border}
              typography={typography}
              isSurveyViewOptionDisplayTextSelected={
                isSurveyViewOptionDisplayTextSelected
              }
            />
          );
        }}
      />
      <Legends
        legends={graphData.map((item) => item.label)}
        spacing={spacing}
        colors={DASHBOARD_CHART_COLORS}
        cornerRadius={cornerRadius}
        tokens={tokens}
        typography={typography}
        isSurveyViewOptionDisplayTextSelected={
          isSurveyViewOptionDisplayTextSelected
        }
      />
    </Stack>
  );
};
