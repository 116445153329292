import {
  GridQuestionResponseV1,
  GridRowResponseV1,
  SubmittedResponseEnums,
} from "@pulse/pulse-rpcs";
import { Instance, types } from "mobx-state-tree";

const RowResponseV1 = types.model("RowResponseV1", {
  answer: types.string,
  rowOptionText: types.maybeNull(types.string),
});

const createRowResponseV1Model = (
  response: GridRowResponseV1,
): Instance<typeof RowResponseV1> => {
  return RowResponseV1.create({
    answer: response.answer,
    rowOptionText: response.rowOptionText?.text,
  });
};

const GridResponsesV1 = types.model("GridResponsesV1", {
  columnHeader: types.maybeNull(types.string),
  rowResponse: types.array(RowResponseV1),
});

const createGridResponsesV1Model = (
  response: GridQuestionResponseV1,
): Instance<typeof GridResponsesV1> => {
  return GridResponsesV1.create({
    columnHeader: response.columnHeader?.text,
    rowResponse: response.gridRowResponses.map((rowResponse) => {
      return createRowResponseV1Model(rowResponse);
    }),
  });
};

export const GridResponseV1Model = types.model("GridResponseV1Model", {
  gridResponses: types.array(GridResponsesV1),
});

export const createGridResponseV1Model = (
  gridResponse: SubmittedResponseEnums.Response.Version1Enums.ResponseTypeV1.Grid,
): Instance<typeof GridResponseV1Model> => {
  return GridResponseV1Model.create({
    gridResponses: gridResponse.answer.map((response) => {
      return createGridResponsesV1Model(response);
    }),
  });
};
