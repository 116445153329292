import { Button } from "@surya-digital/leo-reactjs-material-ui";
import React, { useState } from "react";
import { Download } from "lucide-react";
import DomToImage from "dom-to-image";
import { saveAs } from "file-saver";
import { screamingSnakeToPascal } from "../../projects/utils/StringUtils";
import {
  HIDDEN_ELEMENT_WHILE_DOWNLOADING_GRAPH_CLASS_NAME,
  QUESTION_VIEW_GRAPH_TYPE_SUFFIX,
} from "../../utils/constants";
import { EMPTY_CHARACTER } from "@pulse/shared-components";

export const onGraphDownloadClick = async (
  fileName: string,
  elementRef: React.RefObject<HTMLDivElement>,
): Promise<void> => {
  if (elementRef.current) {
    // Ensure the image will handle cross-origin if necessary
    const img = new Image();
    img.crossOrigin = "anonymous"; // This allows fetching resources cross-origin

    // Get elements by class name
    const hiddenElements = Array.from(
      document.getElementsByClassName(
        HIDDEN_ELEMENT_WHILE_DOWNLOADING_GRAPH_CLASS_NAME,
      ),
    );

    const filter = (node: Node): boolean => {
      return !hiddenElements.includes(node as HTMLElement);
    };

    await DomToImage.toPng(elementRef.current, { filter })
      .then(async function (dataUrl) {
        img.src = dataUrl; // Set the source of the image to the data URL
        saveAs(
          dataUrl,
          screamingSnakeToPascal(fileName) + QUESTION_VIEW_GRAPH_TYPE_SUFFIX,
        );
      })
      .catch(function (error) {
        console.error(`Error while downloading question graph ${error}`);
      });
  }
};

interface GraphDownloadButtonProps {
  elementRef: React.RefObject<HTMLDivElement>;
  fileName: string;
}

export const GraphDownloadButton = ({
  elementRef,
  fileName,
}: GraphDownloadButtonProps): React.ReactElement => {
  const [isGraphDownlaodButtonLoading, setIsGraphDownloadButtonLoading] =
    useState(false);
  return (
    <Button
      label={EMPTY_CHARACTER}
      onClick={async () => {
        setIsGraphDownloadButtonLoading(true);
        await onGraphDownloadClick(fileName, elementRef);
        setIsGraphDownloadButtonLoading(false);
      }}
      name="downloadView"
      size="small"
      variant="outlined-neutral"
      icon={<Download />}
      loading={isGraphDownlaodButtonLoading}
    />
  );
};
