import {
  Button,
  ListX,
  TableActionButtonProps,
  TableReloadHandle,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { useProjectDetailsStore, useSignInStore } from "../../store/Hooks";
import { useTranslation } from "react-i18next";
import { Instance } from "mobx-state-tree";
import { Box, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react";
import {
  IconContainer,
  NetworkingError,
  getEllipsizedTextProperties,
  useBorder,
} from "@pulse/shared-components";
import { UploadIcon } from "lucide-react";
import {
  AssignSurveyDialogState,
  RespondentStore,
} from "../store/RespondentStore";
import {
  processProjectParams,
  useRouteNavigator,
} from "../../../routes/RoutesHelper";
import { UserPrivileges } from "../../store/user/UserPrivileges";
import { RespondentFileUploadComponent } from "./RespondentFileUploadComponent";
import { ValidateRespondentColumnDialog } from "./duplicateValidation/ValidateRespondentColumnDialog";
import { ScheduleJobInProgressState } from "../../../components/ScheduleJobInProgressState";
import { RespondentTable } from "./RespondentTable";

export interface RespondentListProps {
  surveyFilterName?: string;
  respondentStore: Instance<typeof RespondentStore>;
  tableRef: React.MutableRefObject<TableReloadHandle | null>;
}

export const RespondentList = observer(
  ({
    surveyFilterName,
    respondentStore,
    tableRef,
  }: RespondentListProps): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();
    const tokens = useFoundationColorTokens();
    const navigateTo = useRouteNavigator();
    const projectDetailsStore = useProjectDetailsStore();
    const spacing = useSpacing();
    const signInStore = useSignInStore();
    const border = useBorder();
    const isSurveyFilterNameUndefined = surveyFilterName === undefined;
    const cornerRadius = useCornerRadius();

    if (respondentStore.rpcErrors) {
      switch (respondentStore.rpcErrors) {
        case NetworkingError.InternalError: {
          navigateTo.internalServerError();
          break;
        }
        case NetworkingError.PageNotFound: {
          navigateTo.pageNotFound();
          break;
        }
      }
    }

    const EmptyStateContainer = (): React.ReactElement => {
      return (
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          height={"100%"}
          gap={spacing.spaceSM}
        >
          <ValidateRespondentColumnDialog
            t={t}
            spacing={spacing}
            respondentUploadStore={respondentStore.respondentUploadStore}
            tokens={tokens}
            typography={typography}
            respondentStore={respondentStore}
          />
          <RespondentFileUploadComponent
            t={t}
            spacing={spacing}
            tokens={tokens}
            typography={typography}
            navigateTo={navigateTo}
            respondentUploadStore={respondentStore.respondentUploadStore}
            projectStore={projectDetailsStore}
          />
          {IconContainer(
            <ListX width="32px" height="32px" color={tokens.iconLowEmphasis} />,
          )}
          <Stack alignItems={"center"} justifyContent={"center"}>
            <Typography
              sx={{ ...typography.sh3, color: tokens.labelLowEmphasis }}
            >
              {isSurveyFilterNameUndefined
                ? t("projects.respondentList.noRespondentsAddedTitle")
                : t(
                    "projects.respondentList.noSurveyRespondentsAreAssignedTitle",
                  )}
            </Typography>
            {isSurveyFilterNameUndefined &&
              signInStore.isPrivilegeGranted(
                UserPrivileges.processRespondent,
              ) && (
                <Typography
                  sx={{
                    ...typography.b2,
                    ...getEllipsizedTextProperties(),
                    alignItems: "center",
                    color: tokens.labelLowEmphasis,
                  }}
                >
                  {t("projects.respondentList.noRespondentsAddedDescription")}
                </Typography>
              )}
          </Stack>
          {isSurveyFilterNameUndefined &&
            signInStore.isPrivilegeGranted(
              UserPrivileges.processRespondent,
            ) && (
              <Button
                name="uploadCSV"
                size="medium"
                variant="outlined-color"
                onClick={() => {
                  respondentStore.respondentUploadStore?.setIsRespondentUploadDialogVisible(
                    true,
                  );
                }}
                label={t("common.uploadCSV")}
                icon={<UploadIcon />}
                iconPosition="leading"
              />
            )}
        </Stack>
      );
    };

    const tableActionProps: TableActionButtonProps[] = [
      {
        name: "assignToSurvey",
        label: t("projects.assignToSurvey.assignToSurvey"),
        variant: "outlined-color",
        onClick: (): void => {
          respondentStore.setAssignRespondentDialogState(
            AssignSurveyDialogState.FetchingSurveyList,
          );
          respondentStore.respondentUIStore.setIsAssignRespondentDialogVisible(
            true,
          );
          processProjectParams(
            projectDetailsStore.getProjectSurveyNames,
            navigateTo,
          );
        },
      },
      {
        name: "assignToSpoc",
        label: t("projects.assignSpocsToRespondents.assignToSpocButtonText"),
        variant: "outlined-color",
        onClick: (): void => {
          processProjectParams(
            projectDetailsStore.getProjectSpocDetails,
            navigateTo,
          );
          respondentStore.respondentUIStore.setIsAssignSpocsDialogVisible(true);
        },
      },
      {
        name: "deleteRespondent",
        label: t("common.delete"),
        variant: "outlined-color",
        color: "destructive",
        onClick: (): void => {
          respondentStore.respondentUIStore.setIsDeleteRespondentDialogVisible(
            true,
          );
        },
      },
    ];

    if (
      !signInStore.isPrivilegeGranted(
        UserPrivileges.processRespondentSpocAction,
      ) ||
      !respondentStore.isLiveRespondentsSelected
    ) {
      // Remove the assignToSpoc button at index 1.
      tableActionProps.splice(1, 1);
    }

    return respondentStore.isRespondentUpdateStatusInProgress ? (
      <Box
        padding={spacing.spaceXL}
        width="100%"
        height="100%"
        flexBasis="440px"
        flexGrow={1}
        overflow="hidden"
      >
        <ScheduleJobInProgressState
          tokens={tokens}
          spacing={spacing}
          typography={typography}
          t={t}
          refreshButtonOnClick={async () => {
            projectDetailsStore.clearRespondentStore();
          }}
          descriptionText={t("projects.respondentList.inProgressDescription")}
        />
      </Box>
    ) : respondentStore.isPaginatedListVisible ? (
      <Stack height="100%">
        <RespondentTable
          signInStore={signInStore}
          projectDetailsStore={projectDetailsStore}
          tableRef={tableRef}
          t={t}
          spacing={spacing}
          tokens={tokens}
          typography={typography}
          surveyFilterName={surveyFilterName}
          border={border}
          navigateTo={navigateTo}
          tableActionProps={tableActionProps}
          cornerRadius={cornerRadius}
        />
      </Stack>
    ) : (
      <EmptyStateContainer />
    );
  },
);
