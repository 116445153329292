import { DuplicateValidationLoader } from "./DuplicateValidationLoader";
import {
  Dialog,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { observer } from "mobx-react";
import { DuplicateValidationErrorComponent } from "./DuplicateValidationErrorComponent";
import { UniqueColumnSelectionInputField } from "./UniqueColumnSelectionInputField";
import { ValidationResultComponent } from "./ValidationResultComponent";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import { RespondentUploadStore } from "../../store/RespondentUploadStore";
import { RespondentStore } from "../../store/RespondentStore";
import { DeveloperErrorFlow } from "@pulse/shared-components";

export interface ValidateRespondentDialogButtonProps {
  typography: Typography;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
  t: TFunction;
  respondentUploadStore: Instance<typeof RespondentUploadStore>;
}

interface ValidateRespondentColumnDialogProps {
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  spacing: Spacing;
  respondentUploadStore: Instance<typeof RespondentUploadStore> | null;
  respondentStore: Instance<typeof RespondentStore>;
}

export const ValidateRespondentColumnDialog = observer(
  ({
    t,
    tokens,
    typography,
    spacing,
    respondentUploadStore,
    respondentStore,
  }: ValidateRespondentColumnDialogProps): React.ReactElement => {
    if (respondentUploadStore === null) {
      return <DeveloperErrorFlow />;
    }

    const primaryButtonOnClick = (): (() => Promise<void>) | undefined => {
      if (respondentUploadStore.isRPCLoading) {
        return undefined;
      } else if (respondentUploadStore.doesColumnContainDuplicates) {
        return async (): Promise<void> => {
          if (respondentStore.projectId !== null) {
            await respondentUploadStore.setRespectUniqueColumn(false);
            await respondentUploadStore.validateUniqueColumnAndUpdateRespondent(
              respondentStore.projectId,
              respondentStore.isLiveRespondentsSelected,
            );
          } else {
            console.error(
              "Project id was found to be null in the respondent store.",
            );
          }
        };
      } else if (respondentUploadStore.isFileValidated) {
        return undefined;
      } else {
        return async (): Promise<void> => {
          if (respondentStore.projectId !== null) {
            await respondentUploadStore.validateUniqueColumnAndUpdateRespondent(
              respondentStore.projectId,
              respondentStore.isLiveRespondentsSelected,
            );
          } else {
            console.error(
              "Project id was found to be null in the respondent store.",
            );
          }
        };
      }
    };

    const isPrimaryButtonDisabled = (): boolean => {
      if (respondentUploadStore.isRPCLoading) {
        return true;
      } else if (
        respondentUploadStore.doesColumnContainDuplicates ||
        respondentUploadStore.isFileValidated
      ) {
        return false;
      } else {
        return respondentUploadStore.isSelectedUniqueColumnEmpty;
      }
    };

    const getPrimaryButtonText = (): string | undefined => {
      if (respondentUploadStore.isRPCLoading) {
        return undefined;
      } else if (respondentUploadStore.doesColumnContainDuplicates) {
        return t("projects.respondentList.ignoreAndProceed");
      } else if (respondentUploadStore.isFileValidated) {
        return undefined;
      } else {
        return t("projects.respondentList.validate");
      }
    };

    const getSecondaryButtonText = (): string | undefined => {
      if (respondentUploadStore.isRPCLoading) {
        return undefined;
      } else if (respondentUploadStore.doesColumnContainDuplicates) {
        return t("common.cancel");
      } else if (respondentUploadStore.isFileValidated) {
        if (respondentUploadStore.rpcErrors) {
          return t("common.close");
        } else {
          return t("common.done");
        }
      } else {
        return t("common.cancel");
      }
    };

    const getValidationDialogContent = (): React.ReactElement => {
      if (respondentUploadStore.isRPCLoading) {
        return (
          <DuplicateValidationLoader
            t={t}
            spacing={spacing}
            tokens={tokens}
            typography={typography}
            respondentUploadStore={respondentUploadStore}
          />
        );
      } else {
        if (respondentUploadStore.doesColumnContainDuplicates) {
          return (
            <DuplicateValidationErrorComponent
              typography={typography}
              tokens={tokens}
              spacing={spacing}
              t={t}
              respondentUploadStore={respondentUploadStore}
            />
          );
        } else {
          if (respondentUploadStore.isFileValidated) {
            return (
              <ValidationResultComponent
                typography={typography}
                tokens={tokens}
                spacing={spacing}
                t={t}
                respondentUploadStore={respondentUploadStore}
              />
            );
          } else {
            return respondentUploadStore ? (
              <UniqueColumnSelectionInputField
                typography={typography}
                tokens={tokens}
                spacing={spacing}
                t={t}
                respondentUploadStore={respondentUploadStore}
              />
            ) : (
              <></>
            );
          }
        }
      }
    };

    return (
      <Dialog
        open={respondentUploadStore.isDuplicateValidationDialogVisible}
        title={t("projects.respondentList.duplicateValidationTitle")}
        secondaryButtonText={getSecondaryButtonText()}
        isSecondaryButtonDisabled={respondentUploadStore.isRPCLoading}
        contentPadding={spacing.spaceLG}
        onSecondaryButtonClick={() => {
          if (!respondentUploadStore.isRPCLoading) {
            respondentUploadStore.setIsDuplicateValidationDialogVisible(false);
          }
          respondentUploadStore.resetRPCErrors();
          respondentUploadStore.resetValidationErrors();
          respondentUploadStore.resetUploadCSVStates();
        }}
        isPrimaryButtonDisabled={isPrimaryButtonDisabled()}
        onPrimaryButtonClick={primaryButtonOnClick()}
        primaryButtonText={getPrimaryButtonText()}
        width="560px"
      >
        {getValidationDialogContent()}
      </Dialog>
    );
  },
);
