import {
  CreateDashboardViewRPC,
  SurveyViewDetails,
  SurveyViewName,
} from "@pulse/pulse-rpcs";
import {
  CommonErrors,
  EMPTY_CHARACTER,
  surveyViewNameRegex,
} from "@pulse/shared-components";
import { APIClient } from "@surya-digital/tedwig";
import {
  flow,
  getParentOfType,
  getRoot,
  Instance,
  types,
} from "mobx-state-tree";
import { getAPIClient } from "../../networking/APIClient";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import { RootStore } from "../../root/store/RootStore";
import { useCreateDashboardViewRPCClientImpl } from "../rpcs/RPC";
import { ProjectDetailsStore } from "../../projects/store/ProjectDetailsStore";
import { ReportsStore } from "./ReportsStore";

export enum DashboardCustomisations {
  ShowAllQuestions = "SHOW_ALL_QUESTIONS",
  StartFromBlank = "START_FROM_BLANK",
}

export enum SPoCChartCustomisations {
  AllowSPoCToChangeGraphType = "ALLOW_SPOC_TO_CHANGE_GRAPH_TYPE",
  RestrictToSelectType = "RESTRICT_TO_SELECT_TYPE",
}

export enum AddViewDialogState {
  AddView = "ADD_VIEW",
  Error = "ERROR",
}

export enum AddViewRPCError {
  InvalidViewName = "INVALID_VIEW_NAME",
  InvalidViewNameLength = "INVALID_VIEW_NAME_LENGTH",
  SurveyViewAlreadyExists = "SURVEY_VIEW_ALREADY_EXISTS",
  ProjectAlreadyArchived = "PROJECT_ALREADY_ARCHIVED",
  NoSurveyLinkFound = "NO_SURVEY_LINK_FOUND",
  MaximumSurveyViewsReached = "MAXIMUM_SURVEY_VIEWS_REACHED",
}

export const AddViewStore = types
  .model("AddViewStore", {
    viewName: types.optional(types.string, EMPTY_CHARACTER),
    dashboardCustomisation: types.optional(
      types.enumeration(Object.values(DashboardCustomisations)),
      DashboardCustomisations.ShowAllQuestions,
    ),
    spocChartCustomisation: types.optional(
      types.enumeration(Object.values(SPoCChartCustomisations)),
      SPoCChartCustomisations.RestrictToSelectType,
    ),
    addViewDialogState: types.optional(
      types.enumeration(Object.values(AddViewDialogState)),
      AddViewDialogState.AddView,
    ),
    rpcError: types.maybeNull(
      types.enumeration("AddViewRPCError", Object.values(AddViewRPCError)),
    ),
    isRPCLoading: types.optional(types.boolean, false),
  })
  .views((store) => ({
    get doesStoreContainError(): boolean {
      return store.rpcError !== null;
    },
    get doesStoreContainViewNameError(): boolean {
      return (
        store.rpcError === AddViewRPCError.InvalidViewName ||
        store.rpcError === AddViewRPCError.InvalidViewNameLength ||
        store.rpcError === AddViewRPCError.SurveyViewAlreadyExists
      );
    },
    get isPrimaryButtonDisabled(): boolean {
      return store.viewName.trim() === EMPTY_CHARACTER;
    },
    get isAllQuestionsVisible(): boolean {
      return (
        store.dashboardCustomisation ===
        DashboardCustomisations.ShowAllQuestions
      );
    },
    get isSPoCChangeGraphAllowed(): boolean {
      return (
        store.spocChartCustomisation ===
        SPoCChartCustomisations.AllowSPoCToChangeGraphType
      );
    },
  }))
  .actions((store) => ({
    resetStoreValues: (): void => {
      store.viewName = EMPTY_CHARACTER;
      store.dashboardCustomisation = DashboardCustomisations.ShowAllQuestions;
      store.spocChartCustomisation =
        SPoCChartCustomisations.RestrictToSelectType;
      store.addViewDialogState = AddViewDialogState.AddView;
      store.rpcError = null;
    },
    validateViewName: (): boolean => {
      try {
        new SurveyViewName(store.viewName.trim());
        const regex = new RegExp(surveyViewNameRegex);
        if (!regex.test(store.viewName)) {
          store.rpcError = AddViewRPCError.InvalidViewName;
          return false;
        }
        return true;
      } catch (e) {
        store.rpcError = AddViewRPCError.InvalidViewNameLength;
        return false;
      }
    },
    setViewName: (viewName: string): void => {
      store.rpcError = null;
      store.viewName = viewName;
    },
    setDashboardCustomisation: (
      dashboardCustomisation: DashboardCustomisations,
    ): void => {
      store.dashboardCustomisation = dashboardCustomisation;
    },
    setSpoCChartCustomisation: (
      spocChartCustomisation: SPoCChartCustomisations,
    ): void => {
      store.spocChartCustomisation = spocChartCustomisation;
    },
    addView: flow(function* (surveyId: string, projectId: string) {
      store.isRPCLoading = true;
      store.rpcError = null;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new CreateDashboardViewRPC.Request(
          new LeoUUID(surveyId),
          new LeoUUID(projectId),
          store.isAllQuestionsVisible,
          new SurveyViewDetails(
            new SurveyViewName(store.viewName),
            store.isSPoCChangeGraphAllowed,
          ),
        );
        const {
          response,
          error,
        }: {
          response?: CreateDashboardViewRPC.Response;
          error?: CreateDashboardViewRPC.Errors.Errors;
        } =
          yield useCreateDashboardViewRPCClientImpl(apiClient).execute(request);
        if (response) {
          const projectDetailsStore = getParentOfType(
            store,
            ProjectDetailsStore,
          );
          projectDetailsStore.surveyStore.setSurveyName(
            response.surveyDetailsAndDashboardViews.surveyStatusAndName
              .surveyName.name,
          );
          projectDetailsStore.surveyStore.setSurveyStatus(
            response.surveyDetailsAndDashboardViews.surveyStatusAndName
              .surveyStatus,
          );
          getParentOfType(store, ReportsStore).setSurveyDashboardViews(
            response.surveyDetailsAndDashboardViews.surveyDashboardViews,
          );
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              break;
            case AddViewRPCError.InvalidViewName:
              store.rpcError = AddViewRPCError.InvalidViewName;
              // We are returning here and not setting addViewDialogState to AddViewDialogState.Error.
              // This is done because this error is handled through helper text.
              // Setting addViewDialogState to AddViewDialogState.Error will render the error dialog.
              return;
            case AddViewRPCError.MaximumSurveyViewsReached:
              store.rpcError = AddViewRPCError.MaximumSurveyViewsReached;
              break;
            case AddViewRPCError.ProjectAlreadyArchived:
              store.rpcError = AddViewRPCError.ProjectAlreadyArchived;
              break;
            case AddViewRPCError.SurveyViewAlreadyExists:
              store.rpcError = AddViewRPCError.SurveyViewAlreadyExists;
              // We are returning here and not setting addViewDialogState to AddViewDialogState.Error.
              // This is done because this error is handled through helper text.
              // Setting addViewDialogState to AddViewDialogState.Error will render the error dialog.
              return;
            case AddViewRPCError.NoSurveyLinkFound:
              store.rpcError = AddViewRPCError.NoSurveyLinkFound;
              break;
            default:
              console.error(
                `Unhandled error ${error.code} from CreateDashboardViewRPC`,
              );
              break;
          }
          store.addViewDialogState = AddViewDialogState.Error;
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(
            `Unhandled error ${e} in createView action in AddViewStore`,
          );
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
  }));

export const createAddViewStore = (): Instance<typeof AddViewStore> => {
  return AddViewStore.create();
};
