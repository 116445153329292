import { Stack, Popover } from "@mui/material";
import {
  BorderStyle,
  MAX_APPLIED_FILTER_COUNT,
  ConfirmationDialogComponent,
  getIconProps,
  ICON_SIZE,
} from "@pulse/shared-components";
import {
  FoundationColorTokens,
  Typography as LeoTypography,
  Shadow,
  Spacing,
  Button,
  CornerRadius,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { HelpCircle } from "lucide-react";
import { Instance } from "mobx-state-tree";
import { AddFilterButton } from "../../../components/filter/components/AddFilterButton";
import { FilterInputComponent } from "../../../components/filter/components/FilterInputComponent";
import { FilterStore } from "../../../components/filter/store/FilterStore";
import { DividerComponent } from "../../surveys/components/commonQuestionConfigurationDetailsComponents/DividerComponent";
import { Filter } from "../../../components/filter/components/FilterComponent";
import { useState } from "react";
import { FilterMenuButtons } from "../../../components/filter/components/FilterMenuButtons";
import { SelectedFilterButton } from "../../../components/filter/components/SelectedFilterButton";
import { observer } from "mobx-react";
import React from "react";

interface DashboardFilterComponentProps {
  store: Instance<typeof FilterStore>;
  filters: Filter[];
  onApplyFiltersClick: () => Promise<void>;
  onRemoveFiltersClick: () => void;
  isDisabled: boolean;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  border: BorderStyle;
  borderRadius: CornerRadius;
  shadow: Shadow;
  t: TFunction;
}

export const DashboardFilterComponent = observer(
  ({
    store,
    filters,
    onApplyFiltersClick,
    onRemoveFiltersClick,
    isDisabled,
    spacing,
    tokens,
    typography,
    border,
    borderRadius,
    shadow,
    t,
  }: DashboardFilterComponentProps): React.ReactElement => {
    const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(
      null,
    );
    const isMenuOpen = Boolean(anchorElement);
    const [isRemoveAllFiltersDialogOpen, setIsRemoveAllFiltersDialogOpen] =
      useState(false);
    const [isDateRangeInputInvalid, setIsDateRangeInputInvalid] =
      useState(false);

    const handleFilterButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>,
    ): void => {
      setAnchorElement(event.currentTarget);
    };
    const handleFilterButtonClose = (): void => {
      store.removeCurrentSelectedFilter();
      setAnchorElement(null);
    };

    return (
      <>
        <Stack justifyContent="space-between" direction="row">
          <AddFilterButton
            store={store}
            typography={typography}
            tokens={tokens}
            spacing={spacing}
            borderRadius={borderRadius}
            border={border}
            handleFilterButtonClick={handleFilterButtonClick}
            maxFilterLimit={MAX_APPLIED_FILTER_COUNT}
            t={t}
            currentAppliedFiltersCount={store.appliedFilters.length}
            isDisabled={
              store.isMaxFilterLimitReached(MAX_APPLIED_FILTER_COUNT) ||
              isDisabled
            }
            size="small"
          />
          <Popover
            id="basic-menu"
            anchorEl={anchorElement}
            open={isMenuOpen}
            onClose={handleFilterButtonClose}
            autoFocus={false}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              "& .MuiPaper-root": {
                padding: 0,
                marginTop: spacing.spaceXXS,
                borderRadius: borderRadius.radiusXS,
                boxShadow: shadow.level3,
                border: border.default,
              },
            }}
          >
            <Stack
              divider={
                <DividerComponent width="100%" orientation="horizontal" />
              }
            >
              <FilterInputComponent
                store={store}
                filters={filters}
                t={t}
                tokens={tokens}
                typography={typography}
                spacing={spacing}
                setIsDateRangeInputInvalid={setIsDateRangeInputInvalid}
              />
              <FilterMenuButtons
                store={store}
                t={t}
                spacing={spacing}
                filters={filters}
                isDateRangeInputInvalid={isDateRangeInputInvalid}
                handleFilterButtonClose={handleFilterButtonClose}
                onApplyFiltersClick={onApplyFiltersClick}
              />
            </Stack>
          </Popover>
          <Stack direction="row" gap={spacing.spaceSM}>
            <Button
              onClick={() => {
                setIsRemoveAllFiltersDialogOpen(true);
              }}
              name="removeAllFilters"
              label={t("filters.removeAllFilters")}
              variant="outlined-color"
              size="small"
              color="destructive"
              disabled={store.areNoFiltersApplied}
            />
          </Stack>
        </Stack>
        {!store.areNoFiltersAdded && (
          <Stack
            alignItems="flex-start"
            padding={`${spacing.spaceMD} ${spacing.space2XL}`}
            direction="row"
            gap={spacing.spaceXS}
            borderTop={border.default}
            borderBottom={border.default}
            flexWrap="wrap"
            position="absolute"
            top="65px"
            left="0px"
            width="100%"
            bgcolor={tokens.backgroundElevatedLevel1}
            boxShadow={shadow.level3}
          >
            {store.currentAddedFilters.map((filter, index) => {
              return (
                <SelectedFilterButton
                  key={index}
                  filter={filter}
                  index={index}
                  store={store}
                  size="small"
                  onApplyFiltersClick={onApplyFiltersClick}
                />
              );
            })}
          </Stack>
        )}
        <ConfirmationDialogComponent
          isDialogOpen={isRemoveAllFiltersDialogOpen}
          title={t("filters.removeAllFilters")}
          description={t("filters.removeAllFiltersDialogDescription")}
          primaryButtonText={t("filters.removeAllFilters")}
          secondaryButtonText={t("common.cancel")}
          primaryButtonType="primary"
          descriptionTextColor={tokens.label}
          icon={
            <HelpCircle {...getIconProps(tokens.icon, ICON_SIZE.default)} />
          }
          primaryButtonCallBack={async () => {
            store.removeAllFilters();
            await onRemoveFiltersClick();
            setIsRemoveAllFiltersDialogOpen(false);
          }}
          secondaryButtonCallback={() => {
            setIsRemoveAllFiltersDialogOpen(false);
          }}
        />
      </>
    );
  },
);
