import { SubmittedResponseEnums } from "@pulse/pulse-rpcs";
import { Instance, types } from "mobx-state-tree";

export const MultiSelectResponseV1Model = types.model(
  "MultiSelectResponseV1Model",
  {
    multiSelectResponse: types.string,
  },
);

export const createMultiSelectResponseV1Model = (
  multiSelectResponse: SubmittedResponseEnums.Response.Version1Enums.ResponseTypeV1.MultiSelect,
): Instance<typeof MultiSelectResponseV1Model> => {
  return MultiSelectResponseV1Model.create({
    multiSelectResponse: multiSelectResponse.answer,
  });
};
