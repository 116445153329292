import { Instance } from "mobx-state-tree";
import React, { Context, ReactElement, useRef } from "react";
import {
  SurveyViewDetailsStore,
  UpdateQuestionGraphTypeRPCErrors,
} from "../stores/SurveyViewDetailsStore";
import {
  CornerRadius,
  FoundationColorTokens,
  Shadow,
  LoadingIndicator,
  Spacing,
  Typography as LeoTypography,
  Snackbar,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { BorderStyle } from "@pulse/shared-components";
import { Divider, Skeleton, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react";
import {
  NavigateToFunctions,
  processSurveyViewParams,
} from "../../../routes/RoutesHelper";
import { QuestionViewSectionHeader } from "./QuestionViewSectionHeader";
import { getIconProps, ICON_SIZE } from "@pulse/shared-components";
import { FileX2 } from "lucide-react";
import { QuestionViewGraphTypeV1 } from "@pulse/pulse-rpcs";
import { ButtonProps } from "./QuestionView";
import { SignInStore } from "../../auth/store/SignInStore";
import {
  Virtuoso,
  VirtuosoHandle,
  ListProps,
  ScrollSeekPlaceholderProps,
} from "react-virtuoso";
import { QuestionViewRow } from "./QuestionViewRow";

export interface UpdateQuestionViewParams {
  navigateTo: NavigateToFunctions;
  updateQuestionView: (
    projectId: string,
    surveyId: string,
    surveyViewId: string,
    surveyQuestionViewId: string,
    questionViewGraphType: QuestionViewGraphTypeV1.QuestionViewGraphTypeV1,
    onResponse: () => void,
  ) => Promise<void>;
}

interface QuestionViewsProps {
  surveyViewDetailsStore: Instance<typeof SurveyViewDetailsStore>;
  typography: LeoTypography;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
  border: BorderStyle;
  cornerRadius: CornerRadius;
  boxShadow: Shadow;
  navigateTo: NavigateToFunctions;
  signInStore: Instance<typeof SignInStore>;
}

export const QuestionViews = observer(
  ({
    surveyViewDetailsStore,
    spacing,
    t,
    tokens,
    typography,
    border,
    cornerRadius,
    boxShadow,
    navigateTo,
    signInStore,
  }: QuestionViewsProps): ReactElement => {
    const questionViewsSectionComponentRef = useRef<VirtuosoHandle>(null);

    const onScroll = (): void => {
      if (
        !surveyViewDetailsStore.isLastElementReached &&
        !surveyViewDetailsStore.isLazyRPCLoading
      ) {
        processSurveyViewParams(
          async (
            surveyViewId: string,
            surveyId: string,
            projectId: string,
          ): Promise<void> => {
            await surveyViewDetailsStore.getPaginatedSurveyViewResults(
              surveyViewId,
              surveyId,
              projectId,
              true,
            );
          },
          navigateTo,
        );
      }
    };

    const scrollToTop = (): void => {
      surveyViewDetailsStore.resetIsLastElementReached();
      questionViewsSectionComponentRef.current?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const Footer = observer((): React.ReactElement => {
      return surveyViewDetailsStore.isLazyRPCLoading &&
        !surveyViewDetailsStore.isLastElementReached ? (
        <Stack padding={spacing.spaceXL}>
          <LoadingIndicator
            isLoading={true}
            variant="container"
            loadingText={t("surveyDashboard.lazyLoadingText")}
          />
        </Stack>
      ) : surveyViewDetailsStore.isLastElementReached ? (
        <Stack style={{ height: spacing.spaceXL }} />
      ) : (
        <></>
      );
    });

    const getErrorSnackbarMessage = (): string => {
      switch (surveyViewDetailsStore.rpcError) {
        case UpdateQuestionGraphTypeRPCErrors.ProjectAlreadyArchived:
          return t("common.projectAlreadyArchivedErrorText");
        default:
          return t("surveyDashboard.updateGraphViewUnexpectedErrorMessage");
      }
    };

    const QuestionViewsWrapper = React.forwardRef<
      HTMLDivElement,
      ListProps & { context?: Context<unknown> }
    >((props, ref) => {
      return (
        <Stack
          ref={ref}
          {...props}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: spacing.spaceXL,
            ...props.style,
            marginTop: spacing.spaceXL,
          }}
        >
          {props.children}
        </Stack>
      );
    });
    QuestionViewsWrapper.displayName = "QuestionViewsWrapper";

    const ScrollSeekPlaceholder = React.forwardRef<
      HTMLDivElement,
      ScrollSeekPlaceholderProps & { context?: Context<unknown> }
    >((props, ref) => {
      return (
        <Stack
          {...props}
          width="1024px"
          ref={ref}
          divider={<Divider />}
          padding={spacing.spaceXL}
          border={border.default}
          gap={spacing.spaceXL}
        >
          <Skeleton
            height="144px"
            variant="rounded"
            animation="wave"
            style={{ padding: spacing.spaceXL, boxSizing: "border-box" }}
          />
          <Skeleton
            height="100%"
            variant="rounded"
            animation="wave"
            style={{ padding: spacing.spaceXL }}
          />
        </Stack>
      );
    });
    ScrollSeekPlaceholder.displayName = "ScrollSeekPlaceholder";

    return (
      <>
        <QuestionViewSectionHeader
          spacing={spacing}
          surveyViewDetailsStore={surveyViewDetailsStore}
          shadow={boxShadow}
          t={t}
          tokens={tokens}
          typography={typography}
          border={border}
          cornerRadius={cornerRadius}
          navigateTo={navigateTo}
          signInStore={signInStore}
          scrollToTop={scrollToTop}
        />
        <Snackbar
          onClose={(): void => {
            surveyViewDetailsStore.resetRPCError();
          }}
          isOpen={surveyViewDetailsStore.isErrorSnackbarVisible}
          message={getErrorSnackbarMessage()}
          severity="error"
        />
        {surveyViewDetailsStore.isNoDataPresent ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            gap={spacing.spaceXS}
            height="100%"
          >
            <FileX2
              {...getIconProps(tokens.iconLowEmphasis, ICON_SIZE.large)}
            />
            <Typography {...typography.b1} color={tokens.labelLowEmphasis}>
              {t("surveyDashboard.noDataFound")}
            </Typography>
          </Stack>
        ) : (
          <Virtuoso
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
            overscan={5}
            ref={questionViewsSectionComponentRef}
            totalCount={surveyViewDetailsStore.surveyQuestionViews.length}
            endReached={onScroll}
            itemContent={(index) => {
              const questionItemAtIndex =
                surveyViewDetailsStore.surveyQuestionViews.at(index);
              return questionItemAtIndex === undefined ? (
                <></>
              ) : (
                <QuestionViewRow
                  spacing={spacing}
                  t={t}
                  tokens={tokens}
                  typography={typography}
                  border={border}
                  cornerRadius={cornerRadius}
                  boxShadow={boxShadow}
                  navigateTo={navigateTo}
                  signInStore={signInStore}
                  index={index}
                  surveyViewDetailsStore={surveyViewDetailsStore}
                />
              );
            }}
            scrollSeekConfiguration={{
              enter: (velocity) => Math.abs(velocity) > 500,
              exit: (velocity) => Math.abs(velocity) < 200,
            }}
            components={{
              Footer,
              List: QuestionViewsWrapper,
              ScrollSeekPlaceholder,
            }}
          />
        )}
      </>
    );
  },
);

export const getButtonProps = (
  updateQuestionViewParams: UpdateQuestionViewParams | undefined,
  surveyQuestionViewId: string,
  questionViewGraphType: QuestionViewGraphTypeV1.QuestionViewGraphTypeV1,
  isGraphSelectionUpdated: boolean,
  updateSelectedGraphTypeWithUpdatedGraph: () => void,
): ButtonProps | undefined => {
  if (updateQuestionViewParams) {
    const buttonProps: ButtonProps = {
      onSaveChangeClick: () => {
        if (updateQuestionViewParams) {
          processSurveyViewParams(
            async (
              surveyViewId: string,
              surveyId: string,
              projectId: string,
            ) => {
              await updateQuestionViewParams.updateQuestionView(
                projectId,
                surveyId,
                surveyViewId,
                surveyQuestionViewId,
                questionViewGraphType,
                updateSelectedGraphTypeWithUpdatedGraph,
              );
            },
            updateQuestionViewParams.navigateTo,
          );
        }
      },
      isSaveChangeDisabled: !isGraphSelectionUpdated,
    };
    return buttonProps;
  } else {
    return undefined;
  }
};
