import {
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import React from "react";
import { GridResponseV1Model } from "../../models/RespondentSubmittedResponseModels/GridResponseV1Model";
import { Stack } from "@mui/material";
import { S1TypographyLabel } from "./S1TypographyLabel";
import { BorderStyle } from "@pulse/shared-components";
import { B1TypographyLabel } from "./B1TypographyLabel";
import { observer } from "mobx-react";

interface GridResponseProps {
  question: string | null;
  gridResponse: Instance<typeof GridResponseV1Model>;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  spacing: Spacing;
  border: BorderStyle;
}

export const GridResponse = observer(
  ({
    gridResponse,
    question,
    t,
    tokens,
    typography,
    spacing,
    border,
  }: GridResponseProps): React.ReactElement => {
    return (
      <Stack>
        {question && (
          <Stack paddingBottom={spacing.spaceLG}>
            <S1TypographyLabel
              text={t("projects.respondentResponseDialog.questionPrefix", {
                question,
              })}
              tokens={tokens}
              typography={typography}
            />
          </Stack>
        )}
        {gridResponse.gridResponses.map((response, index) => {
          return (
            <Stack
              key={index}
              marginLeft={spacing.spaceMD}
              gap={spacing.spaceXS}
              borderTop={border.default}
              paddingY={spacing.spaceLG}
              paddingBottom={
                index === response.rowResponse.length - 1 ? 0 : spacing.spaceLG
              }
            >
              {response.columnHeader && (
                <S1TypographyLabel
                  text={t("projects.respondentResponseDialog.questionPrefix", {
                    question: response.columnHeader,
                  })}
                  tokens={tokens}
                  typography={typography}
                />
              )}
              {response.rowResponse.map(
                (rowResponseDetails, rowResponseIndex) => {
                  return (
                    <Stack key={rowResponseIndex} direction="row">
                      <B1TypographyLabel
                        text={rowResponseDetails.rowOptionText ?? undefined}
                        tokens={tokens}
                        typography={typography}
                        width="412px"
                      />
                      <B1TypographyLabel
                        text={rowResponseDetails.answer}
                        tokens={tokens}
                        typography={typography}
                        width="412px"
                      />
                    </Stack>
                  );
                },
              )}
            </Stack>
          );
        })}
      </Stack>
    );
  },
);
