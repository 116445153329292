import {
  FoundationColorTokens,
  IconButton,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Users2 } from "lucide-react";
import React, { ReactElement, useState } from "react";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { ManageSurveyViewSPoCsDialog } from "./ManageSurveyViewSPoCsDialog";
import { ManageSurveyViewSPoCsModel } from "../../models/ManageSurveyViewSPoCsModel";
import { Instance } from "mobx-state-tree";
import { NavigateToFunctions } from "../../../../routes/RoutesHelper";
import { BorderStyle } from "@pulse/shared-components";
import { SurveyDashboardView } from "@pulse/pulse-rpcs";

interface ManageSurveyViewSPoCsProps {
  t: TFunction;
  manageSurveyViewSPoCsModel: Instance<typeof ManageSurveyViewSPoCsModel>;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  navigateTo: NavigateToFunctions;
  border: BorderStyle;
  setSurveyDashboardViews: (surveyDashboardView: SurveyDashboardView[]) => void;
}

export const ManageSurveyViewSPoCs = observer(
  ({
    t,
    manageSurveyViewSPoCsModel,
    spacing,
    tokens,
    typography,
    navigateTo,
    border,
    setSurveyDashboardViews,
  }: ManageSurveyViewSPoCsProps): ReactElement => {
    const [
      isManageSurveyViewSPoCsDialogOpen,
      setIsManageSurveyViewSPoCsDialogOpen,
    ] = useState(false);

    return (
      <>
        {isManageSurveyViewSPoCsDialogOpen && (
          <ManageSurveyViewSPoCsDialog
            isManageSurveyViewSPoCsDialogOpen={
              isManageSurveyViewSPoCsDialogOpen
            }
            setIsManageSurveyViewSPoCsDialogOpen={
              setIsManageSurveyViewSPoCsDialogOpen
            }
            t={t}
            manageSurveyViewSPoCs={manageSurveyViewSPoCsModel}
            spacing={spacing}
            tokens={tokens}
            typography={typography}
            navigateTo={navigateTo}
            border={border}
            setSurveyDashboardViews={setSurveyDashboardViews}
          />
        )}
        <IconButton
          name="manageSPoCs"
          size="small"
          icon={<Users2 />}
          variant="plain-neutral"
          onClick={(): void => {
            setIsManageSurveyViewSPoCsDialogOpen(true);
          }}
        />
      </>
    );
  },
);
