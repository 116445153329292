import {
  cast,
  flow,
  getParentOfType,
  getRoot,
  Instance,
  types,
} from "mobx-state-tree";
import {
  createSurveyDashboardViewModel,
  EditViewRPCError,
  SurveyDashboardViewModel,
} from "../models/SurveyDashboardViewModel";
import {
  CommonErrors,
  EMPTY_LIST_LENGTH,
  NetworkingError,
} from "@pulse/shared-components";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "../../networking/APIClient";
import {
  DeleteDashboardViewRPC,
  GetSurveyDashboardViewsRPC,
  SurveyDashboardView,
} from "@pulse/pulse-rpcs";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import { useGetSurveyDashboardViewsRPCClient } from "../rpcs/RPC";
import { ProjectDetailsStore } from "../../projects/store/ProjectDetailsStore";
import { RootStore } from "../../root/store/RootStore";
import { AddViewStore, createAddViewStore } from "./AddViewStore";
import {
  createSurveyViewDetailsStore,
  SurveyViewDetailsStore,
} from "./SurveyViewDetailsStore";
import {
  createDashboardPreferencesStore,
  DashboardPreferencesStore,
} from "./DashboardPreferencesStore";
import { SurveyViewDetailsTabs } from "../pages/SurveyViews";

export const ReportsStore = types
  .model("ReportsStore", {
    surveyDashboardViews: types.array(SurveyDashboardViewModel),
    isRPCLoading: types.optional(types.boolean, false),
    rpcError: types.maybeNull(
      types.union(
        types.enumeration("networkErrors", Object.values(NetworkingError)),
        types.enumeration("EditViewRPCError", Object.values(EditViewRPCError)),
        types.enumeration(
          "DeleteViewRPCError",
          Object.values(DeleteDashboardViewRPC.RPCError),
        ),
      ),
    ),
    addViewStore: AddViewStore,
    surveyViewDetailsStore: SurveyViewDetailsStore,
    selectedTabIndex: types.optional(types.number, SurveyViewDetailsTabs.VIEWS),
    dashboardPreferencesStore: DashboardPreferencesStore,
  })
  .views((store) => ({
    get isSurveyDashboardViewsListEmpty(): boolean {
      return store.surveyDashboardViews.length === EMPTY_LIST_LENGTH;
    },
    get doesStoreContainViewNameError(): boolean {
      return (
        store.rpcError === EditViewRPCError.InvalidViewName ||
        store.rpcError === EditViewRPCError.InvalidViewNameLength ||
        store.rpcError === EditViewRPCError.SurveyViewAlreadyExists
      );
    },
    get doesStoreContainError(): boolean {
      return store.rpcError !== null;
    },
  }))
  .actions((store) => ({
    setSelectedTabIndex: (selectedTabIndex: number): void => {
      store.selectedTabIndex = selectedTabIndex;
    },
    setStoreError: (
      error: EditViewRPCError | DeleteDashboardViewRPC.RPCError,
    ): void => {
      store.rpcError = error;
    },
    removeStoreError: (): void => {
      store.rpcError = null;
    },
    setSurveyDashboardViews: (
      surveyDashboardView: SurveyDashboardView[],
    ): void => {
      store.surveyDashboardViews = cast(
        surveyDashboardView.map((dashboardView) => {
          return createSurveyDashboardViewModel(
            dashboardView.surveyViewId.uuid,
            dashboardView.surveyViewName.name,
            dashboardView.isMasterView,
            dashboardView.isSPoCChangeGraphAllowed,
          );
        }),
      );
    },
    getSurveyDashboardViews: flow(function* (
      surveyId: string,
      projectId: string,
    ) {
      store.isRPCLoading = true;
      store.rpcError = null;
      const errorStore =
        getRoot<typeof RootStore>(store).networkingStore.errorStore;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new GetSurveyDashboardViewsRPC.Request(
          new LeoUUID(surveyId),
          new LeoUUID(projectId),
        );
        const {
          response,
          error,
        }: {
          response?: GetSurveyDashboardViewsRPC.Response;
          error?: GetSurveyDashboardViewsRPC.Errors.Errors;
        } =
          yield useGetSurveyDashboardViewsRPCClient(apiClient).execute(request);
        if (response) {
          const projectDetailsStore = getParentOfType(
            store,
            ProjectDetailsStore,
          );
          projectDetailsStore.surveyStore.setSurveyName(
            response.surveyDetailsAndDashboardViews.surveyStatusAndName
              .surveyName.name,
          );
          projectDetailsStore.surveyStore.setSurveyStatus(
            response.surveyDetailsAndDashboardViews.surveyStatusAndName
              .surveyStatus,
          );
          store.surveyDashboardViews = cast(
            response.surveyDetailsAndDashboardViews.surveyDashboardViews.map(
              (dashboardView: SurveyDashboardView) => {
                return createSurveyDashboardViewModel(
                  dashboardView.surveyViewId.uuid,
                  dashboardView.surveyViewName.name,
                  dashboardView.isMasterView,
                  dashboardView.isSPoCChangeGraphAllowed,
                );
              },
            ),
          );
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              break;
            default: {
              errorStore.setError(NetworkingError.InternalServerError);
              console.error(`Unhandled error occured: ${error}`);
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
  }));

export const createReportsStore = (): Instance<typeof ReportsStore> => {
  return ReportsStore.create({
    addViewStore: createAddViewStore(),
    surveyViewDetailsStore: createSurveyViewDetailsStore(),
    dashboardPreferencesStore: createDashboardPreferencesStore(),
  });
};
