import { Instance } from "mobx-state-tree";
import React from "react";
import { OpenEndedResponseV1Model } from "../../models/RespondentSubmittedResponseModels/OpenEndedResponseV1Model";
import { Stack } from "@mui/material";
import { observer } from "mobx-react";
import { TFunction } from "i18next";
import { S1TypographyLabel } from "./S1TypographyLabel";
import {
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { B1TypographyLabel } from "./B1TypographyLabel";
import { BorderStyle } from "@pulse/shared-components";

interface OpenEndedResponseProps {
  question: string | null;
  openEndedResponse: Instance<typeof OpenEndedResponseV1Model>;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  spacing: Spacing;
  border: BorderStyle;
}

export const OpenEndedResponse = observer(
  ({
    question,
    tokens,
    typography,
    t,
    openEndedResponse,
    spacing,
    border,
  }: OpenEndedResponseProps): React.ReactElement => {
    return (
      <Stack>
        {question && (
          <Stack paddingBottom={spacing.spaceLG}>
            <S1TypographyLabel
              text={t("projects.respondentResponseDialog.questionPrefix", {
                question,
              })}
              tokens={tokens}
              typography={typography}
            />
          </Stack>
        )}
        {openEndedResponse.fieldResponses.map((fieldResponse, index) => {
          return (
            <Stack
              key={index}
              marginLeft={spacing.spaceMD}
              gap={spacing.spaceXS}
              borderTop={border.default}
              paddingY={spacing.spaceLG}
              paddingBottom={
                index === openEndedResponse.fieldResponses.length - 1
                  ? 0
                  : spacing.spaceLG
              }
            >
              {fieldResponse.fieldTitle && (
                <S1TypographyLabel
                  text={t("projects.respondentResponseDialog.questionPrefix", {
                    question: fieldResponse.fieldTitle,
                  })}
                  tokens={tokens}
                  typography={typography}
                />
              )}
              <B1TypographyLabel
                text={t("projects.respondentResponseDialog.answerPrefix", {
                  response: fieldResponse.answer,
                })}
                tokens={tokens}
                typography={typography}
              />
            </Stack>
          );
        })}
      </Stack>
    );
  },
);
