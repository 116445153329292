import {
  QuartileColor,
  ReportType,
  RespondentColumn,
  RespondentName,
  SurveyTheme,
} from "@pulse/pulse-rpcs";
import {
  EMPTY_CHARACTER,
  FontSizeScale,
  SINGLE_SPACING,
} from "@pulse/shared-components";
import { SingleChoicePresets } from "../../surveys/store/SingleChoiceQuestionV1Store";

const isDataNullOrEmpty = (data: string | null): boolean => {
  return data === null || data.trim() === EMPTY_CHARACTER;
};

export const getRespondentColumnOrNull = (
  data: string | null,
): RespondentColumn | null => {
  if (data === null || isDataNullOrEmpty(data)) {
    return null;
  } else {
    return new RespondentColumn(data.trim());
  }
};

export const getRespondentNameOrNull = (
  data: string | null,
): RespondentName | null => {
  if (data === null || isDataNullOrEmpty(data)) {
    return null;
  } else {
    return new RespondentName(data.trim());
  }
};

export const getTrimmedStringOrNull = (data: string | null): string | null => {
  if (data === null || isDataNullOrEmpty(data)) {
    return null;
  } else {
    return data.trim();
  }
};

// This will be used wherever we need to preserve the whitespace of strings. It replaces the default spacing
// with unicode character space which is not compressed by html.
export const getFullySpacedString = (
  data: string | null | undefined,
): string => {
  if (data === null || data === undefined) {
    return EMPTY_CHARACTER;
  } else {
    return data.replaceAll(SINGLE_SPACING, "\u00a0");
  }
};

export const getTitleCase = (value: string): string => {
  return value
    .toLowerCase() // Convert the entire string to lowercase
    .split(SINGLE_SPACING) // Split the string into words based on spaces
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(SINGLE_SPACING); // Join the words back together
};

enum SurveyThemeWithCamelCaseKeyAndValue {
  lilacSerenade = "lilacSerenade",
  summerSunset = "summerSunset",
  winterMidnight = "winterMidnight",
  autumnHarvest = "autumnHarvest",
  springBlossom = "springBlossom",
  crimsonFall = "crimsonFall",
  arcticMonochrome = "arcticMonochrome",
}

export const getCamelCaseFromSurveyTheme = (
  snakeStr: SurveyTheme.SurveyTheme,
): SurveyThemeWithCamelCaseKeyAndValue => {
  const components = snakeStr.toLowerCase().split("_");
  return (components[0] +
    components
      .slice(1)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("")) as SurveyThemeWithCamelCaseKeyAndValue;
};

enum QuartileColorWithCamesCaseKeyAndValue {
  quartileColor1 = "quartileColor1",
  quartileColor2 = "quartileColor2",
  quartileColo3 = "quartileColor3",
  quartileColor4 = "quartileColor4",
  quartileColor5 = "quartileColor5",
  defaultColor = "defaultColor",
}

export const getCamelCaseFromQuartileColor = (
  snakeStr: QuartileColor.QuartileColor,
): QuartileColorWithCamesCaseKeyAndValue => {
  const components = snakeStr.toLowerCase().split("_");
  return (components[0] +
    components
      .slice(1)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("")) as QuartileColorWithCamesCaseKeyAndValue;
};

enum FontSizeScaleWithCamelCaseKeyAndValue {
  defaultScale = "defaultScale",
  smallScale = "smallScale",
  largeScale = "largeScale",
}

export const getCamelCaseFromFontSizeScale = (
  snakeStr: FontSizeScale,
): FontSizeScaleWithCamelCaseKeyAndValue => {
  const components = snakeStr.toLowerCase().split("_");
  return (components[0] +
    components
      .slice(1)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("")) as FontSizeScaleWithCamelCaseKeyAndValue;
};

enum SingleChoicePresetsWithCamelCaseKeyAndValue {
  goodPoor = "goodPoor",
  value = "value",
  agreement = "agreement",
  satisfaction = "satisfaction",
  willingness = "willingness",
  likelihood = "likelihood",
  netPromoterScore = "netPromoterScore",
}

export const getCamelCaseFromSingleChoicePresets = (
  snakeStr: SingleChoicePresets,
): SingleChoicePresetsWithCamelCaseKeyAndValue => {
  const camelCaseStr = snakeStr.charAt(0).toLowerCase() + snakeStr.slice(1);
  return camelCaseStr as SingleChoicePresetsWithCamelCaseKeyAndValue;
};

enum ReportTypeWithCamelCaseKeyAndValue {
  coded = "coded",
  nonCoded = "nonCoded",
}

export const getCamelCaseFromReportType = (
  snakeStr: ReportType.ReportType,
): ReportTypeWithCamelCaseKeyAndValue => {
  const components = snakeStr.toLowerCase().split("_");
  return (components[0] +
    components
      .slice(1)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("")) as ReportTypeWithCamelCaseKeyAndValue;
};

enum LinkTypeWithCamelCaseKeyAndValue {
  live = "live",
  test = "test",
}

export const getCamelCaseFromLinkType = (
  snakeStr: string,
): LinkTypeWithCamelCaseKeyAndValue => {
  const components = snakeStr.toLowerCase().split("_");
  return (components[0] +
    components
      .slice(1)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("")) as LinkTypeWithCamelCaseKeyAndValue;
};

export const screamingSnakeToPascal = (snakeStr: string): string => {
  return snakeStr
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");
};
