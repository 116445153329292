import { Instance } from "mobx-state-tree";
import React, { ReactElement, useEffect, useState } from "react";
import {
  DeleteSurveyViewDialogState,
  SurveyDashboardViewModel,
} from "../models/SurveyDashboardViewModel";
import {
  Dialog,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import {
  NavigateToFunctions,
  processSurveyParams,
} from "../../../routes/RoutesHelper";
import { observer } from "mobx-react";
import { DialogErrorContent } from "../../../components/DialogErrorContent";
import { ReportsStore } from "../stores/ReportsStore";
import { NestedTypographyDestructiveComponent } from "../../projects/components/NestedTypographyDestructiveComponent";
import { DeleteDashboardViewRPC } from "@pulse/pulse-rpcs";
import { COUNTDOWN_INTERVAL_IN_MS } from "@pulse/shared-components";
import {
  TOTAL_COUNTDOWN_TIME_IN_SEC,
  END_OF_COUNTDOWN,
} from "@pulse/shared-components";

interface DeleteSurveyViewDialogProps {
  isDeleteSurveyViewDialogOpen: boolean;
  setIsDeleteSurveyViewDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  surveyDashboardView: Instance<typeof SurveyDashboardViewModel>;
  t: TFunction;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  navigateTo: NavigateToFunctions;
  reportStore: Instance<typeof ReportsStore>;
}

export const DeleteSurveyViewDialog = observer(
  ({
    isDeleteSurveyViewDialogOpen,
    setIsDeleteSurveyViewDialogOpen,
    surveyDashboardView,
    t,
    spacing,
    tokens,
    typography,
    navigateTo,
    reportStore,
  }: DeleteSurveyViewDialogProps): ReactElement => {
    const [isRPCLoading, setIsRPCLoading] = useState(false);
    const [countDownValue, setCountDownValue] = useState(
      TOTAL_COUNTDOWN_TIME_IN_SEC,
    );

    useEffect(() => {
      const interval = setInterval(() => {
        setCountDownValue(countDownValue - 1);
      }, COUNTDOWN_INTERVAL_IN_MS);
      if (countDownValue === END_OF_COUNTDOWN) clearInterval(interval);
      return () => clearInterval(interval);
    }, [countDownValue]);

    useEffect(() => {
      surveyDashboardView.resetStoreValues(reportStore.removeStoreError);
    }, []);

    const getPrimaryButtonText = (): string | undefined => {
      switch (surveyDashboardView.deleteSurveyViewDialogState) {
        case DeleteSurveyViewDialogState.DELETE: {
          if (countDownValue === 0) {
            return t("surveyDashboard.deleteView");
          } else {
            return t("surveyDashboard.deleteViewWithCountdown", {
              primaryButtonCountdown: countDownValue,
            });
          }
        }
        default: {
          return undefined;
        }
      }
    };
    const getSecondaryButtonText = (): string | undefined => {
      switch (surveyDashboardView.deleteSurveyViewDialogState) {
        case DeleteSurveyViewDialogState.DELETE: {
          return t("common.cancel");
        }
        case DeleteSurveyViewDialogState.ERROR: {
          return t("common.close");
        }
      }
    };

    const getErrorText = (): string => {
      switch (reportStore.rpcError) {
        case DeleteDashboardViewRPC.RPCError.ProjectAlreadyArchived:
          return t("common.projectAlreadyArchivedErrorText");
        default:
          return t("surveyDashboard.deleteViewUnexpectedError");
      }
    };

    const getDialogChild = (): React.ReactElement => {
      switch (surveyDashboardView.deleteSurveyViewDialogState) {
        case DeleteSurveyViewDialogState.DELETE: {
          return (
            <NestedTypographyDestructiveComponent
              tokens={tokens}
              typography={typography}
              middleBoldText={`'${surveyDashboardView.surveyViewName}'`}
              startText={t("surveyDashboard.areYouCertainYouWantToDelete")}
              endText={t("surveyDashboard.deleteViewConfirmationDescription")}
              noteText={undefined}
              spacing={spacing}
            />
          );
        }
        case DeleteSurveyViewDialogState.ERROR: {
          return (
            <DialogErrorContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              errorText={getErrorText()}
            />
          );
        }
      }
    };

    return (
      <Dialog
        open={isDeleteSurveyViewDialogOpen}
        title={t("surveyDashboard.deleteViewWithQuestionMark")}
        width="560px"
        primaryButtonText={getPrimaryButtonText()}
        primaryButtonColor="destructive"
        onPrimaryButtonClick={async (): Promise<void> => {
          setIsRPCLoading(true);
          await processSurveyParams(
            async (surveyId: string, projectId: string): Promise<void> => {
              await surveyDashboardView.deleteView(
                surveyId,
                projectId,
                reportStore.setStoreError,
                reportStore.removeStoreError,
                reportStore.setSurveyDashboardViews,
              );
            },
            navigateTo,
          );
          if (
            !reportStore.doesStoreContainError &&
            !reportStore.doesStoreContainViewNameError
          ) {
            setIsDeleteSurveyViewDialogOpen(false);
          }
          setIsRPCLoading(false);
        }}
        isPrimaryButtonDisabled={countDownValue !== 0}
        secondaryButtonText={getSecondaryButtonText()}
        onSecondaryButtonClick={() => {
          setIsDeleteSurveyViewDialogOpen(false);
        }}
        isSecondaryButtonDisabled={isRPCLoading}
        disableBackdropClick={true}
      >
        {/*
    getDialogChild() is not called like <DialogChild />.
    This is done to avoid giving it its own lifecycle which will result in loss of focus from <TextInputField /> on store updation.
    */}
        {getDialogChild()}
      </Dialog>
    );
  },
);
