import { X } from "lucide-react";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import { FilterModel, FilterType } from "../model/FilterModel";
import { FilterStore } from "../store/FilterStore";
import { Button } from "@surya-digital/leo-reactjs-material-ui";
import React from "react";

export const SelectedFilterButton = observer(
  ({
    index,
    filter,
    store,
    size = "medium",
    onApplyFiltersClick,
  }: {
    index: number;
    filter: Instance<typeof FilterModel>;
    store: Instance<typeof FilterStore>;
    size?: "small" | "medium";
    onApplyFiltersClick: () => Promise<void>;
  }): React.ReactElement => {
    const getFilterLabel = (): string => {
      switch (filter.filterType) {
        case FilterType.DateRange:
          return `${filter.key}: ${filter.getDateRangeFilterValueString}`;
        case FilterType.MultiSelect:
          return `${filter.key}: ${filter.commaSeparatedMultiSelectFilterValues}`;
        case FilterType.OpenEnded:
          return `${filter.key}: "${filter.openEndedFilterValue}"`;
      }
    };

    return (
      <Button
        onClick={() => {
          store.removeAddedFilter(index);
          store.applyCurrentAddedFilters();
          onApplyFiltersClick();
        }}
        name="filter"
        label={getFilterLabel()}
        variant="outlined-color"
        size={size}
        icon={<X />}
        iconPosition="trailing"
      />
    );
  },
);
