import { Stack } from "@mui/material";
import { EMPTY_CHARACTER } from "@pulse/shared-components";
import {
  AutoCompleteItem,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  AutoCompleteInputField,
  Button,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import React from "react";
import { observer } from "mobx-react";

export interface SurveySelectionComponentProps {
  spacing: Spacing;
  t: TFunction;
  surveyIdAndNames: AutoCompleteItem[];
  isDisabled: boolean;
  onSelect: (survey: AutoCompleteItem | null) => void;
  selectedValue: AutoCompleteItem | null;
  onViewReportClick: () => void;
  isViewResultButtonDisabled: boolean;
}

export const SurveySelectionComponent = observer(
  ({
    spacing,
    surveyIdAndNames,
    isDisabled,
    onSelect,
    selectedValue,
    t,
    onViewReportClick,
    isViewResultButtonDisabled,
  }: SurveySelectionComponentProps): React.ReactElement => {
    return (
      <Stack
        direction="row"
        gap={spacing.spaceSM}
        width="100%"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Stack width="360px">
          <AutoCompleteInputField
            id="selectSurvey"
            label={EMPTY_CHARACTER}
            placeholder={EMPTY_CHARACTER}
            options={surveyIdAndNames}
            disabled={isDisabled}
            onChange={(survey: AutoCompleteItem | null) => {
              onSelect(survey);
            }}
            value={selectedValue}
          />
        </Stack>
        <Stack paddingTop={spacing.spaceXXS}>
          <Button
            label={t("projects.dashboard.viewResultsButtonText")}
            name="viewReport"
            size="large"
            variant="filled"
            onClick={onViewReportClick}
            disabled={isViewResultButtonDisabled}
          />
        </Stack>
      </Stack>
    );
  },
);
