import { Stack, Typography } from "@mui/material";
import {
  BorderStyle,
  EMPTY_CHARACTER,
  RICH_TEXT_MARKDOWN_PROPS,
  richTextTypography,
} from "@pulse/shared-components";
import {
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypogrpahy,
} from "@surya-digital/leo-reactjs-material-ui";
import { RichTextEditor } from "@surya-digital/leo-reactjs-remirror";
import React, { ReactElement } from "react";

interface TooltipContentProps {
  spacing: Spacing;
  cornerRadius: CornerRadius;
  tokens: FoundationColorTokens<string>;
  label: string;
  percentage?: string;
  count: string;
  border: BorderStyle;
  typography: LeoTypogrpahy;
  isSurveyViewOptionDisplayTextSelected: boolean;
}

export const TooltipContent = ({
  spacing,
  cornerRadius,
  tokens,
  border,
  count,
  label,
  percentage,
  typography,
  isSurveyViewOptionDisplayTextSelected,
}: TooltipContentProps): ReactElement => {
  const percentageText = percentage ? `- ${percentage}%` : EMPTY_CHARACTER;
  return (
    <Stack
      borderRadius={cornerRadius.radiusXS}
      border={border.default}
      sx={{ backgroundColor: tokens.backgroundElevatedLevel1 }}
      maxWidth="364px"
    >
      <Stack
        padding={spacing.spaceSM}
        borderRadius={`${cornerRadius.radiusXS}  ${cornerRadius.radiusXS} 0 0`}
        borderBottom={border.default}
        sx={{ backgroundColor: tokens.backgroundSubtle }}
        maxWidth="364px"
      >
        {isSurveyViewOptionDisplayTextSelected ? (
          <RichTextEditor
            name={label}
            typography={richTextTypography()}
            mode="preview"
            borderOnPreview={false}
            initialValue={label}
            supports={RICH_TEXT_MARKDOWN_PROPS}
          />
        ) : (
          <Typography
            {...typography.b2}
            color={tokens.labelSubtle}
            textAlign="center"
          >
            {label}
          </Typography>
        )}
      </Stack>
      <Typography
        {...typography.b3}
        color={tokens.label}
        padding={spacing.spaceSM}
      >{`${count} ${percentageText}`}</Typography>
    </Stack>
  );
};
