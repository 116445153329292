import React from "react";
import { Stack, Typography } from "@mui/material";
import { getEllipsizedTextProperties } from "@pulse/shared-components";
import {
  EllipsisTypography,
  Spacing,
  Typography as LeoTypography,
  FoundationColorTokens,
  IconButton,
} from "@surya-digital/leo-reactjs-material-ui";
import { X } from "lucide-react";
import { observer } from "mobx-react";

export interface SelectedProjectSPoCListProps {
  spocName: string;
  spocEmailId: string;
  spocId: string;
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  isRemoveSpocButtonVisible: boolean;
  isRPCLoading: boolean;
  removeSelectedSpocId: (selectedSpocId: string) => void;
  isDisabled: boolean;
}

export const SelectedProjectSPoCList = observer(
  ({
    spocName,
    spocEmailId,
    spocId,
    spacing,
    typography,
    tokens,
    isRemoveSpocButtonVisible,
    isRPCLoading,
    removeSelectedSpocId,
    isDisabled,
  }: SelectedProjectSPoCListProps): React.ReactElement => {
    return (
      <Stack direction="row" gap={spacing.spaceSM} width="100%">
        <Stack width="100%">
          <EllipsisTypography
            typography={typography.b1}
            textColor={isDisabled ? "labelLowEmphasis" : "label"}
          >
            {spocName}
          </EllipsisTypography>
          {/* Ideally, this should also be using Ellipsis typography component but doing so cuts off the
          leftmost letter for certain characters like "J" */}
          <Typography
            {...typography.b3}
            color={isDisabled ? tokens.labelLowEmphasis : tokens.labelSubtle}
            // A padding of 2px is given here because so that the overflowed text is not hidden.
            paddingLeft={"2px"}
            sx={{ ...getEllipsizedTextProperties() }}
          >
            {spocEmailId}
          </Typography>
        </Stack>
        {isRemoveSpocButtonVisible && (
          <IconButton
            name="removeSpoc"
            variant="plain-neutral"
            size="medium"
            icon={<X />}
            onClick={() => {
              removeSelectedSpocId(spocId);
            }}
            disabled={isRPCLoading || isDisabled}
          />
        )}
      </Stack>
    );
  },
);
