import {
  QuartileColor,
  QuartileConfiguration,
  QuartileRange,
} from "@pulse/pulse-rpcs";
import { EMPTY_CHARACTER } from "@pulse/shared-components";
import { Instance, types } from "mobx-state-tree";

export enum QuartileRanges {
  BetweenValues = "Between Values",
  LesserThan = "Lesser Than (<)",
  GreaterThan = "Greater Than (>)",
}

export enum QuartileFieldError {
  RepeatedLesserThanQuartileRange = "REPEATED_LESSER_THAN_QUARTILE_RANGE",
  RepeatedGreaterThanQuartileRange = "REPEATED_GREATER_THAN_QUARTILE_RANGE",
}

const QuartileConfigurationUIModel = types
  .model("QuartileConfigurationUIModel", {
    isQuartileColorErrored: types.optional(types.boolean, false),
    isQuartileRangeErrored: types.optional(types.boolean, false),
    isStartValueErrored: types.optional(types.boolean, false),
    isEndValueErrored: types.optional(types.boolean, false),
    isValueErrored: types.optional(types.boolean, false),
  })
  .actions((store) => ({
    setIsQuartileColorErrored: (isQuartileColorErrored: boolean): void => {
      store.isQuartileColorErrored = isQuartileColorErrored;
    },
    setIsQuartileRangeErrored: (isQuartileRangeErrored: boolean): void => {
      store.isQuartileRangeErrored = isQuartileRangeErrored;
    },
    setIsStartValueErrored: (isStartValueErrored: boolean): void => {
      store.isStartValueErrored = isStartValueErrored;
    },
    setIsEndValueErrored: (isEndValueErrored: boolean): void => {
      store.isEndValueErrored = isEndValueErrored;
    },
  }));

const createQuartileConfigurationUIModel = (): Instance<
  typeof QuartileConfigurationUIModel
> => {
  return QuartileConfigurationUIModel.create();
};

export const QuartileConfigurationModel = types
  .model("QuartileConfigurationModel", {
    quartileColor: types.maybe(
      types.enumeration(Object.values(QuartileColor.QuartileColor)),
    ),
    primitiveQuartileColor: types.maybe(types.string),
    defaultSingleSelectQuartileColor: types.maybeNull(types.string),
    quartileRange: types.maybe(
      types.enumeration(Object.values(QuartileRanges)),
    ),
    startValue: types.maybe(types.string),
    endValue: types.maybe(types.string),
    serialNumber: types.number,
    quartileConfigurationUIStore: QuartileConfigurationUIModel,
    quartileFieldError: types.maybeNull(
      types.enumeration(Object.values(QuartileFieldError)),
    ),
    isStartValueGreaterThanEndValue: types.optional(types.boolean, false),
  })
  .actions((store) => ({
    setIsStartValueGreaterThanEndValue: (
      isStartValueGreaterThanEndValue: boolean,
    ): void => {
      store.isStartValueGreaterThanEndValue = isStartValueGreaterThanEndValue;
    },
    setQuartileRangeFieldError: (fieldError: QuartileFieldError): void => {
      store.quartileFieldError = fieldError;
    },
    setSelectedQuartileRange: (selectedQuartileRange: QuartileRanges): void => {
      store.startValue = undefined;
      store.endValue = undefined;
      store.quartileRange = selectedQuartileRange;
      store.quartileConfigurationUIStore.setIsQuartileRangeErrored(false);
      store.quartileConfigurationUIStore.setIsStartValueErrored(false);
      store.quartileConfigurationUIStore.setIsEndValueErrored(false);
    },
    setSelectedQuartileColor: (
      selectedQuartileColor: QuartileColor.QuartileColor,
      primitiveQuartileColor: string | undefined,
      defaultSingleSelectQuartileColor: string | undefined | null,
    ): void => {
      store.quartileColor = selectedQuartileColor;
      store.primitiveQuartileColor = primitiveQuartileColor;
      store.defaultSingleSelectQuartileColor =
        defaultSingleSelectQuartileColor ?? null;
      store.quartileConfigurationUIStore.setIsQuartileColorErrored(false);
    },
    setStartValue: (startValue: string): void => {
      store.startValue =
        startValue === EMPTY_CHARACTER ? undefined : startValue;
      store.isStartValueGreaterThanEndValue = false;
      store.quartileConfigurationUIStore.setIsStartValueErrored(false);
      store.quartileConfigurationUIStore.setIsEndValueErrored(false);
    },
    setEndValue: (endValue: string): void => {
      store.endValue = endValue === EMPTY_CHARACTER ? undefined : endValue;
      store.isStartValueGreaterThanEndValue = false;
      store.quartileConfigurationUIStore.setIsStartValueErrored(false);
      store.quartileConfigurationUIStore.setIsEndValueErrored(false);
    },
  }));

export const createQuartileConfigurationModel = (
  quartileConfiguration: QuartileConfiguration | undefined,
): Instance<typeof QuartileConfigurationModel> => {
  if (quartileConfiguration === undefined) {
    return QuartileConfigurationModel.create({
      quartileColor: undefined,
      primitiveQuartileColor: undefined,
      defaultSingleSelectQuartileColor: undefined,
      quartileRange: undefined,
      startValue: undefined,
      endValue: undefined,
      serialNumber: 1,
      quartileConfigurationUIStore: createQuartileConfigurationUIModel(),
    });
  }
  if (
    quartileConfiguration.quartileRange instanceof QuartileRange.BetweenValues
  ) {
    return QuartileConfigurationModel.create({
      quartileColor: quartileConfiguration.quartileColor.quartileColor,
      primitiveQuartileColor:
        quartileConfiguration.quartileColor.primitiveQuartileColor.code,
      defaultSingleSelectQuartileColor:
        quartileConfiguration.quartileColor.defaultSingleSelectQuartileColor
          ?.code,
      quartileRange: QuartileRanges.BetweenValues,
      startValue: `${quartileConfiguration.quartileRange.startValue}`,
      endValue: `${quartileConfiguration.quartileRange.endValue}`,
      serialNumber: quartileConfiguration.serialNumber,
      quartileConfigurationUIStore: createQuartileConfigurationUIModel(),
    });
  } else if (
    quartileConfiguration.quartileRange instanceof QuartileRange.LesserThan
  ) {
    return QuartileConfigurationModel.create({
      quartileColor: quartileConfiguration.quartileColor.quartileColor,
      primitiveQuartileColor:
        quartileConfiguration.quartileColor.primitiveQuartileColor.code,
      defaultSingleSelectQuartileColor:
        quartileConfiguration.quartileColor.defaultSingleSelectQuartileColor
          ?.code,
      quartileRange: QuartileRanges.LesserThan,
      endValue: `${quartileConfiguration.quartileRange.lesserThanValue}`,
      serialNumber: quartileConfiguration.serialNumber,
      quartileConfigurationUIStore: createQuartileConfigurationUIModel(),
    });
  } else {
    return QuartileConfigurationModel.create({
      quartileColor: quartileConfiguration.quartileColor.quartileColor,
      primitiveQuartileColor:
        quartileConfiguration.quartileColor.primitiveQuartileColor.code,
      defaultSingleSelectQuartileColor:
        quartileConfiguration.quartileColor.defaultSingleSelectQuartileColor
          ?.code,
      quartileRange: QuartileRanges.GreaterThan,
      startValue: `${quartileConfiguration.quartileRange.greaterThanValue}`,
      serialNumber: quartileConfiguration.serialNumber,
      quartileConfigurationUIStore: createQuartileConfigurationUIModel(),
    });
  }
};
