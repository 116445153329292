import {
  CornerRadius,
  FoundationColorTokens,
  Shadow,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { getType, Instance } from "mobx-state-tree";
import React from "react";
import {
  SurveyQuestionViewStoreNames,
  SurveyViewDetailsStore,
} from "../stores/SurveyViewDetailsStore";
import { TFunction } from "i18next";
import { BorderStyle } from "@pulse/shared-components";
import { NavigateToFunctions } from "../../../routes/RoutesHelper";
import { SignInStore } from "../../auth/store/SignInStore";
import { RegularSingleSelectQuestionView } from "./RegularSingleSelectQuestionView";
import { RegularSingleSelectQuestionModelV1 } from "../models/RegularSingleSelectQuestionModelV1";
import { SingleSelectQuestionWithProportionView } from "./SingleSelectQuestionWithProportionView";
import { SingleSelectQuestionWithProportionModelV1 } from "../models/SingleSelectQuestionWithProportionModelV1";
import { NPSSingleSelectQuestionView } from "./NPSSingleSelectQuestionView";
import { NPSSingleSelectQuestionModelV1 } from "../models/NPSSingleSelectQuestionModelV1";
import { OpenEndedQuestionView } from "./OpenEndedQuestionView";
import { OpenEndedQuestionViewModelV1 } from "../models/OpenEndedQuestionViewModelV1";
import { MultiSelectQuestionView } from "./MultiSelectQuestionView";
import { MultiSelectQuestionViewModelV1 } from "../models/MultiSelectQuestionViewModelV1";
import { RankingQuestionView } from "./RankingQuestionView";
import { RankingQuestionViewModelV1 } from "../models/RankingQuestionViewModelV1";
import { GroupQuestionView } from "./GroupQuestionView";
import { GroupQuestionViewModelV1 } from "../models/GroupQuestionViewModelV1";
import { GridQuestionView } from "./gridView/GridQuestionView";
import { GridQuestionViewV1Model } from "../models/GridQuestionV1Model";
import { observer } from "mobx-react";

interface QuestionProps {
  spacing: Spacing;
  surveyViewDetailsStore: Instance<typeof SurveyViewDetailsStore>;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  border: BorderStyle;
  cornerRadius: CornerRadius;
  boxShadow: Shadow;
  navigateTo: NavigateToFunctions;
  signInStore: Instance<typeof SignInStore>;
  index: number;
}

export const QuestionViewRow = observer(
  ({
    spacing,
    surveyViewDetailsStore,
    t,
    tokens,
    typography,
    border,
    cornerRadius,
    boxShadow,
    navigateTo,
    signInStore,
    index,
  }: QuestionProps): React.ReactElement => {
    const surveyQuestionView =
      surveyViewDetailsStore.surveyQuestionViews.at(index);
    // This can happen when the survey view list was updated, in case of the manage question views flow, or if filters were applied/updated.
    if (surveyQuestionView === undefined) {
      return <></>;
    }
    switch (getType(surveyQuestionView).name) {
      case SurveyQuestionViewStoreNames.REGULAR_SINGLE_SELECT_QUESTION_MODEL: {
        return (
          <>
            <RegularSingleSelectQuestionView
              key={index}
              surveyQuestionView={
                surveyQuestionView as Instance<
                  typeof RegularSingleSelectQuestionModelV1
                >
              }
              spacing={spacing}
              t={t}
              tokens={tokens}
              typography={typography}
              border={border}
              cornerRadius={cornerRadius}
              boxShadow={boxShadow}
              updateQuestionViewParams={{
                navigateTo,
                updateQuestionView:
                  surveyViewDetailsStore.updateQuestionGraphType,
              }}
              isSurveyViewOptionDisplayTextSelected={
                surveyViewDetailsStore.isSurveyViewOptionDisplayTextSelected
              }
              allowSPoCToSelectGraphType={
                surveyViewDetailsStore.isSPoCChangeGraphAllowed
              }
              signInStore={signInStore}
            />
          </>
        );
      }
      case SurveyQuestionViewStoreNames.SINGLE_SELECT_QUESTION_WITH_PROPORTION_MODEL: {
        return (
          <>
            <SingleSelectQuestionWithProportionView
              key={index}
              surveyQuestionView={
                surveyQuestionView as Instance<
                  typeof SingleSelectQuestionWithProportionModelV1
                >
              }
              spacing={spacing}
              t={t}
              tokens={tokens}
              typography={typography}
              border={border}
              cornerRadius={cornerRadius}
              boxShadow={boxShadow}
              updateQuestionViewParams={{
                navigateTo,
                updateQuestionView:
                  surveyViewDetailsStore.updateQuestionGraphType,
              }}
              allowSPoCToSelectGraphType={
                surveyViewDetailsStore.isSPoCChangeGraphAllowed
              }
              signInStore={signInStore}
            />
          </>
        );
      }
      case SurveyQuestionViewStoreNames.NPS_SINGLE_SELECT_QUESTION_MODEL: {
        return (
          <>
            <NPSSingleSelectQuestionView
              key={index}
              surveyQuestionView={
                surveyQuestionView as Instance<
                  typeof NPSSingleSelectQuestionModelV1
                >
              }
              spacing={spacing}
              t={t}
              tokens={tokens}
              typography={typography}
              border={border}
              cornerRadius={cornerRadius}
              updateQuestionViewParams={{
                navigateTo,
                updateQuestionView:
                  surveyViewDetailsStore.updateQuestionGraphType,
              }}
              allowSPoCToSelectGraphType={
                surveyViewDetailsStore.isSPoCChangeGraphAllowed
              }
              signInStore={signInStore}
            />
          </>
        );
      }
      case SurveyQuestionViewStoreNames.OPEN_ENDED_QUESTION_MODEL: {
        return (
          <>
            <OpenEndedQuestionView
              key={index}
              surveyQuestionView={
                surveyQuestionView as Instance<
                  typeof OpenEndedQuestionViewModelV1
                >
              }
              spacing={spacing}
              t={t}
              tokens={tokens}
              typography={typography}
              border={border}
              cornerRadius={cornerRadius}
              boxShadow={boxShadow}
              updateQuestionViewParams={{
                navigateTo,
                updateQuestionView:
                  surveyViewDetailsStore.updateQuestionGraphType,
              }}
              navigateTo={navigateTo}
              tableFilter={surveyViewDetailsStore.getFilters()}
              openEndedQuestionType={
                (
                  surveyQuestionView as Instance<
                    typeof OpenEndedQuestionViewModelV1
                  >
                ).getPaginatedOpenEndedResponsesRPCIndividualEnum
              }
              allowSPoCToSelectGraphType={
                surveyViewDetailsStore.isSPoCChangeGraphAllowed
              }
              signInStore={signInStore}
            />
          </>
        );
      }
      case SurveyQuestionViewStoreNames.MULTI_SELECT_QUESTION_MODEL: {
        return (
          <>
            <MultiSelectQuestionView
              key={index}
              surveyQuestionView={
                surveyQuestionView as Instance<
                  typeof MultiSelectQuestionViewModelV1
                >
              }
              spacing={spacing}
              t={t}
              tokens={tokens}
              typography={typography}
              border={border}
              cornerRadius={cornerRadius}
              boxShadow={boxShadow}
              updateQuestionViewParams={{
                navigateTo,
                updateQuestionView:
                  surveyViewDetailsStore.updateQuestionGraphType,
              }}
              isSurveyViewOptionDisplayTextSelected={
                surveyViewDetailsStore.isSurveyViewOptionDisplayTextSelected
              }
              allowSPoCToSelectGraphType={
                surveyViewDetailsStore.isSPoCChangeGraphAllowed
              }
              signInStore={signInStore}
            />
          </>
        );
      }
      case SurveyQuestionViewStoreNames.RANKING_QUESTION_MODEL: {
        return (
          <>
            <RankingQuestionView
              key={index}
              surveyQuestionView={
                surveyQuestionView as Instance<
                  typeof RankingQuestionViewModelV1
                >
              }
              spacing={spacing}
              t={t}
              tokens={tokens}
              typography={typography}
              border={border}
              cornerRadius={cornerRadius}
              boxShadow={boxShadow}
              updateQuestionViewParams={{
                navigateTo,
                updateQuestionView:
                  surveyViewDetailsStore.updateQuestionGraphType,
              }}
              isSurveyViewOptionDisplayTextSelected={
                surveyViewDetailsStore.isSurveyViewOptionDisplayTextSelected
              }
              allowSPoCToSelectGraphType={
                surveyViewDetailsStore.isSPoCChangeGraphAllowed
              }
              signInStore={signInStore}
            />
          </>
        );
      }
      case SurveyQuestionViewStoreNames.GROUP_QUESTION_MODEL: {
        return (
          <>
            <GroupQuestionView
              key={index}
              surveyQuestionView={
                surveyQuestionView as Instance<typeof GroupQuestionViewModelV1>
              }
              spacing={spacing}
              t={t}
              tokens={tokens}
              typography={typography}
              border={border}
              cornerRadius={cornerRadius}
              boxShadow={boxShadow}
              updateQuestionViewParams={{
                navigateTo,
                updateQuestionView:
                  surveyViewDetailsStore.updateQuestionGraphType,
              }}
              isSurveyViewOptionDisplayTextSelected={
                surveyViewDetailsStore.isSurveyViewOptionDisplayTextSelected
              }
              navigateTo={navigateTo}
              tableFilter={surveyViewDetailsStore.getFilters()}
              allowSPoCToSelectGraphType={
                surveyViewDetailsStore.isSPoCChangeGraphAllowed
              }
              signInStore={signInStore}
            />
          </>
        );
      }
      case SurveyQuestionViewStoreNames.GRID_QUESTION_MODEL: {
        return (
          <>
            <GridQuestionView
              key={index}
              surveyQuestionView={
                surveyQuestionView as Instance<typeof GridQuestionViewV1Model>
              }
              spacing={spacing}
              t={t}
              tokens={tokens}
              typography={typography}
              border={border}
              cornerRadius={cornerRadius}
              boxShadow={boxShadow}
              updateQuestionViewParams={{
                navigateTo,
                updateQuestionView:
                  surveyViewDetailsStore.updateQuestionGraphType,
              }}
              isSurveyViewOptionDisplayTextSelected={
                surveyViewDetailsStore.isSurveyViewOptionDisplayTextSelected
              }
              navigateTo={navigateTo}
              tableFilter={surveyViewDetailsStore.getFilters()}
              allowSPoCToSelectGraphType={
                surveyViewDetailsStore.isSPoCChangeGraphAllowed
              }
              signInStore={signInStore}
            />
          </>
        );
      }
    }
    return <></>;
  },
);
