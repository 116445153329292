import { Instance } from "mobx-state-tree";
import React, { ReactElement } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import {
  Spacing,
  Typography as LeoTypography,
  FoundationColorTokens,
  DropdownInputField,
  DropdownItem,
  IconButton,
  TextInputField,
  Button,
  CornerRadius,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { DashboardPreferencesStore } from "../../stores/DashboardPreferencesStore";
import { observer } from "mobx-react";
import {
  QuartileConfigurationModel,
  QuartileRanges,
} from "../../models/QuartileConfigurationModel";
import { MinusCircle, Plus } from "lucide-react";
import { EMPTY_CHARACTER } from "@pulse/shared-components";
import { QuartileColor } from "@pulse/pulse-rpcs";
import { getCamelCaseFromQuartileColor } from "../../../projects/utils/StringUtils";
import { QuartileColorSVG } from "./QuartileColorSVG";

interface QuartileRangeValueFieldProps {
  quartileConfiguration: Instance<typeof QuartileConfigurationModel>;
  t: TFunction;
  spacing: Spacing;
  isSaveButtonClicked: boolean;
  setIsSaveButtonClicked: (isSaveButtonClicked: boolean) => void;
  isStartValueGreaterThanEndValueError: boolean;
  clearRPCError: () => void;
}

const QuartileRangeValueField = observer(
  ({
    quartileConfiguration,
    t,
    spacing,
    isSaveButtonClicked,
    setIsSaveButtonClicked,
    clearRPCError,
    isStartValueGreaterThanEndValueError,
  }: QuartileRangeValueFieldProps): ReactElement => {
    const getBetweenValuesQuartileRangeValueFieldHelperText = (
      value1: string | undefined,
      value2: string | undefined,
      isFieldErrored: boolean,
      isValue1EndValue: boolean,
    ): string | undefined => {
      const startValue = isValue1EndValue ? value2 : value1;
      const endValue = isValue1EndValue ? value1 : value2;
      if (isStartValueGreaterThanEndValueError) {
        return t("surveyDashboard.startValueCannotBeGreaterThanEndValue");
      }
      if (!isFieldErrored || !isSaveButtonClicked) {
        return undefined;
      } else {
        if (value1 === undefined || value1 === EMPTY_CHARACTER) {
          return t("surveyDashboard.pleaseEnterAValidFloatingValue");
        } else if (Number(startValue) > Number(endValue)) {
          return t("surveyDashboard.startValueCannotBeGreaterThanEndValue");
        }
      }
    };
    const getQuartileRangeFieldHelperText = (
      value: string | undefined,
      isFieldErrored: boolean,
    ): string | undefined => {
      if (!isFieldErrored || !isSaveButtonClicked) {
        return undefined;
      } else if (value === undefined || value === EMPTY_CHARACTER) {
        return t("surveyDashboard.pleaseEnterAValidFloatingValue");
      }
    };
    switch (quartileConfiguration.quartileRange) {
      case QuartileRanges.LesserThan: {
        return (
          <TextInputField
            name="lesserThanValue"
            value={
              quartileConfiguration.endValue
                ? quartileConfiguration.endValue
                : undefined
            }
            type="decimal"
            onTextChange={(value: string): void => {
              setIsSaveButtonClicked(false);
              clearRPCError();
              quartileConfiguration.setEndValue(value);
            }}
            placeholder={t("common.valuePlaceholder")}
            label={t("common.value")}
            width="280px"
            error={
              quartileConfiguration.quartileConfigurationUIStore
                .isEndValueErrored
            }
            helperText={getQuartileRangeFieldHelperText(
              quartileConfiguration.endValue,
              quartileConfiguration.quartileConfigurationUIStore
                .isEndValueErrored,
            )}
          />
        );
      }
      case QuartileRanges.BetweenValues: {
        return (
          <Stack
            direction="row"
            gap={spacing.spaceXS}
            width="280px"
            alignItems="flex-start"
            justifyContent="flex-start"
            height="100%"
          >
            <TextInputField
              name="startValue"
              value={
                quartileConfiguration.startValue
                  ? quartileConfiguration.startValue
                  : undefined
              }
              type="decimal"
              onTextChange={(value: string): void => {
                setIsSaveButtonClicked(false);
                clearRPCError();
                quartileConfiguration.setStartValue(value);
              }}
              placeholder={t("common.valuePlaceholder")}
              label={t("surveyDashboard.startValue")}
              error={
                quartileConfiguration.quartileConfigurationUIStore
                  .isStartValueErrored
              }
              helperText={getBetweenValuesQuartileRangeValueFieldHelperText(
                quartileConfiguration.startValue,
                quartileConfiguration.endValue,
                quartileConfiguration.quartileConfigurationUIStore
                  .isStartValueErrored,
                false,
              )}
            />
            <Divider
              orientation="horizontal"
              sx={{
                width: "16px",
                padding: `50px 0 0 0`,
                height: "100%",
              }}
              flexItem
            />
            <TextInputField
              name="endValue"
              value={
                quartileConfiguration.endValue
                  ? quartileConfiguration.endValue
                  : undefined
              }
              type="decimal"
              onTextChange={(value: string): void => {
                setIsSaveButtonClicked(false);
                clearRPCError();
                quartileConfiguration.setEndValue(value);
              }}
              placeholder={t("common.valuePlaceholder")}
              label={t("surveyDashboard.endValue")}
              error={
                quartileConfiguration.quartileConfigurationUIStore
                  .isEndValueErrored
              }
              helperText={getBetweenValuesQuartileRangeValueFieldHelperText(
                quartileConfiguration.endValue,
                quartileConfiguration.startValue,
                quartileConfiguration.quartileConfigurationUIStore
                  .isEndValueErrored,
                true,
              )}
            />
          </Stack>
        );
      }
      case QuartileRanges.GreaterThan:
      default: {
        return (
          <TextInputField
            name="greaterThanValue"
            value={
              quartileConfiguration.startValue
                ? quartileConfiguration.startValue
                : undefined
            }
            type="decimal"
            onTextChange={(value: string): void => {
              setIsSaveButtonClicked(false);
              clearRPCError();
              quartileConfiguration.setStartValue(value);
            }}
            placeholder={t("common.valuePlaceholder")}
            label={t("common.value")}
            width="280px"
            error={
              quartileConfiguration.quartileConfigurationUIStore
                .isStartValueErrored
            }
            helperText={getQuartileRangeFieldHelperText(
              quartileConfiguration.startValue,
              quartileConfiguration.quartileConfigurationUIStore
                .isStartValueErrored,
            )}
          />
        );
      }
    }
  },
);

interface QuartileRowsProps {
  dashboardPreferencesStore: Instance<typeof DashboardPreferencesStore>;
  spacing: Spacing;
  t: TFunction;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  cornerRadius: CornerRadius;
}

const QuartileRows = observer(
  ({
    dashboardPreferencesStore,
    spacing,
    t,
    typography,
    tokens,
    cornerRadius,
  }: QuartileRowsProps): ReactElement => {
    const getQuartileRangeHelperText = (
      quartileRange: QuartileRanges | undefined,
      isQuartileRangeErrored: boolean,
    ): string | undefined => {
      if (!isQuartileRangeErrored) {
        return undefined;
      } else if (quartileRange) {
        return t("surveyDashboard.pleaseSelectAUniqueRange");
      } else {
        return t("surveyDashboard.pleaseSelectARange");
      }
    };
    const getQuartileColorHelperText = (
      quartileColor: QuartileColor.QuartileColor | undefined,
      isFieldErrored: boolean,
    ): string | undefined => {
      if (!isFieldErrored) {
        return undefined;
      } else if (quartileColor) {
        return t("surveyDashboard.pleaseSelectAUniqueColor");
      } else {
        return t("surveyDashboard.pleaseSelectAColor");
      }
    };

    return (
      <Stack gap={spacing.spaceXL} height="fit-content">
        {dashboardPreferencesStore.updatedQuestionConfigurations.map(
          (updatedQuestionConfiguration, index) => {
            return (
              <Stack key={index}>
                <Stack
                  direction="row"
                  gap={spacing.spaceSM}
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography {...typography.s1} color={tokens.label}>
                    {t("surveyDashboard.quartile", {
                      serialNumber: updatedQuestionConfiguration.serialNumber,
                    })}
                  </Typography>
                  <IconButton
                    icon={<MinusCircle />}
                    name="removeConfiguration"
                    size="medium"
                    variant="plain-color"
                    color="destructive"
                    onClick={(): void => {
                      dashboardPreferencesStore.clearAllErrors();
                      dashboardPreferencesStore.removeRow(index);
                    }}
                    disabled={
                      dashboardPreferencesStore.isRemoveRowButtonDisabled
                    }
                  />
                </Stack>
                <Stack direction="row" gap={spacing.spaceMD} flexWrap="wrap">
                  <DropdownInputField
                    name="quartileRange"
                    label={t("surveyDashboard.quartileRange")}
                    value={updatedQuestionConfiguration.quartileRange}
                    options={dashboardPreferencesStore.quartileRangeOptions}
                    onSelect={(value: DropdownItem): void => {
                      dashboardPreferencesStore.clearQuartileRangeErrors();
                      dashboardPreferencesStore.clearRPCError();
                      updatedQuestionConfiguration.setSelectedQuartileRange(
                        value.value as QuartileRanges,
                      );
                    }}
                    placeholder={t("surveyDashboard.selectRange")}
                    width="320px"
                    helperText={getQuartileRangeHelperText(
                      updatedQuestionConfiguration.quartileRange,
                      updatedQuestionConfiguration.quartileConfigurationUIStore
                        .isQuartileRangeErrored,
                    )}
                    error={
                      updatedQuestionConfiguration.quartileConfigurationUIStore
                        .isQuartileRangeErrored
                    }
                  />
                  <QuartileRangeValueField
                    quartileConfiguration={updatedQuestionConfiguration}
                    t={t}
                    spacing={spacing}
                    isSaveButtonClicked={
                      dashboardPreferencesStore.isSaveButtonClicked
                    }
                    setIsSaveButtonClicked={
                      dashboardPreferencesStore.setIsSaveButtonClicked
                    }
                    isStartValueGreaterThanEndValueError={
                      updatedQuestionConfiguration.isStartValueGreaterThanEndValue
                    }
                    clearRPCError={dashboardPreferencesStore.clearRPCError}
                  />
                  <DropdownInputField
                    name="quartileColor"
                    label={t("surveyDashboard.quartileColorLiteral")}
                    value={updatedQuestionConfiguration.quartileColor}
                    options={dashboardPreferencesStore.quartileColors.map(
                      (quartileColor) => {
                        return {
                          value: quartileColor.quartileColor,
                          name: t(
                            `surveyDashboard.quartileColor.${getCamelCaseFromQuartileColor(
                              QuartileColor.QuartileColor[
                                quartileColor.quartileColor as keyof typeof QuartileColor.QuartileColor
                              ],
                            )}`,
                          ),
                          image:
                            quartileColor.defaultSingleSelectQuartileColor ? (
                              <QuartileColorSVG
                                primitiveQuartileColor={
                                  quartileColor.primitiveQuartileColor
                                }
                                defaultSingleSelectQuartileColor={
                                  quartileColor.defaultSingleSelectQuartileColor
                                }
                                props={{
                                  width: "24px",
                                  height: "24px",
                                }}
                              />
                            ) : (
                              <Box
                                borderRadius={cornerRadius.radiusXL}
                                sx={{
                                  backgroundColor:
                                    quartileColor.primitiveQuartileColor,
                                }}
                                marginRight={spacing.spaceXS}
                                width="24px"
                                height="24px"
                              />
                            ),
                        };
                      },
                    )}
                    onSelect={(value: DropdownItem): void => {
                      dashboardPreferencesStore.clearQuartileColorErrors();
                      dashboardPreferencesStore.clearRPCError();
                      const selectedQuartileColor =
                        dashboardPreferencesStore.getSelectedQuartileColor(
                          QuartileColor.QuartileColor[
                            value.value as keyof typeof QuartileColor.QuartileColor
                          ],
                        );
                      updatedQuestionConfiguration.setSelectedQuartileColor(
                        QuartileColor.QuartileColor[
                          value.value as keyof typeof QuartileColor.QuartileColor
                        ],
                        selectedQuartileColor?.primitiveQuartileColor,
                        selectedQuartileColor?.defaultSingleSelectQuartileColor,
                      );
                    }}
                    placeholder={t("surveyDashboard.selectQuartileColor")}
                    width="240px"
                    error={
                      updatedQuestionConfiguration.quartileConfigurationUIStore
                        .isQuartileColorErrored
                    }
                    helperText={getQuartileColorHelperText(
                      updatedQuestionConfiguration.quartileColor,
                      updatedQuestionConfiguration.quartileConfigurationUIStore
                        .isQuartileColorErrored,
                    )}
                  />
                </Stack>
              </Stack>
            );
          },
        )}
        <Button
          name="addConfiguration"
          label={t("surveyDashboard.addNewConfiguration")}
          variant="outlined-neutral"
          icon={<Plus />}
          size="small"
          onClick={(): void => {
            dashboardPreferencesStore.addNewQuartileRow();
          }}
          disabled={
            dashboardPreferencesStore.isAddNewConfigurationButtonDisabled
          }
        />
      </Stack>
    );
  },
);

interface QuartileConfigurationProps {
  dashboardPreferencesStore: Instance<typeof DashboardPreferencesStore>;
  spacing: Spacing;
  typography: LeoTypography;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  cornerRadius: CornerRadius;
}

export const QuartileConfiguration = observer(
  ({
    typography,
    t,
    spacing,
    tokens,
    dashboardPreferencesStore,
    cornerRadius,
  }: QuartileConfigurationProps): ReactElement => {
    return (
      <Stack direction="row" gap={spacing.space4XL}>
        <Typography width="360px" {...typography.sh2} color={tokens.label}>
          {t("surveyDashboard.quartileConfiguration")}
        </Typography>
        <QuartileRows
          dashboardPreferencesStore={dashboardPreferencesStore}
          spacing={spacing}
          t={t}
          typography={typography}
          tokens={tokens}
          cornerRadius={cornerRadius}
        />
      </Stack>
    );
  },
);
