import React from "react";
import {
  RespondentResponseQuestionTypeStoreName,
  SubmittedResponseModel,
} from "../../models/RespondentSubmittedResponseModels/SubmittedResponseModel";
import { getType, Instance } from "mobx-state-tree";
import { BorderStyle, DeveloperErrorFlow } from "@pulse/shared-components";
import { OpenEndedResponse } from "./OpenEndedResponse";
import { OpenEndedResponseV1Model } from "../../models/RespondentSubmittedResponseModels/OpenEndedResponseV1Model";
import { observer } from "mobx-react";
import { QuestionType } from "@pulse/pulse-rpcs";
import {
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { QuestionContainer } from "./QuestionContainer";
import { Stack } from "@mui/material";
import { SingleSelectResponse } from "./SingleSelectResponse";
import { MultiSelectResponse } from "./MultiSelectResponse";
import { RankingResponse } from "./RankingResponse";
import { GridResponse } from "./GridResponse";
import { GroupResponse } from "./GroupResponse";
import { SingleSelectResponseV1Model } from "../../models/RespondentSubmittedResponseModels/SingleSelectResponseV1Model";
import { MultiSelectResponseV1Model } from "../../models/RespondentSubmittedResponseModels/MultiSelectResponseV1Model";
import { RankingResponseV1Model } from "../../models/RespondentSubmittedResponseModels/RankingResponseV1Model";
import { GridResponseV1Model } from "../../models/RespondentSubmittedResponseModels/GridResponseV1Model";
import { GroupResponsesV1Model } from "../../models/RespondentSubmittedResponseModels/GroupResponseV1Model";

interface RespondentResponsesProps {
  responses: Instance<typeof SubmittedResponseModel>[];
  border: BorderStyle;
  tokens: FoundationColorTokens<string>;
  cornerRadius: CornerRadius;
  spacing: Spacing;
  typography: Typography;
  t: TFunction;
}

export const RespondentResponses = observer(
  ({
    responses,
    border,
    cornerRadius,
    spacing,
    tokens,
    typography,
    t,
  }: RespondentResponsesProps): React.ReactElement => {
    return (
      <Stack width="100%">
        {responses.map((response, index) => {
          const paddingBottom =
            index === responses.length - 1 ? undefined : spacing.spaceLG;
          switch (getType(response.response).name) {
            case RespondentResponseQuestionTypeStoreName.OPEN_ENDED_RESPONSE_V1_MODEL: {
              return (
                <QuestionContainer
                  key={index}
                  border={border}
                  tokens={tokens}
                  spacing={spacing}
                  paddingBottom={paddingBottom}
                  questionHeaderProps={{
                    tokens,
                    cornerRadius,
                    typography,
                    t,
                    questionOrder: index + 1,
                    questionType: QuestionType.QuestionType.OPEN_ENDED,
                  }}
                >
                  <OpenEndedResponse
                    key={index}
                    question={response.question}
                    openEndedResponse={
                      response.response as Instance<
                        typeof OpenEndedResponseV1Model
                      >
                    }
                    t={t}
                    tokens={tokens}
                    typography={typography}
                    spacing={spacing}
                    border={border}
                  />
                </QuestionContainer>
              );
            }
            case RespondentResponseQuestionTypeStoreName.SINGLE_SELECT_RESPONSE_V1_MODEL: {
              return (
                <QuestionContainer
                  key={index}
                  border={border}
                  tokens={tokens}
                  spacing={spacing}
                  paddingBottom={paddingBottom}
                  questionHeaderProps={{
                    tokens,
                    cornerRadius,
                    typography,
                    t,
                    questionOrder: index + 1,
                    questionType: QuestionType.QuestionType.SINGLE_CHOICE,
                  }}
                >
                  <SingleSelectResponse
                    singleSelectResponse={
                      response.response as Instance<
                        typeof SingleSelectResponseV1Model
                      >
                    }
                    question={response.question}
                    t={t}
                    tokens={tokens}
                    typography={typography}
                  />
                </QuestionContainer>
              );
            }
            case RespondentResponseQuestionTypeStoreName.MULTI_SELECT_RESPONSE_V1_MODEL: {
              return (
                <QuestionContainer
                  key={index}
                  border={border}
                  tokens={tokens}
                  spacing={spacing}
                  paddingBottom={paddingBottom}
                  questionHeaderProps={{
                    tokens,
                    cornerRadius,
                    typography,
                    t,
                    questionOrder: index + 1,
                    questionType: QuestionType.QuestionType.MULTIPLE_CHOICE,
                  }}
                >
                  <MultiSelectResponse
                    multiSelectResponse={
                      response.response as Instance<
                        typeof MultiSelectResponseV1Model
                      >
                    }
                    question={response.question}
                    t={t}
                    tokens={tokens}
                    typography={typography}
                  />
                </QuestionContainer>
              );
            }
            case RespondentResponseQuestionTypeStoreName.RANKING_RESPONSE_V1_MODEL: {
              return (
                <QuestionContainer
                  key={index}
                  border={border}
                  tokens={tokens}
                  spacing={spacing}
                  paddingBottom={paddingBottom}
                  questionHeaderProps={{
                    tokens,
                    cornerRadius,
                    typography,
                    t,
                    questionOrder: index + 1,
                    questionType: QuestionType.QuestionType.RANKING,
                  }}
                >
                  <RankingResponse
                    rankingResponse={
                      response.response as Instance<
                        typeof RankingResponseV1Model
                      >
                    }
                    question={response.question}
                    t={t}
                    tokens={tokens}
                    typography={typography}
                  />
                </QuestionContainer>
              );
            }
            case RespondentResponseQuestionTypeStoreName.GRID_RESPONSE_V1_MODEL: {
              return (
                <QuestionContainer
                  key={index}
                  border={border}
                  tokens={tokens}
                  spacing={spacing}
                  paddingBottom={paddingBottom}
                  questionHeaderProps={{
                    tokens,
                    cornerRadius,
                    typography,
                    t,
                    questionOrder: index + 1,
                    questionType: QuestionType.QuestionType.GRID,
                  }}
                >
                  <GridResponse
                    gridResponse={
                      response.response as Instance<typeof GridResponseV1Model>
                    }
                    question={response.question}
                    t={t}
                    tokens={tokens}
                    typography={typography}
                    spacing={spacing}
                    border={border}
                  />
                </QuestionContainer>
              );
            }
            case RespondentResponseQuestionTypeStoreName.GROUP_RESPONSES_V1_MODEL: {
              return (
                <QuestionContainer
                  key={index}
                  border={border}
                  tokens={tokens}
                  spacing={spacing}
                  paddingBottom={paddingBottom}
                  questionHeaderProps={{
                    tokens,
                    cornerRadius,
                    typography,
                    t,
                    questionOrder: index + 1,
                    questionType: QuestionType.QuestionType.GROUP,
                  }}
                >
                  <GroupResponse
                    groupResponse={
                      response.response as Instance<
                        typeof GroupResponsesV1Model
                      >
                    }
                    question={response.question}
                    t={t}
                    tokens={tokens}
                    typography={typography}
                    spacing={spacing}
                    border={border}
                  />
                </QuestionContainer>
              );
            }
            default: {
              return <DeveloperErrorFlow />;
            }
          }
        })}
      </Stack>
    );
  },
);
