import {
  OpenEndedFieldResponseV1,
  SubmittedResponseEnums,
} from "@pulse/pulse-rpcs";
import { Instance, types } from "mobx-state-tree";

const FieldResponsesV1Model = types.model("FieldResponsesV1Model", {
  fieldTitle: types.maybeNull(types.string),
  answer: types.string,
});

const createFieldResponsesV1Model = (
  response: OpenEndedFieldResponseV1,
): Instance<typeof FieldResponsesV1Model> => {
  return FieldResponsesV1Model.create({
    fieldTitle: response.optionFieldTitle?.text,
    answer: response.answer,
  });
};

export const OpenEndedResponseV1Model = types.model(
  "OpenEndedResponseV1Model",
  {
    fieldResponses: types.array(FieldResponsesV1Model),
  },
);

export const createOpenEndedResponseV1Model = (
  openEndedResponse: SubmittedResponseEnums.Response.Version1Enums.ResponseTypeV1.OpenEnded,
): Instance<typeof OpenEndedResponseV1Model> => {
  return OpenEndedResponseV1Model.create({
    fieldResponses: openEndedResponse.fieldResponses.map((response) => {
      return createFieldResponsesV1Model(response);
    }),
  });
};
