import React from "react";
import {
  Dialog,
  FoundationColorTokens,
  Spacing,
  TextInputField,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import {
  CreateNewSurveyRPCErrors,
  ProjectDetailsStore,
} from "../../store/ProjectDetailsStore";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../../routes/RoutesHelper";
import { useEffect } from "react";
import {
  DeveloperErrorFlow,
  ICON_SIZE,
  MAX_SURVEY_LIMIT,
  PROJECT_SURVEY_NAME_VALIDATIONS,
  SINGLE_SPACING,
  getIconProps,
} from "@pulse/shared-components";
import { Stack } from "@mui/material";
import { InfoIcon } from "lucide-react";
import { DialogErrorContent } from "../../../../components/DialogErrorContent";
import { observer } from "mobx-react";
import { NestedTypographyComponent } from "../NestedTypographyComponent";
import { EMPTY_CHARACTER } from "@pulse/shared-components";

export enum CloneSurveyDialogState {
  CloneSurvey = "CLONE_SURVEY",
  ERROR = "ERROR",
}

interface CloneSurveyDialogProps {
  isCloneSurveyDialogOpen: boolean;
  t: TFunction;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  projectStore: Instance<typeof ProjectDetailsStore>;
  navigateTo: NavigateToFunctions;
  setIsCloneSurveyDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  surveyName: string | null;
  surveyId: string | null;
}

export const CloneSurveyDialog = observer(
  ({
    isCloneSurveyDialogOpen,
    t,
    spacing,
    tokens,
    typography,
    projectStore,
    navigateTo,
    setIsCloneSurveyDialogOpen,
    surveyName,
    surveyId,
  }: CloneSurveyDialogProps): React.ReactElement => {
    useEffect(() => {
      projectStore.surveyStore.setClonedSurveyName(
        surveyName +
          SINGLE_SPACING +
          t("projects.surveyDetails.cloneSurvey.cloneSurveySuffix"),
      );
      return () => {
        projectStore.clearSurveyNameAndRPCError();
      };
    }, []);

    const getSurveyNameTextFieldHelperText = (): string | undefined => {
      switch (projectStore.rpcError) {
        case CreateNewSurveyRPCErrors.SurveyNameNotUnique:
          return t("surveys.surveyNameHelperText.surveyNameNotUnique");
        case CreateNewSurveyRPCErrors.InvalidSurveyName:
          return t("surveys.surveyNameHelperText.invalidSurveyName", {
            allowedSpecialCharacters:
              PROJECT_SURVEY_NAME_VALIDATIONS.allowedSpecialCharacters,
          });
        case CreateNewSurveyRPCErrors.InvalidSurveyNameLength:
          return t("surveys.surveyNameHelperText.invalidSurveyNameLength", {
            minLength: PROJECT_SURVEY_NAME_VALIDATIONS.minLength,
            maxLength: PROJECT_SURVEY_NAME_VALIDATIONS.maxLength,
          });
      }
    };

    const getErrorText = (): string => {
      switch (projectStore.rpcError) {
        case CreateNewSurveyRPCErrors.MaximumSurveyCountExceeded:
          return t(
            "projects.surveyDetails.cloneSurvey.maxSurveyLimitReachedErrorText",
            {
              surveyLimit: MAX_SURVEY_LIMIT,
            },
          );
        case CreateNewSurveyRPCErrors.ProjectAlreadyArchived:
          return t(
            "projects.surveyDetails.cloneSurvey.projectArchivedErrorText",
          );
        default:
          return t(
            "projects.surveyDetails.cloneSurvey.unexpectedErrorDescription",
          );
      }
    };

    const getDialogChild = (): React.ReactElement => {
      switch (projectStore.cloneSurveyDialogState) {
        case CloneSurveyDialogState.CloneSurvey: {
          return (
            <Stack gap={spacing.spaceLG} maxWidth="520px">
              <Stack flexDirection="row" gap={spacing.spaceXS}>
                <InfoIcon
                  {...getIconProps(tokens.iconSubtle, ICON_SIZE.default)}
                />
                <NestedTypographyComponent
                  textColor={tokens.label}
                  startText={t(
                    "projects.surveyDetails.cloneSurvey.wouldYouLikeText",
                  )}
                  middleBoldText={surveyName ?? EMPTY_CHARACTER}
                  endText={t(
                    "projects.surveyDetails.cloneSurvey.cloneSurveyInfoText",
                  )}
                  startAndEndTextTypography={typography.b2}
                  boldTextTypography={typography.s2}
                  isSpan={true}
                />
              </Stack>
              <TextInputField
                name="cloneSurveyInputField"
                value={projectStore.surveyStore.clonedSurveyName}
                onTextChange={projectStore.surveyStore.setClonedSurveyName}
                label={t("surveys.addSurvey.addSurveyDialog.inputFieldTitle")}
                type="text"
                placeholder={t(
                  "surveys.addSurvey.addSurveyDialog.inputFieldPlaceholder",
                )}
                helperText={getSurveyNameTextFieldHelperText()}
                error={projectStore.doesStoreContainSurveyNameErrors}
                disabled={projectStore.isRPCLoading}
                required={true}
              />
            </Stack>
          );
        }
        case CloneSurveyDialogState.ERROR: {
          return (
            <DialogErrorContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              errorText={getErrorText()}
            />
          );
        }
        default:
          return <DeveloperErrorFlow />;
      }
    };

    return (
      <Dialog
        open={isCloneSurveyDialogOpen}
        width="560px"
        title={t("projects.surveyDetails.cloneSurvey.title")}
        contentPadding={spacing.spaceLG}
        primaryButtonText={
          !projectStore.isCloneSurveyDialogErrorState
            ? t("projects.surveyDetails.cloneSurvey.title")
            : undefined
        }
        onPrimaryButtonClick={async (): Promise<void> => {
          if (
            projectStore.validateClonedSurveyName(
              surveyName ?? EMPTY_CHARACTER,
            ) &&
            surveyId !== null
          ) {
            await processProjectParams(async (projectId) => {
              await projectStore.cloneSurvey(projectId, surveyId);
            }, navigateTo);
            if (
              projectStore.surveyStore.surveyId &&
              projectStore.surveyStore.projectId
            ) {
              navigateTo.surveyDetails(
                projectStore.surveyStore.projectId,
                projectStore.surveyStore.surveyId,
              );
            }
          }
        }}
        isPrimaryButtonDisabled={
          projectStore.isClonedSurveyPrimaryButtonDisabled
        }
        secondaryButtonText={
          projectStore.isCloneSurveyDialogErrorState
            ? t("common.close")
            : t("surveys.addSurvey.addSurveyDialog.secondaryButtonText")
        }
        onSecondaryButtonClick={(): void => {
          setIsCloneSurveyDialogOpen(false);
          projectStore.clearSurveyNameAndRPCError();
        }}
        isSecondaryButtonDisabled={
          projectStore.isCreateSurveySecondaryButtonDisabled
        }
        disableBackdropClick={true}
      >
        {/*
        getDialogChild() is not called like <DialogChild />.
        This is done to avoid giving it its own lifecycle which will result in loss of focus from <TextInputField /> on store updation.
        */}
        {getDialogChild()}
      </Dialog>
    );
  },
);
