import { observer } from "mobx-react";
import { ChildQuestionItemButton } from "./ChildQuestionItemButton";
import React, { SetStateAction } from "react";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import { SurveyQuestionModel } from "../../models/SurveyQuestionModel";
import { QuestionDetailsStore } from "../../store/QuestionDetailsStore";
import {
  ChevronRight,
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
  CornerRadius,
} from "@surya-digital/leo-reactjs-material-ui";
import { Stack, Typography } from "@mui/material";
import {
  BorderStyle,
  CHEVRON_ICON_SIZE,
  getStringDefaultIfUndefined,
} from "@pulse/shared-components";
import { QuestionnaireItemHeader } from "./QuestionnaireItemHeader";
import { IconButton } from "@surya-digital/leo-reactjs-material-ui";

export interface GroupCustomisationQuestionItemProps {
  childQuestionDetails: Instance<typeof SurveyQuestionModel>;
  setSelectedQuestionItemId: (value: SetStateAction<string | null>) => void;
  setIsMessageBoxVisible: (value: SetStateAction<boolean>) => void;
  setMessageBoxQuestionItemId: (value: SetStateAction<string | null>) => void;
  questionDetailsStore: Instance<typeof QuestionDetailsStore>;
  t: TFunction;
  typography: LeoTypography;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  border: BorderStyle;
  isBorderBottomRendered: boolean;
  borderRadius: CornerRadius;
}

export const GroupCustomisationQuestionItem = observer(
  ({
    setIsMessageBoxVisible,
    setMessageBoxQuestionItemId,
    setSelectedQuestionItemId,
    childQuestionDetails,
    t,
    questionDetailsStore,
    typography,
    spacing,
    tokens,
    border,
    isBorderBottomRendered,
    borderRadius,
  }: GroupCustomisationQuestionItemProps): React.ReactElement => {
    const QuestionItem = observer((): React.ReactElement => {
      return (
        <Stack
          spacing={spacing.spaceSM}
          padding={spacing.spaceLG}
          bgcolor={tokens.backgroundElevatedLevel1}
          borderBottom={isBorderBottomRendered ? border.default : undefined}
          direction="row"
          width="100%"
          sx={{
            "&:hover": {
              background: tokens.backgroundHoveredElevated,
            },
          }}
          alignItems="flex-start"
          borderRadius={
            isBorderBottomRendered
              ? `${borderRadius.radiusXXS} ${borderRadius.radiusXXS} 0px 0px`
              : borderRadius.radiusXXS
          }
        >
          <Stack gap={spacing.spaceXS} width="100%">
            <QuestionnaireItemHeader
              tokens={tokens}
              typography={typography}
              t={t}
              questionCode={
                childQuestionDetails.surveyQuestionDetails.questionCode
              }
              questionType={
                childQuestionDetails.surveyQuestionDetails.questionType
              }
              isRuleApplied={childQuestionDetails.isRuleApplied}
              isVisibleByDefault={childQuestionDetails.isVisibleByDefault}
              spacing={spacing}
              isRuleAppliedIconVisible={true}
              isVisiblityIconVisible={true}
            />
            <Stack
              minHeight={
                childQuestionDetails.surveyQuestionDetails.question
                  ? "24px"
                  : "auto"
              }
            >
              <Typography
                sx={{
                  ...typography.s1,
                  width: "fit-content",
                  color: tokens.label,
                }}
              >
                {getStringDefaultIfUndefined(
                  childQuestionDetails.surveyQuestionDetails.question,
                )}
              </Typography>
            </Stack>
          </Stack>
          <Stack gap={spacing.spaceXS} alignItems={"center"}>
            <IconButton
              name="chevronRight"
              size="medium"
              variant="plain-neutral"
              icon={
                <ChevronRight
                  width={CHEVRON_ICON_SIZE}
                  height={CHEVRON_ICON_SIZE}
                  color={tokens.icon}
                />
              }
              onClick={() => {
                // This onClick is being handled in the ChildQuestionItemButton component. Here we are using IconButton as per the design.
              }}
            />
          </Stack>
        </Stack>
      );
    });

    return (
      <ChildQuestionItemButton
        childQuestionDetails={childQuestionDetails}
        setSelectedItemQuestionId={setSelectedQuestionItemId}
        setIsMessageBoxVisible={setIsMessageBoxVisible}
        setMessageBoxQuestionItemId={setMessageBoxQuestionItemId}
        questionDetails={questionDetailsStore}
        questionId={childQuestionDetails.surveyQuestionDetails.questionId}
        t={t}
      >
        <QuestionItem />
      </ChildQuestionItemButton>
    );
  },
);
