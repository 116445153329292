import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  DashboardPreferencesStore,
  OptionDisplayEnum,
  QuartileFieldInvalidValueError,
} from "../../stores/DashboardPreferencesStore";
import { Instance } from "mobx-state-tree";
import { QuartileConfiguration } from "./QuartileConfiguration";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioProps,
  Stack,
  Typography,
} from "@mui/material";
import {
  FoundationColorTokens,
  LoadingIndicator,
  Spacing,
  Typography as LeoTypography,
  RadioButtonColorTokens,
  CornerRadius,
  Snackbar,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import {
  NavigateToFunctions,
  processSurveyParams,
} from "../../../../routes/RoutesHelper";
import { observer } from "mobx-react";
import { DividerComponent } from "../../../surveys/components/commonQuestionConfigurationDetailsComponents/DividerComponent";
import { RadioLabelTypography } from "../AddViewDialogChild";
import { getRadioButtonStyleProps } from "@pulse/shared-components";
import { UpdateSurveyDashboardPreferencesRPC } from "@pulse/pulse-rpcs";
import { MAXIMUM_QUARTILE_CONFIGURATIONS } from "../../../utils/constants";

interface OptionDisplayProps {
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  t: TFunction;
  typography: LeoTypography;
  dashboardPreferencesStore: Instance<typeof DashboardPreferencesStore>;
  radioProps: RadioProps;
}

const OptionDisplay = observer(
  ({
    spacing,
    t,
    tokens,
    typography,
    dashboardPreferencesStore,
    radioProps,
  }: OptionDisplayProps): ReactElement => {
    return (
      <Stack direction="row" gap={spacing.space4XL}>
        <Typography {...typography.sh2} color={tokens.label} width="360px">
          {t("surveyDashboard.optionDisplay")}
        </Typography>
        <RadioGroup
          value={dashboardPreferencesStore.updatedOptionDisplaySelectedOption}
          onChange={(_, value) => {
            switch (value) {
              case OptionDisplayEnum.OptionCode:
                dashboardPreferencesStore.setUpdatedIsSurveyViewOptionDisplayTextSelected(
                  OptionDisplayEnum.OptionCode,
                );
                break;
              case OptionDisplayEnum.OptionText:
                dashboardPreferencesStore.setUpdatedIsSurveyViewOptionDisplayTextSelected(
                  OptionDisplayEnum.OptionText,
                );
                break;
              default: {
                console.error(
                  `The value ${value} is not a valid DashboardCustomisation.`,
                );
              }
            }
          }}
          sx={{
            gap: spacing.spaceSM,
          }}
        >
          <FormControlLabel
            control={<Radio {...radioProps} />}
            value={OptionDisplayEnum.OptionText}
            label={
              <RadioLabelTypography
                title={t("surveyDashboard.optionText")}
                subTitle={t("surveyDashboard.optionTextDescription")}
                tokens={tokens}
                typography={typography}
              />
            }
          />
          <FormControlLabel
            control={<Radio {...radioProps} />}
            value={OptionDisplayEnum.OptionCode}
            label={
              <RadioLabelTypography
                title={t("surveyDashboard.optionCode")}
                subTitle={t("surveyDashboard.optionCodeDescription")}
                tokens={tokens}
                typography={typography}
              />
            }
          />
        </RadioGroup>
      </Stack>
    );
  },
);

interface DashboardPreferencesProps {
  dashboardPreferencesStore: Instance<typeof DashboardPreferencesStore>;
  spacing: Spacing;
  typography: LeoTypography;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  navigateTo: NavigateToFunctions;
  radioButtonTokens: RadioButtonColorTokens<string>;
  cornerRadius: CornerRadius;
}

export const DashboardPreferences = observer(
  ({
    dashboardPreferencesStore,
    spacing,
    typography,
    t,
    tokens,
    navigateTo,
    radioButtonTokens,
    cornerRadius,
  }: DashboardPreferencesProps): ReactElement => {
    const getSurveyDashboardPreferences =
      useCallback(async (): Promise<void> => {
        await processSurveyParams(
          dashboardPreferencesStore.getSurveyDashboardPreferences,
          navigateTo,
        );
      }, []);
    useEffect(() => {
      getSurveyDashboardPreferences();
    }, []);
    const radioProps = getRadioButtonStyleProps(
      tokens,
      radioButtonTokens,
      spacing,
      `0px ${spacing.spaceXS}`,
    );
    const [isErrorSnackbarVisible, setIsErrorSnackbarVisible] = useState(false);

    useEffect(() => {
      if (dashboardPreferencesStore.isRPCErrorPresent) {
        setIsErrorSnackbarVisible(true);
      }
    }, [dashboardPreferencesStore.isRPCErrorPresent]);

    const getSnackbarMessage = (): string => {
      switch (dashboardPreferencesStore.rpcError) {
        case UpdateSurveyDashboardPreferencesRPC.RPCError
          .ProjectAlreadyArchived: {
          return t("common.projectAlreadyArchivedErrorText");
        }
        case UpdateSurveyDashboardPreferencesRPC.RPCError
          .MaximumQuartileConfigurationsReached: {
          return t(
            "surveyDashboard.maximumQuartileConfigurationsReachedErrorMessage",
            { maximumQuartileConfigurations: MAXIMUM_QUARTILE_CONFIGURATIONS },
          );
        }
        case UpdateSurveyDashboardPreferencesRPC.RPCError
          .QuartileColorNotUnique:
        case UpdateSurveyDashboardPreferencesRPC.RPCError
          .FoundDuplicateLesserThanQuartileRange:
        case UpdateSurveyDashboardPreferencesRPC.RPCError
          .FoundDuplicateGreaterThanQuartileRange:
        case QuartileFieldInvalidValueError.QuartileFieldInvalidValueError:
        case UpdateSurveyDashboardPreferencesRPC.RPCError
          .StartValueGreaterThanEndValue: {
          return t("surveyDashboard.pleaseEnsureDataFilledIsInValidFormat");
        }
        case UpdateSurveyDashboardPreferencesRPC.RPCError.InvalidSerialNumber:
        default: {
          return t("common.genericUnexpectedErrorTextWhileSavingChanges");
        }
      }
    };

    return dashboardPreferencesStore.isRPCLoading ? (
      <LoadingIndicator isLoading={true} />
    ) : (
      <Stack
        padding={spacing.space2XL}
        gap={spacing.space2XL}
        width="100%"
        height="100%"
        overflow="auto"
      >
        {dashboardPreferencesStore.isRPCErrorPresent && (
          <Snackbar
            isOpen={
              isErrorSnackbarVisible &&
              dashboardPreferencesStore.isRPCErrorPresent
            }
            severity="error"
            message={getSnackbarMessage()}
            isDismissible={true}
            onClose={() => {
              setIsErrorSnackbarVisible(false);
            }}
          />
        )}
        <LoadingIndicator
          isLoading={
            dashboardPreferencesStore.isUpdateSurveyDashboardPreferencesRPCLoading
          }
        />
        <QuartileConfiguration
          dashboardPreferencesStore={dashboardPreferencesStore}
          spacing={spacing}
          typography={typography}
          t={t}
          tokens={tokens}
          cornerRadius={cornerRadius}
        />
        <DividerComponent orientation="horizontal" width="100%" />
        <OptionDisplay
          dashboardPreferencesStore={dashboardPreferencesStore}
          radioProps={radioProps}
          spacing={spacing}
          tokens={tokens}
          t={t}
          typography={typography}
        />
      </Stack>
    );
  },
);
