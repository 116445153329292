import {
  Dialog,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import React, { useEffect } from "react";
import {
  SingleSelectDropdownOptionsUploadState,
  SingleSelectDropdownOptionsUploadStore,
} from "../../store/SingleSelectDropdownOptionsUploadStore";
import { DialogSuccessContent } from "../../../../components/DialogSuccessContent";
import { DialogErrorContent } from "../../../../components/DialogErrorContent";
import { NavigateToFunctions } from "../../../../routes/RoutesHelper";
import { UploadSingleSelectDropdownOptionsDialogChild } from "./UploadSingleSelectDropdownOptionsDialogChild";
import { observer } from "mobx-react";
import {
  SingleChoiceOption,
  ValidateCSVAndUpdateQuestionOptionsRPC,
} from "@pulse/pulse-rpcs";

interface UploadSingleSelectDropdownOptionsDialogProps {
  isUploadSingleSelectDropdownOptionsDialogOpen: boolean;
  setIsUploadDropdownOptionsCSVDialogVisible: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  t: TFunction;
  singleSelectDropdownOptionsUploadStore: Instance<
    typeof SingleSelectDropdownOptionsUploadStore
  >;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  navigateTo: NavigateToFunctions;
  questionId: string | null;
  updateOptions: (options: SingleChoiceOption[]) => void;
}

export const UploadSingleSelectDropdownOptionsDialog = observer(
  ({
    isUploadSingleSelectDropdownOptionsDialogOpen,
    setIsUploadDropdownOptionsCSVDialogVisible,
    t,
    singleSelectDropdownOptionsUploadStore,
    spacing,
    tokens,
    typography,
    navigateTo,
    questionId,
    updateOptions,
  }: UploadSingleSelectDropdownOptionsDialogProps): React.ReactElement => {
    useEffect(() => {
      return () => {
        singleSelectDropdownOptionsUploadStore.resetStore();
      };
    }, []);
    const getSecondaryButtonText = (): string => {
      switch (
        singleSelectDropdownOptionsUploadStore.singleSelectDropdownOptionsUploadState
      ) {
        case SingleSelectDropdownOptionsUploadState.SingleSelectDropdownOptionsUpload:
        case SingleSelectDropdownOptionsUploadState.ValidationErrorFound: {
          return t("common.cancel");
        }
        case SingleSelectDropdownOptionsUploadState.Error: {
          return t("common.close");
        }
        case SingleSelectDropdownOptionsUploadState.FileUploaded: {
          return t("common.done");
        }
      }
    };

    const getErrorMessage = (): string => {
      switch (singleSelectDropdownOptionsUploadStore.rpcError) {
        case ValidateCSVAndUpdateQuestionOptionsRPC.RPCError
          .ProjectAlreadyArchived: {
          return t("common.projectAlreadyArchivedErrorText");
        }
        case ValidateCSVAndUpdateQuestionOptionsRPC.RPCError.SurveyIsClosed: {
          return t(
            "surveys.singleSelectDropdownUploadCSV.uploadErrorMessage.surveyIsClosed",
          );
        }
        case ValidateCSVAndUpdateQuestionOptionsRPC.RPCError.SurveyIsLive: {
          return t(
            "surveys.singleSelectDropdownUploadCSV.uploadErrorMessage.surveyIsLive",
          );
        }
        case ValidateCSVAndUpdateQuestionOptionsRPC.RPCError
          .QuestionWasDeleted: {
          return t(
            "surveys.singleSelectDropdownUploadCSV.uploadErrorMessage.questionWasDeleted",
          );
        }
        default: {
          return t(
            "surveys.singleSelectDropdownUploadCSV.uploadErrorMessageText",
          );
        }
      }
    };

    const getDialogChild = (): React.ReactElement => {
      switch (
        singleSelectDropdownOptionsUploadStore.singleSelectDropdownOptionsUploadState
      ) {
        case SingleSelectDropdownOptionsUploadState.SingleSelectDropdownOptionsUpload:
        case SingleSelectDropdownOptionsUploadState.ValidationErrorFound: {
          return (
            <UploadSingleSelectDropdownOptionsDialogChild
              navigateTo={navigateTo}
              singleSelectDropdownOptionsUploadStore={
                singleSelectDropdownOptionsUploadStore
              }
              spacing={spacing}
              t={t}
              tokens={tokens}
              typography={typography}
              questionId={questionId}
              updateOptions={updateOptions}
            />
          );
        }
        case SingleSelectDropdownOptionsUploadState.FileUploaded: {
          return (
            <DialogSuccessContent
              description={t(
                "surveys.singleSelectDropdownUploadCSV.uploadSuccessMessage",
              )}
              spacing={spacing}
              tokens={tokens}
              typography={typography}
            />
          );
        }
        case SingleSelectDropdownOptionsUploadState.Error: {
          return (
            <DialogErrorContent
              errorText={getErrorMessage()}
              spacing={spacing}
              tokens={tokens}
              typography={typography}
            />
          );
        }
      }
    };

    return (
      <Dialog
        open={isUploadSingleSelectDropdownOptionsDialogOpen}
        disableBackdropClick={true}
        isSecondaryButtonDisabled={
          singleSelectDropdownOptionsUploadStore.isRPCLoading
        }
        onSecondaryButtonClick={() => {
          setIsUploadDropdownOptionsCSVDialogVisible(false);
        }}
        secondaryButtonText={getSecondaryButtonText()}
        title={t("common.uploadCSV")}
        width="560px"
      >
        {getDialogChild()}
      </Dialog>
    );
  },
);
