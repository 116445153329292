import { Stack } from "@mui/material";
import {
  EMPTY_CHARACTER,
  getIconProps,
  ICON_SIZE,
  IconContainer,
} from "@pulse/shared-components";
import {
  Button,
  FoundationColorTokens,
  List,
  ListItems,
  PaginatedViewOptions,
  Spacing,
  TextInputField,
  Typography as LeoTypography,
  EllipsisTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { ListX, Search } from "lucide-react";
import React from "react";
import { DividerComponent } from "../../surveys/components/commonQuestionConfigurationDetailsComponents/DividerComponent";
import { Instance } from "mobx-state-tree";
import { OpenEndedResponsesModel } from "../models/OpenEndedResponsesModel";
import {
  NavigateToFunctions,
  processSurveyViewParams,
} from "../../../routes/RoutesHelper";
import {
  OPEN_ENDED_RESPONSES_LIST_DEFAULT_INDEX,
  OPEN_ENDED_RESPONSES_LIST_SIZE,
} from "../../utils/constants";
import { observer } from "mobx-react";
import {
  GetPaginatedOpenEndedResponsesRPC,
  TableFilter,
} from "@pulse/pulse-rpcs";

interface SearchComponentProps {
  spacing: Spacing;
  t: TFunction;
}

// eslint-disable-next-line no-unused-vars
const SearchComponent = ({
  spacing,
  t,
}: SearchComponentProps): React.ReactElement => {
  return (
    <Stack
      padding={`${spacing.spaceLG} ${spacing.spaceLG} ${spacing.spaceSM} ${spacing.spaceLG}`}
      gap={spacing.spaceSM}
      width="480px"
      direction="row"
      alignItems="center"
    >
      <TextInputField
        adornmentIcon={<Search />}
        name={"search-response"}
        value={undefined}
        type={"text"}
        onTextChange={(): void => {}}
        placeholder={t("surveyDashboard.searchForResponses")}
        label={EMPTY_CHARACTER}
        disabled={true}
        adornmentPosition="leading"
      />
      <Button
        label={t("surveyDashboard.search")}
        onClick={(): void => {}}
        name={t("surveyDashboard.search")}
        size={"large"}
        variant={"outlined-neutral"}
        disabled={true}
      />
    </Stack>
  );
};

interface OpenEndedResponsesListProps {
  openEndedResponsesStore: Instance<typeof OpenEndedResponsesModel>;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  surveyQuestionViewId: string;
  navigateTo: NavigateToFunctions;
  spacing: Spacing;
  typography: LeoTypography;
  tableFilter: TableFilter[];
  openEndedQuestionType: GetPaginatedOpenEndedResponsesRPC.RequestEnums.OpenEndedQuestionType.OpenEndedQuestionType;
  setIsRPCLoading: (isRPCLoading: boolean) => void;
  fieldCode: string;
}

const OpenEndedResponsesList = observer(
  ({
    openEndedResponsesStore,
    t,
    tokens,
    surveyQuestionViewId,
    navigateTo,
    spacing,
    typography,
    tableFilter,
    openEndedQuestionType,
    setIsRPCLoading,
    fieldCode,
  }: OpenEndedResponsesListProps): React.ReactElement => {
    const getOpenEndedResponses = async (
      options: PaginatedViewOptions,
      setTotalItems: React.Dispatch<React.SetStateAction<number>>,
    ): Promise<string | ListItems> => {
      setIsRPCLoading(true);
      await processSurveyViewParams(
        async (
          surveyViewId: string,
          surveyId: string,
          projectId: string,
        ): Promise<void> => {
          await openEndedResponsesStore.getPaginatedOpenEndedResponses(
            projectId,
            surveyId,
            surveyViewId,
            surveyQuestionViewId,
            options.pageNumber
              ? options.pageNumber - 1
              : OPEN_ENDED_RESPONSES_LIST_DEFAULT_INDEX,
            tableFilter,
            openEndedQuestionType,
            fieldCode,
          );
          setIsRPCLoading(false);
        },
        navigateTo,
      );

      setTotalItems(openEndedResponsesStore.totalItems);

      return openEndedResponsesStore.openEndedResponses.map(
        (openEndedResponse, index) => {
          return (
            <Stack
              key={index}
              padding={`${spacing.spaceSM} ${spacing.spaceLG}`}
            >
              <EllipsisTypography
                {...typography.b2}
                textColor="label"
                maxLines={3}
              >
                {openEndedResponse}
              </EllipsisTypography>
            </Stack>
          );
        },
      );
    };

    return (
      <List
        name={EMPTY_CHARACTER}
        onListOptionsChange={getOpenEndedResponses}
        viewOverrides={{
          empty: {
            icon: IconContainer(
              <ListX
                {...getIconProps(tokens.iconLowEmphasis, ICON_SIZE.large)}
              />,
            ),
            message: t("surveyDashboard.noOpenEndedResponsesFound"),
          },
          loading: {
            message: t("surveyDashboard.loadingResponsesPleaseWait"),
          },
        }}
        styleOverrides={{
          border: "none",
          margin: `${spacing.spaceLG} ${spacing.space2XL} 0px ${spacing.spaceLG}`,
        }}
        paginationOption={{
          itemsPerPage: OPEN_ENDED_RESPONSES_LIST_SIZE,
          position: "BOTTOM",
          getPageIndicatorText(startItem, endItem, totalItems): string {
            return t("common.paginationIndicationText", {
              startItem,
              endItem,
              totalItems,
            });
          },
        }}
      />
    );
  },
);

interface ViewOpenEndedResponsesDialogChildProps {
  spacing: Spacing;
  t: TFunction;
  openEndedResponsesStore: Instance<typeof OpenEndedResponsesModel>;
  tokens: FoundationColorTokens<string>;
  surveyQuestionViewId: string;
  navigateTo: NavigateToFunctions;
  typography: LeoTypography;
  tableFilter: TableFilter[];
  openEndedQuestionType: GetPaginatedOpenEndedResponsesRPC.RequestEnums.OpenEndedQuestionType.OpenEndedQuestionType;
  setIsRPCLoading: (isRPCLoading: boolean) => void;
  fieldCode: string;
}

export const ViewOpenEndedResponsesDialogChild = observer(
  ({
    spacing,
    t,
    openEndedResponsesStore,
    tokens,
    surveyQuestionViewId,
    navigateTo,
    typography,
    tableFilter,
    openEndedQuestionType,
    setIsRPCLoading,
    fieldCode,
  }: ViewOpenEndedResponsesDialogChildProps): React.ReactElement => {
    return (
      <Stack
        divider={<DividerComponent orientation="horizontal" width="100%" />}
        width="100%"
      >
        {/* <SearchComponent spacing={spacing} t={t} /> */}
        <OpenEndedResponsesList
          openEndedResponsesStore={openEndedResponsesStore}
          t={t}
          tokens={tokens}
          surveyQuestionViewId={surveyQuestionViewId}
          navigateTo={navigateTo}
          spacing={spacing}
          typography={typography}
          tableFilter={tableFilter}
          openEndedQuestionType={openEndedQuestionType}
          setIsRPCLoading={setIsRPCLoading}
          fieldCode={fieldCode}
        />
      </Stack>
    );
  },
);
