import {
  Instance,
  types,
  cast,
  flow,
  applySnapshot,
  getRoot,
  getParentOfType,
  clone,
} from "mobx-state-tree";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import {
  EditRespondentDetailsRPC,
  GetRespondentDetailsRPC,
  Respondent,
  RespondentSelection,
  SurveyLinkStatus,
  AssignSurveyRPC,
  DeleteColumnRPC,
  RespondentColumnDetails,
  RespondentColumnName,
  TableFilter,
  UnassignSurveyRPC,
  DeleteRespondentRPC,
  RespondentGetPresignedDownloadURLForRespondentsWithFiltersRPC,
  AssignSpocsToRespondentsRPC,
  GetRespondentSpocsRPC,
  GetProjectSpocDetailsRPC,
} from "@pulse/pulse-rpcs";
import { APIClient } from "@surya-digital/tedwig";
import {
  useEditRespondentDetailsRPCClientImpl,
  useAssignSurveyRPCClientImpl,
  useDeleteColumnRPCClientImpl,
  useDeleteRespondentRPCClientImpl,
  useGetRespondentDetailsRPCClientImpl,
  useUnassignSurveyRPCClientImpl,
  useGetPresignedDownloadURLForRespondentsWithFiltersRPCClient,
  useAssignSpocsToRespondentsRPCClientImpl,
  useGetRespondentSpocsRPCClientImpl,
  useGetProjectSpocDetailsRPCClientImpl,
} from "../rpcs/RPC";
import {
  RespondentColumnDetailsModel,
  TableColumnHeader,
  createRespondentColumnDetailsModel,
} from "../models/RespondentColumnDetailsModel";
import {
  AutoCompleteItem,
  DropdownItem,
  MultiSelectTableStore,
  TableSortOption,
  createMultiSelectTableStore,
} from "@surya-digital/leo-reactjs-material-ui";
import { getSortOrder } from "../utils/TableUtils";
import {
  CommonErrors,
  DEFAULT_ITEMS_PER_PAGE_IN_PG_TABLE,
  EMPTY_CHARACTER,
  EMPTY_LIST_LENGTH,
  LIST_WITH_SINGLE_ELEMENT,
  NetworkingError,
  RESPONDENTS_CSV_NAME,
  TEMPLATE_FILE_DELIMITER,
  getDelimitedFileName,
} from "@pulse/shared-components";
import {
  RespondentUploadStore,
  UploadType,
  createRespondentUploadStore,
} from "./RespondentUploadStore";
import { getAPIClient } from "../../networking/APIClient";
import { TableFilterEnums } from "@pulse/pulse-rpcs";
import {
  RespondentWithSurveyDetailsModel,
  createRespondentWithSurveyDetailsModel,
} from "../models/RespondentWithSurveyDetailsModel";
import {
  FilterStore,
  createFilterStore,
} from "../../../components/filter/store/FilterStore";
import { FilterType } from "../../../components/filter/model/FilterModel";
import {
  DownloadRespondentCSVDialogState,
  GetPresignedDownloadURLForRespondentsWithFiltersErrors,
  RespondentDownloadStore,
  createRespondentDownloadStore,
} from "./RespondentDownloadStore";
import { RespondentModel } from "../models/RespondentModel";
import {
  getRespondentColumnOrNull,
  getRespondentNameOrNull,
  getTrimmedStringOrNull,
} from "../utils/StringUtils";
import {
  SurveyIdAndNameModel,
  createSurveyIdAndNameModel,
} from "../models/SurveyIdAndNameModel";
import {
  RespondentUIStore,
  UnassignRespondentDialogState,
  createRespondentUIStore,
} from "./RespondentUIStore";
import { RespondentFilters } from "../components/RespondentsPane";
import { downloadFile } from "../../utils/FileDownloadUtils";
import { EditRespondentDialogState } from "../components/RespondentListChildComponents/EditRespondentDetailsDialog";
import { RootStore } from "../../root/store/RootStore";
import {
  SpocUserDetailsModel,
  createSpocUserDetailsModel,
} from "../../models/SpocUserDetailsModel";
import { ProjectDetailsStore } from "./ProjectDetailsStore";
import { getFormattedLeoDate } from "../../utils/DateUtils";
import {
  AutoCompleteItemModel,
  createAutoCompleteItemModel,
} from "../../models/AutoCompleteItemModel";
import {
  ReOpenSurveyLinkStore,
  createReOpenSurveyLinkStore,
} from "./ReOpenSurveyLinkStore";
import { TFunction } from "i18next";
import {
  createRespondentResponseStore,
  RespondentResponseStore,
} from "./RespondentResponseStore";

export enum GetRespondentDetailsError {
  InvalidPageIndex = "INVALID_PAGE_INDEX",
  DeletedColumnFilter = "DELETED_COLUMN_FILTER",
}

export enum DeleteColumnError {
  InvalidRespondentColumn = "INVALID_RESPONDENT_COLUMN",
  ProjectArchived = "PROJECT_ARCHIVED",
  ProjectUniqueColumnCannotBeDeleted = "PROJECT_UNIQUE_COLUMN_CANNOT_BE_DELETED",
}

export enum DeleteColumnDialogState {
  SelectColumnState = "SELECT_COLUMN_TO_DELETE",
  SuccessState = "SUCCESS_STATE",
  ErrorState = "ERROR_STATE",
}

export enum UnassignSurveyError {
  DeletedColumnFilter = "DELETED_COLUMN_FILTER",
  InvalidRespondentIdFound = "INVALID_RESPONDENT_ID_FOUND",
  ProjectAlreadyArchived = "PROJECT_ALREADY_ARCHIVED",
  RespondentsAlreadyUnassigned = "RESPONDENTS_ALREADY_UNASSIGNED",
  SurveyAlreadySubmitted = "SURVEY_ALREADY_SUBMITTED",
}

export enum DeleteRespondentError {
  DeletedColumnFilter = "DELETED_COLUMN_FILTER",
  InvalidRespondent = "INVALID_RESPONDENT",
  ProjectArchived = "PROJECT_ARCHIVED",
}

export enum EditRespondentDetailsError {
  SurveyIsClosed = "SURVEY_IS_CLOSED",
  InvalidRespondentId = "INVALID_RESPONDENT_ID",
  ProjectArchived = "PROJECT_ARCHIVED",
  SurveyAlreadySubmitted = "SURVEY_ALREADY_SUBMITTED",
  NoSurveyQuestionsPresent = "NO_SURVEY_QUESTIONS_PRESENT",
  RespondentAssignmentInProgress = "RESPONDENT_ASSIGNMENT_IN_PROGRESS",
  MaximumRespondentSPoCLimitReached = "MAXIMUM_RESPONDENT_SPOC_LIMIT_REACHED",
  InvalidSPoCId = "INVALID_SPOC_ID",
}

const SelectedRespondentDetailsModel = types.model(
  "SelectedRespondentDetails",
  {
    respondentDetails: RespondentModel,
    selectedSurvey: types.maybe(SurveyIdAndNameModel),
    selectedSPoCs: types.array(AutoCompleteItemModel),
  },
);

const createSelectedRespondentDetailsModel = (
  respondentDetails: Instance<typeof RespondentModel>,
  surveyIdAndNameModel: Instance<typeof SurveyIdAndNameModel> | undefined,
  selectedSPoCs: Instance<typeof SpocUserDetailsModel>[],
): Instance<typeof SelectedRespondentDetailsModel> => {
  return SelectedRespondentDetailsModel.create({
    respondentDetails: <Instance<typeof RespondentModel>>(
      JSON.parse(JSON.stringify(respondentDetails))
    ),
    selectedSurvey: surveyIdAndNameModel
      ? <Instance<typeof SurveyIdAndNameModel>>(
          JSON.parse(JSON.stringify(surveyIdAndNameModel))
        )
      : undefined,
    selectedSPoCs: selectedSPoCs.map((selectedSPoC) => {
      return createAutoCompleteItemModel(
        selectedSPoC.spocId,
        selectedSPoC.userName.firstAndLastName,
      );
    }),
  });
};

export enum AssignSurveyError {
  SurveyIsClosed = "SURVEY_IS_CLOSED",
  ProjectAlreadyArchived = "PROJECT_ALREADY_ARCHIVED",
  DeletedColumnFilter = "DELETED_COLUMN_FILTER",
  RespondentsAlreadyAssigned = "RESPONDENTS_ALREADY_ASSIGNED",
  InvalidRespondentIdFound = "INVALID_RESPONDENT_ID_FOUND",
  RespondentAssignmentInProgress = "RESPONDENT_ASSIGNMENT_IN_PROGRESS",
  NoSurveyQuestionsPresent = "NO_SURVEY_QUESTIONS_PRESENT",
}

export enum AssignSurveyDialogState {
  FetchingSurveyList = "FETCHING_SURVEY_LIST",
  FetchingSurveyListError = "FETCHING_SURVEY_LIST_ERROR",
  ChooseFromSurveyList = "CHOOSE_FROM_SURVEY_LIST",
  RespondentsAlreadyAssigned = "RESPONDENTS_ALREADY_ASSIGNED",
  AssignRespondentSuccess = "ASSIGN_RESPONDENT_SUCCESS",
  AssignRespondentError = "ASSIGN_RESPONDENT_ERROR",
  RespondentAssignmentInProgress = "RESPONDENT_ASSIGNMENT_IN_PROGRESS",
  NoSurveyCreated = "NO_SURVEY_CREATED",
}

export enum AssignSpocsToRespondentsErrors {
  InvalidSpocId = "INVALID_SPOC_ID",
  ProjectAlreadyArchived = "PROJECT_ALREADY_ARCHIVED",
  DeletedColumnFilter = "DELETED_COLUMN_FILTER",
  InvalidRespondentIdFound = "INVALID_RESPONDENT_ID_FOUND",
  MaximumRespondentSpocLimitReached = "MAXIMUM_RESPONDENT_SPOC_LIMIT_REACHED",
}

export enum GetRespondentSpocsErrors {
  InvalidSpocId = "INVALID_SPOC_ID",
}

export enum AssignSpocsToRespondentsDialogState {
  FetchingSpocDetails = "FETCHING_SPOC_DETAILS",
  SelectingSpocs = "SELECTING_SPOCS",
  AssignSuccess = "ASSIGN_SUCCESS",
  AssignFailed = "ASSIGN_FAILED",
}

export enum RespondentListType {
  NoRespondentsFound = "NO_RESPONDENTS_FOUND",
  NoMatchingRespondentsFound = "NO_MATCHING_RESPONDENTS_FOUND",
  RespondentsFound = "RESPONDENTS_FOUND",
}

export const RespondentStore = types
  .model("RespondentStore", {
    respondentUIStore: RespondentUIStore,
    multiSelectTableStore: MultiSelectTableStore,
    projectId: types.maybeNull(types.string),
    respondentSpocs: types.array(SpocUserDetailsModel),
    totalAssignedRespondents: types.maybeNull(types.number),
    assignSpocsToRespondentsErrors: types.maybeNull(
      types.enumeration(
        "AssignSpocsToRespondentsErrors",
        Object.values(AssignSpocsToRespondentsErrors),
      ),
    ),
    getRespondentSpocsErrors: types.maybeNull(
      types.enumeration(
        "GetRespondentSpocsErrors",
        Object.values(GetRespondentSpocsErrors),
      ),
    ),
    assignAndViewSpocsToRespondentsDialogState: types.optional(
      types.enumeration(
        "AssignSpocsToRespondentsDialogState",
        Object.values(AssignSpocsToRespondentsDialogState),
      ),
      AssignSpocsToRespondentsDialogState.FetchingSpocDetails,
    ),
    respondentWithSurveyDetailsList: types.array(
      RespondentWithSurveyDetailsModel,
    ),
    respondentColumns: types.array(RespondentColumnDetailsModel),
    filterStore: FilterStore,
    surveyIdAndNames: types.array(SurveyIdAndNameModel),
    sortOrder: types.enumeration(
      "SortOrder",
      Object.values(GetRespondentDetailsRPC.RequestEnums.SortOrder.SortOrder),
    ),
    sortColumn: types.enumeration(
      "SortColumn",
      Object.values(GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn),
    ),
    rpcErrors: types.maybeNull(
      types.union(
        types.enumeration(
          "GetRespondentDetailsError",
          Object.values(GetRespondentDetailsError),
        ),
        types.enumeration(Object.values(UnassignSurveyError)),
        types.enumeration(Object.values(DeleteRespondentError)),
        types.enumeration(Object.values(AssignSurveyError)),
        types.enumeration("networkErrors", Object.values(NetworkingError)),
        types.enumeration(
          "UnassignSurveyError",
          Object.values(UnassignSurveyError),
        ),
        types.enumeration("NetworkingError", Object.values(NetworkingError)),
        types.enumeration(
          "DeleteColumnError",
          Object.values(DeleteColumnError),
        ),
        types.enumeration(
          "EditRespondentDetailsError",
          Object.values(EditRespondentDetailsError),
        ),
      ),
    ),
    totalItems: types.number,
    isRespondentUpdateStatusInProgress: types.boolean,
    isGetRespondentDetailsRPCCalled: types.boolean,
    respondentUploadStore: RespondentUploadStore,
    respondentDownloadStore: RespondentDownloadStore,
    selectedDeleteColumn: types.maybe(RespondentColumnDetailsModel),
    deleteColumnDialogState: types.optional(
      types.enumeration(Object.values(DeleteColumnDialogState)),
      DeleteColumnDialogState.SelectColumnState,
    ),
    selectedRespondentInitialDetails: types.maybe(
      SelectedRespondentDetailsModel,
    ),
    selectedRespondentDetails: types.maybe(SelectedRespondentDetailsModel),
    surveyList: types.array(SurveyIdAndNameModel),
    doesEditRespondentFieldsContainError: types.array(types.boolean),
    assignRespondentDialogState: types.optional(
      types.enumeration(Object.values(AssignSurveyDialogState)),
      AssignSurveyDialogState.FetchingSurveyList,
    ),
    selectedSurvey: types.maybe(SurveyIdAndNameModel),
    respondentsSelected: types.maybe(types.number),
    respondentsAssigned: types.maybe(types.number),
    respondentsNotAssigned: types.maybe(types.number),
    isRPCLoading: types.optional(types.boolean, false),
    editRespondentRPCError: types.maybeNull(
      types.union(
        types.enumeration(
          "EditRespondentDetailsError",
          Object.values(EditRespondentDetailsError),
        ),
        types.enumeration("NetworkingError", Object.values(NetworkingError)),
      ),
    ),
    respondentListType: types.optional(
      types.enumeration(Object.values(RespondentListType)),
      RespondentListType.NoRespondentsFound,
    ),
    isLiveRespondentsSelected: types.boolean,
    reOpenSurveyLinkStore: ReOpenSurveyLinkStore,
    // projectSpocs is redundant as it is present in the parent as well, this will be fixed as part of this ticket: https://feedbackinsights.atlassian.net/browse/PUL2-807
    projectSpocs: types.array(SpocUserDetailsModel),
    respondentResponseStore: RespondentResponseStore,
  })
  .views((store) => ({
    get isRespondentSpocListEmpty(): boolean {
      return store.respondentSpocs.length === EMPTY_LIST_LENGTH;
    },
    get isEditRespondentPrimaryButtonDisabled(): boolean {
      return (
        JSON.stringify(store.selectedRespondentInitialDetails) ===
        JSON.stringify(store.selectedRespondentDetails)
      );
    },
    get isEditRespondentSurveyAlreadySubmittedError(): boolean {
      return (
        store.editRespondentRPCError ===
        EditRespondentDetailsError.SurveyAlreadySubmitted
      );
    },
    get isEditRespondentAssignmentInProgressError(): boolean {
      return (
        store.editRespondentRPCError ===
        EditRespondentDetailsError.RespondentAssignmentInProgress
      );
    },
    get isEditRespondentErrorBannerShown(): boolean {
      return (
        store.editRespondentRPCError ===
          EditRespondentDetailsError.SurveyAlreadySubmitted ||
        store.editRespondentRPCError ===
          EditRespondentDetailsError.RespondentAssignmentInProgress
      );
    },
    get isDeletedColumnFilterError(): boolean {
      return store.rpcErrors === GetRespondentDetailsError.DeletedColumnFilter;
    },
    get isRespondentAlreadyAssigned(): boolean {
      return (
        store.respondentsSelected !== undefined &&
        store.respondentsAssigned !== undefined &&
        store.respondentsNotAssigned !== undefined
      );
    },
    get doesRespondentStoreContainError(): boolean {
      return store.rpcErrors !== null;
    },
    get isDeleteColumnDisabled(): boolean {
      return store.respondentColumns.length === EMPTY_LIST_LENGTH;
    },
    get isRespondentWithSurveyDetailsListEmpty(): boolean {
      return store.respondentWithSurveyDetailsList.length === EMPTY_LIST_LENGTH;
    },
    get isRespondentWithSurveyDetailsListNotEmptyAndRespondentIsSelected(): boolean {
      if (store.selectedRespondentDetails) {
        return store.respondentWithSurveyDetailsList.length > EMPTY_LIST_LENGTH;
      }
      return false;
    },
    get doesAnyFieldInEditRespondentContainError(): boolean {
      let isFieldErrored = false;
      store.doesEditRespondentFieldsContainError.map((field) => {
        if (field === true) {
          isFieldErrored = true;
        }
      });
      return isFieldErrored;
    },
    get isSingleRespondentSelected(): boolean {
      return (
        (store.selectedRespondentDetails &&
          store.selectedRespondentDetails.respondentDetails.id !== undefined) ??
        store.multiSelectTableStore.selectedRowIds.length ===
          LIST_WITH_SINGLE_ELEMENT
      );
    },
  }))
  .views((store) => ({
    get selectedRespondentId(): number {
      return store.selectedRespondentDetails?.respondentDetails.id
        ? store.selectedRespondentDetails.respondentDetails.id
        : parseInt(store.multiSelectTableStore.selectedRowIds[0]);
    },
    get selectedRespondentFirstName(): string {
      if (
        store.isRespondentWithSurveyDetailsListNotEmptyAndRespondentIsSelected
      ) {
        return (
          store.selectedRespondentDetails?.respondentDetails.firstName ??
          EMPTY_CHARACTER
        );
      } else {
        return EMPTY_CHARACTER;
      }
    },
    get selectedRespondentLastName(): string {
      if (
        store.isRespondentWithSurveyDetailsListNotEmptyAndRespondentIsSelected
      ) {
        return (
          store.selectedRespondentDetails?.respondentDetails.lastName ??
          EMPTY_CHARACTER
        );
      } else {
        return EMPTY_CHARACTER;
      }
    },
    get selectedRespondentEmailAddress(): string {
      if (
        store.isRespondentWithSurveyDetailsListNotEmptyAndRespondentIsSelected
      ) {
        return (
          store.selectedRespondentDetails?.respondentDetails.emailId ??
          EMPTY_CHARACTER
        );
      } else {
        return EMPTY_CHARACTER;
      }
    },
    get selectedRespondentPhoneNumber(): string {
      if (
        store.isRespondentWithSurveyDetailsListNotEmptyAndRespondentIsSelected
      ) {
        return (
          store.selectedRespondentDetails?.respondentDetails.phoneNumber ??
          EMPTY_CHARACTER
        );
      } else {
        return EMPTY_CHARACTER;
      }
    },
    get selectedRespondentLinkStatus():
      | SurveyLinkStatus.SurveyLinkStatus
      | undefined {
      if (store.selectedRespondentDetails) {
        return store.respondentWithSurveyDetailsList.find(
          (respondentWithSurveyDetails) =>
            respondentWithSurveyDetails.respondent.id ===
            store.selectedRespondentDetails?.respondentDetails.id,
        )?.surveyLinkStatus;
      }
    },
    selectedRespondentColumnData(column: string): string {
      if (
        store.isRespondentWithSurveyDetailsListNotEmptyAndRespondentIsSelected
      ) {
        return store.selectedRespondentDetails?.respondentDetails
          ? ((store.selectedRespondentDetails.respondentDetails[
              column as keyof typeof store.selectedRespondentDetails.respondentDetails
            ] as string) ?? EMPTY_CHARACTER)
          : EMPTY_CHARACTER;
      } else {
        return EMPTY_CHARACTER;
      }
    },
    get itemsPerPage(): number {
      return DEFAULT_ITEMS_PER_PAGE_IN_PG_TABLE;
    },
    get isRespondentListEmpty(): boolean {
      return store.respondentWithSurveyDetailsList.length === EMPTY_LIST_LENGTH;
    },
    get isPaginatedListVisible(): boolean {
      if (store.isGetRespondentDetailsRPCCalled) {
        return (
          !store.isRespondentUpdateStatusInProgress &&
          store.respondentListType !== RespondentListType.NoRespondentsFound
        );
      } else {
        return true;
      }
    },
    get isRespondentsAlreadyUnassignedErrorPresent(): boolean {
      return (
        store.rpcErrors === UnassignSurveyError.RespondentsAlreadyUnassigned
      );
    },
    get isProjectAlreadyArchivedErrorPresent(): boolean {
      return store.rpcErrors === UnassignSurveyError.ProjectAlreadyArchived;
    },
    get isRespondentsAlreadyAssignedErrorPresent(): boolean {
      return store.rpcErrors === AssignSurveyError.RespondentsAlreadyAssigned;
    },
    get isAssignRespondentDialogPrimaryButtonDisabled(): boolean {
      return store.selectedSurvey === undefined || store.isRPCLoading;
    },
    get isAssignRespondentDialogPrimaryButtonVisible(): boolean {
      return (
        store.rpcErrors === null &&
        store.assignRespondentDialogState !==
          AssignSurveyDialogState.AssignRespondentSuccess &&
        store.assignRespondentDialogState !==
          AssignSurveyDialogState.FetchingSurveyListError &&
        store.assignRespondentDialogState !==
          AssignSurveyDialogState.NoSurveyCreated
      );
    },
    get selectedRespondentsCount(): number {
      return store.multiSelectTableStore.areAllRowsSelected
        ? store.totalItems
        : store.multiSelectTableStore.selectedRowIds.length;
    },
    get isDeleteColumnPrimaryButtonDisabled(): boolean {
      return store.selectedDeleteColumn === undefined || store.isRPCLoading;
    },
    get isDeleteColumnSelectState(): boolean {
      return (
        store.deleteColumnDialogState ===
        DeleteColumnDialogState.SelectColumnState
      );
    },
    get areRespondentSectionHeaderActionElementsVisible(): boolean {
      return !(
        !store.isGetRespondentDetailsRPCCalled ||
        store.isRespondentUpdateStatusInProgress ||
        (store.rpcErrors !== null && store.rpcErrors in DeleteColumnError)
      );
    },
    matchedRespondentSpocs(
      spocName: string,
    ): Instance<typeof SpocUserDetailsModel>[] {
      return store.respondentSpocs.filter((respondentSpoc) => {
        return respondentSpoc.userName.firstAndLastName
          .toLowerCase()
          .includes(spocName.toLowerCase());
      });
    },
    tableColumnHeaders(t: TFunction): TableColumnHeader[] {
      return [
        {
          columnId:
            GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn.ID,
          label: t("projects.respondentList.columnNames.id"),
          width: "120px",
          sortable: true,
        },
        {
          columnId:
            GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn
              .FIRST_NAME,
          label: t("projects.respondentList.columnNames.firstName"),
          width: "184px",
          sortable: true,
        },
        {
          columnId:
            GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn
              .LAST_NAME,
          label: t("projects.respondentList.columnNames.lastName"),
          width: "184px",
          sortable: true,
        },
        {
          columnId:
            GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn.EMAIL_ID,
          label: t("projects.respondentList.columnNames.emailAddress"),
          width: "208px",
          sortable: true,
        },
        {
          columnId: "PhoneNumber",
          label: t("projects.respondentList.columnNames.phoneNumber"),
          width: "200px",
        },
        {
          columnId: "SurveyName",
          label: t("projects.respondentList.columnNames.survey"),
          width: "280px",
        },
        {
          columnId: "SurveyStatus",
          label: t("projects.respondentList.columnNames.surveyStatus"),
          width: "200px",
        },
        ...store.respondentColumns.map((column) => {
          return column.tableColumnHeader;
        }),
        {
          columnId: "IndividualRespondentAction",
          label: EMPTY_CHARACTER,
          width: store.isLiveRespondentsSelected ? "200px" : "160px",
        },
      ];
    },
  }))
  .actions((store) => ({
    getProjectSpocDetails: flow(function* (projectId: string) {
      try {
        store.assignAndViewSpocsToRespondentsDialogState =
          AssignSpocsToRespondentsDialogState.FetchingSpocDetails;
        store.isRPCLoading = true;
        const apiClient: APIClient = getAPIClient(store);

        const request = new GetProjectSpocDetailsRPC.Request(
          new LeoUUID(projectId),
        );
        const {
          response,
          error,
        }: {
          response?: GetProjectSpocDetailsRPC.Response;
          error?: GetProjectSpocDetailsRPC.Errors.Errors;
        } =
          yield useGetProjectSpocDetailsRPCClientImpl(apiClient).execute(
            request,
          );
        if (response) {
          store.projectSpocs = cast(
            response.spocUserDetails.map((spocUserDetail) => {
              return createSpocUserDetailsModel(spocUserDetail);
            }),
          );
          store.assignAndViewSpocsToRespondentsDialogState =
            AssignSpocsToRespondentsDialogState.SelectingSpocs;
        } else if (error) {
          store.assignAndViewSpocsToRespondentsDialogState =
            AssignSpocsToRespondentsDialogState.AssignFailed;
          switch (error.code) {
            case CommonErrors.InvalidProjectId: {
              break;
            }
            default: {
              console.error(`Unhandled error ${error.code}`);
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occurred: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
    clearFiltersAndRowSelection: (): void => {
      store.filterStore.clearStore();
      store.multiSelectTableStore.unselectAllRows();
    },
    setIsLiveRespondentsSelected: (
      isLiveRespondentsSelected: boolean,
    ): void => {
      store.isLiveRespondentsSelected = isLiveRespondentsSelected;
    },
    resetIsLiveRespondentsSelected: (): void => {
      store.isLiveRespondentsSelected = false;
    },
    resetIsGetRespondentsRPCCalled: (): void => {
      store.isGetRespondentDetailsRPCCalled = false;
    },
    resetEditRespondentRPCError: (): void => {
      store.editRespondentRPCError = null;
    },
    applyFilters: (requestFilters: TableFilter[]): void => {
      try {
        if (!store.filterStore.areNoFiltersAdded) {
          store.filterStore.appliedFilters.map((filter) => {
            if (filter.key === undefined) {
              console.error("The filter key cannot be null");
              return;
            }
            let tableFilters:
              | TableFilterEnums.Input.DateRange
              | TableFilterEnums.Input.OpenEnded
              | TableFilterEnums.Input.PresetMultiSelect;
            if (filter.filterType === FilterType.OpenEnded) {
              if (filter.openEndedFilterValue === undefined) {
                console.error(
                  "The filter value for Open Ended Filter cannot be null",
                );
                return;
              }
              tableFilters = new TableFilterEnums.Input.OpenEnded(
                filter.openEndedFilterValue,
              );
            } else if (filter.filterType === FilterType.MultiSelect) {
              tableFilters = new TableFilterEnums.Input.PresetMultiSelect(
                filter.multiSelectFilterValue.map((filterValue) => {
                  if (filterValue.id === undefined) {
                    console.error(
                      "The filter value for Multi Select Filter cannot be null",
                    );
                  }
                  return filterValue.id ?? EMPTY_CHARACTER;
                }),
              );
            } else {
              if (
                filter.dateRangeFilterValue &&
                filter.dateRangeFilterValue.startDate &&
                filter.dateRangeFilterValue.endDate
              ) {
                tableFilters = new TableFilterEnums.Input.DateRange(
                  getFormattedLeoDate(filter.dateRangeFilterValue.startDate),
                  getFormattedLeoDate(filter.dateRangeFilterValue.endDate),
                );
              } else {
                console.error("The filter value for date range cannot be null");
                return;
              }
            }
            requestFilters.push(
              new TableFilter(filter.key ?? EMPTY_CHARACTER, tableFilters),
            );
          });
        }
      } catch (e) {
        console.error(
          `Unexpected error ${e} at applyFilters action in RespondentStore.`,
        );
      }
    },
    getFilters: (): TableFilter[] => {
      const requestFilters: TableFilter[] = [];
      try {
        if (!store.filterStore.areNoFiltersAdded) {
          store.filterStore.appliedFilters.map((filter) => {
            if (filter.key === undefined) {
              console.error("The filter key cannot be null");
              return;
            }
            let tableFilters:
              | TableFilterEnums.Input.DateRange
              | TableFilterEnums.Input.OpenEnded
              | TableFilterEnums.Input.PresetMultiSelect;
            if (filter.filterType === FilterType.OpenEnded) {
              if (filter.openEndedFilterValue === undefined) {
                console.error(
                  "The filter value for Open Ended Filter cannot be null",
                );
                return;
              }
              tableFilters = new TableFilterEnums.Input.OpenEnded(
                filter.openEndedFilterValue,
              );
            } else if (filter.filterType === FilterType.MultiSelect) {
              tableFilters = new TableFilterEnums.Input.PresetMultiSelect(
                filter.multiSelectFilterValue.map((filterValue) => {
                  if (filterValue.id === undefined) {
                    console.error(
                      "The filter value for Multi Select Filter cannot be null",
                    );
                  }
                  return filterValue.id ?? EMPTY_CHARACTER;
                }),
              );
            } else {
              if (
                filter.dateRangeFilterValue &&
                filter.dateRangeFilterValue.startDate &&
                filter.dateRangeFilterValue.endDate
              ) {
                tableFilters = new TableFilterEnums.Input.DateRange(
                  getFormattedLeoDate(filter.dateRangeFilterValue.startDate),
                  getFormattedLeoDate(filter.dateRangeFilterValue.endDate),
                );
              } else {
                console.error("The filter value for date range cannot be null");
                return;
              }
            }
            requestFilters.push(
              new TableFilter(filter.key ?? EMPTY_CHARACTER, tableFilters),
            );
          });
        }
      } catch (e) {
        console.error(
          `Unexpected error ${e} at applyFilters action in RespondentStore.`,
        );
      }
      return requestFilters;
    },
    clearRpcErrors: (): void => {
      store.rpcErrors = null;
    },
  }))
  .actions((store) => ({
    setProjectId: (projectId: string): void => {
      store.projectId = projectId;
    },
    resetAssignSpocDialog: (): void => {
      const projectDetailsStore = getParentOfType(store, ProjectDetailsStore);
      projectDetailsStore.clearProjectSpocs();
      projectDetailsStore.clearSelectedSpocs();
      store.totalAssignedRespondents = null;
      store.assignSpocsToRespondentsErrors = null;
      store.assignAndViewSpocsToRespondentsDialogState =
        AssignSpocsToRespondentsDialogState.FetchingSpocDetails;
      store.multiSelectTableStore.unselectAllRows();
      store.respondentSpocs.clear();
    },
    getRespondentSpocs: flow(function* (
      projectId: string,
      respondentId: number,
    ) {
      try {
        store.assignAndViewSpocsToRespondentsDialogState =
          AssignSpocsToRespondentsDialogState.FetchingSpocDetails;
        store.isRPCLoading = true;
        const apiClient: APIClient = getAPIClient(store);

        const request = new GetRespondentSpocsRPC.Request(
          new LeoUUID(projectId),
          respondentId,
        );
        const {
          response,
          error,
        }: {
          response?: GetRespondentSpocsRPC.Response;
          error?: GetRespondentSpocsRPC.Errors.Errors;
        } =
          yield useGetRespondentSpocsRPCClientImpl(apiClient).execute(request);
        if (response) {
          store.respondentSpocs = cast(
            response.spocUserDetails.map((spocUserDetail) => {
              return createSpocUserDetailsModel(spocUserDetail);
            }),
          );
          store.assignAndViewSpocsToRespondentsDialogState =
            AssignSpocsToRespondentsDialogState.SelectingSpocs;
        } else if (error) {
          store.assignAndViewSpocsToRespondentsDialogState =
            AssignSpocsToRespondentsDialogState.AssignFailed;
          switch (error.code) {
            // This error is being handled by the default error interceptor in the Networking Store.
            case CommonErrors.InvalidProjectId: {
              break;
            }
            case GetRespondentSpocsErrors.InvalidSpocId: {
              store.getRespondentSpocsErrors =
                GetRespondentSpocsErrors.InvalidSpocId;
              break;
            }
            default: {
              console.error(`Unhandled error ${error.code}`);
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occurred: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
    assignSpocsToRespondents: flow(function* (projectId: string) {
      try {
        const projectDetailsStore = getParentOfType(store, ProjectDetailsStore);
        store.isRPCLoading = true;
        const apiClient: APIClient = getAPIClient(store);
        const requestFilters: TableFilter[] = [];
        store.applyFilters(requestFilters);
        const respondentSelection = store.multiSelectTableStore
          .areAllRowsSelected
          ? new RespondentSelection.AllRowsSelected(
              store.isLiveRespondentsSelected,
              requestFilters,
            )
          : new RespondentSelection.SomeRowsSelected(
              store.multiSelectTableStore.selectedRowIds.map((id) =>
                Number(id),
              ),
            );
        const request: AssignSpocsToRespondentsRPC.Request =
          new AssignSpocsToRespondentsRPC.Request(
            respondentSelection,
            projectDetailsStore.selectedSpocIds.map(
              (spocId) => new LeoUUID(spocId),
            ),
            new LeoUUID(projectId),
          );
        const {
          response,
          error,
        }: {
          response?: AssignSpocsToRespondentsRPC.Response;
          error?: AssignSpocsToRespondentsRPC.Errors.Errors;
        } =
          yield useAssignSpocsToRespondentsRPCClientImpl(apiClient).execute(
            request,
          );
        if (response) {
          store.totalAssignedRespondents = response.totalAssignedRespondents;
          store.assignAndViewSpocsToRespondentsDialogState =
            AssignSpocsToRespondentsDialogState.AssignSuccess;
        } else if (error) {
          store.assignAndViewSpocsToRespondentsDialogState =
            AssignSpocsToRespondentsDialogState.AssignFailed;
          switch (error.code) {
            case CommonErrors.InvalidProjectId: {
              break;
            }
            case AssignSpocsToRespondentsErrors.ProjectAlreadyArchived: {
              store.assignSpocsToRespondentsErrors =
                AssignSpocsToRespondentsErrors.ProjectAlreadyArchived;
              break;
            }
            case AssignSpocsToRespondentsErrors.DeletedColumnFilter: {
              store.assignSpocsToRespondentsErrors =
                AssignSpocsToRespondentsErrors.DeletedColumnFilter;
              break;
            }
            case AssignSpocsToRespondentsErrors.InvalidRespondentIdFound: {
              store.assignSpocsToRespondentsErrors =
                AssignSpocsToRespondentsErrors.InvalidRespondentIdFound;
              break;
            }
            case AssignSpocsToRespondentsErrors.InvalidSpocId: {
              store.assignSpocsToRespondentsErrors =
                AssignSpocsToRespondentsErrors.InvalidSpocId;
              break;
            }
            case AssignSpocsToRespondentsErrors.MaximumRespondentSpocLimitReached: {
              store.assignSpocsToRespondentsErrors =
                AssignSpocsToRespondentsErrors.MaximumRespondentSpocLimitReached;
              break;
            }
            default: {
              console.error(`Unhandled error ${error.code}`);
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occurred: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
    getPresignedDownloadURLForRespondents: flow(function* (
      projectId: string,
      projectName: string,
    ) {
      try {
        store.isRPCLoading = true;
        const requestFilters: TableFilter[] = [];
        store.applyFilters(requestFilters);
        const request =
          new RespondentGetPresignedDownloadURLForRespondentsWithFiltersRPC.Request(
            new LeoUUID(projectId),
            requestFilters,
            store.isLiveRespondentsSelected,
          );
        const apiClient: APIClient = getAPIClient(store);

        const {
          response,
          error,
        }: {
          response?: RespondentGetPresignedDownloadURLForRespondentsWithFiltersRPC.Response;
          error?: RespondentGetPresignedDownloadURLForRespondentsWithFiltersRPC.Errors.Errors;
        } =
          yield useGetPresignedDownloadURLForRespondentsWithFiltersRPCClient(
            apiClient,
          ).execute(request);
        if (response) {
          const fileName =
            getDelimitedFileName(projectName) +
            TEMPLATE_FILE_DELIMITER +
            RESPONDENTS_CSV_NAME;
          yield downloadFile(response.documentURL.href, fileName);
          store.respondentDownloadStore.downloadRespondentCSVDialogState =
            DownloadRespondentCSVDialogState.SuccessState;
        } else if (error) {
          store.respondentDownloadStore.downloadRespondentCSVDialogState =
            DownloadRespondentCSVDialogState.ErrorState;
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
              break;
            case GetPresignedDownloadURLForRespondentsWithFiltersErrors.DeletedColumnFilter:
              store.respondentDownloadStore.rpcErrors =
                GetPresignedDownloadURLForRespondentsWithFiltersErrors.DeletedColumnFilter;
              break;
            default:
              console.error(`Unhandled error ${error.code}`);
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occurred: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
    setIsEditRespondentErrored: (index: number, isError: boolean): void => {
      store.doesEditRespondentFieldsContainError[index] = isError;
    },
    setEditRespondentDialogState: (
      editRespondentDialogState: EditRespondentDialogState,
    ): void => {
      store.respondentUIStore.editRespondentDialogState =
        editRespondentDialogState;
    },
    updateSelectedRespondentSurvey: (value: DropdownItem): void => {
      if (store.selectedRespondentDetails) {
        store.selectedRespondentDetails.selectedSurvey =
          createSurveyIdAndNameModel(value.value, value.name);
      } else {
        console.error(`selectedRespondentDetails cannot be null in this flow.`);
      }
    },
    updateSelectedRespondentSPoCs: (
      selectedSPoCs: AutoCompleteItem[],
    ): void => {
      if (store.selectedRespondentDetails) {
        store.selectedRespondentDetails.selectedSPoCs = cast(
          selectedSPoCs.map((selectedSPoC) => {
            return createAutoCompleteItemModel(
              selectedSPoC.id,
              selectedSPoC.label,
            );
          }),
        );
      } else {
        console.error(`selectedRespondentDetails cannot be null in this flow.`);
      }
    },
    updateSelectedRespondentFirstName: (firstName: string): void => {
      if (store.selectedRespondentDetails) {
        store.selectedRespondentDetails.respondentDetails.firstName = firstName;
      } else {
        console.error(`selectedRespondentDetails cannot be null in this flow.`);
      }
    },
    updateSelectedRespondentLastName: (lastName: string): void => {
      if (store.selectedRespondentDetails) {
        store.selectedRespondentDetails.respondentDetails.lastName = lastName;
      } else {
        console.error(`selectedRespondentDetails cannot be null in this flow.`);
      }
    },
    updateSelectedRespondentPhoneNumber: (phoneNumber: string): void => {
      if (store.selectedRespondentDetails) {
        store.selectedRespondentDetails.respondentDetails.phoneNumber =
          phoneNumber;
      } else {
        console.error(`selectedRespondentDetails cannot be null in this flow.`);
      }
    },
    updateSelectedRespondentEmailAddress: (emailAddress: string): void => {
      if (store.selectedRespondentDetails) {
        store.selectedRespondentDetails.respondentDetails.emailId =
          emailAddress;
      } else {
        console.error(`selectedRespondentDetails cannot be null in this flow.`);
      }
    },
    updateSelectedRespondentColumnData: (
      column: string,
      value: string,
    ): void => {
      if (store.selectedRespondentDetails) {
        applySnapshot(store.selectedRespondentDetails.respondentDetails, {
          ...store.selectedRespondentDetails.respondentDetails,
          [column]: value,
        });
      } else {
        console.error(`selectedRespondentDetails cannot be null in this flow.`);
      }
    },
    setSelectedRespondentDetails: (
      selectedRespondentId: number | undefined,
    ): void => {
      const selectedRespondentDetails =
        store.respondentWithSurveyDetailsList.find(
          (respondentWithSurveyDetails) =>
            respondentWithSurveyDetails.respondent.id === selectedRespondentId,
        );
      const selectedRespondentSurveyIdAndName = store.surveyList.find(
        (survey) => survey.surveyName === selectedRespondentDetails?.surveyName,
      );
      if (selectedRespondentDetails) {
        store.selectedRespondentDetails = createSelectedRespondentDetailsModel(
          selectedRespondentDetails.respondent,
          selectedRespondentSurveyIdAndName,
          clone(store.respondentSpocs),
        );
        store.selectedRespondentInitialDetails =
          createSelectedRespondentDetailsModel(
            selectedRespondentDetails.respondent,
            selectedRespondentSurveyIdAndName,
            clone(store.respondentSpocs),
          );
        for (let i = 0; i < 104; i++) {
          store.doesEditRespondentFieldsContainError.push(false);
        }
      } else {
        console.error(`selectedRespondentDetails cannot be null in this flow.`);
      }
    },
    mapRespondentColumnsToColumnWithIndex: (): string[] => {
      return store.respondentColumns.map((column) => {
        return `column${column.index}`;
      });
    },
    setIsRespondentUpdateStatusInProgress: (
      isRespondentUpdateStatusInProgress: boolean,
    ): void => {
      store.isRespondentUpdateStatusInProgress =
        isRespondentUpdateStatusInProgress;
    },
    getRespondentDetails: flow(function* (
      pageIndex: number,
      projectId: string,
      isLiveRespondentsSelected: boolean,
      sort?: TableSortOption,
      surveyFilterName?: string,
    ) {
      const projectDetailsStore = getParentOfType(store, ProjectDetailsStore);
      store.sortColumn =
        GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn.ID;
      store.sortOrder =
        GetRespondentDetailsRPC.RequestEnums.SortOrder.SortOrder.ASCENDING;
      store.isRPCLoading = true;
      try {
        const requestFilters: TableFilter[] = surveyFilterName
          ? [
              new TableFilter(
                RespondentFilters.Survey,
                new TableFilterEnums.Input.PresetMultiSelect([
                  surveyFilterName,
                ]),
              ),
            ]
          : [];

        store.applyFilters(requestFilters);

        if (sort) {
          store.sortOrder = getSortOrder(sort.order);
          store.sortColumn =
            sort.id as GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn;
        }

        const apiClient: APIClient = getAPIClient(store);
        const request = new GetRespondentDetailsRPC.Request(
          new LeoUUID(projectId),
          requestFilters,
          pageIndex,
          store.itemsPerPage,
          store.sortOrder,
          store.sortColumn,
          isLiveRespondentsSelected,
        );
        const {
          response,
          error,
        }: {
          response?: GetRespondentDetailsRPC.Response;
          error?: GetRespondentDetailsRPC.Errors.Errors;
        } =
          yield useGetRespondentDetailsRPCClientImpl(apiClient).execute(
            request,
          );
        if (response) {
          store.projectId = projectId;
          const projectDetails = response.projectDetails;
          if (projectDetailsStore.projectName === EMPTY_CHARACTER) {
            projectDetailsStore.setProjectName(projectDetails.projectName.name);
          }
          if (
            projectDetailsStore.projectStartDate === null ||
            projectDetailsStore.projectEndDate === null
          ) {
            projectDetailsStore.setProjectDateRangeValue({
              startDate: new Date(projectDetails.startDate.date),
              endDate: new Date(projectDetails.endDate.date),
            });
          }

          if (
            response.respondentUpdateStatus instanceof
            GetRespondentDetailsRPC.ResponseEnums.RespondentUpdateStatus
              .Completed
          ) {
            if (
              response.respondentUpdateStatus.respondents instanceof
              GetRespondentDetailsRPC.ResponseEnums.RespondentUpdateStatus
                .CompletedEnums.Respondents.NoRespondentsFound
            ) {
              store.respondentWithSurveyDetailsList.clear();
              store.respondentListType = RespondentListType.NoRespondentsFound;
            } else if (
              response.respondentUpdateStatus.respondents instanceof
              GetRespondentDetailsRPC.ResponseEnums.RespondentUpdateStatus
                .CompletedEnums.Respondents.NoMatchingRespondentsFound
            ) {
              store.respondentWithSurveyDetailsList.clear();
              store.respondentListType =
                RespondentListType.NoMatchingRespondentsFound;
            } else if (
              response.respondentUpdateStatus.respondents instanceof
              GetRespondentDetailsRPC.ResponseEnums.RespondentUpdateStatus
                .CompletedEnums.Respondents.RespondentsFound
            ) {
              store.respondentListType = RespondentListType.RespondentsFound;
              store.respondentWithSurveyDetailsList = cast(
                response.respondentUpdateStatus.respondents.respondents.map(
                  (respondent) => {
                    return createRespondentWithSurveyDetailsModel(respondent);
                  },
                ),
              );
            }

            store.respondentColumns = cast(
              response.respondentUpdateStatus.respondentColumnDetails.map(
                (column) => {
                  return createRespondentColumnDetailsModel(column);
                },
              ),
            );
            store.totalItems = response.respondentUpdateStatus.totalItems;
            if (
              response.respondentUpdateStatus
                .isProjectRespondentUniqueColumnPresent
            ) {
              store.respondentUploadStore.setUploadType(UploadType.Subsequent);
            } else {
              store.respondentUploadStore.setUploadType(UploadType.First);
            }
            store.surveyList = cast(
              response.respondentUpdateStatus.surveyDetails.map((survey) => {
                return createSurveyIdAndNameModel(
                  survey.surveyId.uuid,
                  survey.surveyName.name,
                );
              }),
            );
          } else if (
            response.respondentUpdateStatus instanceof
            GetRespondentDetailsRPC.ResponseEnums.RespondentUpdateStatus
              .InProgress
          ) {
            store.isRespondentUpdateStatusInProgress = true;
          }
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
              break;
            case GetRespondentDetailsError.InvalidPageIndex:
              store.rpcErrors = GetRespondentDetailsError.InvalidPageIndex;
              break;
            case GetRespondentDetailsError.DeletedColumnFilter:
              store.rpcErrors = GetRespondentDetailsError.DeletedColumnFilter;
              store.respondentUIStore.setIsDeletedColumnFilterErrorDialogVisible(
                true,
              );
              break;
            default:
              console.error(`Unhandled error ${error.code}.`);
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occurred: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
        store.isGetRespondentDetailsRPCCalled = true;
      }
    }),
    resetSelectedDeleteColumn: (): void => {
      store.selectedDeleteColumn = undefined;
    },
    setSelectedDeleteColumn: (
      newSelectedDeleteColumnName: string,
      newSelectedDeleteColumnId: string,
    ): void => {
      store.selectedDeleteColumn = createRespondentColumnDetailsModel(
        new RespondentColumnDetails(
          new RespondentColumnName(newSelectedDeleteColumnName),
          parseInt(newSelectedDeleteColumnId),
        ),
      );
    },
    resetDeleteColumnDialog: (): void => {
      store.deleteColumnDialogState = DeleteColumnDialogState.SelectColumnState;
      store.selectedDeleteColumn = undefined;
      store.clearRpcErrors();
      store.respondentUIStore.setIsDeleteColumnDialogVisible(false);
    },
    resetSelectedRespondentDetails: (): void => {
      store.clearRpcErrors();
      store.selectedRespondentDetails = undefined;
      store.selectedRespondentInitialDetails = undefined;
      store.multiSelectTableStore.unselectAllRows();
      store.doesEditRespondentFieldsContainError.clear();
    },
  }))
  .actions((store) => ({
    resetEditRespondentDialog: (): void => {
      store.resetSelectedRespondentDetails();
      store.multiSelectTableStore.unselectAllRows();
      store.respondentUIStore.setEditRespondentDialogState(
        EditRespondentDialogState.Edit,
      );
      store.respondentUIStore.setIsEditRespondentDetailsDialogVisible(false);
      store.resetEditRespondentRPCError();
    },
  }))
  .actions((store) => ({
    setAssignRespondentDialogState: (
      newAssignSurveyDialogState: AssignSurveyDialogState,
    ): void => {
      store.assignRespondentDialogState = newAssignSurveyDialogState;
    },
    resetAssignRespondentDialog: (): void => {
      store.clearRpcErrors();
      store.selectedSurvey = undefined;
      store.assignRespondentDialogState =
        AssignSurveyDialogState.FetchingSurveyList;
      store.multiSelectTableStore.unselectAllRows();
    },
    setSelectedSurvey: (surveyId: string, surveyName: string): void => {
      store.selectedSurvey = createSurveyIdAndNameModel(surveyId, surveyName);
    },
    assignSurvey: flow(function* (projectId: string, surveyId: string) {
      try {
        store.isGetRespondentDetailsRPCCalled = false;
        store.isRPCLoading = true;
        const apiClient = getAPIClient(store);
        const requestFilters: TableFilter[] = [];
        store.applyFilters(requestFilters);
        const respondentSelection = store.multiSelectTableStore
          .areAllRowsSelected
          ? new RespondentSelection.AllRowsSelected(
              store.isLiveRespondentsSelected,
              [...requestFilters],
            )
          : new RespondentSelection.SomeRowsSelected(
              store.multiSelectTableStore.selectedRowIds.map((id) =>
                Number(id),
              ),
            );
        const request = new AssignSurveyRPC.Request(
          respondentSelection,
          new LeoUUID(surveyId),
          new LeoUUID(projectId),
        );
        const {
          response,
          error,
        }: {
          response?: AssignSurveyRPC.Response;
          error?: AssignSurveyRPC.Errors.Errors;
        } = yield useAssignSurveyRPCClientImpl(apiClient).execute(request);
        if (response) {
          store.assignRespondentDialogState =
            AssignSurveyDialogState.AssignRespondentSuccess;
        } else if (error) {
          store.assignRespondentDialogState =
            AssignSurveyDialogState.AssignRespondentError;
          switch (error.code) {
            case AssignSurveyError.DeletedColumnFilter:
              store.rpcErrors = AssignSurveyError.DeletedColumnFilter;
              break;
            case AssignSurveyError.InvalidRespondentIdFound:
              store.rpcErrors = AssignSurveyError.InvalidRespondentIdFound;
              break;
            case AssignSurveyError.ProjectAlreadyArchived:
              store.rpcErrors = AssignSurveyError.ProjectAlreadyArchived;
              break;
            case AssignSurveyError.RespondentsAlreadyAssigned:
              store.assignRespondentDialogState =
                AssignSurveyDialogState.RespondentsAlreadyAssigned;
              store.rpcErrors = AssignSurveyError.RespondentsAlreadyAssigned;
              if (
                error instanceof
                AssignSurveyRPC.Errors.RespondentsAlreadyAssigned
              ) {
                store.respondentsSelected = error.respondentsSelected;
                store.respondentsAssigned = error.respondentsAssigned;
                store.respondentsNotAssigned = error.respondentsNotAssigned;
              } else {
                console.error(
                  error,
                  "error must be an instance of RespondentsAlreadyAssigned in this flow. ",
                );
              }
              break;
            case AssignSurveyError.SurveyIsClosed:
              store.rpcErrors = AssignSurveyError.SurveyIsClosed;
              break;
            case AssignSurveyError.RespondentAssignmentInProgress:
              store.assignRespondentDialogState =
                AssignSurveyDialogState.RespondentAssignmentInProgress;
              store.rpcErrors =
                AssignSurveyError.RespondentAssignmentInProgress;
              break;
            case AssignSurveyError.NoSurveyQuestionsPresent:
              store.rpcErrors = AssignSurveyError.NoSurveyQuestionsPresent;
              break;
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              store.multiSelectTableStore.unselectAllRows();
              store.assignRespondentDialogState =
                AssignSurveyDialogState.FetchingSurveyList;
              store.respondentUIStore.setIsAssignRespondentDialogVisible(false);
              store.selectedSurvey = undefined;
              break;
            default:
              console.error(`Unhandled error ${error.code}`);
              store.rpcErrors = NetworkingError.InternalError;
              break;
          }
        }
      } catch (e) {
        // If there is an error, we need to reset the assign survey dialog, since the user can navigate back to this page.
        store.clearFiltersAndRowSelection();
        store.assignRespondentDialogState =
          AssignSurveyDialogState.FetchingSurveyList;
        store.respondentUIStore.setIsAssignRespondentDialogVisible(false);
        store.selectedSurvey = undefined;
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(
            `Unhandled error ${e} in assignSurvey action in respondentStore.`,
          );
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
    unassignSurvey: flow(function* (
      projectId: string,
      surveyFilterName: string,
    ) {
      try {
        store.isGetRespondentDetailsRPCCalled = false;
        store.isRPCLoading = true;
        const apiClient = getAPIClient(store);
        const requestFilters: TableFilter[] = [];
        store.applyFilters(requestFilters);
        const respondentSelection = store.multiSelectTableStore
          .areAllRowsSelected
          ? new RespondentSelection.AllRowsSelected(
              store.isLiveRespondentsSelected,
              [
                ...requestFilters,
                new TableFilter(
                  RespondentFilters.Survey,
                  new TableFilterEnums.Input.PresetMultiSelect([
                    surveyFilterName,
                  ]),
                ),
              ],
            )
          : new RespondentSelection.SomeRowsSelected(
              store.isSingleRespondentSelected
                ? [store.selectedRespondentId]
                : store.multiSelectTableStore.selectedRowIds.map((id) =>
                    Number(id),
                  ),
            );

        const request = new UnassignSurveyRPC.Request(
          respondentSelection,
          new LeoUUID(projectId),
        );
        const {
          response,
          error,
        }: {
          response?: UnassignSurveyRPC.Response;
          error?: UnassignSurveyRPC.Errors.Errors;
        } = yield useUnassignSurveyRPCClientImpl(apiClient).execute(request);
        if (response) {
          store.multiSelectTableStore.unselectAllRows();
          store.selectedRespondentDetails = undefined;
          store.respondentUIStore.setUnassignRespondentDialogState(
            UnassignRespondentDialogState.Success,
          );
        } else if (error) {
          store.respondentUIStore.setUnassignRespondentDialogState(
            UnassignRespondentDialogState.Error,
          );
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
              store.multiSelectTableStore.unselectAllRows();
              store.respondentUIStore.setIsUnassignRespondentDialogVisible(
                false,
              );
              store.clearRpcErrors();
              break;
            case UnassignSurveyError.DeletedColumnFilter:
              store.rpcErrors = UnassignSurveyError.DeletedColumnFilter;
              break;
            case UnassignSurveyError.InvalidRespondentIdFound:
              store.rpcErrors = UnassignSurveyError.InvalidRespondentIdFound;
              break;
            case UnassignSurveyError.ProjectAlreadyArchived:
              store.rpcErrors = UnassignSurveyError.ProjectAlreadyArchived;
              break;
            case UnassignSurveyError.RespondentsAlreadyUnassigned:
              store.rpcErrors =
                UnassignSurveyError.RespondentsAlreadyUnassigned;
              break;
            case UnassignSurveyError.SurveyAlreadySubmitted:
              store.rpcErrors = UnassignSurveyError.SurveyAlreadySubmitted;
              break;
            default:
              console.error(`Unhandled error ${error.code}.`);
              break;
          }
        }
      } catch (e) {
        // If there is an error, we need to reset the unassign survey dialog, since the user can navigate back to this page.
        store.multiSelectTableStore.unselectAllRows();
        store.respondentUIStore.setIsUnassignRespondentDialogVisible(false);
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occurred: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
        store.clearFiltersAndRowSelection();
        yield store.getRespondentDetails(
          0,
          projectId,
          store.isLiveRespondentsSelected,
          undefined,
          undefined,
        );
      }
    }),
    editRespondentDetails: flow(function* () {
      try {
        store.isGetRespondentDetailsRPCCalled = false;
        store.editRespondentRPCError = null;
        if (store.selectedRespondentDetails) {
          const apiClient = getAPIClient(store);
          const request = new EditRespondentDetailsRPC.Request(
            new LeoUUID(store.projectId),
            new Respondent(
              store.selectedRespondentDetails.respondentDetails.id,
              getRespondentNameOrNull(
                store.selectedRespondentDetails.respondentDetails.firstName,
              ),
              getRespondentNameOrNull(
                store.selectedRespondentDetails.respondentDetails.lastName,
              ),
              getTrimmedStringOrNull(
                store.selectedRespondentDetails.respondentDetails.emailId,
              ),
              getTrimmedStringOrNull(
                store.selectedRespondentDetails.respondentDetails.phoneNumber,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column0,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column1,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column2,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column3,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column4,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column5,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column6,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column7,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column8,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column9,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column10,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column11,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column12,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column13,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column14,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column15,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column16,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column17,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column18,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column19,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column20,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column21,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column22,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column23,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column24,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column25,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column26,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column27,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column28,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column29,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column30,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column31,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column32,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column33,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column34,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column35,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column36,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column37,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column38,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column39,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column40,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column41,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column42,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column43,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column44,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column45,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column46,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column47,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column48,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column49,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column50,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column51,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column52,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column53,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column54,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column55,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column56,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column57,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column58,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column59,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column60,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column61,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column62,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column63,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column64,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column65,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column66,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column67,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column68,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column69,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column70,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column71,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column72,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column73,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column74,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column75,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column76,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column77,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column78,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column79,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column80,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column81,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column82,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column83,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column84,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column85,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column86,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column87,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column88,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column89,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column90,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column91,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column92,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column93,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column94,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column95,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column96,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column97,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column98,
              ),
              getRespondentColumnOrNull(
                store.selectedRespondentDetails.respondentDetails.column99,
              ),
            ),
            store.selectedRespondentDetails.selectedSPoCs.map(
              (selectedSPoC) => {
                return new LeoUUID(selectedSPoC.id);
              },
            ),
            store.selectedRespondentDetails.selectedSurvey
              ? new LeoUUID(
                  store.selectedRespondentDetails.selectedSurvey?.surveyId,
                )
              : null,
          );
          const {
            response,
            error,
          }: {
            response?: EditRespondentDetailsRPC.Response;
            error?: EditRespondentDetailsRPC.Errors.Errors;
          } =
            yield useEditRespondentDetailsRPCClientImpl(apiClient).execute(
              request,
            );
          if (response) {
            store.respondentUIStore.setEditRespondentDialogState(
              EditRespondentDialogState.Success,
            );
          } else if (error) {
            switch (error.code) {
              case CommonErrors.InvalidProjectId:
              case CommonErrors.InvalidSurveyId:
                store.resetEditRespondentDialog();
                break;
              case EditRespondentDetailsError.InvalidRespondentId:
              case EditRespondentDetailsError.InvalidSPoCId:
                store.editRespondentRPCError =
                  EditRespondentDetailsError.InvalidRespondentId;
                break;
              case EditRespondentDetailsError.ProjectArchived:
                store.editRespondentRPCError =
                  EditRespondentDetailsError.ProjectArchived;
                break;
              case EditRespondentDetailsError.SurveyIsClosed:
                store.editRespondentRPCError =
                  EditRespondentDetailsError.SurveyIsClosed;
                break;
              case EditRespondentDetailsError.NoSurveyQuestionsPresent:
                store.editRespondentRPCError =
                  EditRespondentDetailsError.NoSurveyQuestionsPresent;
                break;
              case EditRespondentDetailsError.MaximumRespondentSPoCLimitReached:
                store.editRespondentRPCError =
                  EditRespondentDetailsError.MaximumRespondentSPoCLimitReached;
                break;
              case EditRespondentDetailsError.SurveyAlreadySubmitted:
                store.editRespondentRPCError =
                  EditRespondentDetailsError.SurveyAlreadySubmitted;
                // We return here as the error is handled in error banner and the dialog state should not be changed in this flow.
                return;
              case EditRespondentDetailsError.RespondentAssignmentInProgress:
                store.editRespondentRPCError =
                  EditRespondentDetailsError.RespondentAssignmentInProgress;
                // We return here as the error is handled in error banner and the dialog state should not be changed in this flow.
                return;
              default:
                console.error(
                  `Encountered unhandled error ${error} from EditRespondentDetailsRPC.`,
                );
                store.editRespondentRPCError = NetworkingError.InternalError;
                break;
            }
            store.respondentUIStore.setEditRespondentDialogState(
              EditRespondentDialogState.Error,
            );
          }
        }
      } catch (e) {
        // If there is an error, we need to reset the edit respondent column dialog, since the user can navigate back to this page.
        store.resetEditRespondentDialog();
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(
            `Encountered unhandled error ${e} in editRespondentDetails action.`,
          );
        }
      }
    }),
    deleteColumn: flow(function* (projectId: string) {
      try {
        store.isGetRespondentDetailsRPCCalled = false;
        if (store.selectedDeleteColumn === undefined) {
          console.error(
            "selectedDeleteColumn cannot be undefined in this flow.",
          );
          return;
        }
        store.isRPCLoading = true;
        const apiClient = getAPIClient(store);
        const request = new DeleteColumnRPC.Request(
          new LeoUUID(projectId),
          new RespondentColumnDetails(
            new RespondentColumnName(store.selectedDeleteColumn.name),
            store.selectedDeleteColumn.index,
          ),
        );
        const {
          response,
          error,
        }: {
          response?: DeleteColumnRPC.Response;
          error?: DeleteColumnRPC.Errors.Errors;
        } = yield useDeleteColumnRPCClientImpl(apiClient).execute(request);
        if (response) {
          store.deleteColumnDialogState = DeleteColumnDialogState.SuccessState;
          // store.respondentUploadStore should not be reset here. It will be fixed as part of this ticket: https://feedbackinsights.atlassian.net/browse/PUL2-333
          store.respondentUploadStore = createRespondentUploadStore();
          store.getRespondentDetails(
            0,
            projectId,
            store.isLiveRespondentsSelected,
            undefined,
            undefined,
          );
        } else if (error) {
          store.deleteColumnDialogState = DeleteColumnDialogState.ErrorState;
          switch (error.code) {
            case DeleteColumnError.InvalidRespondentColumn:
              store.rpcErrors = DeleteColumnError.InvalidRespondentColumn;
              break;
            case DeleteColumnError.ProjectArchived:
              store.rpcErrors = DeleteColumnError.ProjectArchived;
              break;
            case DeleteColumnError.ProjectUniqueColumnCannotBeDeleted:
              store.rpcErrors =
                DeleteColumnError.ProjectUniqueColumnCannotBeDeleted;
              break;
            case CommonErrors.InvalidProjectId:
              store.resetDeleteColumnDialog();
              break;
            default:
              console.error(`Unhandled error ${error.code}.`);
              break;
          }
        }
      } catch (e) {
        // If there is an error, we need to reset the delete column dialog, since the user can navigate back to this page.
        store.resetDeleteColumnDialog();
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occurred: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
        store.clearFiltersAndRowSelection();
        store.getRespondentDetails(
          0,
          projectId,
          store.isLiveRespondentsSelected,
          undefined,
        );
      }
    }),
    deleteRespondent: flow(function* (projectId: string) {
      try {
        store.isGetRespondentDetailsRPCCalled = false;
        store.isRPCLoading = true;
        const apiClient = getAPIClient(store);
        const requestFilters: TableFilter[] = [];
        store.applyFilters(requestFilters);
        const respondentSelection = store.multiSelectTableStore
          .areAllRowsSelected
          ? new RespondentSelection.AllRowsSelected(
              store.isLiveRespondentsSelected,
              requestFilters,
            )
          : new RespondentSelection.SomeRowsSelected(
              store.isSingleRespondentSelected
                ? [store.selectedRespondentId]
                : store.multiSelectTableStore.selectedRowIds.map((id) =>
                    Number(id),
                  ),
            );
        const request = new DeleteRespondentRPC.Request(
          new LeoUUID(projectId),
          respondentSelection,
        );
        const {
          response,
          error,
        }: {
          response?: DeleteRespondentRPC.Response;
          error?: DeleteRespondentRPC.Errors.Errors;
        } = yield useDeleteRespondentRPCClientImpl(apiClient).execute(request);
        if (response) {
          store.respondentUIStore.setIsDeleteRespondentDialogVisible(false);
          store.selectedRespondentDetails = undefined;
          store.multiSelectTableStore.unselectAllRows();
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
              store.multiSelectTableStore.unselectAllRows();
              store.respondentUIStore.setIsDeleteRespondentDialogVisible(false);
              store.clearRpcErrors();
              break;
            case DeleteRespondentError.DeletedColumnFilter:
              store.rpcErrors = DeleteRespondentError.DeletedColumnFilter;
              break;
            case DeleteRespondentError.InvalidRespondent:
              store.rpcErrors = DeleteRespondentError.InvalidRespondent;
              break;
            case DeleteRespondentError.ProjectArchived:
              store.rpcErrors = DeleteRespondentError.ProjectArchived;
              break;
            default:
              console.error(
                `Unhandled RPCError ${error} in deleteRespondent action in RespondentStore.`,
              );
              break;
          }
        }
      } catch (e) {
        // If there is an error, we need to reset the delete respondent dialog, since the user can navigate back to this page.
        store.multiSelectTableStore.unselectAllRows();
        store.respondentUIStore.setIsDeleteRespondentDialogVisible(false);
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(
            `Unhandled error ${e} in deleteRespondent action in RespondentStore.`,
          );
        }
      } finally {
        store.clearFiltersAndRowSelection();
        yield store.getRespondentDetails(
          0,
          projectId,
          store.isLiveRespondentsSelected,
          undefined,
          undefined,
        );
        store.isRPCLoading = false;
      }
    }),
  }))
  .views((store) => ({
    get respondentSelection(): RespondentSelection.RespondentSelection {
      return store.multiSelectTableStore.areAllRowsSelected
        ? new RespondentSelection.AllRowsSelected(
            store.isLiveRespondentsSelected,
            [...store.getFilters()],
          )
        : new RespondentSelection.SomeRowsSelected(
            store.multiSelectTableStore.selectedRowIds.map((id) => Number(id)),
          );
    },
  }));

export const createRespondentStore = (
  isLiveRespondentsSelected = false,
): Instance<typeof RespondentStore> => {
  return RespondentStore.create({
    multiSelectTableStore: createMultiSelectTableStore(),
    sortColumn: GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn.ID,
    sortOrder:
      GetRespondentDetailsRPC.RequestEnums.SortOrder.SortOrder.ASCENDING,
    totalItems: 0,
    isGetRespondentDetailsRPCCalled: false,
    isRespondentUpdateStatusInProgress: false,
    respondentUploadStore: createRespondentUploadStore(),
    filterStore: createFilterStore(),
    respondentDownloadStore: createRespondentDownloadStore(),
    respondentUIStore: createRespondentUIStore(),
    isLiveRespondentsSelected,
    reOpenSurveyLinkStore: createReOpenSurveyLinkStore(),
    respondentResponseStore: createRespondentResponseStore(),
  });
};
