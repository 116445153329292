import { SubmittedResponseEnums } from "@pulse/pulse-rpcs";
import { Instance, types } from "mobx-state-tree";

export const RankingResponseV1Model = types.model("RankingResponseV1Model", {
  rankingResponse: types.string,
});

export const createRankingResponseV1Model = (
  rankingResponse: SubmittedResponseEnums.Response.Version1Enums.ResponseTypeV1.Ranking,
): Instance<typeof RankingResponseV1Model> => {
  return RankingResponseV1Model.create({
    rankingResponse: rankingResponse.answer,
  });
};
