import React from "react";

interface QuartileColorSVGProps {
  props?: React.SVGProps<SVGSVGElement>;
  primitiveQuartileColor: string;
  defaultSingleSelectQuartileColor: string;
}

export const QuartileColorSVG = ({
  primitiveQuartileColor,
  defaultSingleSelectQuartileColor,
  props,
}: QuartileColorSVGProps): React.ReactElement<
  React.SVGProps<SVGSVGElement>
> => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 0C13.5759 0 15.1363 0.310389 16.5922 0.913446C18.0481 1.5165 19.371 2.40042 20.4853 3.51472C21.5996 4.62902 22.4835 5.95189 23.0866 7.4078C23.6896 8.86371 24 10.4241 24 12C24 13.5759 23.6896 15.1363 23.0866 16.5922C22.4835 18.0481 21.5996 19.371 20.4853 20.4853C19.371 21.5996 18.0481 22.4835 16.5922 23.0866C15.1363 23.6896 13.5759 24 12 24L12 12V0Z"
        fill={primitiveQuartileColor}
      />
      <path
        d="M12 24C8.8174 24 5.76515 22.7357 3.51472 20.4853C1.26428 18.2348 3.41443e-07 15.1826 0 12C-3.41443e-07 8.8174 1.26428 5.76516 3.51472 3.51472C5.76515 1.26428 8.8174 1.90735e-06 12 0L12 12L12 24Z"
        fill={defaultSingleSelectQuartileColor}
      />
    </svg>
  );
};
