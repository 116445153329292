import {
  QuestionType,
  SubmittedResponse,
  SubmittedResponseEnums,
} from "@pulse/pulse-rpcs";
import { Instance, types } from "mobx-state-tree";
import {
  createGroupResponsesV1Model,
  GroupResponsesV1Model,
} from "./GroupResponseV1Model";
import {
  createOpenEndedResponseV1Model,
  OpenEndedResponseV1Model,
} from "./OpenEndedResponseV1Model";
import {
  createSingleSelectResponseV1Model,
  SingleSelectResponseV1Model,
} from "./SingleSelectResponseV1Model";
import {
  createMultiSelectResponseV1Model,
  MultiSelectResponseV1Model,
} from "./MultiSelectResponseV1Model";
import {
  createRankingResponseV1Model,
  RankingResponseV1Model,
} from "./RankingResponseV1Model";
import {
  createGridResponseV1Model,
  GridResponseV1Model,
} from "./GridResponseV1Model";

export enum RespondentResponseQuestionTypeStoreName {
  OPEN_ENDED_RESPONSE_V1_MODEL = "OpenEndedResponseV1Model",
  SINGLE_SELECT_RESPONSE_V1_MODEL = "SingleSelectResponseV1Model",
  MULTI_SELECT_RESPONSE_V1_MODEL = "MultiSelectResponseV1Model",
  RANKING_RESPONSE_V1_MODEL = "RankingResponseV1Model",
  GRID_RESPONSE_V1_MODEL = "GridResponseV1Model",
  GROUP_RESPONSES_V1_MODEL = "GroupResponsesV1Model",
}

export const SubmittedResponseModel = types.model("SubmittedResponseModel", {
  question: types.maybeNull(types.string),
  questionCode: types.string,
  questionId: types.string,
  questionType: types.enumeration(Object.values(QuestionType.QuestionType)),
  response: types.maybe(
    types.union(
      OpenEndedResponseV1Model,
      SingleSelectResponseV1Model,
      MultiSelectResponseV1Model,
      RankingResponseV1Model,
      GridResponseV1Model,
      GroupResponsesV1Model,
    ),
  ),
});

export const parseResponseType = (
  response: SubmittedResponseEnums.Response.Version1,
):
  | Instance<typeof OpenEndedResponseV1Model>
  | Instance<typeof SingleSelectResponseV1Model>
  | Instance<typeof MultiSelectResponseV1Model>
  | Instance<typeof RankingResponseV1Model>
  | Instance<typeof GridResponseV1Model>
  | Instance<typeof GroupResponsesV1Model>
  | undefined => {
  if (
    response.responseTypeV1 instanceof
    SubmittedResponseEnums.Response.Version1Enums.ResponseTypeV1.OpenEnded
  ) {
    return createOpenEndedResponseV1Model(response.responseTypeV1);
  } else if (
    response.responseTypeV1 instanceof
    SubmittedResponseEnums.Response.Version1Enums.ResponseTypeV1.SingleSelect
  ) {
    return createSingleSelectResponseV1Model(response.responseTypeV1);
  } else if (
    response.responseTypeV1 instanceof
    SubmittedResponseEnums.Response.Version1Enums.ResponseTypeV1.MultiSelect
  ) {
    return createMultiSelectResponseV1Model(response.responseTypeV1);
  } else if (
    response.responseTypeV1 instanceof
    SubmittedResponseEnums.Response.Version1Enums.ResponseTypeV1.Ranking
  ) {
    return createRankingResponseV1Model(response.responseTypeV1);
  } else if (
    response.responseTypeV1 instanceof
    SubmittedResponseEnums.Response.Version1Enums.ResponseTypeV1.Grid
  ) {
    return createGridResponseV1Model(response.responseTypeV1);
  }
};

export const createSubmittedResponseModel = (
  submittedResponse: SubmittedResponse,
): Instance<typeof SubmittedResponseModel> => {
  let response:
    | Instance<typeof OpenEndedResponseV1Model>
    | Instance<typeof SingleSelectResponseV1Model>
    | Instance<typeof MultiSelectResponseV1Model>
    | Instance<typeof RankingResponseV1Model>
    | Instance<typeof GridResponseV1Model>
    | Instance<typeof GroupResponsesV1Model>
    | undefined;
  if (
    submittedResponse.response.responseTypeV1 instanceof
    SubmittedResponseEnums.Response.Version1Enums.ResponseTypeV1.OpenEnded
  ) {
    response = createOpenEndedResponseV1Model(
      submittedResponse.response.responseTypeV1,
    );
  } else if (
    submittedResponse.response.responseTypeV1 instanceof
    SubmittedResponseEnums.Response.Version1Enums.ResponseTypeV1.SingleSelect
  ) {
    response = createSingleSelectResponseV1Model(
      submittedResponse.response.responseTypeV1,
    );
  } else if (
    submittedResponse.response.responseTypeV1 instanceof
    SubmittedResponseEnums.Response.Version1Enums.ResponseTypeV1.MultiSelect
  ) {
    response = createMultiSelectResponseV1Model(
      submittedResponse.response.responseTypeV1,
    );
  } else if (
    submittedResponse.response.responseTypeV1 instanceof
    SubmittedResponseEnums.Response.Version1Enums.ResponseTypeV1.Ranking
  ) {
    response = createRankingResponseV1Model(
      submittedResponse.response.responseTypeV1,
    );
  } else if (
    submittedResponse.response.responseTypeV1 instanceof
    SubmittedResponseEnums.Response.Version1Enums.ResponseTypeV1.Grid
  ) {
    response = createGridResponseV1Model(
      submittedResponse.response.responseTypeV1,
    );
  } else if (
    submittedResponse.response.responseTypeV1 instanceof
    SubmittedResponseEnums.Response.Version1Enums.ResponseTypeV1.Group
  ) {
    response = createGroupResponsesV1Model(
      submittedResponse.response.responseTypeV1,
    );
  } else {
    response = parseResponseType(submittedResponse.response);
  }
  return SubmittedResponseModel.create({
    question: submittedResponse.question?.text,
    questionCode: submittedResponse.questionCode?.code,
    questionId: submittedResponse.questionId.uuid,
    questionType: submittedResponse.questionType,
    response,
  });
};
