import { Typography } from "@mui/material";
import {
  FoundationColorTokens,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";

interface B1TypographyLabelProps {
  text?: string;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  width?: string;
}

export const B1TypographyLabel = ({
  text,
  tokens,
  typography,
  width,
}: B1TypographyLabelProps): React.ReactElement => {
  return (
    <Typography {...typography.b1} color={tokens.label} width={width}>
      {text}
    </Typography>
  );
};
