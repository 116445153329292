import { Stack, Typography } from "@mui/material";
import {
  DeveloperErrorFlow,
  ICON_SIZE,
  MAX_SURVEY_LIMIT,
  PROJECT_SURVEY_NAME_VALIDATIONS,
  getIconProps,
} from "@pulse/shared-components";
import {
  Dialog,
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
  TextInputField,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { InfoIcon } from "lucide-react";
import React from "react";
import {
  CreateNewSurveyRPCErrors,
  ProjectDetailsStore,
} from "../store/ProjectDetailsStore";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../routes/RoutesHelper";
import { DialogErrorContent } from "../../../components/DialogErrorContent";

export enum CreateSurveyDialogState {
  CreateSurvey = "CREATE_SURVEY",
  ERROR = "ERROR",
}

interface CreateSurveyDialogProps {
  isCreateSurveyDialogOpen: boolean;
  t: TFunction;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  projectStore: Instance<typeof ProjectDetailsStore>;
  navigateTo: NavigateToFunctions;
  setIsCreateSurveyDialogOpen: (value: React.SetStateAction<boolean>) => void;
}

export const CreateSurveyDialog = observer(
  ({
    isCreateSurveyDialogOpen,
    t,
    spacing,
    tokens,
    typography,
    projectStore,
    navigateTo,
    setIsCreateSurveyDialogOpen,
  }: CreateSurveyDialogProps): React.ReactElement => {
    const getSurveyNameTextFieldHelperText = (): string | undefined => {
      switch (projectStore.rpcError) {
        case CreateNewSurveyRPCErrors.InvalidSurveyName:
          return t("surveys.surveyNameHelperText.invalidSurveyName", {
            allowedSpecialCharacters:
              PROJECT_SURVEY_NAME_VALIDATIONS.allowedSpecialCharacters,
          });
        case CreateNewSurveyRPCErrors.InvalidSurveyNameLength:
          return t("surveys.surveyNameHelperText.invalidSurveyNameLength", {
            minLength: PROJECT_SURVEY_NAME_VALIDATIONS.minLength,
            maxLength: PROJECT_SURVEY_NAME_VALIDATIONS.maxLength,
          });
        case CreateNewSurveyRPCErrors.SurveyNameNotUnique:
          return t("surveys.surveyNameHelperText.surveyNameNotUnique");
      }
    };

    const getErrorText = (): string => {
      switch (projectStore.rpcError) {
        case CreateNewSurveyRPCErrors.MaximumSurveyCountExceeded:
          return t(
            "surveys.addSurvey.addSurveyDialog.maximumSurveyCountExceededErrorText",
            {
              maxSurveys: MAX_SURVEY_LIMIT,
            },
          );
        case CreateNewSurveyRPCErrors.ProjectAlreadyArchived:
          return t(
            "surveys.addSurvey.addSurveyDialog.projectAlreadyArchivedErrorText",
          );
        default:
          return t("surveys.addSurvey.addSurveyDialog.permanentError");
      }
    };

    const getDialogChild = (): React.ReactElement => {
      switch (projectStore.createSurveyDialogState) {
        case CreateSurveyDialogState.CreateSurvey: {
          return (
            <Stack gap={spacing.spaceLG} maxWidth="520px">
              <Stack flexDirection="row" gap={spacing.spaceXS}>
                <InfoIcon
                  {...getIconProps(tokens.iconSubtle, ICON_SIZE.default)}
                />
                <Typography
                  {...typography.b2}
                  color={tokens.labelSubtle}
                  maxWidth="492px"
                >
                  {t("surveys.addSurvey.addSurveyDialog.description")}
                </Typography>
              </Stack>
              <TextInputField
                name={t("surveys.addSurvey.addSurveyDialog.inputFieldTitle")}
                value={projectStore.surveyStore.surveyName}
                onTextChange={projectStore.surveyStore.setSurveyName}
                label={t("surveys.addSurvey.addSurveyDialog.inputFieldTitle")}
                type={"text"}
                placeholder={t(
                  "surveys.addSurvey.addSurveyDialog.inputFieldPlaceholder",
                )}
                helperText={getSurveyNameTextFieldHelperText()}
                error={projectStore.doesStoreContainSurveyNameErrors}
                disabled={projectStore.isRPCLoading}
                required={true}
              />
            </Stack>
          );
        }
        case CreateSurveyDialogState.ERROR: {
          return (
            <DialogErrorContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              errorText={getErrorText()}
            />
          );
        }
        default:
          return <DeveloperErrorFlow />;
      }
    };

    return (
      <Dialog
        open={isCreateSurveyDialogOpen}
        width="560px"
        title={t("surveys.addSurvey.addSurveyDialog.title")}
        contentPadding={spacing.spaceLG}
        primaryButtonText={
          !projectStore.isCreateSurveyDialogErrorState
            ? t("surveys.addSurvey.addSurveyDialog.primaryButtonText")
            : undefined
        }
        onPrimaryButtonClick={async (): Promise<void> => {
          if (projectStore.validateSurveyName()) {
            await processProjectParams(projectStore.createSurvey, navigateTo);
            if (
              projectStore.surveyStore.surveyId &&
              projectStore.surveyStore.projectId &&
              !projectStore.surveyStore.doesStoreContainError &&
              !projectStore.doesStoreContainError
            ) {
              navigateTo.surveyDetails(
                projectStore.surveyStore.projectId,
                projectStore.surveyStore.surveyId,
              );
            }
          }
        }}
        isPrimaryButtonDisabled={
          projectStore.isCreateSurveyPrimaryButtonDisabled
        }
        secondaryButtonText={
          projectStore.isCreateSurveyDialogErrorState
            ? t("common.close")
            : t("surveys.addSurvey.addSurveyDialog.secondaryButtonText")
        }
        onSecondaryButtonClick={(): void => {
          setIsCreateSurveyDialogOpen(false);
          projectStore.clearSurveyNameAndRPCError();
        }}
        isSecondaryButtonDisabled={
          projectStore.isCreateSurveySecondaryButtonDisabled
        }
        disableBackdropClick={true}
      >
        {/*
        getDialogChild() is not called like <DialogChild />.
        This is done to avoid giving it its own lifecycle which will result in loss of focus from <TextInputField /> on store updation.
        */}
        {getDialogChild()}
      </Dialog>
    );
  },
);
