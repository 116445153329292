import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { DetailSectionComponent } from "../../../components/DetailSectionComponent";
import {
  useReportsStore,
  useSignInStore,
  useSurveyStore,
} from "../../store/Hooks";
import { getSurveyStatusChipProps } from "../../utils/getSurveyStatusChipProps";
import { useTranslation } from "react-i18next";
import {
  NavigateToFunctions,
  processProjectParams,
  processSurveyParams,
  useRouteNavigator,
} from "../../../routes/RoutesHelper";
import {
  Button,
  FoundationColorTokens,
  LoadingIndicator,
  Spacing,
  Typography as LeoTypography,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
  RadioButtonColorTokens,
  useRadioButtonColorTokens,
  TabProps,
  CornerRadius,
} from "@surya-digital/leo-reactjs-material-ui";
import { ProjectDetailsTabs } from "../../projects/pages/ProjectDetailsPage";
import {
  BorderStyle,
  getIconProps,
  ICON_SIZE,
  useBorder,
} from "@pulse/shared-components";
import { observer } from "mobx-react";
import { FileX2, Plus } from "lucide-react";
import { navigateOrGetNetworkErrorPage } from "../../../utility/navigateOrGetNetworkErrorPage";
import { useNetworkingErrorStore } from "../../networking/store/hooks";
import { SurveyDashboardViews } from "../components/SurveyDashboardViews";
import { TFunction } from "i18next";
import { AddViewDialog } from "../components/AddViewDialog";
import { Instance } from "mobx-state-tree";
import { AddViewStore } from "../stores/AddViewStore";
import { Stack, Typography } from "@mui/material";
import { UserPrivileges } from "../../store/user/UserPrivileges";
import { SectionHeaderProps } from "../../../components/SectionHeader";
import { ReportsStore } from "../stores/ReportsStore";
import { DashboardPreferences } from "../components/dashboardPreferences/DashboardPreferences";
import { SignInStore } from "../../auth/store/SignInStore";

export enum SurveyViewDetailsTabs {
  VIEWS,
  DASHBOARD_PREFERENCES,
}

interface TabElementProps {
  index: SurveyViewDetailsTabs;
  reportsStore: Instance<typeof ReportsStore>;
  isGetSurveyDashboardViewsCalled: boolean;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  border: BorderStyle;
  cornerRadius: CornerRadius;
  navigateTo: NavigateToFunctions;
  t: TFunction;
  radioButtonTokens: RadioButtonColorTokens<string>;
  signInStore: Instance<typeof SignInStore>;
}

const TabElement = observer(
  ({
    index,
    reportsStore,
    isGetSurveyDashboardViewsCalled,
    border,
    cornerRadius,
    navigateTo,
    spacing,
    tokens,
    typography,
    t,
    radioButtonTokens,
    signInStore,
  }: TabElementProps): ReactElement => {
    switch (index) {
      case SurveyViewDetailsTabs.VIEWS: {
        return reportsStore.isRPCLoading ? (
          <LoadingIndicator isLoading={true} />
        ) : reportsStore.isSurveyDashboardViewsListEmpty &&
          isGetSurveyDashboardViewsCalled ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            gap={spacing.spaceXS}
          >
            <FileX2
              {...getIconProps(tokens.iconLowEmphasis, ICON_SIZE.large)}
            />
            <Typography {...typography.b1} color={tokens.labelLowEmphasis}>
              {t("surveyDashboard.noViewsFound")}
            </Typography>
          </Stack>
        ) : (
          <SurveyDashboardViews
            border={border}
            cornerRadius={cornerRadius}
            spacing={spacing}
            tokens={tokens}
            typography={typography}
            t={t}
            navigateTo={navigateTo}
            radioButtonTokens={radioButtonTokens}
            reportStore={reportsStore}
            signInStore={signInStore}
          />
        );
      }
      case SurveyViewDetailsTabs.DASHBOARD_PREFERENCES: {
        return (
          <DashboardPreferences
            dashboardPreferencesStore={reportsStore.dashboardPreferencesStore}
            spacing={spacing}
            typography={typography}
            t={t}
            tokens={tokens}
            navigateTo={navigateTo}
            radioButtonTokens={radioButtonTokens}
            cornerRadius={cornerRadius}
          />
        );
      }
    }
  },
);

interface AddViewProps {
  t: TFunction;
  addViewStore: Instance<typeof AddViewStore>;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  radioButtonTokens: RadioButtonColorTokens<string>;
  border: BorderStyle;
  navigateTo: NavigateToFunctions;
}

const AddView = observer(
  ({
    t,
    addViewStore,
    spacing,
    tokens,
    typography,
    radioButtonTokens,
    border,
    navigateTo,
  }: AddViewProps): ReactElement => {
    const [isAddViewDialogOpen, setIsAddViewDialogOpen] = useState(false);
    return (
      <>
        <Button
          label={t("surveyDashboard.addViewDialog.addView")}
          name="addView"
          size="medium"
          variant="outlined-neutral"
          onClick={(): void => {
            setIsAddViewDialogOpen(true);
          }}
          icon={<Plus />}
        />
        <AddViewDialog
          isAddViewDialogOpen={isAddViewDialogOpen}
          setIsAddViewDialogOpen={setIsAddViewDialogOpen}
          addViewStore={addViewStore}
          t={t}
          spacing={spacing}
          tokens={tokens}
          typography={typography}
          radioButtonTokens={radioButtonTokens}
          border={border}
          navigateTo={navigateTo}
        />
      </>
    );
  },
);

export const SurveyViews = observer((): ReactElement => {
  const surveyStore = useSurveyStore();
  const { t } = useTranslation();
  const navigateTo = useRouteNavigator();
  const errorStore = useNetworkingErrorStore();
  const reportsStore = useReportsStore();
  const border = useBorder();
  const cornerRadius = useCornerRadius();
  const spacing = useSpacing();
  const tokens = useFoundationColorTokens();
  const typography = useTypography();
  const [isGetSurveyDashboardViewsCalled, setIsGetSurveyDashboardViewsCalled] =
    useState(false);
  const radioButtonTokens = useRadioButtonColorTokens();
  const signInStore = useSignInStore();

  const errorPage = navigateOrGetNetworkErrorPage(
    errorStore.error,
    navigateTo,
    errorStore.removeError,
  );

  if (errorPage) {
    errorStore.removeError();
    return errorPage;
  }

  const getSurveyDashboardViews = useCallback(async () => {
    await processSurveyParams(reportsStore.getSurveyDashboardViews, navigateTo);
    setIsGetSurveyDashboardViewsCalled(true);
  }, []);

  useEffect(() => {
    getSurveyDashboardViews();
    return () => {
      reportsStore.setSelectedTabIndex(SurveyViewDetailsTabs.VIEWS);
    };
  }, []);

  const getTabs = (): TabProps[] => {
    const tabs: TabProps[] = [
      { label: t("surveyDashboard.views.viewsStringLiteral") },
    ];

    if (
      signInStore.isPrivilegeGranted(
        UserPrivileges.processSurveyDashboardPreferences,
      )
    ) {
      tabs.push({ label: t("surveyDashboard.dashboardPreferences") });
    }
    return tabs;
  };
  const getSectionHeaderProps = (): SectionHeaderProps | undefined => {
    switch (reportsStore.selectedTabIndex) {
      case SurveyViewDetailsTabs.VIEWS: {
        return {
          actionElement: signInStore.isPrivilegeGranted(
            UserPrivileges.processSurveyDashboardViews,
          ) ? (
            <AddView
              t={t}
              addViewStore={reportsStore.addViewStore}
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              radioButtonTokens={radioButtonTokens}
              border={border}
              navigateTo={navigateTo}
            />
          ) : undefined,
          title: t("surveyDashboard.views.viewsStringLiteral"),
          prefersLargeTitle: true,
        };
      }
      case SurveyViewDetailsTabs.DASHBOARD_PREFERENCES: {
        return {
          title: t("projects.settings.settingsTitle"),
          prefersLargeTitle: true,
          actionElement: (
            <>
              <Button
                label={t("common.saveChangesButtonText")}
                name="saveChanges"
                size="medium"
                variant="filled"
                disabled={
                  reportsStore.dashboardPreferencesStore.isSaveButtonDisabled
                }
                onClick={async (): Promise<void> => {
                  if (reportsStore.dashboardPreferencesStore.validateFields()) {
                    await processSurveyParams(
                      reportsStore.dashboardPreferencesStore
                        .updateSurveyDashboardPreferences,
                      navigateTo,
                    );
                  }
                }}
              />
            </>
          ),
        };
      }
      default: {
        console.error(
          `The selected tab ${reportsStore.selectedTabIndex} index is not handled.`,
        );
        return undefined;
      }
    }
  };

  return (
    <DetailSectionComponent
      isManageUsersLogoVisible={true}
      isAuditLogsLogoVisible={true}
      mainHeaderProps={{
        title: surveyStore.surveyName,
        prefersLargeTitle: true,
        chipProps:
          surveyStore.surveyStatus &&
          getSurveyStatusChipProps(surveyStore.surveyStatus, t),
        backButtonCallback: () => {
          processProjectParams(async (projectId): Promise<void> => {
            navigateTo.projectDetails(projectId, ProjectDetailsTabs.Dashboard);
          }, navigateTo);
        },
      }}
      isChildHeightVariable={
        reportsStore.isSurveyDashboardViewsListEmpty ? false : true
      }
      isOverflowHidden={true}
      onLogoClickCallback={() => {
        navigateTo.project();
      }}
      sectionHeaderProps={getSectionHeaderProps()}
      tabsProps={{
        tabs: getTabs(),
        selectedTabIndex: reportsStore.selectedTabIndex,
        onTabChange: (index: number) => {
          reportsStore.setSelectedTabIndex(index);
        },
        disableBottomBorder: true,
      }}
    >
      <TabElement
        index={reportsStore.selectedTabIndex}
        border={border}
        cornerRadius={cornerRadius}
        navigateTo={navigateTo}
        spacing={spacing}
        tokens={tokens}
        typography={typography}
        t={t}
        radioButtonTokens={radioButtonTokens}
        isGetSurveyDashboardViewsCalled={isGetSurveyDashboardViewsCalled}
        reportsStore={reportsStore}
        signInStore={signInStore}
      />
    </DetailSectionComponent>
  );
});
