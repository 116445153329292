import {
  Dialog,
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
  useRadioButtonColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import React, { SetStateAction } from "react";
import { TFunction } from "i18next";
import {
  DownloadReportsDialogState,
  ReportStore,
  SurveyReportError,
} from "../../store/ReportStore";
import { Instance } from "mobx-state-tree";
import { ErrorSuccessDialogContent } from "../ErrorSuccessDialogContent";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../../routes/RoutesHelper";
import { ProjectDetailsStore } from "../../store/ProjectDetailsStore";
import { InformationDialogContent } from "../InformationDialogContent";
import {
  EMPTY_CHARACTER,
  getRadioButtonStyleProps,
} from "@pulse/shared-components";
import { DownloadReportsPreferences } from "./DownloadReportsPreferences";
import { Stack } from "@mui/material";

interface DownloadReportsDialogProps {
  isDownloadReportsDialogOpen: boolean;
  setIsDownloadReportsDialogOpen: React.Dispatch<SetStateAction<boolean>>;
  setIsSnackbarVisible: React.Dispatch<SetStateAction<boolean>>;
  t: TFunction;
  spacing: Spacing;
  reportStore: Instance<typeof ReportStore>;
  projectStore: Instance<typeof ProjectDetailsStore>;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  navigateTo: NavigateToFunctions;
}

export const DownloadReportsDialog = observer(
  ({
    isDownloadReportsDialogOpen,
    setIsDownloadReportsDialogOpen,
    t,
    spacing,
    reportStore,
    projectStore,
    tokens,
    typography,
    navigateTo,
  }: DownloadReportsDialogProps): React.ReactElement => {
    const radioButtonTokens = useRadioButtonColorTokens();
    if (
      projectStore.dashboardStore.initialSelectedSurvey.surveyId !==
      EMPTY_CHARACTER
    ) {
      reportStore.setSelectedSurvey(
        projectStore.dashboardStore.initialSelectedSurvey.surveyId,
        projectStore.dashboardStore.initialSelectedSurvey.surveyName,
      );
    } else {
      console.error("Selected survey not found.");
      navigateTo.internalServerError();
    }

    const radioProps = getRadioButtonStyleProps(
      tokens,
      radioButtonTokens,
      spacing,
      `0px ${spacing.spaceXS}`,
    );

    const getErrorText = (): string => {
      switch (reportStore.rpcErrors) {
        case SurveyReportError.SurveyIsInDraftState:
          return t("projects.reports.surveyIsInDraftState");
        case SurveyReportError.NoSurveyAnswerOrLinkFound:
          return t("projects.reports.noSurveyAnswerOrLinkFound");
        default:
          return t("projects.reports.downloadReportsErrorText");
      }
    };

    const DownloadReportsDialogContent = observer((): React.ReactElement => {
      switch (reportStore.downloadReportsDialogState) {
        case DownloadReportsDialogState.DownloadPreference:
          return (
            <Stack width="100%" padding={spacing.spaceLG}>
              <DownloadReportsPreferences
                reportStore={reportStore}
                typography={typography}
                spacing={spacing}
                t={t}
                tokens={tokens}
                radioProps={radioProps}
              />
            </Stack>
          );
        case DownloadReportsDialogState.NoSurveyCreated:
          return (
            <InformationDialogContent
              spacing={spacing}
              tokens={tokens}
              informationText={t("projects.reports.noSurveyCreatedText")}
              typography={typography}
            />
          );
        case DownloadReportsDialogState.ReportGenerationSuccess:
          return (
            <InformationDialogContent
              spacing={spacing}
              tokens={tokens}
              informationText={t("projects.reports.downloadReportsSuccessText")}
              typography={typography}
            />
          );
        case DownloadReportsDialogState.ReportGenerationError:
          return (
            <ErrorSuccessDialogContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              isError={true}
              errorSuccessText={getErrorText()}
            />
          );
      }
    });

    const getSecondaryButtonText = (): string => {
      switch (reportStore.downloadReportsDialogState) {
        case DownloadReportsDialogState.ReportGenerationError:
          return t("common.close");
        case DownloadReportsDialogState.NoSurveyCreated:
        case DownloadReportsDialogState.ReportGenerationSuccess:
          return t("common.dismiss");
        default:
          return t("common.cancel");
      }
    };

    return (
      <Dialog
        open={isDownloadReportsDialogOpen}
        title={t("projects.reports.downloadReports")}
        secondaryButtonText={getSecondaryButtonText()}
        isSecondaryButtonDisabled={
          reportStore.isRPCLoading || projectStore.isRPCLoading
        }
        onSecondaryButtonClick={() => {
          setIsDownloadReportsDialogOpen(false);
          reportStore.clearStore();
          projectStore.clearRPCError();
        }}
        isPrimaryButtonDisabled={
          reportStore.isDownloadReportsDialogPrimaryButtonDisabled
        }
        primaryButtonText={
          reportStore.isDownloadReportsDialogPrimaryButtonVisible
            ? t("common.download")
            : undefined
        }
        onPrimaryButtonClick={() =>
          processProjectParams(reportStore.requestSurveyReport, navigateTo)
        }
        disableBackdropClick={true}
        contentPadding={
          reportStore.isDownloadReportsContentPaddingPresent
            ? "0px"
            : spacing.spaceLG
        }
      >
        <DownloadReportsDialogContent />
      </Dialog>
    );
  },
);
