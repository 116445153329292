import React from "react";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import {
  UploadTranslationsDialogState,
  ValidateAndUploadTranslationErrors,
} from "../../store/TranslationUploadStore";
import {
  Dialog,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { DialogSuccessContent } from "../../../../components/DialogSuccessContent";
import { DialogLoaderContent } from "../../../projects/components/DialogLoaderContent";
import { TFunction } from "i18next";
import { DialogErrorContent } from "../../../../components/DialogErrorContent";
import { TranslationsStore } from "../../store/TranslationsStore";
import {
  NavigateToFunctions,
  processSurveyParams,
} from "../../../../routes/RoutesHelper";
import {
  DeveloperErrorFlow,
  MAX_TRANSLATION_LANGUAGE_LIMIT,
} from "@pulse/shared-components";

export interface TranslationValidationDialogProps {
  translationsStore: Instance<typeof TranslationsStore>;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  t: TFunction;
  typography: Typography;
  navigateTo: NavigateToFunctions;
}

export const TranslationValidationDialog = observer(
  ({
    translationsStore,
    t,
    tokens,
    typography,
    spacing,
    navigateTo,
  }: TranslationValidationDialogProps): React.ReactElement => {
    const translationsUploadStore = translationsStore.translationUploadStore;

    const getButtonText = (): string | undefined => {
      switch (translationsUploadStore.uploadTranslationsDialogState) {
        case UploadTranslationsDialogState.ValidatingFile: {
          return undefined;
        }
        case UploadTranslationsDialogState.ErrorState: {
          return t("common.close");
        }
        default: {
          return t("common.done");
        }
      }
    };

    const getDialogErrorDescription = (): string => {
      switch (translationsUploadStore.rpcError) {
        case ValidateAndUploadTranslationErrors.MaximumTranslationLimitReached: {
          return t(
            "surveys.translations.maximumTranslationLimitReachedDescription",
            {
              translationLimit: MAX_TRANSLATION_LANGUAGE_LIMIT,
            },
          );
        }
        case ValidateAndUploadTranslationErrors.ProjectAlreadyArchived: {
          return t("common.projectAlreadyArchivedErrorText");
        }
        case ValidateAndUploadTranslationErrors.TranslationUpdateInProgress: {
          return t(
            "surveys.translations.translationUpdateInProgressDescription",
          );
        }
        default: {
          return t("surveys.translations.unexpectedErrorDescription");
        }
      }
    };

    const DialogContent = observer((): React.ReactElement => {
      switch (translationsUploadStore.uploadTranslationsDialogState) {
        case UploadTranslationsDialogState.ValidatingFile: {
          return (
            <DialogLoaderContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              loaderText={t("surveys.translations.validationLoaderDescription")}
            />
          );
        }
        case UploadTranslationsDialogState.ValidationSuccessful: {
          return (
            <DialogSuccessContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              description={t(
                "surveys.translations.translationUploadProcessSuccessDescription",
              )}
            />
          );
        }
        case UploadTranslationsDialogState.ErrorState: {
          return (
            <DialogErrorContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              errorText={getDialogErrorDescription()}
            />
          );
        }
        default: {
          return <DeveloperErrorFlow />;
        }
      }
    });

    return (
      <Dialog
        open={translationsUploadStore.isTranslationUploadDialogVisible}
        title={t("surveys.translations.uploadTranslationsText")}
        secondaryButtonText={getButtonText()}
        contentPadding={spacing.spaceLG}
        onSecondaryButtonClick={async () => {
          translationsUploadStore.setIsTranslationDialogVisible(false);
          if (
            translationsUploadStore.uploadTranslationsDialogState ===
            UploadTranslationsDialogState.ValidationSuccessful
          ) {
            await processSurveyParams(async (surveyId, projectId) => {
              await translationsStore.getAllTranslations(surveyId, projectId);
            }, navigateTo);
          }
        }}
        width="560px"
      >
        <DialogContent />
      </Dialog>
    );
  },
);
