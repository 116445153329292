import { Instance, types } from "mobx-state-tree";
import {
  createOpenEndedResultsV1Model,
  OpenEndedResultV1Model,
} from "./OpenEndedResultV1Model";
import { TOP_N_OPEN_ENDED_WORDS } from "../../utils/constants";
import { DataProps } from "../components/DataSection";
import {
  Code,
  GetPaginatedOpenEndedResponsesRPC,
  GridOpenEndedResultWithRowDetailsV1,
  GridResultV1Enums,
  OpenEndedGraphTypeV1,
} from "@pulse/pulse-rpcs";
import { QuestionTypeGraphs } from "../components/ChartSelection";

export const OpenEndedResultsWithRowDetails = types
  .model("OpenEndedResultsWithRowDetails", {
    openEndedV1Results: types.array(OpenEndedResultV1Model),
    columnHeader: types.maybeNull(types.string),
    rowOptionText: types.maybeNull(types.string),
    rowCode: types.string,
    topNWords: types.optional(types.number, TOP_N_OPEN_ENDED_WORDS[0]),
    fieldCode: types.string,
  })
  .views((store) => ({
    getPaginatedOpenEndedResponsesRPCGroupEnum(
      groupChildQuestionCode: string | undefined,
      columnCode: string,
    ):
      | GetPaginatedOpenEndedResponsesRPC.RequestEnums.OpenEndedQuestionType.Group
      | GetPaginatedOpenEndedResponsesRPC.RequestEnums.OpenEndedQuestionType.Grid {
      try {
        if (groupChildQuestionCode) {
          return new GetPaginatedOpenEndedResponsesRPC.RequestEnums.OpenEndedQuestionType.Group(
            new Code(groupChildQuestionCode),
            new GetPaginatedOpenEndedResponsesRPC.RequestEnums.OpenEndedQuestionType.GroupEnums.ChildQuestionType.Grid(
              new Code(store.rowCode),
              new Code(columnCode),
            ),
          );
        } else {
          return new GetPaginatedOpenEndedResponsesRPC.RequestEnums.OpenEndedQuestionType.Grid(
            new Code(store.rowCode),
            new Code(columnCode),
          );
        }
      } catch (e) {
        console.error(
          `Error while creating object with groupChildQuestionCode: ${groupChildQuestionCode}, columnCode: ${columnCode} and rowCode: ${store.rowCode}`,
        );
        return new GetPaginatedOpenEndedResponsesRPC.RequestEnums.OpenEndedQuestionType.Grid(
          new Code(store.rowCode),
          new Code(columnCode),
        );
      }
    },
    get openEndedResultData(): DataProps[] {
      return store.openEndedV1Results
        .map((openEndedResult): DataProps => {
          return {
            option: openEndedResult.answer,
            count: openEndedResult.count,
            percentage: openEndedResult.percentage,
            id: openEndedResult.answer,
          };
        })
        .slice(0, store.topNWords);
    },
  }))
  .actions((store) => ({
    setTopNWords: (topNWords: number): void => {
      store.topNWords = topNWords;
    },
  }));

const createOpenEndedResultsWithRowDetails = (
  openEndedResultWithRowDetailsV1: GridOpenEndedResultWithRowDetailsV1,
  columnHeader: string | undefined,
): Instance<typeof OpenEndedResultsWithRowDetails> => {
  return OpenEndedResultsWithRowDetails.create({
    openEndedV1Results: createOpenEndedResultsV1Model(
      openEndedResultWithRowDetailsV1.openEndedResults,
    ),
    columnHeader,
    rowOptionText:
      openEndedResultWithRowDetailsV1.gridRowDetails.rowOptionText?.text,
    rowCode: openEndedResultWithRowDetailsV1.gridRowDetails.rowCode.code,
    fieldCode: openEndedResultWithRowDetailsV1.fieldCode.code,
  });
};

export const GridOpenEndedModel = types
  .model("GridOpenEndedModel", {
    openEndedResultsWithRowDetails: types.array(OpenEndedResultsWithRowDetails),
    selectedGraphType: types.enumeration(
      Object.values(OpenEndedGraphTypeV1.OpenEndedGraphTypeV1),
    ),
    updatedGraphType: types.enumeration(
      Object.values(OpenEndedGraphTypeV1.OpenEndedGraphTypeV1),
    ),
    columnHeader: types.maybeNull(types.string),
  })
  .views((store) => ({
    get isGraphSelectionUpdated(): boolean {
      return store.selectedGraphType !== store.updatedGraphType;
    },
  }))
  .actions((store) => ({
    updateSelectedGraphType: (
      updatedSingleSelectGraphType: QuestionTypeGraphs,
    ): void => {
      store.updatedGraphType =
        OpenEndedGraphTypeV1.OpenEndedGraphTypeV1[
          updatedSingleSelectGraphType as keyof typeof OpenEndedGraphTypeV1.OpenEndedGraphTypeV1
        ];
    },
    updateSelectedGraphTypeWithUpdatedGraph: (): void => {
      store.selectedGraphType = store.updatedGraphType;
    },
  }));

export const createGridOpenEndedModel = (
  gridOpenEnded: GridResultV1Enums.ColumnQuestionType.OpenEnded,
  columnHeader: string | undefined,
): Instance<typeof GridOpenEndedModel> => {
  return GridOpenEndedModel.create({
    openEndedResultsWithRowDetails: gridOpenEnded.openEndedResults.map(
      (openEndedResult) =>
        createOpenEndedResultsWithRowDetails(openEndedResult, columnHeader),
    ),
    selectedGraphType: gridOpenEnded.openEndedGraphType,
    updatedGraphType: gridOpenEnded.openEndedGraphType,
    columnHeader,
  });
};
