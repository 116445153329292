import {
  FoundationColorTokens,
  IconButton,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Trash2 } from "lucide-react";
import React, { ReactElement, useState } from "react";
import { SurveyDashboardViewModel } from "../models/SurveyDashboardViewModel";
import { Instance } from "mobx-state-tree";
import { TFunction } from "i18next";
import { NavigateToFunctions } from "../../../routes/RoutesHelper";
import { observer } from "mobx-react";
import { ReportsStore } from "../stores/ReportsStore";
import { DeleteSurveyViewDialog } from "./DeleteSurveyViewDialog";

interface DeleteSurveyViewProps {
  surveyDashboardView: Instance<typeof SurveyDashboardViewModel>;
  t: TFunction;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  navigateTo: NavigateToFunctions;
  reportStore: Instance<typeof ReportsStore>;
}

export const DeleteSurveyView = observer(
  ({
    surveyDashboardView,
    t,
    spacing,
    tokens,
    typography,
    navigateTo,
    reportStore,
  }: DeleteSurveyViewProps): ReactElement => {
    const [isDeleteSurveyViewDialogOpen, setIsDeleteSurveyViewDialogOpen] =
      useState(false);

    return (
      <>
        {isDeleteSurveyViewDialogOpen && (
          <DeleteSurveyViewDialog
            isDeleteSurveyViewDialogOpen={isDeleteSurveyViewDialogOpen}
            setIsDeleteSurveyViewDialogOpen={setIsDeleteSurveyViewDialogOpen}
            surveyDashboardView={surveyDashboardView}
            t={t}
            spacing={spacing}
            tokens={tokens}
            typography={typography}
            navigateTo={navigateTo}
            reportStore={reportStore}
          />
        )}
        <IconButton
          name="delete"
          size="small"
          icon={<Trash2 />}
          variant="plain-color"
          color="destructive"
          onClick={(): void => {
            setIsDeleteSurveyViewDialogOpen(true);
          }}
        />
      </>
    );
  },
);
