import {
  GroupChildVisibilityWithQuestionDetails,
  QuestionType,
  SurveyQuestionViewDetails,
} from "@pulse/pulse-rpcs";
import { clone, Instance, types } from "mobx-state-tree";

export const GroupChildQuestionModel = types
  .model("GroupChildQuestionModel", {
    isViewVisible: types.boolean,
    questionText: types.maybe(types.string),
    questionType: types.enumeration(Object.values(QuestionType.QuestionType)),
    questionCode: types.string,
  })
  .actions((store) => ({
    updateIsViewVisible: (): void => {
      store.isViewVisible = !store.isViewVisible;
    },
  }));

const createGroupChildQuestionModel = (
  groupChildQuestion: GroupChildVisibilityWithQuestionDetails,
): Instance<typeof GroupChildQuestionModel> => {
  return GroupChildQuestionModel.create({
    isViewVisible: groupChildQuestion.groupChildVisibility.isViewVisible,
    questionText: groupChildQuestion.question?.text,
    questionType: groupChildQuestion.questionType,
    questionCode:
      groupChildQuestion.groupChildVisibility.childQuestionCode.code,
  });
};

export const SurveyQuestionViewsModel = types
  .model("SurveyQuestionViewsModel", {
    surveyQuestionViewId: types.string,
    questionCode: types.string,
    questionType: types.enumeration(Object.values(QuestionType.QuestionType)),
    questionText: types.maybe(types.string),
    order: types.number,
    isViewVisible: types.boolean,
    groupChildQuestions: types.array(GroupChildQuestionModel),
  })
  .actions((store) => ({
    updateIsViewVisible: (): void => {
      store.isViewVisible = !store.isViewVisible;
    },
    moveChildQuestionAfter: (
      draggingQuestionCode: string,
      nextToQuestionCode: string,
    ): void => {
      const draggingQuestionIndex = store.groupChildQuestions.findIndex(
        (childQuestion) => childQuestion.questionCode === draggingQuestionCode,
      );
      if (draggingQuestionIndex === -1) {
        console.error(`Question with code ${draggingQuestionCode} not found.`);
        return;
      }

      const draggingQuestion = clone(
        store.groupChildQuestions[draggingQuestionIndex],
      );
      store.groupChildQuestions.splice(draggingQuestionIndex, 1);

      const nextToQuestionIndex = store.groupChildQuestions.findIndex(
        (childQuestion) => childQuestion.questionCode === nextToQuestionCode,
      );
      if (nextToQuestionIndex === -1) {
        console.error(
          `Child question with code ${nextToQuestionCode} not found.`,
        );
        store.groupChildQuestions.push(draggingQuestion);
      } else if (nextToQuestionIndex === store.groupChildQuestions.length - 1) {
        store.groupChildQuestions.push(draggingQuestion);
      } else {
        store.groupChildQuestions.splice(
          nextToQuestionIndex + 1,
          0,
          draggingQuestion,
        );
      }
    },
    moveChildQuestionBefore: (
      draggingQuestionCode: string,
      beforeQuestionCode: string,
    ): void => {
      const draggingQuestionIndex = store.groupChildQuestions.findIndex(
        (childQuestion) => childQuestion.questionCode === draggingQuestionCode,
      );

      if (draggingQuestionIndex === -1) {
        console.error(`Question with code ${draggingQuestionCode} not found.`);
        return;
      }

      const draggingQuestion = clone(
        store.groupChildQuestions[draggingQuestionIndex],
      );

      store.groupChildQuestions.splice(draggingQuestionIndex, 1);

      const beforeQuestionIndex = store.groupChildQuestions.findIndex(
        (childQuestion) => childQuestion.questionCode === beforeQuestionCode,
      );

      if (beforeQuestionIndex === -1) {
        console.error(
          `Child question with code ${beforeQuestionCode} not found.`,
        );
        store.groupChildQuestions.push(draggingQuestion);
      } else if (beforeQuestionIndex === 0) {
        store.groupChildQuestions.unshift(draggingQuestion);
      } else {
        store.groupChildQuestions.splice(
          beforeQuestionIndex,
          0,
          draggingQuestion,
        );
      }
    },
  }))
  .actions((store) => ({
    reorderChildQuestions: (activeId: string, overId: string): void => {
      const activeIndex = store.groupChildQuestions.findIndex(
        (question) => question.questionCode === activeId,
      );

      const overIndex = store.groupChildQuestions.findIndex(
        (question) => question.questionCode === overId,
      );

      const isMovingAbove = overIndex < activeIndex;
      if (isMovingAbove) {
        store.moveChildQuestionBefore(activeId, overId);
      } else {
        store.moveChildQuestionAfter(activeId, overId);
      }
    },
  }));

export const createSurveyQuestionViewsModel = (
  surveyQuestionViewDetails: SurveyQuestionViewDetails,
): Instance<typeof SurveyQuestionViewsModel> => {
  return SurveyQuestionViewsModel.create({
    isViewVisible: surveyQuestionViewDetails.isViewVisible,
    order: surveyQuestionViewDetails.order.order,
    questionCode: surveyQuestionViewDetails.questionCode.code,
    questionText: surveyQuestionViewDetails.question?.text,
    questionType: surveyQuestionViewDetails.questionType,
    surveyQuestionViewId: surveyQuestionViewDetails.surveyQuestionViewId.uuid,
    groupChildQuestions: surveyQuestionViewDetails.groupChildVisibilities.map(
      (groupChildVisibility) => {
        return createGroupChildQuestionModel(groupChildVisibility);
      },
    ),
  });
};
