import { Instance, types } from "mobx-state-tree";
import {
  createOpenEndedResponseV1Model,
  OpenEndedResponseV1Model,
} from "./OpenEndedResponseV1Model";
import {
  createSingleSelectResponseV1Model,
  SingleSelectResponseV1Model,
} from "./SingleSelectResponseV1Model";
import {
  createMultiSelectResponseV1Model,
  MultiSelectResponseV1Model,
} from "./MultiSelectResponseV1Model";
import {
  createRankingResponseV1Model,
  RankingResponseV1Model,
} from "./RankingResponseV1Model";
import {
  createGridResponseV1Model,
  GridResponseV1Model,
} from "./GridResponseV1Model";
import {
  GroupQuestionResponseV1,
  GroupQuestionResponseV1Enums,
  QuestionType,
  SubmittedResponseEnums,
} from "@pulse/pulse-rpcs";

export const GroupResponseV1Model = types.model("GroupResponseV1Model", {
  question: types.maybeNull(types.string),
  questionCode: types.string,
  questionId: types.string,
  questionType: types.enumeration(Object.values(QuestionType.QuestionType)),
  response: types.maybe(
    types.union(
      OpenEndedResponseV1Model,
      SingleSelectResponseV1Model,
      MultiSelectResponseV1Model,
      RankingResponseV1Model,
      GridResponseV1Model,
    ),
  ),
});

export const createGroupResponseV1Model = (
  groupQuestionChild: GroupQuestionResponseV1,
): Instance<typeof GroupResponseV1Model> => {
  let response:
    | Instance<typeof OpenEndedResponseV1Model>
    | Instance<typeof SingleSelectResponseV1Model>
    | Instance<typeof MultiSelectResponseV1Model>
    | Instance<typeof RankingResponseV1Model>
    | Instance<typeof GridResponseV1Model>
    | undefined;

  if (
    groupQuestionChild.childResponse instanceof
    GroupQuestionResponseV1Enums.ChildResponse.OpenEnded
  ) {
    response = createOpenEndedResponseV1Model(groupQuestionChild.childResponse);
  } else if (
    groupQuestionChild.childResponse instanceof
    GroupQuestionResponseV1Enums.ChildResponse.SingleSelect
  ) {
    response = createSingleSelectResponseV1Model(
      groupQuestionChild.childResponse,
    );
  } else if (
    groupQuestionChild.childResponse instanceof
    GroupQuestionResponseV1Enums.ChildResponse.MultiSelect
  ) {
    response = createMultiSelectResponseV1Model(
      groupQuestionChild.childResponse,
    );
  } else if (
    groupQuestionChild.childResponse instanceof
    GroupQuestionResponseV1Enums.ChildResponse.Ranking
  ) {
    response = createRankingResponseV1Model(groupQuestionChild.childResponse);
  } else if (
    groupQuestionChild.childResponse instanceof
    GroupQuestionResponseV1Enums.ChildResponse.Grid
  ) {
    response = createGridResponseV1Model(groupQuestionChild.childResponse);
  }
  return GroupResponseV1Model.create({
    question: groupQuestionChild.question?.text,
    questionCode: groupQuestionChild.questionCode?.code,
    questionId: groupQuestionChild.questionId.uuid,
    questionType: groupQuestionChild.questionType,
    response,
  });
};

export const GroupResponsesV1Model = types.model("GroupResponsesV1Model", {
  groupResponses: types.array(GroupResponseV1Model),
});

export const createGroupResponsesV1Model = (
  groupResponses: SubmittedResponseEnums.Response.Version1Enums.ResponseTypeV1.Group,
): Instance<typeof GroupResponsesV1Model> => {
  return GroupResponsesV1Model.create({
    groupResponses: groupResponses.answer.map((groupResponse) => {
      return createGroupResponseV1Model(groupResponse);
    }),
  });
};
