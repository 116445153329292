import { Stack } from "@mui/material";
import React, { Fragment, ReactElement } from "react";
import { TFunction } from "i18next";
import {
  CornerRadius,
  FoundationColorTokens,
  Shadow,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  BorderStyle,
  PRIMARY_FONT,
  RICH_TEXT_DEFAULT_FONT_COLOR,
  RICH_TEXT_MARKDOWN_PROPS,
  richTextTypography,
  RTERegularTextType,
} from "@pulse/shared-components";
import { Instance } from "mobx-state-tree";
import { observer } from "mobx-react";
import { DataProps, DataSection } from "../DataSection";
import { ChartSelection, QuestionTypeGraphs } from "../ChartSelection";
import { SurveyQuestionViewStoreNames } from "../../stores/SurveyViewDetailsStore";
import { HeaderQuestionDetails } from "../HeaderQuestionDetails";
import { SelectedGraph } from "../SelectedGraph";
import { TopNWordsDropDown } from "../TopNWordsDropDown";
import { RichTextEditor } from "@surya-digital/leo-reactjs-remirror";
import { SurveySettingsEnums, TableFilter } from "@pulse/pulse-rpcs";
import { GridOpenEndedModel } from "../../models/GridOpenEndedModel";
import { ViewOpenEndedResponses } from "../ViewOpenEndedResponses";
import { OpenEndedResponsesModel } from "../../models/OpenEndedResponsesModel";
import { NavigateToFunctions } from "../../../../routes/RoutesHelper";
import { UserPrivileges } from "../../../store/user/UserPrivileges";
import { SignInStore } from "../../../auth/store/SignInStore";

export interface TopNWordsProps {
  selectedTopNWords: number;
  setSelectedTopNWords: (topNWords: number) => void;
}

interface GridOpenEndedQuestionViewProps {
  t: TFunction;
  spacing: Spacing;
  typography: Typography;
  tokens: FoundationColorTokens<string>;
  cornerRadius: CornerRadius;
  border: BorderStyle;
  columnHeader: string | null;
  selectedGraph: QuestionTypeGraphs;
  updateSelectedGraphType: (graphType: QuestionTypeGraphs) => void;
  boxShadow: Shadow;
  gridOpenEndedQuestionView: Instance<typeof GridOpenEndedModel>;
  openEndedResponsesStore: Instance<typeof OpenEndedResponsesModel>;
  navigateTo: NavigateToFunctions;
  surveyQuestionViewId: string;
  tableFilter: TableFilter[];
  columnCode: string;
  groupChildQuestionCode?: string;
  allowSPoCToSelectGraphType: boolean;
  signInStore: Instance<typeof SignInStore>;
}

export const GridOpenEndedQuestionView = observer(
  ({
    spacing,
    t,
    tokens,
    typography,
    cornerRadius,
    border,
    columnHeader,
    selectedGraph,
    updateSelectedGraphType,
    boxShadow,
    gridOpenEndedQuestionView,
    openEndedResponsesStore,
    navigateTo,
    tableFilter,
    surveyQuestionViewId,
    columnCode,
    groupChildQuestionCode,
    allowSPoCToSelectGraphType,
    signInStore,
  }: GridOpenEndedQuestionViewProps): ReactElement => {
    return (
      <Stack
        width="100%"
        alignItems="center"
        borderRadius={cornerRadius.radiusXS}
        gap={spacing.spaceXL}
        paddingBottom={spacing.space2XL}
      >
        <Stack
          paddingY={spacing.spaceSM}
          width="100%"
          borderBottom={border.default}
        >
          <Stack direction="row">
            <HeaderQuestionDetails
              questionText={columnHeader}
              sampleBreakdown={undefined}
              typography={typography}
              tokens={tokens}
              t={t}
              spacing={spacing}
              surveyQuestionViewType={
                SurveyQuestionViewStoreNames.OPEN_ENDED_QUESTION_MODEL
              }
              isQuestionTypeIconVisible={false}
            />
            {(allowSPoCToSelectGraphType ||
              signInStore.isPrivilegeGranted(
                UserPrivileges.processSurveyDashboardViews,
              )) && (
              <ChartSelection
                border={border}
                cornerRadius={cornerRadius}
                spacing={spacing}
                surveyQuestionViewType={
                  SurveyQuestionViewStoreNames.GRID_OPEN_ENDED_MODEL
                }
                selectedGraph={selectedGraph}
                onChartIconClick={updateSelectedGraphType}
                tokens={tokens}
              />
            )}
          </Stack>
        </Stack>
        {gridOpenEndedQuestionView.openEndedResultsWithRowDetails.map(
          (openEndedResultsWithRowDetail, index) => {
            return (
              <Fragment key={index}>
                {openEndedResultsWithRowDetail.rowOptionText && (
                  <Stack
                    width="100%"
                    borderBottom={border.default}
                    paddingY={spacing.spaceXS}
                  >
                    <RichTextEditor
                      name="rowOptiontext"
                      initialValue={openEndedResultsWithRowDetail.rowOptionText}
                      typography={richTextTypography(
                        PRIMARY_FONT,
                        SurveySettingsEnums.FontSizeScale.FontSizeScale
                          .DEFAULT_SCALE,
                        RICH_TEXT_DEFAULT_FONT_COLOR,
                        true,
                        RTERegularTextType.BACK_OFFICE,
                      )}
                      mode="preview"
                      supports={RICH_TEXT_MARKDOWN_PROPS}
                      borderOnPreview={false}
                    />
                  </Stack>
                )}
                <Stack
                  padding={spacing.space2XL}
                  gap={spacing.spaceXL}
                  width="100%"
                >
                  <SelectedGraph
                    selectedGraph={QuestionTypeGraphs[selectedGraph]}
                    data={openEndedResultsWithRowDetail.openEndedResultData}
                    isNeutral={false}
                    cornerRadius={cornerRadius}
                    tokens={tokens}
                    spacing={spacing}
                    typography={typography}
                    border={border}
                    naOptionCode={null}
                    t={t}
                    isSurveyViewOptionDisplayTextSelected={true}
                  />
                  <Stack
                    width="100%"
                    justifyContent="space-between"
                    direction="row"
                  >
                    <TopNWordsDropDown
                      spacing={spacing}
                      tokens={tokens}
                      cornerRadius={cornerRadius}
                      boxShadow={boxShadow}
                      border={border}
                      selectedTopNWords={
                        openEndedResultsWithRowDetail.topNWords
                      }
                      setSelectedTopNWords={
                        openEndedResultsWithRowDetail.setTopNWords
                      }
                      typography={typography}
                      t={t}
                    />
                    <ViewOpenEndedResponses
                      t={t}
                      openEndedResponsesStore={openEndedResponsesStore}
                      spacing={spacing}
                      navigateTo={navigateTo}
                      surveyQuestionViewId={surveyQuestionViewId}
                      tokens={tokens}
                      typography={typography}
                      tableFilter={tableFilter}
                      openEndedQuestionType={openEndedResultsWithRowDetail.getPaginatedOpenEndedResponsesRPCGroupEnum(
                        groupChildQuestionCode,
                        columnCode,
                      )}
                      fieldCode={openEndedResultsWithRowDetail.fieldCode}
                    />
                  </Stack>
                  <DataSection
                    spacing={spacing}
                    t={t}
                    typography={typography}
                    tokens={tokens}
                    cornerRadius={cornerRadius}
                    total={null}
                    data={
                      openEndedResultsWithRowDetail.openEndedResultData as DataProps[]
                    }
                    border={border}
                    isColoredSwatchRequired={false}
                    customOptionHeaders={undefined}
                    isSurveyViewOptionDisplayTextSelected={true}
                  />
                </Stack>
              </Fragment>
            );
          },
        )}
      </Stack>
    );
  },
);
