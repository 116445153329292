import { APIClient } from "@surya-digital/tedwig";
import {
  flow,
  getParentOfType,
  getRoot,
  Instance,
  types,
} from "mobx-state-tree";
import { getAPIClient } from "../../networking/APIClient";
import {
  DeleteDashboardViewRPC,
  EditDashboardViewRPC,
  SurveyDashboardView,
  SurveyViewDetails,
  SurveyViewName,
} from "@pulse/pulse-rpcs";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import { SPoCChartCustomisations } from "../stores/AddViewStore";
import {
  useDeleteDashboardViewRPCClientImpl,
  useEditDashboardViewRPCClientImpl,
} from "../rpcs/RPC";
import { ProjectDetailsStore } from "../../projects/store/ProjectDetailsStore";
import { CommonErrors, surveyViewNameRegex } from "@pulse/shared-components";
import { RootStore } from "../../root/store/RootStore";
import {
  createManageSurveyViewSPoCsModel,
  ManageSurveyViewSPoCsModel,
} from "./ManageSurveyViewSPoCsModel";

export enum EditSurveyViewDialogState {
  EDIT = "EDIT",
  ERROR = "ERROR",
}

export enum DeleteSurveyViewDialogState {
  DELETE = "DELETE",
  ERROR = "ERROR",
}

export enum EditViewRPCError {
  ProjectAlreadyArchived = "PROJECT_ALREADY_ARCHIVED",
  InvalidSurveyViewId = "INVALID_SURVEY_VIEW_ID",
  SurveyViewAlreadyExists = "SURVEY_VIEW_ALREADY_EXISTS",
  MasterSurveyViewCannotBeEdited = "MASTER_SURVEY_VIEW_CANNOT_BE_EDITED",
  InvalidViewName = "INVALID_VIEW_NAME",
  InvalidViewNameLength = "INVALID_VIEW_NAME_LENGTH",
}

export const SurveyDashboardViewModel = types
  .model("SurveyDashboardViewModel", {
    surveyViewId: types.string,
    surveyViewName: types.string,
    updatedSurveyViewName: types.string,
    isMasterView: types.boolean,
    editSurveyViewDialogState: types.optional(
      types.enumeration(Object.values(EditSurveyViewDialogState)),
      EditSurveyViewDialogState.EDIT,
    ),
    deleteSurveyViewDialogState: types.optional(
      types.enumeration(Object.values(DeleteSurveyViewDialogState)),
      DeleteSurveyViewDialogState.DELETE,
    ),
    isRPCLoading: types.optional(types.boolean, false),
    spocChartCustomisation: types.enumeration(
      Object.values(SPoCChartCustomisations),
    ),
    updatedSPoCChartCustomisation: types.enumeration(
      Object.values(SPoCChartCustomisations),
    ),
    manageSurveyViewSPoCs: ManageSurveyViewSPoCsModel,
  })
  .views((store) => ({
    get isEditSurveyViewDialogPrimaryButtonDisabled(): boolean {
      return (
        store.surveyViewName === store.updatedSurveyViewName &&
        store.spocChartCustomisation === store.updatedSPoCChartCustomisation
      );
    },
    get isUpdatedSPoCChangeGraphAllowed(): boolean {
      return (
        store.updatedSPoCChartCustomisation ===
        SPoCChartCustomisations.AllowSPoCToChangeGraphType
      );
    },
  }))
  .actions((store) => ({
    resetStoreValues: (removeStoreError: () => void): void => {
      removeStoreError();
      store.updatedSurveyViewName = store.surveyViewName;
      store.editSurveyViewDialogState = EditSurveyViewDialogState.EDIT;
      store.updatedSPoCChartCustomisation = store.spocChartCustomisation;
      store.deleteSurveyViewDialogState = DeleteSurveyViewDialogState.DELETE;
    },
    setUpdatedSurveyViewName: (
      updatedSurveyViewName: string,
      removeStoreError: () => void,
    ): void => {
      removeStoreError();
      store.updatedSurveyViewName = updatedSurveyViewName;
    },
    setUpdatedSpoCChartCustomisation: (
      spocChartCustomisation: SPoCChartCustomisations,
    ): void => {
      store.updatedSPoCChartCustomisation = spocChartCustomisation;
    },
    validateViewName: (
      setStoreError: (rpcError: EditViewRPCError) => void,
    ): boolean => {
      try {
        new SurveyViewName(store.updatedSurveyViewName.trim());
        const regex = new RegExp(surveyViewNameRegex);
        if (!regex.test(store.updatedSurveyViewName)) {
          setStoreError(EditViewRPCError.InvalidViewName);
          return false;
        }
        return true;
      } catch (e) {
        setStoreError(EditViewRPCError.InvalidViewNameLength);
        return false;
      }
    },
    editView: flow(function* (
      surveyId: string,
      projectId: string,
      setStoreError: (rpcError: EditViewRPCError) => void,
      removeStoreError: () => void,
      setSurveyDashboardViews: (
        surveyDashboardView: SurveyDashboardView[],
      ) => void,
    ) {
      const projectDetailsStore = getParentOfType(store, ProjectDetailsStore);
      removeStoreError();
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new EditDashboardViewRPC.Request(
          new LeoUUID(projectId),
          new LeoUUID(surveyId),
          new LeoUUID(store.surveyViewId),
          new SurveyViewDetails(
            new SurveyViewName(store.updatedSurveyViewName),
            store.isUpdatedSPoCChangeGraphAllowed,
          ),
        );
        const {
          response,
          error,
        }: {
          response?: EditDashboardViewRPC.Response;
          error?: EditDashboardViewRPC.Errors.Errors;
        } = yield useEditDashboardViewRPCClientImpl(apiClient).execute(request);
        if (response) {
          projectDetailsStore.surveyStore.setSurveyName(
            response.surveyDetailsAndDashboardViews.surveyStatusAndName
              .surveyName.name,
          );
          projectDetailsStore.surveyStore.setSurveyStatus(
            response.surveyDetailsAndDashboardViews.surveyStatusAndName
              .surveyStatus,
          );
          setSurveyDashboardViews(
            response.surveyDetailsAndDashboardViews.surveyDashboardViews,
          );
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              break;
            case EditViewRPCError.InvalidViewName:
              setStoreError(EditViewRPCError.InvalidViewName);
              // We are returning here and not setting EditSurveyViewDialogState to EditSurveyViewDialogState.Error.
              // This is done because this error is handled through helper text.
              // Setting EditSurveyViewDialogState to EditSurveyViewDialogState.Error will render the error dialog.
              return;
            case EditViewRPCError.ProjectAlreadyArchived:
              setStoreError(EditViewRPCError.ProjectAlreadyArchived);
              break;
            case EditViewRPCError.SurveyViewAlreadyExists:
              setStoreError(EditViewRPCError.SurveyViewAlreadyExists);
              // We are returning here and not setting EditSurveyViewDialogState to EditSurveyViewDialogState.Error.
              // This is done because this error is handled through helper text.
              // Setting EditSurveyViewDialogState to EditSurveyViewDialogState.Error will render the error dialog.
              return;
            case EditViewRPCError.InvalidSurveyViewId:
              setStoreError(EditViewRPCError.InvalidSurveyViewId);
              break;
            case EditViewRPCError.InvalidViewNameLength:
              setStoreError(EditViewRPCError.InvalidViewNameLength);
              break;
            case EditViewRPCError.MasterSurveyViewCannotBeEdited:
              setStoreError(EditViewRPCError.MasterSurveyViewCannotBeEdited);
              break;
            default:
              console.error(
                `Unhandled error ${error.code} from EditDashboardViewRPC`,
              );
              break;
          }
          store.editSurveyViewDialogState = EditSurveyViewDialogState.ERROR;
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(
            `Unhandled error ${e} in createView action in AddViewStore`,
          );
        }
      }
    }),
    deleteView: flow(function* (
      surveyId: string,
      projectId: string,
      setStoreError: (rpcError: DeleteDashboardViewRPC.RPCError) => void,
      removeStoreError: () => void,
      setSurveyDashboardViews: (
        surveyDashboardView: SurveyDashboardView[],
      ) => void,
    ) {
      const projectDetailsStore = getParentOfType(store, ProjectDetailsStore);
      removeStoreError();
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new DeleteDashboardViewRPC.Request(
          new LeoUUID(projectId),
          new LeoUUID(surveyId),
          new LeoUUID(store.surveyViewId),
        );
        const {
          response,
          error,
        }: {
          response?: DeleteDashboardViewRPC.Response;
          error?: DeleteDashboardViewRPC.Errors.Errors;
        } =
          yield useDeleteDashboardViewRPCClientImpl(apiClient).execute(request);
        if (response) {
          projectDetailsStore.surveyStore.setSurveyName(
            response.surveyDetailsAndDashboardViews.surveyStatusAndName
              .surveyName.name,
          );
          projectDetailsStore.surveyStore.setSurveyStatus(
            response.surveyDetailsAndDashboardViews.surveyStatusAndName
              .surveyStatus,
          );
          setSurveyDashboardViews(
            response.surveyDetailsAndDashboardViews.surveyDashboardViews,
          );
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              break;
            case DeleteDashboardViewRPC.RPCError.ProjectAlreadyArchived:
              setStoreError(
                DeleteDashboardViewRPC.RPCError.ProjectAlreadyArchived,
              );
              break;
            case DeleteDashboardViewRPC.RPCError.InvalidSurveyViewId:
              setStoreError(
                DeleteDashboardViewRPC.RPCError.InvalidSurveyViewId,
              );
              break;
            case DeleteDashboardViewRPC.RPCError
              .MasterSurveyViewCannotBeDeleted:
              setStoreError(
                DeleteDashboardViewRPC.RPCError.MasterSurveyViewCannotBeDeleted,
              );
              break;
            default:
              console.error(
                `Unhandled error ${error.code} from DeleeteDashboardViewRPC`,
              );
              break;
          }
          store.deleteSurveyViewDialogState = DeleteSurveyViewDialogState.ERROR;
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(
            `Unhandled error ${e} in deleteView action in SurveyDashboardViewModel in ReportsStore`,
          );
        }
      }
    }),
  }));

export const createSurveyDashboardViewModel = (
  surveyViewId: string,
  surveyViewName: string,
  isMasterView: boolean,
  isSPoCChangeGraphAllowed: boolean,
): Instance<typeof SurveyDashboardViewModel> => {
  return SurveyDashboardViewModel.create({
    surveyViewId,
    surveyViewName,
    isMasterView,
    updatedSurveyViewName: surveyViewName,
    spocChartCustomisation: isSPoCChangeGraphAllowed
      ? SPoCChartCustomisations.AllowSPoCToChangeGraphType
      : SPoCChartCustomisations.RestrictToSelectType,
    updatedSPoCChartCustomisation: isSPoCChangeGraphAllowed
      ? SPoCChartCustomisations.AllowSPoCToChangeGraphType
      : SPoCChartCustomisations.RestrictToSelectType,
    manageSurveyViewSPoCs: createManageSurveyViewSPoCsModel(surveyViewId),
  });
};
