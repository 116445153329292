import {
  Dialog,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import React, { useEffect, useState } from "react";
import {
  OpenEndedResponsesModel,
  ViewOpenEndedResponsesDialogState,
} from "../models/OpenEndedResponsesModel";
import { ViewOpenEndedResponsesDialogChild } from "./ViewOpenEndedResponsesDialogChild";
import { NavigateToFunctions } from "../../../routes/RoutesHelper";
import { observer } from "mobx-react";
import { DialogErrorContent } from "../../../components/DialogErrorContent";
import {
  GetPaginatedOpenEndedResponsesRPC,
  TableFilter,
} from "@pulse/pulse-rpcs";

interface ViewOpenEndedResponsesDialogProps {
  isViewOpenEndedResponsesDialogVisible: boolean;
  t: TFunction;
  spacing: Spacing;
  setIsViewOpenEndedResponsesDialogVisible: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  openEndedResponsesStore: Instance<typeof OpenEndedResponsesModel>;
  navigateTo: NavigateToFunctions;
  surveyQuestionViewId: string;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  tableFilter: TableFilter[];
  openEndedQuestionType: GetPaginatedOpenEndedResponsesRPC.RequestEnums.OpenEndedQuestionType.OpenEndedQuestionType;
  fieldCode: string;
}

export const ViewOpenEndedResponsesDialog = observer(
  ({
    isViewOpenEndedResponsesDialogVisible,
    spacing,
    t,
    setIsViewOpenEndedResponsesDialogVisible,
    openEndedResponsesStore,
    navigateTo,
    surveyQuestionViewId,
    tokens,
    typography,
    tableFilter,
    openEndedQuestionType,
    fieldCode,
  }: ViewOpenEndedResponsesDialogProps): React.ReactElement => {
    const [isRPCLoading, setIsRPCLoading] = useState(false);
    useEffect(() => {
      openEndedResponsesStore.resetStore();
    }, []);
    const getPrimaryButtonText = (): string | undefined => {
      return undefined;
    };
    const getSecondaryButtonText = (): string | undefined => {
      return t("common.close");
    };

    const getDialogChild = (): React.ReactElement => {
      switch (openEndedResponsesStore.viewOpenEndedResponsesDialogState) {
        case ViewOpenEndedResponsesDialogState.ViewResponses: {
          return (
            <ViewOpenEndedResponsesDialogChild
              spacing={spacing}
              t={t}
              openEndedResponsesStore={openEndedResponsesStore}
              navigateTo={navigateTo}
              surveyQuestionViewId={surveyQuestionViewId}
              tokens={tokens}
              typography={typography}
              tableFilter={tableFilter}
              openEndedQuestionType={openEndedQuestionType}
              setIsRPCLoading={setIsRPCLoading}
              fieldCode={fieldCode}
            />
          );
        }
        case ViewOpenEndedResponsesDialogState.Error: {
          return (
            <DialogErrorContent
              errorText={t("surveyDashboard.openEndedResponsesUnexpectedError")}
              spacing={spacing}
              tokens={tokens}
              typography={typography}
            />
          );
        }
        case ViewOpenEndedResponsesDialogState.Success: {
          return <></>;
        }
      }
    };

    const getTitleText = (): string => {
      switch (openEndedResponsesStore.viewOpenEndedResponsesDialogState) {
        case ViewOpenEndedResponsesDialogState.Success:
        case ViewOpenEndedResponsesDialogState.ViewResponses: {
          return t("surveyDashboard.openEndedResponses");
        }
        case ViewOpenEndedResponsesDialogState.Error: {
          return t("surveyDashboard.failedToLoadResponses");
        }
      }
    };

    return (
      <Dialog
        open={isViewOpenEndedResponsesDialogVisible}
        title={getTitleText()}
        contentPadding="0px"
        disableBackdropClick={true}
        primaryButtonText={getPrimaryButtonText()}
        secondaryButtonText={getSecondaryButtonText()}
        isPrimaryButtonDisabled={true}
        onPrimaryButtonClick={async (): Promise<void> => {}}
        isSecondaryButtonDisabled={isRPCLoading}
        onSecondaryButtonClick={() => {
          openEndedResponsesStore.resetStore();
          setIsViewOpenEndedResponsesDialogVisible(false);
        }}
        width="960px"
      >
        {/*
          getDialogChild() is not called like <DialogChild />.
          This is done to avoid giving it its own lifecycle which will result in loss of focus from <TextInputField /> on store updation.
        */}
        {getDialogChild()}
      </Dialog>
    );
  },
);
