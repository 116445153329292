import {
  Instance,
  applySnapshot,
  cast,
  flow,
  getParent,
  getParentOfType,
  getRoot,
  getSnapshot,
  types,
} from "mobx-state-tree";
import {
  AddSurveyQuestionRPC,
  BasicQuestionDetails,
  Code,
  GroupQuestionV1,
  GroupQuestionV1DTO,
  GetQuestionDetailsRPC,
  JsonVersion,
  OpenEndedFieldV1,
  OpenEndedQuestionV1,
  QuestionDetails,
  QuestionType,
  SurveyLocalizedText,
  UpdateSurveyQuestionRPC,
  GenerateSurveyPreviewLinkRPC,
  DeleteQuestionRPC,
  SingleChoiceDisplayTypeV1,
  SingleChoiceOption,
} from "@pulse/pulse-rpcs";
import { createOpenEndedFieldV1Store } from "./OpenEndedFieldV1Store";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import {
  useAddSurveyQuestionClientImpl,
  useDeleteQuestionClientImpl,
  useGenerateSurveyPreviewLinkClientImpl,
  useGetQuestionDetailsRPCClientImpl,
  useUpdateSurveyQuestionClientImpl,
} from "../rpcs/RPC";
import { SurveyStore } from "./SurveyStore";
import {
  CardDirection,
  EMPTY_CHARACTER,
  EMPTY_LIST_LENGTH,
  MAX_GROUP_CHILD_QUESTIONS,
  NetworkingError,
  createCardDirectionV1Model,
  createMultiChoiceQuestionV1Model,
  createOpenEndedFieldModel,
  createRankingQuestionOptionsModel,
  createRankingQuestionV1Model,
} from "@pulse/shared-components";
import { PresetScales } from "@pulse/shared-components";
import {
  QuestionDetailsModel,
  createQuestionDetailsModel,
} from "../models/QuestionDetailsModel";
import { SingleChoiceNAOptionModel } from "@pulse/shared-components";
import { createQuestionOptionsModel } from "@pulse/shared-components";
import {
  SingleChoicePresets,
  SingleChoiceQuestionV1Store,
} from "./SingleChoiceQuestionV1Store";
import { TFunction } from "i18next";
import { getAPIClient } from "../../networking/APIClient";
import { initializeGridColumn } from "../components/gridQuestionComponents/GridColumnQuestion";
import { GridQuestionV1Store } from "./GridQuestionV1Store";
import {
  SingleChoiceDisplayTypeV1Enum,
  SingleSelectQuestionV1Model,
} from "@pulse/shared-components";
import { GridTabElements } from "../components/GridQuestionCustomisationPane";
import { RootStore } from "../../root/store/RootStore";
import { GridColumnQuestionV1Model } from "../models/GridColumnQuestionV1Model";

export enum FieldValidations {
  UnfilledOptionField = "UNFILLED_FIELD",
  UnfilledQuestionCode = "UNFILLED_QUESTION_CODE",
  UnfilledMessage = "UNFILLED_MESSAGE",
  MaxSelectionExceeded = "MAX_SELECTION_EXCEEDED",
  MaxSelectionCannotBeLessThanValidValue = "MAX_SELECTION_CANNOT_BE_LESS_THAN_VALID_VALUE",
  QuestionAndOptionCodesCannotBeUpdated = "QUESTION_AND_OPTION_CODES_CANNOT_BE_UPDATED",
  InvalidNPSOptionsCount = "INVALID_NPS_OPTIONS_COUNT",
  MaxSliderRatingScaleCountReached = "MAX_SLIDER_RATING_SCALE_COUNT_REACHED",
  InvalidGridNPSSelected = "INVALID_GRID_NPS_SELECTED",
  MaxGridSizeReached = "MAX_GRID_SIZE_REACHED",
  MaxGridRadioButtonCountReached = "MAX_GRID_RADIO_BUTTON_COUNT_REACHED",
  MaxGridMultiChoiceOptionsCountReached = "MAX_GRID_MULTI_CHOICE_OPTIONS_COUNT_REACHED",
}

export enum EditSurveyQuestionErrors {
  SurveyIsClosed = "SURVEY_IS_CLOSED",
  ProjectAlreadyArchived = "PROJECT_ALREADY_ARCHIVED",
  MaxQuestionsReached = "MAX_QUESTIONS_REACHED",
  QuestionCodeNotUnique = "QUESTION_CODE_NOT_UNIQUE",
  OptionCodeNotUnique = "OPTION_CODE_NOT_UNIQUE",
  JsonVersionNotSupported = "JSON_VERSION_NOT_SUPPORTED",
  InvalidQuestionDetailsJSON = "INVALID_QUESTION_DETAILS_JSON",
  InvalidQuestionId = "INVALID_QUESTION_ID",
}

export enum GetQuestionDetailsErrors {
  InvalidQuestionId = "INVALID_QUESTION_ID",
  QuestionWasDeleted = "QUESTION_WAS_DELETED",
}

export enum DeleteQuestionErrors {
  SurveyIsClosed = "SURVEY_IS_CLOSED",
  ProjectAlreadyArchived = "PROJECT_ALREADY_ARCHIVED",
  InvalidQuestionId = "INVALID_QUESTION_ID",
  SurveyIsLive = "SURVEY_IS_LIVE",
}

export enum JsonParserEnum {
  DeserializeJSON = "DESERIALIZE_JSON",
  SerializeJSON = "SERIALIZE_JSON",
  ValidateFields = "VALIDATE_FIELDS",
  ValidateInitialFieldCode = "VALIDATE_INITIAL_FIELD_CODES",
}

export const initializeSingleChoiceNAOptionModel = (
  t: TFunction,
): Instance<typeof SingleChoiceNAOptionModel> => {
  return SingleChoiceNAOptionModel.create({
    naOptionCode: t("surveys.addSurveyQuestion.singleChoice.na"),
    naOptionString: t("surveys.addSurveyQuestion.singleChoice.notApplicable"),
    isNAOptionChecked: false,
  });
};

export const QuestionDetailsStore = types
  .model("QuestionDetailsStore", {
    questionType: types.enumeration(Object.values(QuestionType.QuestionType)),
    jsonVersion: types.integer,
    initialQuestionDetails: QuestionDetailsModel,
    updatedQuestionDetails: QuestionDetailsModel,
    singleChoicePresetScale: types.number,
    singleChoicePresetType: types.maybe(
      types.enumeration(Object.values(SingleChoicePresets)),
    ),
    parentQuestionId: types.maybeNull(types.string),
    childQuestionIds: types.array(types.string),
    isRPCLoading: types.boolean,
    duplicateOptionCode: types.maybeNull(types.string),
    previewSurveyLink: types.maybe(types.string),
    rpcErrors: types.maybeNull(
      types.union(
        types.enumeration(
          "addSurveyQuestionErrors",
          Object.values(EditSurveyQuestionErrors),
        ),
        types.enumeration(
          "getQuestionDetailsErrors",
          Object.values(GetQuestionDetailsErrors),
        ),
        types.enumeration(
          "deleteQuestionErrors",
          Object.values(DeleteQuestionErrors),
        ),
        types.enumeration("networkErrors", Object.values(NetworkingError)),
      ),
    ),
    fieldValidations: types.maybeNull(
      types.enumeration(Object.values(FieldValidations)),
    ),
  })
  .views((store) => ({
    get isParentIdNull(): boolean {
      return store.parentQuestionId === null;
    },
    get isParentQuestionMandatory(): boolean {
      const surveyStore = getParentOfType(store, SurveyStore);
      if (store.parentQuestionId === null) {
        return false;
      }
      const parentQuestion = surveyStore.findQuestionById(
        store.parentQuestionId,
      );
      if (parentQuestion === undefined) {
        console.error("Parent question could not be found");
        return false;
      }
      return parentQuestion.isMandatory;
    },
  }))
  .actions((store) => ({
    saveInitialQuestionDetails: (): void => {
      const initialQuestionDetails = getSnapshot(store.updatedQuestionDetails);
      applySnapshot(store.initialQuestionDetails, initialQuestionDetails);
    },
  }))
  .actions((store) => ({
    resetChildAndParentQuestionIds: (): void => {
      store.childQuestionIds = cast([]);
      store.parentQuestionId = null;
    },
    updateSingleSelectQuestionOptions: (
      options: SingleChoiceOption[],
    ): void => {
      store.updatedQuestionDetails.singleChoiceQuestionV1Store.updateOptions(
        options,
      );
      store.initialQuestionDetails.singleChoiceQuestionV1Store.updateOptions(
        options,
      );
    },
    setFieldValidations: (fieldValidations: FieldValidations | null): void => {
      store.fieldValidations = fieldValidations;
    },
    setRPCErrors: (rpcErrors: EditSurveyQuestionErrors | null): void => {
      store.rpcErrors = rpcErrors;
    },
    setQuestionType: (questionType: QuestionType.QuestionType): void => {
      store.questionType = questionType;
    },
    validateQuestionCode: (): void => {
      if (
        store.updatedQuestionDetails.basicQuestionDetails.questionCode.trim() ===
        EMPTY_CHARACTER
      ) {
        store.fieldValidations = FieldValidations.UnfilledQuestionCode;
      }
    },
    validateInitialQuestionCode: (): void => {
      if (
        store.updatedQuestionDetails.basicQuestionDetails.questionCode.trim() !==
        store.initialQuestionDetails.basicQuestionDetails.questionCode
      ) {
        store.fieldValidations =
          FieldValidations.QuestionAndOptionCodesCannotBeUpdated;
      }
    },
    setDuplicateOptionCode: (duplicateOptionCode: string | null): void => {
      store.duplicateOptionCode = duplicateOptionCode;
    },
    clearStore: (t: TFunction): void => {
      store.updatedQuestionDetails.basicQuestionDetails.setQuestionDescriptionString(
        EMPTY_CHARACTER,
      );
      store.updatedQuestionDetails.basicQuestionDetails.question =
        EMPTY_CHARACTER;
      store.updatedQuestionDetails.basicQuestionDetails.questionCode =
        EMPTY_CHARACTER;
      store.updatedQuestionDetails.basicQuestionDetails.isMandatory = false;
      store.updatedQuestionDetails.basicQuestionDetails.isVisibleByDefault =
        true;
      store.duplicateOptionCode = null;
      store.updatedQuestionDetails.questionDetailsJSON = EMPTY_CHARACTER;
      store.updatedQuestionDetails.openEndedFieldV1Store =
        createOpenEndedFieldV1Store();
      store.updatedQuestionDetails.multiChoiceQuestionV1Store.multiChoiceQuestionV1Model =
        createMultiChoiceQuestionV1Model(undefined, [
          createQuestionOptionsModel(),
        ]);
      store.updatedQuestionDetails.rankingQuestionV1Store.rankingQuestionV1Model =
        createRankingQuestionV1Model(undefined, [
          createRankingQuestionOptionsModel(),
        ]);
      store.updatedQuestionDetails.singleChoiceQuestionV1Store =
        SingleChoiceQuestionV1Store.create({
          singleSelectQuestionV1Model: SingleSelectQuestionV1Model.create({
            options: [createQuestionOptionsModel()],
            singleChoiceDisplayType: SingleChoiceDisplayTypeV1Enum.RadioButton,
            NAOption: initializeSingleChoiceNAOptionModel(t),
            isNPSSelectionColored: true,
            cardDirection: createCardDirectionV1Model(
              CardDirection.Horizontal,
              SingleChoiceDisplayTypeV1.CardEnums.CardDirection.HorizontalEnums
                .TextAlignment.TextAlignment.LEFT,
            ),
          }),
        });

      store.updatedQuestionDetails.gridQuestionV1Store =
        GridQuestionV1Store.create({
          rowOptions: [createQuestionOptionsModel()],
          rowHeader: EMPTY_CHARACTER,
          areAllRowOptionsMandatoryIfRowAttempted: false,
          gridColumnQuestions: [initializeGridColumn(0, t)],
          gridTabIndex: GridTabElements.ROW_QUESTIONS,
        });

      store.rpcErrors = null;
      store.fieldValidations = null;
      store.duplicateOptionCode = null;
      store.saveInitialQuestionDetails();
    },
    parseQuestionJSON: (parsingMode: JsonParserEnum, t: TFunction): void => {
      try {
        switch (store.jsonVersion) {
          case 1: {
            switch (store.questionType) {
              case QuestionType.QuestionType.OPEN_ENDED: {
                switch (parsingMode) {
                  case JsonParserEnum.DeserializeJSON: {
                    const openEndedQuestionV1 = <OpenEndedQuestionV1>(
                      JSON.parse(
                        store.updatedQuestionDetails.questionDetailsJSON,
                      )
                    );
                    store.updatedQuestionDetails.openEndedFieldV1Store.openEndedField =
                      cast(
                        openEndedQuestionV1.options.map(
                          (openEndedQuestionField: OpenEndedFieldV1) => {
                            return createOpenEndedFieldModel(
                              openEndedQuestionField.optionFieldTitle?.text,
                              openEndedQuestionField.optionFieldHint
                                ? openEndedQuestionField.optionFieldHint.text
                                : EMPTY_CHARACTER,
                              openEndedQuestionField.optionCode.code,
                            );
                          },
                        ),
                      );
                    store.updatedQuestionDetails.openEndedFieldV1Store.isChoiceResetAllowed =
                      openEndedQuestionV1.isChoiceResetAllowed ?? false;
                    store.updatedQuestionDetails.openEndedFieldV1Store.isMultiLineTextEntryAllowed =
                      openEndedQuestionV1.isMultiLineTextEntryAllowed ?? false;
                    break;
                  }
                  case JsonParserEnum.SerializeJSON: {
                    const openEndedQuestionV1 = new OpenEndedQuestionV1(
                      store.updatedQuestionDetails.openEndedFieldV1Store.openEndedField.map(
                        (openEndedQuestionField) => {
                          return new OpenEndedFieldV1(
                            openEndedQuestionField.fieldTitle.trim() !==
                            EMPTY_CHARACTER
                              ? new SurveyLocalizedText(
                                  openEndedQuestionField.fieldTitle.trim(),
                                )
                              : null,
                            openEndedQuestionField.fieldHint.trim() !==
                            EMPTY_CHARACTER
                              ? new SurveyLocalizedText(
                                  openEndedQuestionField.fieldHint.trim(),
                                )
                              : null,
                            new Code(openEndedQuestionField.code),
                          );
                        },
                      ),
                      store.updatedQuestionDetails.openEndedFieldV1Store.isChoiceResetAllowed,
                      store.updatedQuestionDetails.openEndedFieldV1Store.isMultiLineTextEntryAllowed,
                    );
                    store.updatedQuestionDetails.questionDetailsJSON =
                      JSON.stringify(openEndedQuestionV1.toDTO());
                    break;
                  }
                  case JsonParserEnum.ValidateFields:
                    {
                      store.updatedQuestionDetails.openEndedFieldV1Store.validateFields();
                    }
                    break;
                  case JsonParserEnum.ValidateInitialFieldCode: {
                    store.updatedQuestionDetails.openEndedFieldV1Store.validateInitialFieldCodes(
                      store.initialQuestionDetails.openEndedFieldV1Store,
                    );
                    break;
                  }
                }
                break;
              }
              case QuestionType.QuestionType.SINGLE_CHOICE: {
                switch (parsingMode) {
                  case JsonParserEnum.SerializeJSON: {
                    store.updatedQuestionDetails.questionDetailsJSON =
                      store.updatedQuestionDetails.singleChoiceQuestionV1Store.singleSelectQuestionV1Model.serializedJSON;
                    break;
                  }
                  case JsonParserEnum.DeserializeJSON: {
                    store.updatedQuestionDetails.singleChoiceQuestionV1Store.singleSelectQuestionV1Model.deserializeQuestionJSON(
                      store.updatedQuestionDetails.questionDetailsJSON,
                    );
                    break;
                  }
                  case JsonParserEnum.ValidateFields: {
                    store.updatedQuestionDetails.singleChoiceQuestionV1Store.validateFields();
                    break;
                  }
                  case JsonParserEnum.ValidateInitialFieldCode: {
                    store.updatedQuestionDetails.singleChoiceQuestionV1Store.validateInitialOptionCodes(
                      store.initialQuestionDetails.singleChoiceQuestionV1Store,
                    );
                    break;
                  }
                }
                break;
              }
              case QuestionType.QuestionType.MULTIPLE_CHOICE: {
                switch (parsingMode) {
                  case JsonParserEnum.SerializeJSON: {
                    store.updatedQuestionDetails.questionDetailsJSON =
                      store.updatedQuestionDetails.multiChoiceQuestionV1Store.serializedJSON;
                    break;
                  }
                  case JsonParserEnum.DeserializeJSON: {
                    store.updatedQuestionDetails.multiChoiceQuestionV1Store.deserializeJSON();
                    break;
                  }
                  case JsonParserEnum.ValidateFields: {
                    store.updatedQuestionDetails.multiChoiceQuestionV1Store.validateFields();
                    break;
                  }
                  case JsonParserEnum.ValidateInitialFieldCode: {
                    store.updatedQuestionDetails.multiChoiceQuestionV1Store.validateInitialOptionCodes(
                      store.initialQuestionDetails.multiChoiceQuestionV1Store,
                    );
                    break;
                  }
                }
                break;
              }
              case QuestionType.QuestionType.RANKING: {
                switch (parsingMode) {
                  case JsonParserEnum.SerializeJSON: {
                    store.updatedQuestionDetails.questionDetailsJSON =
                      store.updatedQuestionDetails.rankingQuestionV1Store.serializedJSON;
                    break;
                  }
                  case JsonParserEnum.DeserializeJSON: {
                    store.updatedQuestionDetails.rankingQuestionV1Store.deserializeJSON();
                    break;
                  }
                  case JsonParserEnum.ValidateFields: {
                    store.updatedQuestionDetails.rankingQuestionV1Store.validateFields();
                    break;
                  }
                  case JsonParserEnum.ValidateInitialFieldCode: {
                    store.updatedQuestionDetails.rankingQuestionV1Store.validateInitialOptionCodes(
                      store.initialQuestionDetails.rankingQuestionV1Store,
                    );
                    break;
                  }
                }
                break;
              }
              case QuestionType.QuestionType.GRID: {
                switch (parsingMode) {
                  case JsonParserEnum.SerializeJSON: {
                    store.updatedQuestionDetails.questionDetailsJSON =
                      store.updatedQuestionDetails.gridQuestionV1Store.serializedJSON;
                    break;
                  }
                  case JsonParserEnum.DeserializeJSON: {
                    store.updatedQuestionDetails.gridQuestionV1Store.deserializeJSON(
                      t,
                    );
                    break;
                  }
                  case JsonParserEnum.ValidateFields: {
                    store.updatedQuestionDetails.gridQuestionV1Store.validateFields();
                    break;
                  }
                  case JsonParserEnum.ValidateInitialFieldCode: {
                    store.updatedQuestionDetails.gridQuestionV1Store.validateInitialOptionCodes(
                      store.initialQuestionDetails.gridQuestionV1Store,
                    );
                    break;
                  }
                }
                break;
              }
              case QuestionType.QuestionType.MESSAGE: {
                switch (parsingMode) {
                  case JsonParserEnum.SerializeJSON: {
                    store.updatedQuestionDetails.questionDetailsJSON =
                      store.updatedQuestionDetails.messageQuestionV1Store.serializedJSON;
                    break;
                  }
                  case JsonParserEnum.DeserializeJSON: {
                    break;
                  }
                  case JsonParserEnum.ValidateFields: {
                    break;
                  }
                  case JsonParserEnum.ValidateInitialFieldCode: {
                    break;
                  }
                }
                break;
              }
              case QuestionType.QuestionType.GROUP: {
                switch (parsingMode) {
                  case JsonParserEnum.SerializeJSON: {
                    store.updatedQuestionDetails.questionDetailsJSON =
                      JSON.stringify(
                        new GroupQuestionV1(
                          store.childQuestionIds.map((childQuestionId) => {
                            return new LeoUUID(childQuestionId);
                          }),
                        ).toDTO(),
                      );
                    break;
                  }
                  case JsonParserEnum.DeserializeJSON: {
                    const groupQuestionV1DTO = <GroupQuestionV1DTO>(
                      JSON.parse(
                        store.updatedQuestionDetails.questionDetailsJSON,
                      )
                    );
                    const groupQuestionJson =
                      GroupQuestionV1.fromDTO(groupQuestionV1DTO);
                    store.childQuestionIds = cast(
                      groupQuestionJson.childQuestionIds.map(
                        (childQuestionId) => {
                          return childQuestionId.uuid;
                        },
                      ),
                    );
                    break;
                  }
                  case JsonParserEnum.ValidateFields: {
                    break;
                  }
                  case JsonParserEnum.ValidateInitialFieldCode: {
                    break;
                  }
                }
                break;
              }
            }
          }
        }
      } catch (e) {
        console.error(e);
        store.rpcErrors = EditSurveyQuestionErrors.InvalidQuestionDetailsJSON;
      }
    },
  }))
  //A separate action is created for the these functions so that we can utilize the functions of the other action. Example: deserializeQuestionJSON()
  .actions((store) => ({
    setParentQuestionId: (parentQuestionId: string | null): void => {
      store.parentQuestionId = parentQuestionId;
    },
    getQuestionDetails: flow(function* getQuestionDetails(
      questionId: string,
      t: TFunction,
    ) {
      store.isRPCLoading = true;
      store.setRPCErrors(null);
      store.setFieldValidations(null);
      store.setDuplicateOptionCode(null);
      try {
        const requestQuestionId = new LeoUUID(questionId);
        const apiClient = getAPIClient(store);
        const request = new GetQuestionDetailsRPC.Request(requestQuestionId);
        const {
          response,
          error,
        }: {
          response?: GetQuestionDetailsRPC.Response;
          error?: GetQuestionDetailsRPC.Errors.Errors;
        } =
          yield useGetQuestionDetailsRPCClientImpl(apiClient).execute(request);
        if (response) {
          store.updatedQuestionDetails.isResponseResetAllowed =
            response.isResponseResetAllowed;
          const responseQuestionDetails =
            response.questionDetails.basicQuestionDetails;
          store.updatedQuestionDetails.basicQuestionDetails.questionCode =
            responseQuestionDetails.questionCode.code;
          store.updatedQuestionDetails.basicQuestionDetails.question =
            responseQuestionDetails.question?.text ?? EMPTY_CHARACTER;
          store.updatedQuestionDetails.basicQuestionDetails.questionDescription =
            responseQuestionDetails.questionDescription
              ? responseQuestionDetails.questionDescription.text
              : EMPTY_CHARACTER;
          store.updatedQuestionDetails.basicQuestionDetails.isMandatory =
            responseQuestionDetails.isMandatory;
          store.updatedQuestionDetails.basicQuestionDetails.isVisibleByDefault =
            responseQuestionDetails.isVisibleByDefault;

          store.updatedQuestionDetails.questionDetailsJSON =
            response.questionDetails.questionDetailsJSON;
          store.jsonVersion = response.questionDetails.jsonVersion.version;
          const questionDetails =
            response.surveyDetailsAndQuestions.surveyQuestionDetails.find(
              (surveyQuestion) => {
                return surveyQuestion.questionId.uuid === questionId;
              },
            );
          if (questionDetails) {
            store.parentQuestionId =
              questionDetails.parentQuestionId?.uuid ?? null;
            store.questionType = questionDetails.questionType;
          }

          if (store.isParentIdNull) {
            store.resetChildAndParentQuestionIds();
          }
          store.parseQuestionJSON(JsonParserEnum.DeserializeJSON, t);

          store.saveInitialQuestionDetails();
          const surveyStore = <Instance<typeof SurveyStore>>getParent(store);
          surveyStore.setSurveyName(
            response.surveyDetailsAndQuestions.surveyStatusAndName.surveyName
              .name,
          );
          surveyStore.setSurveyStatus(
            response.surveyDetailsAndQuestions.surveyStatusAndName.surveyStatus,
          );
          surveyStore.setSurveyQuestions(
            response.surveyDetailsAndQuestions.surveyQuestionDetails.sort(
              (firstQuestion, secondQuestion) =>
                firstQuestion.order.order - secondQuestion.order.order,
            ),
          );
        } else if (error) {
          store.rpcErrors = <GetQuestionDetailsErrors>error.code;
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
    getGeneratedPreviewLink: flow(function* (
      surveyId: string,
      projectId: string,
    ) {
      store.rpcErrors = null;

      if (store.fieldValidations === null) {
        try {
          const apiClient = getAPIClient(store);
          const request: GenerateSurveyPreviewLinkRPC.Request =
            new GenerateSurveyPreviewLinkRPC.Request(
              new LeoUUID(surveyId),
              new LeoUUID(projectId),
            );
          const {
            response,
            error,
          }: {
            response?: GenerateSurveyPreviewLinkRPC.Response;
            error?: GenerateSurveyPreviewLinkRPC.Errors.Errors;
          } =
            yield useGenerateSurveyPreviewLinkClientImpl(apiClient).execute(
              request,
            );

          if (response) {
            store.previewSurveyLink = response.previewURL;
          } else if (error) {
            store.rpcErrors = <EditSurveyQuestionErrors>error.code;
          }
        } catch (e) {
          if (e instanceof Error) {
            const rootStore = getRoot<typeof RootStore>(store);
            rootStore.networkingStore.errorStore.setLeoError(e);
          } else {
            console.error(`Unhandled error occured: ${e}`);
          }
        } finally {
          store.isRPCLoading = false;
        }
      }
    }),
    addNewQuestion: flow(function* addNewQuestion(
      surveyId: string,
      projectId: string,
      t: TFunction,
    ) {
      const surveyStore = <Instance<typeof SurveyStore>>getParent(store);
      store.rpcErrors = null;
      store.parseQuestionJSON(JsonParserEnum.ValidateFields, t);
      store.validateQuestionCode();
      if (store.fieldValidations === null) {
        store.isRPCLoading = true;
        try {
          const apiClient = getAPIClient(store);
          store.parseQuestionJSON(JsonParserEnum.SerializeJSON, t);
          const request: AddSurveyQuestionRPC.Request =
            new AddSurveyQuestionRPC.Request(
              new LeoUUID(surveyId),
              store.questionType,
              new QuestionDetails(
                new BasicQuestionDetails(
                  new Code(
                    store.updatedQuestionDetails.basicQuestionDetails.questionCode,
                  ),
                  store.updatedQuestionDetails.basicQuestionDetails.question
                    ? new SurveyLocalizedText(
                        store.updatedQuestionDetails.basicQuestionDetails.question,
                      )
                    : null,
                  store.updatedQuestionDetails.basicQuestionDetails
                    .questionDescription
                    ? new SurveyLocalizedText(
                        store.updatedQuestionDetails.basicQuestionDetails.questionDescription,
                      )
                    : null,
                  store.updatedQuestionDetails.basicQuestionDetails.isMandatory,
                  store.updatedQuestionDetails.basicQuestionDetails.isVisibleByDefault,
                ),
                store.updatedQuestionDetails.questionDetailsJSON,
                new JsonVersion(store.jsonVersion),
              ),
              new LeoUUID(projectId),
              store.parentQuestionId
                ? new LeoUUID(store.parentQuestionId)
                : null,
            );
          const {
            response,
            error,
          }: {
            response?: AddSurveyQuestionRPC.Response;
            error?: AddSurveyQuestionRPC.Errors.Errors;
          } = yield useAddSurveyQuestionClientImpl(apiClient).execute(request);
          if (response) {
            surveyStore.setSurveyName(
              response.surveyDetailsAndQuestions.surveyStatusAndName.surveyName
                .name,
            );
            surveyStore.setSurveyStatus(
              response.surveyDetailsAndQuestions.surveyStatusAndName
                .surveyStatus,
            );
            surveyStore.setSurveyQuestions(
              response.surveyDetailsAndQuestions.surveyQuestionDetails.sort(
                (firstQuestion, secondQuestion) =>
                  firstQuestion.order.order - secondQuestion.order.order,
              ),
            );
            if (store.parentQuestionId !== null) {
              store.childQuestionIds.push(response.questionId.uuid);
            }
            store.saveInitialQuestionDetails();
            return response.questionId.uuid;
          } else if (error) {
            if (
              error instanceof AddSurveyQuestionRPC.Errors.OptionCodeNotUnique
            ) {
              store.rpcErrors = EditSurveyQuestionErrors.OptionCodeNotUnique;
              store.duplicateOptionCode = error.optionCode.code;
            }
            store.rpcErrors = <EditSurveyQuestionErrors>error.code;
          }
        } catch (e) {
          if (e instanceof Error) {
            const rootStore = getRoot<typeof RootStore>(store);
            rootStore.networkingStore.errorStore.setLeoError(e);
          } else {
            console.error(`Unhandled error occured: ${e}`);
          }
        } finally {
          store.isRPCLoading = false;
        }
      }
    }),
    updateQuestion: flow(function* updateQuestion(
      questionId: string,
      t: TFunction,
    ) {
      const surveyStore = <Instance<typeof SurveyStore>>getParent(store);
      store.rpcErrors = null;
      store.parseQuestionJSON(JsonParserEnum.ValidateFields, t);
      store.validateQuestionCode();
      if (surveyStore.isSurveyActive) {
        store.validateInitialQuestionCode();
        store.parseQuestionJSON(JsonParserEnum.ValidateInitialFieldCode, t);
      }
      if (store.fieldValidations === null) {
        store.isRPCLoading = true;
        try {
          const apiClient = getAPIClient(store);
          store.parseQuestionJSON(JsonParserEnum.SerializeJSON, t);
          const request: UpdateSurveyQuestionRPC.Request =
            new UpdateSurveyQuestionRPC.Request(
              new LeoUUID(surveyStore.surveyId),
              new LeoUUID(questionId),
              new QuestionDetails(
                new BasicQuestionDetails(
                  new Code(
                    store.updatedQuestionDetails.basicQuestionDetails.questionCode,
                  ),
                  store.updatedQuestionDetails.basicQuestionDetails.question
                    ? new SurveyLocalizedText(
                        store.updatedQuestionDetails.basicQuestionDetails.question,
                      )
                    : null,
                  store.updatedQuestionDetails.basicQuestionDetails
                    .questionDescription
                    ? new SurveyLocalizedText(
                        store.updatedQuestionDetails.basicQuestionDetails.questionDescription,
                      )
                    : null,
                  store.updatedQuestionDetails.basicQuestionDetails.isMandatory,
                  store.updatedQuestionDetails.basicQuestionDetails.isVisibleByDefault,
                ),
                store.updatedQuestionDetails.questionDetailsJSON,
                new JsonVersion(store.jsonVersion),
              ),
              new LeoUUID(surveyStore.projectId),
            );
          const {
            response,
            error,
          }: {
            response?: UpdateSurveyQuestionRPC.Response;
            error?: UpdateSurveyQuestionRPC.Errors.Errors;
          } =
            yield useUpdateSurveyQuestionClientImpl(apiClient).execute(request);

          if (response) {
            const surveyStatusAndName =
              response.surveyDetailsAndQuestions.surveyStatusAndName;
            surveyStore.setSurveyName(surveyStatusAndName.surveyName.name);
            surveyStore.setSurveyStatus(surveyStatusAndName.surveyStatus);
            surveyStore.setSurveyQuestions(
              response.surveyDetailsAndQuestions.surveyQuestionDetails.sort(
                (firstQuestion, secondQuestion) =>
                  firstQuestion.order.order - secondQuestion.order.order,
              ),
            );
            store.saveInitialQuestionDetails();
          }

          if (error) {
            if (
              error instanceof
              UpdateSurveyQuestionRPC.Errors.OptionCodeNotUnique
            ) {
              store.rpcErrors = EditSurveyQuestionErrors.OptionCodeNotUnique;
              store.duplicateOptionCode = error.optionCode.code;
            }
            store.rpcErrors = <EditSurveyQuestionErrors>error.code;
          }
        } catch (e) {
          if (e instanceof Error) {
            const rootStore = getRoot<typeof RootStore>(store);
            rootStore.networkingStore.errorStore.setLeoError(e);
          } else {
            console.error(`Unhandled error occured: ${e}`);
          }
        } finally {
          store.isRPCLoading = false;
        }
      }
    }),
    deleteQuestion: flow(function* deleteQuestion(questionId: string) {
      store.isRPCLoading = true;
      store.setRPCErrors(null);
      store.setFieldValidations(null);
      store.setDuplicateOptionCode(null);
      try {
        const surveyStore = <Instance<typeof SurveyStore>>getParent(store);
        const requestQuestionId = new LeoUUID(questionId);
        const apiClient = getAPIClient(store);
        const request: DeleteQuestionRPC.Request =
          new DeleteQuestionRPC.Request(
            requestQuestionId,
            new LeoUUID(surveyStore.surveyId),
            new LeoUUID(surveyStore.projectId),
          );
        const {
          response,
          error,
        }: {
          response?: DeleteQuestionRPC.Response;
          error?: DeleteQuestionRPC.Errors.Errors;
        } = yield useDeleteQuestionClientImpl(apiClient).execute(request);
        if (response) {
          surveyStore.setSurveyName(
            response.surveyDetailsAndQuestions.surveyStatusAndName.surveyName
              .name,
          );
          surveyStore.setSurveyStatus(
            response.surveyDetailsAndQuestions.surveyStatusAndName.surveyStatus,
          );
          surveyStore.setSurveyQuestions(
            response.surveyDetailsAndQuestions.surveyQuestionDetails.sort(
              (firstQuestion, secondQuestion) =>
                firstQuestion.order.order - secondQuestion.order.order,
            ),
          );
        }
        if (error) {
          console.error(error.code);
          store.rpcErrors = <DeleteQuestionErrors>error.code;
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
    setSingleChoicePresetScale: (scale: number): void => {
      store.singleChoicePresetScale = scale;
    },
    resetChoicePresetFields: (): void => {
      store.singleChoicePresetScale = PresetScales.LowPresetScale;
      store.singleChoicePresetType = undefined;
    },
    setSingleChoicePresetType: (
      singleChoicePresetType: string | undefined,
    ): void => {
      store.singleChoicePresetType = <SingleChoicePresets>(
        singleChoicePresetType
      );
    },
  }))
  .views((store) => ({
    get isValidationErrorPresent(): boolean {
      return store.fieldValidations !== null;
    },
    get isSingleChoicePresetScaleLow(): boolean {
      return store.singleChoicePresetScale === PresetScales.LowPresetScale;
    },
    get isSingleChoicePresetScaleNps(): boolean {
      return store.singleChoicePresetScale === PresetScales.NpsPresetScale;
    },
    get isPresetUndefined(): boolean {
      return store.singleChoicePresetType === undefined;
    },
    get isMultiChoiceTypeQuestion(): boolean {
      return store.questionType === QuestionType.QuestionType.MULTIPLE_CHOICE;
    },
    get isOpenEndedTypeQuestion(): boolean {
      return store.questionType === QuestionType.QuestionType.OPEN_ENDED;
    },
    get isMaxSelectionExceeded(): boolean {
      return store.fieldValidations === FieldValidations.MaxSelectionExceeded;
    },
    get areQuestionAndOptionCodesNotUpdatable(): boolean {
      return (
        store.fieldValidations ===
        FieldValidations.QuestionAndOptionCodesCannotBeUpdated
      );
    },
    get isMaxSelectionZero(): boolean {
      return (
        store.fieldValidations ===
        FieldValidations.MaxSelectionCannotBeLessThanValidValue
      );
    },
    get isNPSOptionsCountInvalid(): boolean {
      return store.fieldValidations === FieldValidations.InvalidNPSOptionsCount;
    },
    get isMaxRatingScaleSliderOptionReached(): boolean {
      return (
        store.fieldValidations ===
        FieldValidations.MaxSliderRatingScaleCountReached
      );
    },
    get isInvalidGridNPSSelected(): boolean {
      return store.fieldValidations === FieldValidations.InvalidGridNPSSelected;
    },
    get isMaxGridSizeReached(): boolean {
      return store.fieldValidations === FieldValidations.MaxGridSizeReached;
    },
    get isMaxGridRadioButtonCountReached(): boolean {
      return (
        store.fieldValidations ===
        FieldValidations.MaxGridRadioButtonCountReached
      );
    },
    get isMaxGridMultiChoiceOptionsCountReached(): boolean {
      return (
        store.fieldValidations ===
        FieldValidations.MaxGridMultiChoiceOptionsCountReached
      );
    },
    get isMessageQuestion(): boolean {
      return store.questionType === QuestionType.QuestionType.MESSAGE;
    },
    get isGridQuestion(): boolean {
      return store.questionType === QuestionType.QuestionType.GRID;
    },
    get isGroupQuestion(): boolean {
      return store.questionType === QuestionType.QuestionType.GROUP;
    },
    get isSingleSelectQuestion(): boolean {
      return store.questionType === QuestionType.QuestionType.SINGLE_CHOICE;
    },
    get isQuestionCodeNotUnique(): boolean {
      return store.rpcErrors === EditSurveyQuestionErrors.QuestionCodeNotUnique;
    },
    get validateMaxSelection(): boolean {
      return (
        store.fieldValidations === FieldValidations.MaxSelectionExceeded ||
        store.fieldValidations ===
          FieldValidations.MaxSelectionCannotBeLessThanValidValue
      );
    },
    get isMaxSelectionFieldVisible(): boolean {
      return (
        store.questionType === QuestionType.QuestionType.MULTIPLE_CHOICE ||
        store.questionType === QuestionType.QuestionType.RANKING
      );
    },
    get isMandatoryQuestionCheckboxVisible(): boolean {
      return store.questionType !== QuestionType.QuestionType.MESSAGE;
    },
    get isAllOptionMandatoryCheckboxVisible(): boolean {
      return store.questionType === QuestionType.QuestionType.RANKING;
    },
    get isOptionCodeDuplicate(): boolean {
      return store.rpcErrors === EditSurveyQuestionErrors.OptionCodeNotUnique;
    },
    get isSaveButtonDisabled(): boolean {
      const survey = <Instance<typeof SurveyStore>>getParent(store);
      return (
        JSON.stringify(store.updatedQuestionDetails) ===
          JSON.stringify(store.initialQuestionDetails) || survey.isSurveyClosed
      );
    },
    get doesQuestionCodeFieldContainErrors(): boolean {
      return (
        store.rpcErrors === EditSurveyQuestionErrors.QuestionCodeNotUnique ||
        store.fieldValidations === FieldValidations.UnfilledQuestionCode
      );
    },
    get doesQuestionFieldContainErrors(): boolean {
      return store.fieldValidations === FieldValidations.UnfilledMessage;
    },
    isOptionCodeNotUnique(optionCode: string): boolean {
      if (store.duplicateOptionCode) {
        return optionCode.trim() === store.duplicateOptionCode;
      }
      return false;
    },
    get isRPCErrorDialogOpen(): boolean {
      return store.rpcErrors !== null;
    },
    get isSuccessfulDialogOpen(): boolean {
      return store.rpcErrors === null && store.fieldValidations === null;
    },
    get isChildQuestionListEmpty(): boolean {
      return store.childQuestionIds.length === EMPTY_LIST_LENGTH;
    },
    isNPSSelected(
      gridColumnQuestion: Instance<typeof GridColumnQuestionV1Model>,
    ): boolean {
      const isNPSInitiallySelected: boolean =
        store.initialQuestionDetails.gridQuestionV1Store.gridColumnQuestions.find(
          (columnQuestion) => {
            return columnQuestion.columnCode === gridColumnQuestion.columnCode;
          },
        )?.singleChoice?.gridSingleSelectQuestionV1Model?.isNPS ?? false;
      return (
        isNPSInitiallySelected ||
        gridColumnQuestion.singleChoice.gridSingleSelectQuestionV1Model.isNPS
      );
    },
  }))
  .views((store) => ({
    get isAddNewChildQuestionButtonDisabled(): boolean {
      return (
        !store.isSaveButtonDisabled ||
        store.childQuestionIds.length >= MAX_GROUP_CHILD_QUESTIONS
      );
    },
    getIsCloneQuestionButtonDisabled(
      selectedQuestionItemId: string | null,
    ): boolean {
      return (
        !store.isSaveButtonDisabled ||
        selectedQuestionItemId === null ||
        selectedQuestionItemId === EMPTY_CHARACTER
      );
    },
  }));

export const createQuestionDetailsStore = (): Instance<
  typeof QuestionDetailsStore
> => {
  return QuestionDetailsStore.create({
    questionType: QuestionType.QuestionType.OPEN_ENDED,
    updatedQuestionDetails: createQuestionDetailsModel(),
    initialQuestionDetails: createQuestionDetailsModel(),
    jsonVersion: 1,
    isRPCLoading: false,
    singleChoicePresetScale: 3,
  });
};
