import { Bar, BarCustomLayerProps, BarDatum } from "@nivo/bar";
import React, { ReactElement } from "react";
import { GraphData } from "../SelectedGraph";
import {
  DASHBOARD_CHART_PRIMARY_COLORS,
  PROPORTION_GRAPH_LABEL_SKIP_WIDTH_IN_PIXELS,
  QUESTION_VIEW_MAX_WIDTH_IN_PIXELS,
} from "../../../utils/constants";
import { DataProps } from "../DataSection";
import {
  BorderStyle,
  EMPTY_CHARACTER,
  getDarkGradientColorForSliderByIndexPosition,
  RGG_PALETTE_COLORS,
} from "@pulse/shared-components";
import { Stack } from "@mui/material";
import { Legends } from "./Legends";
import {
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TooltipContent } from "./TooltipContent";
import { CustomWidthTooltip } from "../../../surveys/components/CustomWidthToolTip";

interface ProportionGraphProps {
  data: DataProps[];
  isNeutral: boolean | undefined;
  spacing: Spacing;
  cornerRadius: CornerRadius;
  tokens: FoundationColorTokens<string>;
  border: BorderStyle;
  typography: Typography;
  isLegendsVisible: boolean;
  graphWidth: number | undefined;
  customColours?: string[];
}
export const ProportionGraph = ({
  data,
  isNeutral,
  cornerRadius,
  spacing,
  tokens,
  border,
  typography,
  isLegendsVisible,
  graphWidth,
  customColours,
}: ProportionGraphProps): ReactElement => {
  const graphData: GraphData[] = data.map((item, index) => {
    return {
      id: item.option ?? EMPTY_CHARACTER,
      label: (item.option ?? EMPTY_CHARACTER) + index,
      value: item.count,
      percentage: item.percentage,
    };
  });
  const transformedDataForProportion: BarDatum[] = [
    {
      ...Object.fromEntries(
        graphData
          .filter((item) => item.value > 0) // Filter out items with value 0
          .map((item) => [item.label, item.value]),
      ),
    },
  ];

  const colors =
    customColours ??
    (isNeutral
      ? data.length < 12
        ? DASHBOARD_CHART_PRIMARY_COLORS.slice(
            DASHBOARD_CHART_PRIMARY_COLORS.length / 2 - data.length / 2,
            DASHBOARD_CHART_PRIMARY_COLORS.length / 2 + data.length / 2,
          )
        : DASHBOARD_CHART_PRIMARY_COLORS
      : graphData.map((_item, index) => {
          return getDarkGradientColorForSliderByIndexPosition(
            index,
            graphData.length,
            false,
          );
        }));

  const CustomLabelLayer = (
    props: BarCustomLayerProps<BarDatum>,
  ): ReactElement => {
    const textColor = (color: string): string => {
      if (isNeutral) {
        switch (color) {
          case DASHBOARD_CHART_PRIMARY_COLORS[0]:
          case DASHBOARD_CHART_PRIMARY_COLORS[1]:
          case DASHBOARD_CHART_PRIMARY_COLORS[2]:
          case DASHBOARD_CHART_PRIMARY_COLORS[3]:
          case DASHBOARD_CHART_PRIMARY_COLORS[4]:
          case DASHBOARD_CHART_PRIMARY_COLORS[5]: {
            return tokens.labelHighEmphasis;
          }
          default: {
            return tokens.labelOnSurface;
          }
        }
      } else {
        switch (color) {
          case RGG_PALETTE_COLORS.LEFT.darkMode.selected:
          case RGG_PALETTE_COLORS.LEFT.darkMode.unselected:
          case RGG_PALETTE_COLORS.LEFT.lightMode.selected:
          case RGG_PALETTE_COLORS.LEFT.lightMode.unselected:
          case RGG_PALETTE_COLORS.MIDDLE.darkMode.selected:
          case RGG_PALETTE_COLORS.MIDDLE.darkMode.unselected:
          case RGG_PALETTE_COLORS.MIDDLE.lightMode.selected:
          case RGG_PALETTE_COLORS.MIDDLE.lightMode.unselected: {
            return tokens.labelHighEmphasis;
          }
          default: {
            return tokens.labelOnSurface;
          }
        }
      }
    };

    return (
      <g transform="translate(0, 0)">
        {graphData.map((item, index) =>
          props.bars[index].width &&
          props.bars[index].width >=
            PROPORTION_GRAPH_LABEL_SKIP_WIDTH_IN_PIXELS ? (
            <foreignObject
              key={index}
              width={props.bars[index].width}
              height={props.bars[index].height}
              x={props.bars[index].x}
              y={props.bars[index].y}
            >
              <CustomWidthTooltip
                title={
                  <TooltipContent
                    spacing={spacing}
                    cornerRadius={cornerRadius}
                    tokens={tokens}
                    count={`${item.value}`}
                    label={`${item.label.slice(0, -String(index).length)}`}
                    percentage={`${item.percentage}`}
                    border={border}
                    typography={typography}
                    isSurveyViewOptionDisplayTextSelected={true}
                  />
                }
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: tokens.backgroundElevatedLevel1,
                      padding: "0px !important",
                    },
                  },
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  {PROPORTION_GRAPH_LABEL_SKIP_WIDTH_IN_PIXELS && (
                    <>
                      {" "}
                      <text
                        style={{
                          color: textColor(props.bars[index].color),
                        }}
                      >
                        {props.bars[index].data.value}
                      </text>
                      <text
                        style={{
                          color: textColor(props.bars[index].color),
                        }}
                      >
                        {`${item.percentage}%`}
                      </text>
                    </>
                  )}
                </Stack>
              </CustomWidthTooltip>
            </foreignObject>
          ) : (
            <foreignObject
              key={index}
              width={props.bars[index].width}
              height={props.bars[index].height}
              x={props.bars[index].x}
              y={props.bars[index].y}
            >
              <CustomWidthTooltip
                title={
                  <TooltipContent
                    spacing={spacing}
                    cornerRadius={cornerRadius}
                    tokens={tokens}
                    count={`${item.value}`}
                    label={`${item.label.slice(0, -String(index).length)}`}
                    percentage={`${item.percentage}`}
                    border={border}
                    typography={typography}
                    isSurveyViewOptionDisplayTextSelected={true}
                  />
                }
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: tokens.backgroundElevatedLevel1,
                      padding: "0px !important",
                    },
                  },
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                ></Stack>
              </CustomWidthTooltip>
            </foreignObject>
          ),
        )}
      </g>
    );
  };
  return (
    <Stack width={`${QUESTION_VIEW_MAX_WIDTH_IN_PIXELS}px`}>
      <Bar
        data={transformedDataForProportion}
        keys={graphData.map((item) => item.label)}
        height={200}
        width={graphWidth ?? QUESTION_VIEW_MAX_WIDTH_IN_PIXELS}
        margin={{ top: 20, right: 0, bottom: 50, left: 0 }}
        layout="horizontal"
        animate={true}
        groupMode="stacked"
        colors={colors}
        indexBy="count"
        axisBottom={null}
        axisLeft={null}
        enableLabel={false}
        labelFormat={(value): string => `${value}%`}
        layers={[
          "grid",
          "axes",
          "bars",
          "totals",
          "markers",
          "annotations",
          CustomLabelLayer,
        ]}
        tooltip={() => {
          return <></>;
        }}
      />
      {isLegendsVisible && (
        <Legends
          legends={graphData.map((item, index) =>
            item.label.slice(0, -String(index).length),
          )}
          spacing={spacing}
          colors={colors}
          cornerRadius={cornerRadius}
          tokens={tokens}
          typography={typography}
          isSurveyViewOptionDisplayTextSelected={true}
        />
      )}
    </Stack>
  );
};
