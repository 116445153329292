import {
  FoundationColorTokens,
  IconButton,
  RadioButtonColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Edit } from "lucide-react";
import React, { ReactElement, useState } from "react";
import { EditSurveyViewDialog } from "./EditSurveyViewDialog";
import { SurveyDashboardViewModel } from "../models/SurveyDashboardViewModel";
import { Instance } from "mobx-state-tree";
import { TFunction } from "i18next";
import {
  BorderStyle,
  getRadioButtonStyleProps,
} from "@pulse/shared-components";
import { NavigateToFunctions } from "../../../routes/RoutesHelper";
import { observer } from "mobx-react";
import { ReportsStore } from "../stores/ReportsStore";

interface EditSurveyViewProps {
  surveyDashboardView: Instance<typeof SurveyDashboardViewModel>;
  t: TFunction;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  border: BorderStyle;
  radioButtonTokens: RadioButtonColorTokens<string>;
  navigateTo: NavigateToFunctions;
  reportStore: Instance<typeof ReportsStore>;
}

export const EditSurveyView = observer(
  ({
    surveyDashboardView,
    t,
    border,
    spacing,
    tokens,
    typography,
    radioButtonTokens,
    navigateTo,
    reportStore,
  }: EditSurveyViewProps): ReactElement => {
    const [isEditSurveyViewDialogOpen, setIsEditSurveyViewDialogOpen] =
      useState(false);

    const radioProps = getRadioButtonStyleProps(
      tokens,
      radioButtonTokens,
      spacing,
      `0px ${spacing.spaceXS}`,
    );

    return (
      <>
        {isEditSurveyViewDialogOpen && (
          <EditSurveyViewDialog
            isEditSurveyViewDialogOpen={isEditSurveyViewDialogOpen}
            setIsEditSurveyViewDialogOpen={setIsEditSurveyViewDialogOpen}
            surveyDashboardView={surveyDashboardView}
            t={t}
            spacing={spacing}
            tokens={tokens}
            typography={typography}
            radioProps={radioProps}
            border={border}
            navigateTo={navigateTo}
            reportStore={reportStore}
          />
        )}
        <IconButton
          name="edit"
          size="small"
          icon={<Edit />}
          variant="plain-neutral"
          onClick={(): void => {
            setIsEditSurveyViewDialogOpen(true);
          }}
        />
      </>
    );
  },
);
