import {
  Instance,
  cast,
  flow,
  getParentOfType,
  getRoot,
  types,
} from "mobx-state-tree";
import { HeaderTabElements } from "../pages/SurveyDetailsPage";
import {
  QuestionDetailsStore,
  createQuestionDetailsStore,
} from "./QuestionDetailsStore";
import {
  SurveyRulesUploadStore,
  createSurveyRulesUploadStore,
} from "./SurveyRulesUploadStore";
import {
  GetQuestionsOfSurveyRPC,
  QuestionType,
  SurveyQuestionDetails,
  SurveyStatus,
} from "@pulse/pulse-rpcs";
import { SurveyQuestionModel } from "../models/SurveyQuestionModel";
import {
  BASE_QUESTION_ORDER,
  CommonErrors,
  EMPTY_CHARACTER,
  EMPTY_LIST_LENGTH,
  NetworkingError,
} from "@pulse/shared-components";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "../../networking/APIClient";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import { useGetQuestionsOfSurveyRPCClientImpl } from "../rpcs/RPC";
import { ProjectDetailsStore } from "../../projects/store/ProjectDetailsStore";
import {
  ManageQuestionsStore,
  createManageQuestionsStore,
} from "./ManageQuestionsStore";
import { SurveyDetailsModel } from "../models/SurveyDetailsModel";
import {
  createQuestionDetailsModel,
  QuestionDetailsModel,
} from "../models/QuestionDetailsModel";
import { RootStore } from "../../root/store/RootStore";
import {
  BackOfficeSurveySettingsStore,
  createBackOfficeSurveySettingsStore,
} from "./BackOfficeSurveySettingsStore";
import {
  TranslationsStore,
  createTranslationsStore,
} from "./TranslationsStore";
import {
  createSingleSelectDropdownOptionsUploadStore,
  SingleSelectDropdownOptionsUploadStore,
} from "./SingleSelectDropdownOptionsUploadStore";

export enum GetQuestionsOfSurveyError {
  MaxQuestionsReached = "MAX_QUESTIONS_REACHED",
}

export const SurveyStore = types
  .model("SurveyStore", {
    surveyDetailsList: types.array(SurveyDetailsModel),
    questionDetailsStore: QuestionDetailsStore,
    manageQuestionStore: ManageQuestionsStore,
    tabIndex: types.number,
    surveyRulesUploadStore: SurveyRulesUploadStore,
    surveyName: types.optional(types.string, EMPTY_CHARACTER),
    clonedSurveyName: types.optional(types.string, EMPTY_CHARACTER),
    surveyId: types.maybe(types.string),
    projectId: types.maybe(types.string),
    surveyStatus: types.maybe(
      types.enumeration<SurveyStatus.SurveyStatus>(
        "SurveyStatus",
        Object.values(SurveyStatus.SurveyStatus),
      ),
    ),
    questionList: types.array(SurveyQuestionModel),
    rpcError: types.maybeNull(
      types.union(
        types.enumeration(
          "GetQuestionsOfSurveyError",
          Object.values(GetQuestionsOfSurveyError),
        ),
        types.enumeration("NetworkingError", Object.values(NetworkingError)),
      ),
    ),
    isRPCLoading: types.optional(types.boolean, false),
    doesSurveyContainRules: types.optional(types.boolean, false),
    backOfficeSurveySettingsStore: BackOfficeSurveySettingsStore,
    translationsStore: TranslationsStore,
    isGenerateGenericLinkClicked: types.optional(types.boolean, false),
    singleSelectDropdownOptionsUploadStore:
      SingleSelectDropdownOptionsUploadStore,
  })
  .views((store) => ({
    get doesStoreContainError(): boolean {
      return store.rpcError !== null;
    },
    get isNoSurveyAdded(): boolean {
      return store.surveyDetailsList.length === EMPTY_LIST_LENGTH;
    },
    getQuestionById(
      questionId: string | null,
    ): Instance<typeof SurveyQuestionModel> | undefined {
      return store.questionList.find(
        (question) => question.surveyQuestionDetails.questionId === questionId,
      );
    },
    get numberOfQuestions(): number {
      return store.questionList.length;
    },
    get isManageQuestionsDisabled(): boolean {
      return (
        store.questionList.length === EMPTY_LIST_LENGTH ||
        store.questionList[0].surveyQuestionDetails.questionId ===
          EMPTY_CHARACTER
      );
    },
    get newQuestionOrder(): number {
      const lastQuestion = store.questionList
        .filter(
          (question) =>
            question.surveyQuestionDetails.questionId !== EMPTY_CHARACTER &&
            question.surveyQuestionDetails.parentQuestionId === null,
        )
        .at(-1);
      if (lastQuestion) {
        return lastQuestion.surveyQuestionDetails.order + 1;
      }
      return 1;
    },
    getQuestionTypeOrUndefinedById(
      questionId: string,
    ): QuestionType.QuestionType | undefined {
      return store.questionList.find(
        (question) => question.surveyQuestionDetails.questionId === questionId,
      )?.surveyQuestionDetails.questionType;
    },
    findNewlyAddedGroupChildQuestion: (
      parentId: string,
    ): Instance<typeof SurveyQuestionModel> | undefined => {
      return store.questionList.find((question) => {
        return (
          question.surveyQuestionDetails.questionId === EMPTY_CHARACTER &&
          question.surveyQuestionDetails.parentQuestionId === parentId
        );
      });
    },
    get newlyAddedQuestion(): Instance<typeof SurveyQuestionModel> | undefined {
      return store.questionList.find((question) => {
        return question.surveyQuestionDetails.questionId === EMPTY_CHARACTER;
      });
    },
    get isNewlyAddedQuestionPresent(): boolean {
      return store.questionList.some((question) => {
        return question.surveyQuestionDetails.questionId === EMPTY_CHARACTER;
      });
    },
    findQuestionById: (
      questionId: string,
    ): Instance<typeof SurveyQuestionModel> | undefined => {
      const questionDetails = store.questionList.find((question) => {
        return question.surveyQuestionDetails.questionId === questionId;
      });
      if (questionDetails === undefined) {
        console.error("Invalid Question ID");
      }
      return questionDetails;
    },
    get isQuestionListEmpty(): boolean {
      return store.questionList.length === 0;
    },
    get isSurveyClosed(): boolean {
      return store.surveyStatus === SurveyStatus.SurveyStatus.CLOSED;
    },
    get isSurveyActive(): boolean {
      return store.surveyStatus === SurveyStatus.SurveyStatus.ACTIVE;
    },
    get isSurveyDraft(): boolean {
      return store.surveyStatus === SurveyStatus.SurveyStatus.DRAFT;
    },
    get isRespondentTabIndexSelected(): boolean {
      return store.tabIndex === HeaderTabElements.RESPONDENTS;
    },
    get isQuestionnaireTabIndexSelected(): boolean {
      return store.tabIndex === HeaderTabElements.QUESTIONNAIRE;
    },
    get isSurveySettingsTabIndexSelected(): boolean {
      return store.tabIndex === HeaderTabElements.SETTINGS;
    },
    get isTranslationsTabIndexSelected(): boolean {
      return store.tabIndex === HeaderTabElements.TRANSLATIONS;
    },
  }))
  .views((store) => ({
    isChildQuestionSelected: (
      parentId: string,
      selectedQuestionItemId: string | null,
    ): boolean => {
      return (
        store.questionList.find((question) => {
          return (
            question.surveyQuestionDetails.parentQuestionId === parentId &&
            selectedQuestionItemId === question.surveyQuestionDetails.questionId
          );
        }) !== undefined ||
        store.findNewlyAddedGroupChildQuestion(parentId) !== undefined
      );
    },
    isChildQuestion: (questionId: string): boolean => {
      return (
        store.questionList.find(
          (question) =>
            question.surveyQuestionDetails.questionId === questionId &&
            question.surveyQuestionDetails.parentQuestionId !== null,
        ) !== undefined
      );
    },
  }))
  .actions((store) => ({
    resetSurveyName: (): void => {
      store.surveyName = EMPTY_CHARACTER;
    },
    setTabIndex: (tabIndex: number): void => {
      store.tabIndex = tabIndex;
    },
    clearQuestionStoreFlags: (): void => {
      store.isRPCLoading = false;
      store.doesSurveyContainRules = false;
    },
    clearQuestionDetailStore: (): void => {
      store.questionDetailsStore = createQuestionDetailsStore();
    },
    updateQuestionsListOrder: (): void => {
      let newQuestionOrder = BASE_QUESTION_ORDER;
      store.questionList.map((question) => {
        if (
          question.surveyQuestionDetails.parentQuestionId === null ||
          question.surveyQuestionDetails.parentQuestionId === EMPTY_CHARACTER
        ) {
          question.surveyQuestionDetails.order = newQuestionOrder++;
          if (
            question.surveyQuestionDetails.questionType ===
            QuestionType.QuestionType.GROUP
          ) {
            let newChildQuestionOrder = BASE_QUESTION_ORDER;
            question.surveyQuestionDetails.childQuestionIds.map(
              (questionId) => {
                const childQuestionOfOldParent =
                  store.findQuestionById(questionId);
                if (childQuestionOfOldParent) {
                  childQuestionOfOldParent.surveyQuestionDetails.order =
                    newChildQuestionOrder++;
                }
              },
            );
          }
        }
      });
    },
  }))
  .actions((store) => ({
    resetTabIndex: (): void => {
      store.tabIndex = HeaderTabElements.QUESTIONNAIRE;
    },
    setSurveyName: (surveyName: string): void => {
      const projectStore = getParentOfType(store, ProjectDetailsStore);
      projectStore.clearRPCError();
      store.surveyName = surveyName;
    },
    setClonedSurveyName: (clonedSurveyName: string): void => {
      const projectStore = getParentOfType(store, ProjectDetailsStore);
      projectStore.clearRPCError();
      store.clonedSurveyName = clonedSurveyName;
    },
    setSurveyStatus: (surveyStatus: SurveyStatus.SurveyStatus): void => {
      store.surveyStatus = surveyStatus;
    },
    setSurveyQuestions: (
      surveyQuestionDetails: SurveyQuestionDetails[],
    ): void => {
      store.questionList = cast(
        surveyQuestionDetails.map((surveyQuestion) => {
          const {
            questionId,
            question,
            questionCode,
            questionType,
            order,
            isRuleApplied,
            isQuestionVisible,
            parentQuestionId,
            childQuestionIds,
            isMandatory,
          } = surveyQuestion;
          return SurveyQuestionModel.create({
            surveyQuestionDetails: {
              questionType,
              questionId: questionId.uuid,
              questionCode: questionCode.code,
              question: question?.text ?? null,
              order: order.order,
              parentQuestionId: parentQuestionId?.uuid,
              childQuestionIds: childQuestionIds.map(
                (leoQuestionId) => leoQuestionId.uuid,
              ),
            },
            isRuleApplied,
            isVisibleByDefault: isQuestionVisible,
            isMandatory,
          });
        }),
      );
      store.updateQuestionsListOrder();
    },
    clearRpcErrors: (): void => {
      store.rpcError = null;
    },
    removeUnsavedQuestion: (): void => {
      const unsavedQuestion = store.questionList.find(
        (question) =>
          question.surveyQuestionDetails.questionId === EMPTY_CHARACTER,
      );
      if (unsavedQuestion !== undefined) {
        store.questionList.remove(unsavedQuestion);
        store.questionDetailsStore.updatedQuestionDetails = <
          Instance<typeof QuestionDetailsModel>
        >JSON.parse(
          JSON.stringify(store.questionDetailsStore.initialQuestionDetails),
        );
      }
    },
    cloneQuestion: (question: Instance<typeof SurveyQuestionModel>): void => {
      question.surveyQuestionDetails.removeQuestionId();
      question.surveyQuestionDetails.setOrder(store.newQuestionOrder);
      store.questionList.push(question);
      store.questionDetailsStore.initialQuestionDetails =
        createQuestionDetailsModel();
    },
    addNewQuestion: (
      questionCode: string,
      questionId: string,
      questionType: QuestionType.QuestionType,
      order: number,
      isRuleApplied: boolean,
      isVisibleByDefault: boolean,
      parentQuestionId: string | null,
      isMandatory: boolean,
    ): void => {
      const unsavedQuestion = store.questionList.find(
        (question) =>
          question.surveyQuestionDetails.questionId === EMPTY_CHARACTER,
      );
      if (unsavedQuestion !== undefined) {
        store.questionList.remove(unsavedQuestion);
      }
      store.questionList.push({
        isRuleApplied,
        isVisibleByDefault,
        isMandatory,
        surveyQuestionDetails: {
          questionCode,
          questionId,
          questionType,
          order,
          parentQuestionId,
        },
      });
    },
    getQuestionsOfSurvey: flow(function* (surveyId: string, projectId: string) {
      store.isRPCLoading = true;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new GetQuestionsOfSurveyRPC.Request(
          new LeoUUID(surveyId),
          new LeoUUID(projectId),
        );
        const {
          response,
          error,
        }: {
          response?: GetQuestionsOfSurveyRPC.Response;
          error?: GetQuestionsOfSurveyRPC.Errors.Errors;
        } =
          yield useGetQuestionsOfSurveyRPCClientImpl(apiClient).execute(
            request,
          );
        if (response) {
          const projectStore = getParentOfType(store, ProjectDetailsStore);
          projectStore.setProjectName(response.projectName.name);
          store.projectId = projectId;
          store.surveyId = surveyId;
          store.doesSurveyContainRules = response.doesSurveyContainRules;
          store.surveyName =
            response.surveyDetailsAndQuestions.surveyStatusAndName.surveyName.name;
          store.surveyStatus =
            response.surveyDetailsAndQuestions.surveyStatusAndName.surveyStatus;
          store.questionList = cast(
            response.surveyDetailsAndQuestions.surveyQuestionDetails
              .map((surveyQuestion) => {
                const {
                  questionId,
                  question,
                  questionCode,
                  questionType,
                  order,
                  parentQuestionId,
                  childQuestionIds,
                } = surveyQuestion;
                return SurveyQuestionModel.create({
                  surveyQuestionDetails: {
                    questionType,
                    questionId: questionId.uuid,
                    questionCode: questionCode.code,
                    question: question?.text ?? null,
                    order: order.order,
                    parentQuestionId: parentQuestionId?.uuid,
                    childQuestionIds: childQuestionIds.map(
                      (childQuestionId) => childQuestionId.uuid,
                    ),
                  },
                  isRuleApplied: surveyQuestion.isRuleApplied,
                  isVisibleByDefault: surveyQuestion.isQuestionVisible,
                  isMandatory: surveyQuestion.isMandatory,
                });
              })
              .sort(
                (firstQuestion, secondQuestion) =>
                  firstQuestion.surveyQuestionDetails.order -
                  secondQuestion.surveyQuestionDetails.order,
              ),
          );
          store.updateQuestionsListOrder();
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              break;
            case GetQuestionsOfSurveyError.MaxQuestionsReached:
              store.rpcError = GetQuestionsOfSurveyError.MaxQuestionsReached;
              break;
            default:
              store.rpcError = null;
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
    resetIsGenerateGenericLinkClicked: (): void => {
      store.isGenerateGenericLinkClicked = false;
    },
  }))
  .actions((store) => ({
    callRpcUsingTabIndex: async (
      surveyId: string,
      projectId: string,
    ): Promise<void> => {
      switch (store.tabIndex) {
        case HeaderTabElements.QUESTIONNAIRE:
          await store.getQuestionsOfSurvey(surveyId, projectId);
          return;
        case HeaderTabElements.SETTINGS:
          await store.backOfficeSurveySettingsStore.getSurveySettings(
            surveyId,
            projectId,
          );
          return;
        case HeaderTabElements.TRANSLATIONS: {
          await store.translationsStore.getAllTranslations(surveyId, projectId);
          return;
        }
      }
    },
  }));

export const createSurveyStore = (): Instance<typeof SurveyStore> => {
  return SurveyStore.create({
    questionDetailsStore: createQuestionDetailsStore(),
    tabIndex: HeaderTabElements.QUESTIONNAIRE,
    surveyRulesUploadStore: createSurveyRulesUploadStore(),
    manageQuestionStore: createManageQuestionsStore(),
    backOfficeSurveySettingsStore: createBackOfficeSurveySettingsStore(),
    translationsStore: createTranslationsStore(),
    singleSelectDropdownOptionsUploadStore:
      createSingleSelectDropdownOptionsUploadStore(),
  });
};
