import {
  GetIndividualSubmittedResponsesRPC,
  GetPresignedDownloadURLForIndividualSubmittedResponsesRPC,
} from "@pulse/pulse-rpcs";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import { cast, flow, getRoot, Instance, types } from "mobx-state-tree";
import {
  useGetIndividualSubmittedResponsesRPCClientImpl,
  useGetPresignedDownloadURLForIndividualSubmittedResponsesRPCClientImpl,
} from "../rpcs/RPC";
import { getAPIClient } from "../../networking/APIClient";
import { RootStore } from "../../root/store/RootStore";
import {
  createSubmittedResponseModel,
  SubmittedResponseModel,
} from "../models/RespondentSubmittedResponseModels/SubmittedResponseModel";
import {
  RESPONDENT_RESPONSE_FILE_NAME_SUFFIX,
  UnexpectedError,
} from "../../utils/constants";
import {
  CommonErrors,
  TEMPLATE_FILE_DELIMITER,
} from "@pulse/shared-components";
import { downloadFile } from "../../utils/FileDownloadUtils";
import { convertTimestampFormat } from "../../utils/DateUtils";

export enum RespondentResponseDialogState {
  Loading = "LOADING",
  RespondentResponse = "RESPONDENT_RESPONSE",
  ERROR = "ERROR",
}

export const RespondentResponseStore = types
  .model("RespondentResponseStore", {
    respondentId: types.maybeNull(types.number),
    endTime: types.maybeNull(types.string),
    browser: types.maybeNull(types.string),
    deviceType: types.maybeNull(types.string),
    submittedResponses: types.array(SubmittedResponseModel),
    respondentResponsesDialogState: types.optional(
      types.enumeration(Object.values(RespondentResponseDialogState)),
      RespondentResponseDialogState.Loading,
    ),
    rpcError: types.maybeNull(
      types.union(
        types.enumeration(
          Object.values(GetIndividualSubmittedResponsesRPC.RPCError),
        ),
        types.enumeration(Object.values(UnexpectedError)),
      ),
    ),
  })
  .views((store) => ({
    get isPrimaryButtonDisabled(): boolean {
      return (
        store.rpcError !== null ||
        store.respondentResponsesDialogState !==
          RespondentResponseDialogState.RespondentResponse
      );
    },
  }))
  .actions((store) => ({
    resetStore: (): void => {
      store.browser = null;
      store.deviceType = null;
      store.endTime = null;
      store.submittedResponses.clear();
      store.respondentResponsesDialogState =
        RespondentResponseDialogState.Loading;
      store.rpcError = null;
    },
    setRespondentId: (respondentId: number): void => {
      store.respondentId = respondentId;
    },
    getIndividualSubmittedResponse: flow(function* (projectId: string) {
      try {
        const apiClient = getAPIClient(store);
        if (store.respondentId === null) {
          console.error("Respondent id cannot be undefined");
          return;
        }
        const request = new GetIndividualSubmittedResponsesRPC.Request(
          new LeoUUID(projectId),
          store.respondentId,
        );
        const {
          response,
          error,
        }: {
          response?: GetIndividualSubmittedResponsesRPC.Response;
          error?: GetIndividualSubmittedResponsesRPC.Errors.Errors;
        } =
          yield useGetIndividualSubmittedResponsesRPCClientImpl(
            apiClient,
          ).execute(request);
        if (response) {
          store.browser = response.browser;
          store.deviceType = response.deviceType;
          store.endTime = convertTimestampFormat(
            response.surveyEndTime.timestamp,
          );
          store.submittedResponses = cast(
            response.submittedResponses.map((submittedResponse) => {
              return createSubmittedResponseModel(submittedResponse);
            }),
          );
          store.respondentResponsesDialogState =
            RespondentResponseDialogState.RespondentResponse;
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
              break;
            case GetIndividualSubmittedResponsesRPC.RPCError
              .SurveyNotSubmitted: {
              store.rpcError =
                GetIndividualSubmittedResponsesRPC.RPCError.SurveyNotSubmitted;
              break;
            }
            case GetIndividualSubmittedResponsesRPC.RPCError
              .InvalidRespondentId: {
              store.rpcError =
                GetIndividualSubmittedResponsesRPC.RPCError.InvalidRespondentId;
              break;
            }
            default:
              console.error(
                `Encountered unhandled error ${error} from GetIndividualSubmittedResponsesRPC.`,
              );
              store.rpcError = UnexpectedError.UnhandledError;
              break;
          }
          store.respondentResponsesDialogState =
            RespondentResponseDialogState.ERROR;
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(
            `Unhandled error ${e} in assignSurvey action in respondentStore.`,
          );
        }
      }
    }),
    getPresignedDownloadURLForIndividualSubmittedResponsesRPC: flow(function* (
      projectId: string,
    ) {
      try {
        const apiClient = getAPIClient(store);
        if (store.respondentId === null) {
          console.error("Respondent id cannot be undefined");
          return;
        }
        const request =
          new GetPresignedDownloadURLForIndividualSubmittedResponsesRPC.Request(
            new LeoUUID(projectId),
            store.respondentId,
          );
        const {
          response,
          error,
        }: {
          response?: GetPresignedDownloadURLForIndividualSubmittedResponsesRPC.Response;
          error?: GetPresignedDownloadURLForIndividualSubmittedResponsesRPC.Errors.Errors;
        } =
          yield useGetPresignedDownloadURLForIndividualSubmittedResponsesRPCClientImpl(
            apiClient,
          ).execute(request);
        if (response) {
          const fileName =
            store.respondentId +
            TEMPLATE_FILE_DELIMITER +
            RESPONDENT_RESPONSE_FILE_NAME_SUFFIX;
          yield downloadFile(response.documentURL.href, fileName);
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
              break;
            case GetPresignedDownloadURLForIndividualSubmittedResponsesRPC
              .RPCError.SurveyNotSubmitted: {
              store.rpcError =
                GetPresignedDownloadURLForIndividualSubmittedResponsesRPC.RPCError.SurveyNotSubmitted;
              break;
            }
            case GetPresignedDownloadURLForIndividualSubmittedResponsesRPC
              .RPCError.InvalidRespondentId: {
              store.rpcError =
                GetPresignedDownloadURLForIndividualSubmittedResponsesRPC.RPCError.InvalidRespondentId;
              break;
            }
            default:
              console.error(
                `Encountered unhandled error ${error} from GetPresignedDownloadURLForIndividualSubmittedResponsesRPC.`,
              );
              store.rpcError = UnexpectedError.UnhandledError;
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(
            `Unhandled error ${e} in assignSurvey action in respondentStore.`,
          );
        }
      }
    }),
  }));

export const createRespondentResponseStore = (): Instance<
  typeof RespondentResponseStore
> => {
  return RespondentResponseStore.create();
};
