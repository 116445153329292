import {
  Code,
  GridElementGraphTypeV1,
  GridElementGraphTypeV1Enums,
  GridMultiSelectGraphTypeV1,
  GridResultV1,
  GridResultV1Enums,
  GridSingleSelectGraphTypeV1,
  GroupChildGraphTypeV1,
  OpenEndedGraphTypeV1,
  QuestionViewGraphTypeV1,
  QuestionViewTypeV1,
} from "@pulse/pulse-rpcs";
import { getType, Instance, types } from "mobx-state-tree";
import {
  createSampleBreakdownModel,
  SampleBreakdownModel,
} from "./SampleBreakdownModel";
import {
  createGridSingleSelectModel,
  GridSingleSelectModel,
} from "./GridSingleSelectModel";
import {
  createGridSingleSelectWithMeanModel,
  GridSingleSelectWithMeanModel,
} from "./GridSingleSelectWithMeanModel";
import {
  createGridOpenEndedModel,
  GridOpenEndedModel,
} from "./GridOpenEndedModel";
import {
  createGridMultiSelectModel,
  GridMultiSelectModel,
} from "./GridMultiSelectModel";
import {
  createOpenEndedResponsesModel,
  OpenEndedResponsesModel,
} from "./OpenEndedResponsesModel";

export enum GridQuestionViewTypeV1Enum {
  GRID_SINGLE_SELECT_MODEL = "GridSingleSelectModel",
  GRID_SINGLE_SELECT_PROPORTION_MODEL = "GridSingleSelectWithMeanModel",
  GRID_OPEN_ENDED_MODEL = "GridOpenEndedModel",
  GRID_MULTI_SELECT_MODEL = "GridMultiSelectModel",
}

const GridResultV1Model = types.model("GridResultV1Model", {
  columnCode: types.string,
  columnHeader: types.maybeNull(types.string),
  columnQuestionType: types.union(
    GridSingleSelectModel,
    GridSingleSelectWithMeanModel,
    GridOpenEndedModel,
    GridMultiSelectModel,
  ),
});

const createGridResultV1Model = (
  gridResult: GridResultV1,
): Instance<typeof GridResultV1Model> => {
  const getColumnQuestionType = (): Instance<
    | typeof GridSingleSelectModel
    | typeof GridSingleSelectWithMeanModel
    | typeof GridOpenEndedModel
    | typeof GridMultiSelectModel
  > => {
    if (
      gridResult.columnQuestionType instanceof
      GridResultV1Enums.ColumnQuestionType.SingleSelect
    ) {
      return createGridSingleSelectModel(
        gridResult.columnQuestionType,
        gridResult.columnHeader?.text,
      );
    } else if (
      gridResult.columnQuestionType instanceof
      GridResultV1Enums.ColumnQuestionType.SingleSelectWithProportion
    ) {
      return createGridSingleSelectWithMeanModel(
        gridResult.columnQuestionType,
        gridResult.columnHeader?.text,
      );
    } else if (
      gridResult.columnQuestionType instanceof
      GridResultV1Enums.ColumnQuestionType.OpenEnded
    ) {
      return createGridOpenEndedModel(
        gridResult.columnQuestionType,
        gridResult.columnHeader?.text,
      );
    } else {
      return createGridMultiSelectModel(
        gridResult.columnQuestionType,
        gridResult.columnHeader?.text,
      );
    }
  };

  return GridResultV1Model.create({
    columnCode: gridResult.columnCode.code,
    columnHeader: gridResult.columnHeader?.text,
    columnQuestionType: getColumnQuestionType(),
  });
};

export const GridQuestionViewV1Model = types
  .model("GridQuestionViewV1Model", {
    question: types.maybeNull(types.string),
    surveyQuestionViewId: types.string,
    sampleBreakdown: SampleBreakdownModel,
    gridResults: types.array(GridResultV1Model),
    groupChildQuestionCode: types.string,
    openEndedResponsesStore: OpenEndedResponsesModel,
  })
  .views((store) => ({
    get gridElementGraphTypeV1(): GridElementGraphTypeV1[] {
      const gridElementGraphTypeV1: GridElementGraphTypeV1[] = [];
      store.gridResults.map((gridResult) => {
        switch (getType(gridResult.columnQuestionType).name) {
          case GridQuestionViewTypeV1Enum.GRID_OPEN_ENDED_MODEL: {
            const gridOpenEndedModel =
              gridResult.columnQuestionType as Instance<
                typeof GridOpenEndedModel
              >;
            gridOpenEndedModel.openEndedResultsWithRowDetails.map(
              (openEndedResultsWithRowDetail) => {
                gridElementGraphTypeV1.push(
                  new GridElementGraphTypeV1(
                    new Code(openEndedResultsWithRowDetail.rowCode),
                    new Code(gridResult.columnCode),
                    new GridElementGraphTypeV1Enums.ColumnQuestionType.OpenEnded(
                      OpenEndedGraphTypeV1.OpenEndedGraphTypeV1[
                        gridOpenEndedModel.updatedGraphType as keyof typeof OpenEndedGraphTypeV1.OpenEndedGraphTypeV1
                      ],
                    ),
                  ),
                );
              },
            );

            break;
          }
          case GridQuestionViewTypeV1Enum.GRID_MULTI_SELECT_MODEL: {
            gridElementGraphTypeV1.push(
              new GridElementGraphTypeV1(
                null,
                new Code(gridResult.columnCode),
                new GridElementGraphTypeV1Enums.ColumnQuestionType.MultiSelect(
                  GridMultiSelectGraphTypeV1.GridMultiSelectGraphTypeV1[
                    gridResult.columnQuestionType
                      .updatedGraphType as keyof typeof GridMultiSelectGraphTypeV1.GridMultiSelectGraphTypeV1
                  ],
                ),
              ),
            );
            break;
          }
          case GridQuestionViewTypeV1Enum.GRID_SINGLE_SELECT_MODEL: {
            gridElementGraphTypeV1.push(
              new GridElementGraphTypeV1(
                null,
                new Code(gridResult.columnCode),
                new GridElementGraphTypeV1Enums.ColumnQuestionType.SingleSelect(
                  GridSingleSelectGraphTypeV1.GridSingleSelectGraphTypeV1[
                    gridResult.columnQuestionType
                      .updatedGraphType as keyof typeof GridSingleSelectGraphTypeV1.GridSingleSelectGraphTypeV1
                  ],
                ),
              ),
            );
            break;
          }
          case GridQuestionViewTypeV1Enum.GRID_SINGLE_SELECT_PROPORTION_MODEL: {
            gridElementGraphTypeV1.push(
              new GridElementGraphTypeV1(
                null,
                new Code(gridResult.columnCode),
                new GridElementGraphTypeV1Enums.ColumnQuestionType.SingleSelectWithProportion(
                  GridSingleSelectGraphTypeV1.GridSingleSelectGraphTypeV1[
                    gridResult.columnQuestionType
                      .updatedGraphType as keyof typeof GridSingleSelectGraphTypeV1.GridSingleSelectGraphTypeV1
                  ],
                ),
              ),
            );
            break;
          }
        }
      });
      return gridElementGraphTypeV1;
    },
  }))
  .views((store) => ({
    get isGraphSelectionUpdated(): boolean {
      let isButtonEnabled = false;
      store.gridResults.map((gridResult) => {
        if (
          !isButtonEnabled &&
          gridResult.columnQuestionType.isGraphSelectionUpdated
        ) {
          isButtonEnabled =
            gridResult.columnQuestionType.isGraphSelectionUpdated;
        }
      });
      return isButtonEnabled;
    },
    get questionViewGraphType(): QuestionViewGraphTypeV1.Grid {
      return new QuestionViewGraphTypeV1.Grid(store.gridElementGraphTypeV1);
    },
    get groupQuestionViewGraphtype(): GroupChildGraphTypeV1.GroupChildGraphTypeV1 {
      return new GroupChildGraphTypeV1.Grid(store.gridElementGraphTypeV1);
    },
  }))
  .actions((store) => ({
    updateSelectedGraphTypeWithUpdatedGraph: (): void => {
      store.gridResults.map((gridResult) => {
        gridResult.columnQuestionType.updateSelectedGraphTypeWithUpdatedGraph();
      });
    },
  }));

export const createGridQuestionViewV1Model = (
  gridQuestion: QuestionViewTypeV1.Grid,
  surveyQuestionViewId: string,
  groupChildQuestionCode: string,
  question: string | null = null,
): Instance<typeof GridQuestionViewV1Model> => {
  return GridQuestionViewV1Model.create({
    question,
    surveyQuestionViewId,
    sampleBreakdown: createSampleBreakdownModel(gridQuestion.sampleBreakdown),
    gridResults: gridQuestion.gridResults.map((gridResult) =>
      createGridResultV1Model(gridResult),
    ),
    groupChildQuestionCode,
    openEndedResponsesStore: createOpenEndedResponsesModel(),
  });
};
