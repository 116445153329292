import { Stack, Popover } from "@mui/material";
import {
  ConfirmationDialogComponent,
  ICON_SIZE,
  MAX_APPLIED_FILTER_COUNT,
  getIconProps,
  useBorder,
} from "@pulse/shared-components";
import {
  AutoCompleteItem,
  useCornerRadius,
  useFoundationColorTokens,
  useShadow,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { FilterStore } from "../store/FilterStore";
import { Instance } from "mobx-state-tree";
import { Button } from "@surya-digital/leo-reactjs-material-ui";
import { HelpCircle } from "lucide-react";
import { DividerComponent } from "../../../modules/surveys/components/commonQuestionConfigurationDetailsComponents/DividerComponent";
import { FilterType } from "../model/FilterModel";
import { useTranslation } from "react-i18next";
import { FilterInputComponent } from "./FilterInputComponent";
import { AddFilterButton } from "./AddFilterButton";
import { FilterMenuButtons } from "./FilterMenuButtons";
import { SelectedFilterButton } from "./SelectedFilterButton";

export interface Filter {
  key: string;
  valueType: FilterType;
  values?: AutoCompleteItem[];
}

export interface FilterComponentProps {
  store: Instance<typeof FilterStore>;
  filters: Filter[];
  onApplyFiltersClick: () => Promise<void>;
  onRemoveFiltersClick: () => void;
  isDisabled: boolean;
}

export const FilterComponent = observer(
  ({
    store,
    onApplyFiltersClick,
    onRemoveFiltersClick,
    filters,
    isDisabled,
  }: FilterComponentProps): React.ReactElement => {
    const border = useBorder();
    const borderRadius = useCornerRadius();
    const spacing = useSpacing();
    const tokens = useFoundationColorTokens();
    const typography = useTypography();
    const shadow = useShadow();
    const { t } = useTranslation();
    const [anchorElement, setAnchorElement] =
      React.useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorElement);
    const [isRemoveAllFiltersDialogOpen, setIsRemoveAllFiltersDialogOpen] =
      useState(false);
    const [isDateRangeInputInvalid, setIsDateRangeInputInvalid] =
      useState(false);

    useEffect(() => {
      store.clearStore();
    }, []);

    const handleFilterButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>,
    ): void => {
      setAnchorElement(event.currentTarget);
    };
    const handleFilterButtonClose = (): void => {
      store.removeCurrentSelectedFilter();
      setAnchorElement(null);
    };

    return (
      <Stack
        width="100%"
        border={border.default}
        borderRadius={borderRadius.radiusXS}
      >
        <Stack
          justifyContent="space-between"
          direction="row"
          padding={`${spacing.spaceMD} ${spacing.spaceXL}`}
        >
          <AddFilterButton
            store={store}
            typography={typography}
            tokens={tokens}
            spacing={spacing}
            borderRadius={borderRadius}
            border={border}
            handleFilterButtonClick={handleFilterButtonClick}
            maxFilterLimit={MAX_APPLIED_FILTER_COUNT}
            t={t}
            currentAppliedFiltersCount={store.appliedFilters.length}
            isDisabled={
              store.isMaxFilterLimitReached(MAX_APPLIED_FILTER_COUNT) ||
              isDisabled
            }
          />
          <Popover
            id="basic-menu"
            anchorEl={anchorElement}
            open={isMenuOpen}
            onClose={handleFilterButtonClose}
            autoFocus={false}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              "& .MuiPaper-root": {
                padding: 0,
                marginTop: spacing.spaceXXS,
                borderRadius: borderRadius.radiusXS,
                boxShadow: shadow.level3,
                border: border.default,
              },
            }}
          >
            <Stack
              divider={
                <DividerComponent width="100%" orientation="horizontal" />
              }
            >
              <FilterInputComponent
                store={store}
                filters={filters}
                t={t}
                tokens={tokens}
                typography={typography}
                spacing={spacing}
                setIsDateRangeInputInvalid={setIsDateRangeInputInvalid}
              />
              <FilterMenuButtons
                store={store}
                handleFilterButtonClose={handleFilterButtonClose}
                t={t}
                spacing={spacing}
                filters={filters}
                isDateRangeInputInvalid={isDateRangeInputInvalid}
                onApplyFiltersClick={onApplyFiltersClick}
              />
            </Stack>
          </Popover>
          <Stack direction="row" gap={spacing.spaceSM}>
            <Button
              onClick={() => {
                setIsRemoveAllFiltersDialogOpen(true);
              }}
              name="removeAllFilters"
              label={t("filters.removeAllFilters")}
              variant="outlined-color"
              size="medium"
              color="destructive"
              disabled={store.areNoFiltersApplied}
            />
          </Stack>
        </Stack>
        {!store.areNoFiltersAdded && (
          <Stack
            alignItems="flex-start"
            padding={`${spacing.spaceMD} ${spacing.spaceXL}`}
            direction="row"
            gap={spacing.spaceSM}
            borderTop={border.default}
            flexWrap="wrap"
          >
            {store.currentAddedFilters.map((filter, index) => {
              return (
                <SelectedFilterButton
                  key={index}
                  filter={filter}
                  index={index}
                  store={store}
                  onApplyFiltersClick={onApplyFiltersClick}
                />
              );
            })}
          </Stack>
        )}
        <ConfirmationDialogComponent
          isDialogOpen={isRemoveAllFiltersDialogOpen}
          title={t("filters.removeAllFilters")}
          description={t("filters.removeAllFiltersDialogDescription")}
          primaryButtonText={t("filters.removeAllFilters")}
          secondaryButtonText={t("common.cancel")}
          primaryButtonType="primary"
          descriptionTextColor={tokens.label}
          icon={
            <HelpCircle {...getIconProps(tokens.icon, ICON_SIZE.default)} />
          }
          primaryButtonCallBack={async () => {
            store.removeAllFilters();
            await onRemoveFiltersClick();
            setIsRemoveAllFiltersDialogOpen(false);
          }}
          secondaryButtonCallback={() => {
            setIsRemoveAllFiltersDialogOpen(false);
          }}
        />
      </Stack>
    );
  },
);
