import { LeoDate, LeoTimestamp } from "@surya-digital/leo-ts-runtime";

export const getFormattedLeoDate = (date: Date | undefined | null): LeoDate => {
  if (date) {
    const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    return new LeoDate(dateString);
  } else {
    // If date is undefined or null then LeoDate returns current date.
    return new LeoDate();
  }
};

export const getFormattedLeoTimeStamp = (
  date: Date | undefined | null,
): LeoTimestamp => {
  if (date) {
    const offsetInMilliSeconds = date.getTimezoneOffset() * 60000;
    const temp = new LeoTimestamp(
      new Date(date.getTime() - offsetInMilliSeconds).toISOString(),
    );
    return temp;
  } else {
    // If date is undefined or null then LeoTimestamp returns current timestamp.
    return new LeoTimestamp();
  }
};

export const convertTimestampFormat = (timestamp: string): string => {
  const date = new Date(timestamp);

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };

  return date.toLocaleString("en-US", options);
};
