import { cast, flow, getRoot, Instance, types } from "mobx-state-tree";
import {
  createRespondentColumnDetailsModel,
  RespondentColumnDetailsModel,
  TableColumnHeader,
} from "../models/RespondentColumnDetailsModel";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "../../networking/APIClient";
import {
  GetPivotTableDataRPC,
  GetRespondentColumnNamesRPC,
  RespondentColumnDetails,
  RespondentColumnName,
} from "@pulse/pulse-rpcs";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import {
  useGetPivotTableDataRPCClientImpl,
  useGetRespondentColumnNamesRPCClientImpl,
} from "../rpcs/RPC";
import { CommonErrors, EMPTY_CHARACTER } from "@pulse/shared-components";
import { RootStore } from "../../root/store/RootStore";
import { DropdownItem } from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import {
  createPivotTableDataModel,
  PivotTableDataModel,
} from "../models/PivotTableDataModel";

export enum PivotTableColumns {
  Total = "Total",
  ResponseRate = "Response Rate",
  NotAssigned = "Not Assigned",
  NotStarted = "Not Started",
  Viewed = "Viewed",
  Started = "Started",
  InProgress = "In Progress",
  LastPage = "Last Page",
  Submitted = "Submitted",
}

enum GetPivotTableDataRPCError {
  ColumnNotFound = "COLUMN_NOT_FOUND",
}

export const PivotTableStore = types
  .model("PivotTableStore", {
    respondentsColumnDetails: types.array(RespondentColumnDetailsModel),
    isRPCLoading: types.optional(types.boolean, false),
    selectedColumn: types.maybe(RespondentColumnDetailsModel),
    pivotTableData: types.array(PivotTableDataModel),
    isPivotDataFetched: types.optional(types.boolean, false),
    rpcError: types.maybeNull(
      types.enumeration(
        "GetPivotTableDataRPCError",
        Object.values(GetPivotTableDataRPCError),
      ),
    ),
  })
  .views((store) => ({
    get respondentColumnsDropdownOptions(): DropdownItem[] {
      return store.respondentsColumnDetails.map(
        (respondentColumn): DropdownItem => {
          return {
            name: respondentColumn.name,
            value: `${respondentColumn.index}`,
          };
        },
      );
    },
    get isErrorDialogVisible(): boolean {
      return store.rpcError !== null;
    },
    tableColumnHeaders(t: TFunction): TableColumnHeader[] {
      const commonColumnWidth = "124px";
      return [
        {
          columnId: store.selectedColumn?.name ?? EMPTY_CHARACTER,
          label: store.selectedColumn?.name ?? EMPTY_CHARACTER,
          width: "360px",
        },
        {
          columnId: PivotTableColumns.Total,
          label: t("common.total"),
          width: "100px",
        },
        {
          columnId: PivotTableColumns.ResponseRate,
          label: t(
            "projects.dashboard.pivotTable.columnHeaders.submissionRate",
          ),
          width: "140px",
        },
        {
          columnId: PivotTableColumns.Submitted,
          label: t("surveyLinkStatus.submitted"),
          width: commonColumnWidth,
        },
        {
          columnId: PivotTableColumns.LastPage,
          label: t("surveyLinkStatus.lastPage"),
          width: commonColumnWidth,
        },
        {
          columnId: PivotTableColumns.InProgress,
          label: t("surveyLinkStatus.inProgress"),
          width: commonColumnWidth,
        },
        {
          columnId: PivotTableColumns.Started,
          label: t("surveyLinkStatus.started"),
          width: "108px",
        },
        {
          columnId: PivotTableColumns.Viewed,
          label: t("surveyLinkStatus.viewed"),
          width: "108px",
        },
        {
          columnId: PivotTableColumns.NotStarted,
          label: t("surveyLinkStatus.notStarted"),
          width: commonColumnWidth,
        },
        {
          columnId: PivotTableColumns.NotAssigned,
          label: t("surveyLinkStatus.unassigned"),
          width: "132px",
        },
      ];
    },
  }))
  .actions((store) => ({
    clearStore: (): void => {
      store.isRPCLoading = false;
      store.respondentsColumnDetails.clear();
      store.selectedColumn = undefined;
      store.pivotTableData.clear();
      store.isPivotDataFetched = false;
      store.rpcError = null;
    },
    setIsPivotDataFetched: (isPivotDataFetched: boolean): void => {
      store.isPivotDataFetched = isPivotDataFetched;
    },
    setSelectedColumn: (selectedColumn: DropdownItem | null): void => {
      if (selectedColumn) {
        store.selectedColumn = createRespondentColumnDetailsModel(
          new RespondentColumnDetails(
            new RespondentColumnName(selectedColumn.name),
            Number(selectedColumn.value),
          ),
        );
      } else {
        console.error(
          "This action should not be called when selectedColumn is null",
        );
      }
    },
    getRespondentColumnNames: flow(function* (projectId: string) {
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new GetRespondentColumnNamesRPC.Request(
          new LeoUUID(projectId),
        );
        const {
          response,
          error,
        }: {
          response?: GetRespondentColumnNamesRPC.Response;
          error?: GetRespondentColumnNamesRPC.Errors.Errors;
        } =
          yield useGetRespondentColumnNamesRPCClientImpl(apiClient).execute(
            request,
          );
        if (response) {
          store.respondentsColumnDetails = cast(
            response.respondentColumnDetails.map((respondentColumnDetail) => {
              return createRespondentColumnDetailsModel(respondentColumnDetail);
            }),
          );
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
              // This error is already handled in default response interceptor.
              break;
            default: {
              console.error(`Unhandled error occured: ${error}`);
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      }
    }),
    getPivotTableData: flow(function* (projectId: string) {
      store.isRPCLoading = true;
      try {
        if (store.selectedColumn === undefined) {
          console.error("Selected column cannot be null in this flow.");
          return;
        }
        const apiClient: APIClient = getAPIClient(store);
        const request = new GetPivotTableDataRPC.Request(
          new LeoUUID(projectId),
          store.selectedColumn.index,
        );
        const {
          response,
          error,
        }: {
          response?: GetPivotTableDataRPC.Response;
          error?: GetPivotTableDataRPC.Errors.Errors;
        } = yield useGetPivotTableDataRPCClientImpl(apiClient).execute(request);
        if (response) {
          store.pivotTableData = cast(
            response.tableData.map((data) => {
              return createPivotTableDataModel(data);
            }),
          );
          store.isPivotDataFetched = true;
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
              // This error is already handled in default response interceptor.
              break;
            case GetPivotTableDataRPCError.ColumnNotFound:
              store.rpcError = GetPivotTableDataRPCError.ColumnNotFound;
              break;
            default: {
              console.error(`Unhandled error occured: ${error}`);
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
  }));

export const createPivotTableStore = (): Instance<typeof PivotTableStore> => {
  return PivotTableStore.create();
};
