import { Stack, Typography } from "@mui/material";
import { QuestionType, SurveySettingsEnums } from "@pulse/pulse-rpcs";
import {
  EMPTY_CHARACTER,
  getIconProps,
  ICON_SIZE,
  PRIMARY_FONT,
  RICH_TEXT_DEFAULT_FONT_COLOR,
  RICH_TEXT_MARKDOWN_PROPS,
  richTextTypography,
  RTERegularTextType,
} from "@pulse/shared-components";
import { RichTextEditor } from "@surya-digital/leo-reactjs-remirror";
import React, { PropsWithChildren, ReactElement } from "react";
import { DividerComponent } from "../../surveys/components/commonQuestionConfigurationDetailsComponents/DividerComponent";
import { SkipForward, Users2 } from "lucide-react";
import {
  FoundationColorTokens,
  Typography as LeoTypography,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { SampleBreakdownModel } from "../models/SampleBreakdownModel";
import { Instance } from "mobx-state-tree";
import { SurveyQuestionViewStoreNames } from "../stores/SurveyViewDetailsStore";
import {
  getQuestionTypeIcon,
  getQuestionTypeTypography,
} from "../../utils/QuestionTypeIconAndTypographyUtils";

const B2LabelSubtleTypography = ({
  text,
  typography,
  tokens,
}: {
  text: string;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
}): ReactElement => {
  return (
    <Typography {...typography.b2} color={tokens.labelSubtle} width="100%">
      {text}
    </Typography>
  );
};

interface IconLabelStackProps extends PropsWithChildren {
  text: string;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  spacing: Spacing;
}

const IconLabelStack = ({
  text,
  tokens,
  typography,
  spacing,
  children,
}: IconLabelStackProps): ReactElement => {
  return (
    <Stack direction="row" gap={spacing.spaceXXS}>
      {children}
      <B2LabelSubtleTypography
        tokens={tokens}
        typography={typography}
        text={text}
      />
    </Stack>
  );
};

interface HeaderQuestionDetailsProps {
  questionText: string | null;
  sampleBreakdown: Instance<typeof SampleBreakdownModel> | undefined;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  t: TFunction;
  spacing: Spacing;
  surveyQuestionViewType: SurveyQuestionViewStoreNames;
  isQuestionTypeIconVisible: boolean;
}

export const HeaderQuestionDetails = ({
  questionText,
  sampleBreakdown,
  typography,
  tokens,
  t,
  spacing,
  surveyQuestionViewType,
  isQuestionTypeIconVisible,
}: HeaderQuestionDetailsProps): ReactElement => {
  const getQuestionTypeText = (): string => {
    switch (surveyQuestionViewType) {
      case SurveyQuestionViewStoreNames.SINGLE_SELECT_QUESTION_WITH_PROPORTION_MODEL:
      case SurveyQuestionViewStoreNames.NPS_SINGLE_SELECT_QUESTION_MODEL:
      case SurveyQuestionViewStoreNames.REGULAR_SINGLE_SELECT_QUESTION_MODEL: {
        return getQuestionTypeTypography(
          QuestionType.QuestionType.SINGLE_CHOICE,
          t,
        );
      }
      case SurveyQuestionViewStoreNames.OPEN_ENDED_QUESTION_MODEL: {
        return getQuestionTypeTypography(
          QuestionType.QuestionType.OPEN_ENDED,
          t,
        );
      }
      case SurveyQuestionViewStoreNames.MULTI_SELECT_QUESTION_MODEL: {
        return getQuestionTypeTypography(
          QuestionType.QuestionType.MULTIPLE_CHOICE,
          t,
        );
      }
      case SurveyQuestionViewStoreNames.RANKING_QUESTION_MODEL: {
        return getQuestionTypeTypography(QuestionType.QuestionType.RANKING, t);
      }
      case SurveyQuestionViewStoreNames.GROUP_QUESTION_MODEL: {
        return getQuestionTypeTypography(QuestionType.QuestionType.GROUP, t);
      }
      case SurveyQuestionViewStoreNames.GRID_QUESTION_MODEL: {
        return getQuestionTypeTypography(QuestionType.QuestionType.GRID, t);
      }
      case SurveyQuestionViewStoreNames.GRID_MULTI_SELECT_MODEL:
      case SurveyQuestionViewStoreNames.GRID_OPEN_ENDED_MODEL:
      case SurveyQuestionViewStoreNames.GRID_SINGLE_SELECT_MODEL:
      case SurveyQuestionViewStoreNames.GRID_SINGLE_SELECT_PROPORTION_MODEL: {
        return EMPTY_CHARACTER;
      }
    }
  };
  const QuestionTypeIcon = (): ReactElement => {
    switch (surveyQuestionViewType) {
      case SurveyQuestionViewStoreNames.SINGLE_SELECT_QUESTION_WITH_PROPORTION_MODEL:
      case SurveyQuestionViewStoreNames.NPS_SINGLE_SELECT_QUESTION_MODEL:
      case SurveyQuestionViewStoreNames.REGULAR_SINGLE_SELECT_QUESTION_MODEL: {
        return getQuestionTypeIcon(
          QuestionType.QuestionType.SINGLE_CHOICE,
          tokens.iconSubtle,
          false,
        );
      }
      case SurveyQuestionViewStoreNames.OPEN_ENDED_QUESTION_MODEL: {
        return getQuestionTypeIcon(
          QuestionType.QuestionType.OPEN_ENDED,
          tokens.iconSubtle,
          false,
        );
      }
      case SurveyQuestionViewStoreNames.MULTI_SELECT_QUESTION_MODEL: {
        return getQuestionTypeIcon(
          QuestionType.QuestionType.MULTIPLE_CHOICE,
          tokens.iconSubtle,
          false,
        );
      }
      case SurveyQuestionViewStoreNames.RANKING_QUESTION_MODEL: {
        return getQuestionTypeIcon(
          QuestionType.QuestionType.RANKING,
          tokens.iconSubtle,
          false,
        );
      }
      case SurveyQuestionViewStoreNames.GROUP_QUESTION_MODEL: {
        return getQuestionTypeIcon(
          QuestionType.QuestionType.GROUP,
          tokens.iconSubtle,
          false,
        );
      }
      case SurveyQuestionViewStoreNames.GRID_QUESTION_MODEL: {
        return getQuestionTypeIcon(
          QuestionType.QuestionType.GRID,
          tokens.iconSubtle,
          false,
        );
      }
      case SurveyQuestionViewStoreNames.GRID_MULTI_SELECT_MODEL:
      case SurveyQuestionViewStoreNames.GRID_OPEN_ENDED_MODEL:
      case SurveyQuestionViewStoreNames.GRID_SINGLE_SELECT_MODEL:
      case SurveyQuestionViewStoreNames.GRID_SINGLE_SELECT_PROPORTION_MODEL: {
        return <></>;
      }
    }
  };

  return (
    <Stack width="100%" gap={spacing.spaceSM}>
      <RichTextEditor
        name="Question Title"
        initialValue={questionText ?? undefined}
        typography={richTextTypography(
          PRIMARY_FONT,
          SurveySettingsEnums.FontSizeScale.FontSizeScale.DEFAULT_SCALE,
          RICH_TEXT_DEFAULT_FONT_COLOR,
          true,
          RTERegularTextType.BACK_OFFICE,
        )}
        mode="preview"
        supports={RICH_TEXT_MARKDOWN_PROPS}
        borderOnPreview={false}
      />
      <Stack
        direction="row"
        divider={<DividerComponent orientation="vertical" height="100%" />}
        width="100%"
        gap={spacing.spaceXS}
        justifyContent="flex-start"
      >
        {isQuestionTypeIconVisible && (
          <IconLabelStack
            text={getQuestionTypeText()}
            tokens={tokens}
            typography={typography}
            spacing={spacing}
          >
            <QuestionTypeIcon />
          </IconLabelStack>
        )}
        {sampleBreakdown && (
          <Stack
            direction="row"
            divider={<DividerComponent orientation="vertical" height="100%" />}
            gap={spacing.spaceXS}
            justifyContent="flex-start"
          >
            <IconLabelStack
              text={t("surveyDashboard.views.respondedCount", {
                responded: sampleBreakdown.sample,
              })}
              tokens={tokens}
              typography={typography}
              spacing={spacing}
            >
              <Users2 {...getIconProps(tokens.iconSubtle, ICON_SIZE.default)} />
            </IconLabelStack>
            <IconLabelStack
              text={t("surveyDashboard.views.skipped", {
                skipped: sampleBreakdown.skipped,
              })}
              tokens={tokens}
              typography={typography}
              spacing={spacing}
            >
              <SkipForward
                {...getIconProps(tokens.iconSubtle, ICON_SIZE.default)}
              />
            </IconLabelStack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
