import {
  BoUserManagementItemsPerPage,
  BoUserManagementPageIndex,
  BoUserManagementSortOrder,
  GetBOUsersRPC,
  GetBOUsersSortOrder,
  GetBOUsersSortOrderEnums,
  GetProjectBOUsersRPC,
  GetProjectBOUsersSortOrder,
  ProjectItemsPerPage,
  ProjectPageIndex,
  ProjectSortOrder,
  TableFilter,
  TableFilterEnums,
} from "@pulse/pulse-rpcs";
import { GetProjectBOUsersSortOrderEnums } from "@pulse/pulse-rpcs";
import {
  CommonErrors,
  DEFAULT_ITEMS_PER_PAGE_IN_PG_TABLE,
  DEFAULT_PROJECT_LIST_SORT_INDEX,
  EMPTY_CHARACTER,
  EMPTY_LIST_LENGTH,
  LIST_WITH_SINGLE_ELEMENT,
  ZERO_VALUE,
} from "@pulse/shared-components";
import {
  MultiSelectTableStore,
  TableSortOption,
  createMultiSelectTableStore,
} from "@surya-digital/leo-reactjs-material-ui";
import { Instance, cast, flow, getRoot, types } from "mobx-state-tree";
import {
  ManageBOUserListHeaderEnum,
  ManageProjectBOUserListHeaderEnum,
} from "../components/userAccess/ManageBOUserPane";
import { getAPIClient } from "../../networking/APIClient";
import { LeoDate } from "@surya-digital/leo-ts-runtime";
import {
  FilterStore,
  createFilterStore,
} from "../../../components/filter/store/FilterStore";
import { RootStore } from "../../root/store/RootStore";
import { useGetProjectBOUsersRPCClient } from "../rpcs/RPC";
import {
  ManageBOUsersDetailsModel,
  createManageBOUsersDetailsModel,
} from "../models/ManageBOUsersDetailsModel";
import { useGetBOUsersRPCClient } from "../../manageUsers/rpcs/RPC";
import {
  ManageProjectBOUsersDetailsModel,
  createManageProjectBOUsersDetailsModel,
} from "../models/ManageProjectBOUsersDetailsModel";
import { FilterType } from "../../../components/filter/model/FilterModel";
import { getFormattedLeoDate } from "../../utils/DateUtils";
import {
  DeleteProjectBOUserStore,
  createDeleteProjectBOUserStore,
} from "./DeleteProjectBOUsersStore";

export interface GetProjectBOUserListSortOptions {
  columnName: GetProjectBOUsersSortOrderEnums.ColumnName.ColumnName;
  order: ProjectSortOrder.ProjectSortOrder;
}

export interface GetBOUserListSortOptions {
  columnName: GetBOUsersSortOrderEnums.ColumnName.ColumnName;
  order: BoUserManagementSortOrder.BoUserManagementSortOrder;
}

export interface ManageProjectBOUserListFilters {
  name: string | null | undefined;
  emailAddress: string | null | undefined;
  role: string | null | undefined;
  addedOnStartDate: LeoDate | null | undefined;
  addedOnEndDate: LeoDate | null | undefined;
}

export interface ManageBOUserListFilters {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  emailAddress: string | null | undefined;
  role: string | null | undefined;
  addedOnStartDate: LeoDate | null | undefined;
  addedOnEndDate: LeoDate | null | undefined;
}

export enum GetProjectBOUsersRPCErrors {
  InvalidPageIndex = "INVALID_PAGE_INDEX",
  InvalidDateRange = "INVALID_DATE_RANGE",
}

export const ManageBOUserStore = types
  .model("ManageBOUserStore", {
    isRPCLoading: types.optional(types.boolean, false),
    manageProjectBOUserListFilter: FilterStore,
    projectBOUserList: types.array(ManageProjectBOUsersDetailsModel),
    boUserList: types.array(ManageBOUsersDetailsModel),
    totalItems: types.optional(types.number, ZERO_VALUE),
    multiSelectTableStore: MultiSelectTableStore,
    rpcErrors: types.maybeNull(
      types.enumeration(
        "GetProjectBOUsersRPCErrors",
        Object.values(GetProjectBOUsersRPCErrors),
      ),
    ),
    deleteProjectBOUserStore: DeleteProjectBOUserStore,
  })
  .views((store) => ({
    get isSingleUserSelected(): boolean {
      return (
        store.multiSelectTableStore.totalSelectedItems(store.totalItems) ===
        LIST_WITH_SINGLE_ELEMENT
      );
    },
    get doesStoreContainBOUserListError(): boolean {
      return (
        store.rpcErrors === GetProjectBOUsersRPCErrors.InvalidDateRange ||
        store.rpcErrors === GetProjectBOUsersRPCErrors.InvalidPageIndex
      );
    },
    manageProjectBOUserListSortOptions(
      sort: TableSortOption | undefined,
    ): GetProjectBOUserListSortOptions {
      let columnName =
        GetProjectBOUsersSortOrderEnums.ColumnName.ColumnName.ADDED_ON;
      if (sort === undefined) {
        return {
          columnName,
          order: ProjectSortOrder.ProjectSortOrder.DESCENDING,
        };
      }
      switch (sort.id) {
        case ManageProjectBOUserListHeaderEnum.AddedOn: {
          columnName =
            GetProjectBOUsersSortOrderEnums.ColumnName.ColumnName.ADDED_ON;
          break;
        }
      }
      return {
        columnName,
        order:
          sort.order === "asc"
            ? ProjectSortOrder.ProjectSortOrder.ASCENDING
            : ProjectSortOrder.ProjectSortOrder.DESCENDING,
      };
    },
    manageBOUserListSortOptions(
      sort: TableSortOption | undefined,
    ): GetBOUserListSortOptions {
      let columnName = GetBOUsersSortOrderEnums.ColumnName.ColumnName.ADDED_ON;
      if (sort === undefined) {
        return {
          columnName,
          order: BoUserManagementSortOrder.BoUserManagementSortOrder.DESCENDING,
        };
      }
      switch (sort.id) {
        case ManageProjectBOUserListHeaderEnum.AddedOn: {
          columnName = GetBOUsersSortOrderEnums.ColumnName.ColumnName.ADDED_ON;
          break;
        }
        case ManageBOUserListHeaderEnum.FirstName: {
          columnName =
            GetBOUsersSortOrderEnums.ColumnName.ColumnName.FIRST_NAME;
          break;
        }
        case ManageBOUserListHeaderEnum.LastName: {
          columnName = GetBOUsersSortOrderEnums.ColumnName.ColumnName.LAST_NAME;
          break;
        }
        case ManageBOUserListHeaderEnum.EmailAddress: {
          columnName =
            GetBOUsersSortOrderEnums.ColumnName.ColumnName.EMAIL_ADDRESS;
          break;
        }
      }
      return {
        columnName,
        order:
          sort.order === "asc"
            ? BoUserManagementSortOrder.BoUserManagementSortOrder.ASCENDING
            : BoUserManagementSortOrder.BoUserManagementSortOrder.DESCENDING,
      };
    },
    get projectBOUserListFilters(): ManageProjectBOUserListFilters {
      let name: string | null | undefined = null;
      let emailAddress: string | null | undefined = null;
      let role: string | null | undefined = null;
      let addedOnStartDate: LeoDate | null | undefined = null;
      let addedOnEndDate: LeoDate | null | undefined = null;
      if (
        store.manageProjectBOUserListFilter.appliedFilters.length !==
        EMPTY_LIST_LENGTH
      ) {
        store.manageProjectBOUserListFilter.appliedFilters.forEach((filter) => {
          switch (filter.key) {
            case ManageProjectBOUserListHeaderEnum.Name: {
              name = filter.openEndedFilterValue;
              break;
            }
            case ManageProjectBOUserListHeaderEnum.EmailAddress: {
              emailAddress = filter.openEndedFilterValue;
              break;
            }
            case ManageProjectBOUserListHeaderEnum.Role: {
              role = filter.openEndedFilterValue;
              break;
            }
            case ManageProjectBOUserListHeaderEnum.AddedOn: {
              if (filter.dateRangeFilterValue?.startDate !== null) {
                addedOnStartDate = filter.dateRangeFilterValue?.leoStartDate;
              }
              if (filter.dateRangeFilterValue?.endDate !== null) {
                addedOnEndDate = filter.dateRangeFilterValue?.leoEndDate;
              }
              break;
            }
          }
        });
      }
      return {
        name,
        emailAddress,
        role,
        addedOnStartDate,
        addedOnEndDate,
      };
    },
    get projectBoUserListFilters(): ManageProjectBOUserListFilters {
      let name: string | null | undefined = null;
      let emailAddress: string | null | undefined = null;
      let role: string | null | undefined = null;
      let addedOnStartDate: LeoDate | null | undefined = null;
      let addedOnEndDate: LeoDate | null | undefined = null;
      if (
        store.manageProjectBOUserListFilter.appliedFilters.length !==
        EMPTY_LIST_LENGTH
      ) {
        store.manageProjectBOUserListFilter.appliedFilters.forEach((filter) => {
          switch (filter.key) {
            case ManageProjectBOUserListHeaderEnum.Name: {
              name = filter.openEndedFilterValue;
              break;
            }
            case ManageProjectBOUserListHeaderEnum.EmailAddress: {
              emailAddress = filter.openEndedFilterValue;
              break;
            }
            case ManageProjectBOUserListHeaderEnum.Role: {
              role = filter.openEndedFilterValue;
              break;
            }
            case ManageProjectBOUserListHeaderEnum.AddedOn: {
              if (filter.dateRangeFilterValue?.startDate !== null) {
                addedOnStartDate = filter.dateRangeFilterValue?.leoStartDate;
              }
              if (filter.dateRangeFilterValue?.endDate !== null) {
                addedOnEndDate = filter.dateRangeFilterValue?.leoEndDate;
              }
              break;
            }
          }
        });
      }
      return {
        name,
        emailAddress,
        role,
        addedOnStartDate,
        addedOnEndDate,
      };
    },
    get boUserListFilters(): ManageBOUserListFilters {
      let firstName: string | null | undefined = null;
      let lastName: string | null | undefined = null;
      let emailAddress: string | null | undefined = null;
      let role: string | null | undefined = null;
      let addedOnStartDate: LeoDate | null | undefined = null;
      let addedOnEndDate: LeoDate | null | undefined = null;
      if (
        store.manageProjectBOUserListFilter.appliedFilters.length !==
        EMPTY_LIST_LENGTH
      ) {
        store.manageProjectBOUserListFilter.appliedFilters.forEach((filter) => {
          switch (filter.key) {
            case ManageBOUserListHeaderEnum.FirstName: {
              firstName = filter.openEndedFilterValue;
              break;
            }
            case ManageBOUserListHeaderEnum.LastName: {
              lastName = filter.openEndedFilterValue;
              break;
            }
            case ManageBOUserListHeaderEnum.EmailAddress: {
              emailAddress = filter.openEndedFilterValue;
              break;
            }
            case ManageBOUserListHeaderEnum.Role: {
              role = filter.openEndedFilterValue;
              break;
            }
            case ManageBOUserListHeaderEnum.AddedOn: {
              if (filter.dateRangeFilterValue?.startDate !== null) {
                addedOnStartDate = filter.dateRangeFilterValue?.leoStartDate;
              }
              if (filter.dateRangeFilterValue?.endDate !== null) {
                addedOnEndDate = filter.dateRangeFilterValue?.leoEndDate;
              }
              break;
            }
          }
        });
      }
      return {
        firstName,
        lastName,
        emailAddress,
        role,
        addedOnStartDate,
        addedOnEndDate,
      };
    },
  }))
  .actions((store) => ({
    applyFilters: (requestFilters: TableFilter[]): void => {
      try {
        if (!store.manageProjectBOUserListFilter.areNoFiltersAdded) {
          store.manageProjectBOUserListFilter.appliedFilters.map((filter) => {
            if (filter.key === undefined) {
              console.error("The filter key cannot be null");
              return;
            }
            let tableFilters:
              | TableFilterEnums.Input.DateRange
              | TableFilterEnums.Input.OpenEnded
              | TableFilterEnums.Input.PresetMultiSelect;
            if (filter.filterType === FilterType.OpenEnded) {
              if (filter.openEndedFilterValue === undefined) {
                console.error(
                  "The filter value for Open Ended Filter cannot be null",
                );
                return;
              }
              tableFilters = new TableFilterEnums.Input.OpenEnded(
                filter.openEndedFilterValue,
              );
            } else if (filter.filterType === FilterType.MultiSelect) {
              tableFilters = new TableFilterEnums.Input.PresetMultiSelect(
                filter.multiSelectFilterValue.map((filterValue) => {
                  if (filterValue.id === undefined) {
                    console.error(
                      "The filter value for Multi Select Filter cannot be null",
                    );
                  }
                  return filterValue.id ?? EMPTY_CHARACTER;
                }),
              );
            } else {
              if (
                filter.dateRangeFilterValue &&
                filter.dateRangeFilterValue.startDate &&
                filter.dateRangeFilterValue.endDate
              ) {
                tableFilters = new TableFilterEnums.Input.DateRange(
                  getFormattedLeoDate(filter.dateRangeFilterValue.startDate),
                  getFormattedLeoDate(filter.dateRangeFilterValue.endDate),
                );
              } else {
                console.error("The filter value for date range cannot be null");
                return;
              }
            }
            requestFilters.push(
              new TableFilter(filter.key ?? EMPTY_CHARACTER, tableFilters),
            );
          });
        }
      } catch (e) {
        console.error(
          `Unexpected error ${e} at applyFilters action in ManageBOUserStore.`,
        );
      }
    },
  }))
  .actions((store) => ({
    resetStore: (): void => {
      store.isRPCLoading = false;
      store.manageProjectBOUserListFilter = createFilterStore();
      store.projectBOUserList.clear();
      store.totalItems = ZERO_VALUE;
      store.rpcErrors = null;
    },
    getBOUsers: flow(function* (pageIndex: number, sort?: TableSortOption) {
      store.isRPCLoading = true;
      const sortOption = [
        new GetBOUsersSortOrder(
          DEFAULT_PROJECT_LIST_SORT_INDEX,
          store.manageBOUserListSortOptions(sort).columnName,
          store.manageBOUserListSortOptions(sort).order,
        ),
      ];

      try {
        const apiClient = getAPIClient(store);
        const manageProjectBOUserListFilters = store.boUserListFilters;
        const request = new GetBOUsersRPC.Request(
          sortOption,
          manageProjectBOUserListFilters.firstName,
          manageProjectBOUserListFilters.lastName,
          manageProjectBOUserListFilters.emailAddress,
          manageProjectBOUserListFilters.role,
          manageProjectBOUserListFilters.addedOnStartDate,
          manageProjectBOUserListFilters.addedOnEndDate,
          new BoUserManagementItemsPerPage(DEFAULT_ITEMS_PER_PAGE_IN_PG_TABLE),
          new BoUserManagementPageIndex(pageIndex),
        );
        const {
          response,
          error,
        }: {
          response?: GetBOUsersRPC.Response;
          error?: GetBOUsersRPC.Errors.Errors;
        } = yield useGetBOUsersRPCClient(apiClient).execute(request);
        if (response) {
          store.boUserList = cast(
            response.getBOUsersPaginationResponse.map((paginationResponse) => {
              return createManageBOUsersDetailsModel(paginationResponse);
            }),
          );
          store.totalItems = response.totalItems;
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId: {
              break;
            }
            case GetProjectBOUsersRPCErrors.InvalidPageIndex: {
              store.rpcErrors = GetProjectBOUsersRPCErrors.InvalidPageIndex;
              break;
            }
            default:
              console.error(`Unhandled error ${error.code}.`);
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
    getProjectBOUsers: flow(function* (
      pageIndex: number,
      projectId: string,
      sort?: TableSortOption,
    ) {
      store.isRPCLoading = true;
      const sortOption = [
        new GetProjectBOUsersSortOrder(
          DEFAULT_PROJECT_LIST_SORT_INDEX,
          store.manageProjectBOUserListSortOptions(sort).columnName,
          store.manageProjectBOUserListSortOptions(sort).order,
        ),
      ];

      try {
        const apiClient = getAPIClient(store);
        const manageProjectBOUserListFilters = store.projectBOUserListFilters;
        const request = new GetProjectBOUsersRPC.Request(
          sortOption,
          manageProjectBOUserListFilters.name,
          manageProjectBOUserListFilters.emailAddress,
          projectId,
          manageProjectBOUserListFilters.role,
          manageProjectBOUserListFilters.addedOnStartDate,
          manageProjectBOUserListFilters.addedOnEndDate,
          new ProjectItemsPerPage(DEFAULT_ITEMS_PER_PAGE_IN_PG_TABLE),
          new ProjectPageIndex(pageIndex),
        );
        const {
          response,
          error,
        }: {
          response?: GetProjectBOUsersRPC.Response;
          error?: GetProjectBOUsersRPC.Errors.Errors;
        } = yield useGetProjectBOUsersRPCClient(apiClient).execute(request);
        if (response) {
          store.projectBOUserList = cast(
            response.getProjectBOUsersPaginationResponse.map(
              (paginationResponse) => {
                return createManageProjectBOUsersDetailsModel(
                  paginationResponse,
                );
              },
            ),
          );
          store.totalItems = response.totalItems;
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId: {
              break;
            }
            case GetProjectBOUsersRPCErrors.InvalidPageIndex: {
              store.rpcErrors = GetProjectBOUsersRPCErrors.InvalidPageIndex;
              break;
            }
            default:
              console.error(`Unhandled error ${error.code}.`);
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
  }));

export const createManageBOUserStore = (): Instance<
  typeof ManageBOUserStore
> => {
  return ManageBOUserStore.create({
    manageProjectBOUserListFilter: createFilterStore(),
    multiSelectTableStore: createMultiSelectTableStore(),
    deleteProjectBOUserStore: createDeleteProjectBOUserStore(),
  });
};
