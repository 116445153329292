import {
  Dialog,
  FoundationColorTokens,
  LoadingIndicator,
  Typography as LeoTypography,
  Spacing,
  CornerRadius,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import React, { useCallback, useEffect } from "react";
import {
  RespondentResponseDialogState,
  RespondentResponseStore,
} from "../../store/RespondentResponseStore";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../../routes/RoutesHelper";
import { observer } from "mobx-react";
import { RespondentResponseDialogChild } from "./RespondentResponseDialogChild";
import { BorderStyle } from "@pulse/shared-components";
import { Stack } from "@mui/material";
import { DialogErrorContent } from "../../../../components/DialogErrorContent";
import { GetIndividualSubmittedResponsesRPC } from "@pulse/pulse-rpcs";

interface RespondentResponseDialogProps {
  isRespondentResponseDialogOpen: boolean;
  setIsRespondentResponseDialogOpen: (isDialogVisible: boolean) => void;
  t: TFunction;
  respondentResponseStore: Instance<typeof RespondentResponseStore>;
  navigateTo: NavigateToFunctions;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
  border: BorderStyle;
  cornerRadius: CornerRadius;
}

export const RespondentResponseDialog = observer(
  ({
    isRespondentResponseDialogOpen,
    setIsRespondentResponseDialogOpen,
    t,
    respondentResponseStore,
    navigateTo,
    tokens,
    typography,
    spacing,
    border,
    cornerRadius,
  }: RespondentResponseDialogProps): React.ReactElement => {
    const getRespondentResponses = useCallback(async (): Promise<void> => {
      await processProjectParams(
        respondentResponseStore.getIndividualSubmittedResponse,
        navigateTo,
      );
    }, []);

    useEffect(() => {
      getRespondentResponses();
      return (): void => {
        setIsRespondentResponseDialogOpen(false);
        respondentResponseStore.resetStore();
      };
    }, []);

    const getErrorText = (): string => {
      switch (respondentResponseStore.rpcError) {
        case GetIndividualSubmittedResponsesRPC.RPCError.SurveyNotSubmitted: {
          return t("projects.respondentResponseDialog.responseNotSubmitted");
        }
        case GetIndividualSubmittedResponsesRPC.RPCError.InvalidRespondentId:
        default: {
          return t(
            "projects.respondentResponseDialog.somethingWentWrongErrorDescription",
          );
        }
      }
    };

    const DialogChild = observer((): React.ReactElement => {
      switch (respondentResponseStore.respondentResponsesDialogState) {
        case RespondentResponseDialogState.Loading:
          return (
            <Stack
              padding={spacing.spaceLG}
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <LoadingIndicator
                isLoading={true}
                loadingText={t("projects.respondentResponseDialog.loadingText")}
                variant="container"
              />
            </Stack>
          );
        case RespondentResponseDialogState.RespondentResponse:
          return (
            <RespondentResponseDialogChild
              respondentResponseStore={respondentResponseStore}
              tokens={tokens}
              typography={typography}
              t={t}
              spacing={spacing}
              border={border}
              cornerRadius={cornerRadius}
            />
          );
        case RespondentResponseDialogState.ERROR:
          return (
            <Stack
              padding={spacing.spaceLG}
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <DialogErrorContent
                spacing={spacing}
                tokens={tokens}
                typography={typography}
                errorText={getErrorText()}
              />
            </Stack>
          );
      }
    });

    return (
      <Dialog
        width="960px"
        open={isRespondentResponseDialogOpen}
        title={t("projects.respondentResponseDialog.title")}
        primaryButtonText={t(
          "projects.respondentResponseDialog.primaryButtonText",
        )}
        onPrimaryButtonClick={async (): Promise<void> => {
          await processProjectParams(
            respondentResponseStore.getPresignedDownloadURLForIndividualSubmittedResponsesRPC,
            navigateTo,
          );
        }}
        disableBackdropClick={true}
        onClose={() => {
          setIsRespondentResponseDialogOpen(false);
        }}
        isPrimaryButtonDisabled={
          respondentResponseStore.isPrimaryButtonDisabled
        }
        showCloseButton={true}
        contentPadding="0px"
      >
        <DialogChild />
      </Dialog>
    );
  },
);
