import React, { useEffect } from "react";

import { EmptyListPane } from "./EmptyListPane";
import { observer } from "mobx-react";
import { QuestionnaireListItem } from "./QuestionnaireListItem";
import { Stack } from "@mui/material";
import {
  CornerRadius,
  LoadingIndicator,
  Shadow,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { ErrorComponent } from "../../../components/ErrorComponent";
import { BorderStyle, NetworkingError } from "@pulse/shared-components";
import {
  NavigateToFunctions,
  processSurveyParams,
} from "../../../routes/RoutesHelper";
import { DividerComponent } from "./commonQuestionConfigurationDetailsComponents/DividerComponent";
import { GetQuestionsOfSurveyError, SurveyStore } from "../store/SurveyStore";
import { clone, Instance } from "mobx-state-tree";
import { TFunction } from "i18next";

export interface QuestionnaireListPaneProps {
  surveyStore: Instance<typeof SurveyStore>;
  navigateTo: NavigateToFunctions;
  spacing: Spacing;
  t: TFunction;
  border: BorderStyle;
  borderRadius: CornerRadius;
  shadow: Shadow;
}

export const QuestionnaireListPane = observer(
  ({
    surveyStore,
    navigateTo,
    spacing,
    t,
    border,
    borderRadius,
    shadow,
  }: QuestionnaireListPaneProps): React.ReactElement => {
    useEffect(() => {
      return surveyStore.clearRpcErrors();
    }, []);

    if (surveyStore.rpcError) {
      switch (surveyStore.rpcError) {
        case NetworkingError.InternalError: {
          navigateTo.internalServerError();
          break;
        }
        case NetworkingError.PageNotFound: {
          navigateTo.pageNotFound();
          break;
        }
        case GetQuestionsOfSurveyError.MaxQuestionsReached: {
          return (
            <ErrorComponent
              errorMessage={t(
                "surveys.questionnaireList.errorFetchingQuestionnaire",
              )}
              resolveButtonText={t("surveys.questionnaireList.backToSurveys")}
              resolveButtonCallback={(): void => {
                navigateTo.parent();
              }}
            />
          );
        }
      }
    }

    const QuestionnaireList = (): React.ReactElement => {
      return (
        <Stack
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          {!surveyStore.isRPCLoading && surveyStore.isQuestionListEmpty ? (
            <EmptyListPane
              headingText={t("surveys.addSurveyQuestion.noQuestionsCreated")}
              subHeadingText={t(
                "surveys.questionnaireList.createQuestionsDescription",
              )}
              helperButtonText={t(
                "surveys.questionnaireList.createQuestionnaire",
              )}
              helperButtonCallback={() =>
                processSurveyParams(async (surveyId, projectId) => {
                  navigateTo.surveyAddQuestion(projectId, surveyId);
                }, navigateTo)
              }
            />
          ) : (
            !surveyStore.isRPCLoading && (
              <Stack
                style={{
                  width: "100%",
                  height: "fit-content",
                  flexBasis: "600px",
                  flexGrow: 1,
                }}
              >
                <Stack
                  alignSelf="center"
                  sx={{
                    width: "90%",
                    maxWidth: "1024px",
                    margin: `${spacing.spaceXL} 0px`,
                    border: border.default,
                    borderRadius: borderRadius.radiusXS,
                  }}
                  divider={
                    <DividerComponent width="100%" orientation="horizontal" />
                  }
                >
                  {surveyStore.questionList.map((question, index) => {
                    return (
                      !surveyStore.isChildQuestion(
                        question.surveyQuestionDetails.questionId,
                      ) && (
                        <QuestionnaireListItem
                          key={index}
                          question={question.surveyQuestionDetails.question}
                          questionCode={
                            question.surveyQuestionDetails.questionCode
                          }
                          questionType={
                            question.surveyQuestionDetails.questionType
                          }
                          order={question.surveyQuestionDetails.order}
                          isRuleApplied={question.isRuleApplied}
                          questionId={question.surveyQuestionDetails.questionId}
                          isVisibleByDefault={question.isVisibleByDefault}
                          childQuestions={question.surveyQuestionDetails.childQuestionIds.map(
                            (childQuestionId) => {
                              // We are returning clone of the child question as it is no longer part of the tree when it is being accessed.
                              // Which results in errors being shown in the console.
                              return clone(
                                surveyStore.findQuestionById(childQuestionId),
                              );
                            },
                          )}
                          shadow={shadow}
                          manageQuestionsStore={undefined}
                          questionDetails={question}
                          questionIndex={index}
                        />
                      )
                    );
                  })}
                </Stack>
              </Stack>
            )
          )}
        </Stack>
      );
    };

    return (
      <>
        <QuestionnaireList />
        <LoadingIndicator isLoading={surveyStore.isRPCLoading} />
      </>
    );
  },
);
