import { Stack, Typography } from "@mui/material";
import { QuestionType } from "@pulse/pulse-rpcs";
import {
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
  CornerRadius,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { QuestionItemOrder } from "../../../surveys/components/questionItemComponents/QuestionItemOrder";
import { BorderStyle } from "@pulse/shared-components";
import {
  getQuestionTypeIcon,
  getQuestionTypeTypography,
} from "../../../utils/QuestionTypeIconAndTypographyUtils";
import { TFunction } from "i18next";
import { DividerComponent } from "../../../surveys/components/commonQuestionConfigurationDetailsComponents/DividerComponent";

interface QuestionTypeHeaderProps {
  questionOrder: number;
  questionType: QuestionType.QuestionType;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  border: BorderStyle;
  typography: LeoTypography;
  cornerRadius: CornerRadius;
  t: TFunction;
}

export const QuestionTypeHeader = ({
  spacing,
  border,
  tokens,
  typography,
  cornerRadius,
  questionOrder,
  questionType,
  t,
}: QuestionTypeHeaderProps): React.ReactElement => {
  return (
    <Stack direction="row" gap={spacing.spaceXS}>
      <QuestionItemOrder
        border={border}
        tokens={tokens}
        spacing={spacing}
        cornerRadius={cornerRadius}
        typography={typography}
        isItemSelected={false}
        order={questionOrder}
      />
      <Typography {...typography.b2} color={tokens.labelLowEmphasis}>
        {getQuestionTypeTypography(questionType, t)}
      </Typography>
      <DividerComponent orientation="vertical" height="100%" />
      <Stack direction="row" gap={spacing.spaceXXS}>
        {getQuestionTypeIcon(questionType, tokens.iconLowEmphasis)}
        <Typography {...typography.b2} color={tokens.labelLowEmphasis}>
          {getQuestionTypeTypography(questionType, t)}
        </Typography>
      </Stack>
    </Stack>
  );
};
