import { APIClient } from "@surya-digital/tedwig";
import { cast, clone, flow, getRoot, Instance, types } from "mobx-state-tree";
import { getAPIClient } from "../../networking/APIClient";
import {
  GetDashboardViewAndProjectSpocsRPC,
  ManageDashboardViewSpocsRPC,
} from "@pulse/pulse-rpcs";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import {
  useGetDashboardViewAndProjectSpocsRPCClientImpl,
  useManageDashboardViewSpocsRPCClientImpl,
} from "../rpcs/RPC";
import {
  createSpocUserDetailsModel,
  SpocUserDetailsModel,
} from "../../models/SpocUserDetailsModel";
import { CommonErrors, EMPTY_LIST_LENGTH } from "@pulse/shared-components";
import { RootStore } from "../../root/store/RootStore";
import { UnexpectedError } from "../../utils/constants";

export enum ManageSurveyViewSPoCsDialogState {
  Loading = "LOADING",
  ManageSurveyViewSPoCs = "MANAGE_SURVEY_VIEW_SPOCS",
  Error = "ERROR",
}

export const ManageSurveyViewSPoCsModel = types
  .model("ManageSurveyViewSPoCsModel", {
    surveyViewId: types.string,
    isAccessibleByProjectSPoCs: types.optional(types.boolean, false),
    updatedIsAccessibleByProjectSPoCs: types.optional(types.boolean, false),
    projectSPoCs: types.array(SpocUserDetailsModel),
    selectedSPoCs: types.array(SpocUserDetailsModel),
    updatedSelectedSPoCs: types.array(SpocUserDetailsModel),
    rpcError: types.maybeNull(
      types.union(
        types.enumeration(
          Object.values(GetDashboardViewAndProjectSpocsRPC.RPCError),
        ),
        types.enumeration(Object.values(ManageDashboardViewSpocsRPC.RPCError)),
        types.enumeration(Object.values(UnexpectedError)),
      ),
    ),
    ManageSurveyViewSPoCsDialogState: types.optional(
      types.enumeration(Object.values(ManageSurveyViewSPoCsDialogState)),
      ManageSurveyViewSPoCsDialogState.Loading,
    ),
  })
  .views((store) => ({
    get isNoSPoCSelected(): boolean {
      return store.updatedSelectedSPoCs.length === EMPTY_LIST_LENGTH;
    },
    get isManageSurveyViewSPoCsDialogPrimaryButtonDisabled(): boolean {
      return (
        store.isAccessibleByProjectSPoCs ===
          store.updatedIsAccessibleByProjectSPoCs &&
        JSON.stringify(store.selectedSPoCs) ===
          JSON.stringify(store.updatedSelectedSPoCs)
      );
    },
    get nonSelectedProjectSpocs(): Instance<typeof SpocUserDetailsModel>[] {
      // Returns the list of SPoCs which have not been selected.
      return store.projectSPoCs.filter((projectSpoc) => {
        return (
          store.updatedSelectedSPoCs.find((selectedSPoC) => {
            return projectSpoc.spocId === selectedSPoC.spocId;
          }) === undefined
        );
      });
    },
    get viewAccessType(): ManageDashboardViewSpocsRPC.RequestEnums.ViewAccessType.ViewAccessType {
      if (store.updatedIsAccessibleByProjectSPoCs) {
        return new ManageDashboardViewSpocsRPC.RequestEnums.ViewAccessType.Project();
      } else {
        return new ManageDashboardViewSpocsRPC.RequestEnums.ViewAccessType.Spocs(
          store.updatedSelectedSPoCs.map((updatedSelectedSPoC) => {
            return new LeoUUID(updatedSelectedSPoC.spocId);
          }),
        );
      }
    },
    get doesStoreContainError(): boolean {
      return store.rpcError !== null;
    },
  }))
  .actions((store) => ({
    resetStore: (): void => {
      store.isAccessibleByProjectSPoCs = false;
      store.updatedIsAccessibleByProjectSPoCs = false;
      store.projectSPoCs.clear();
      store.selectedSPoCs.clear();
      store.updatedSelectedSPoCs.clear();
      store.rpcError = null;
      store.ManageSurveyViewSPoCsDialogState =
        ManageSurveyViewSPoCsDialogState.Loading;
    },
  }))
  .actions((store) => ({
    setIsAccessibleByProjectSPoCs: (): void => {
      store.updatedIsAccessibleByProjectSPoCs =
        !store.updatedIsAccessibleByProjectSPoCs;
    },
    addSelectedSpoc: (selectedSpocId: string): void => {
      const selectedSPoC = clone(
        store.projectSPoCs.find((projectSpoc) => {
          return projectSpoc.spocId === selectedSpocId;
        }),
      );
      if (selectedSPoC) {
        store.updatedSelectedSPoCs.push(selectedSPoC);
      } else {
        console.error(`${selectedSpocId} is not present in project SPoCs.`);
      }
    },
    removeSelectedSPoC: (selectedSpocId: string): void => {
      const spocToBeRemoved = store.updatedSelectedSPoCs.find(
        (selectedSPoC) => {
          return selectedSPoC.spocId === selectedSpocId;
        },
      );
      if (
        spocToBeRemoved &&
        store.updatedSelectedSPoCs.includes(spocToBeRemoved)
      ) {
        store.updatedSelectedSPoCs.splice(
          store.updatedSelectedSPoCs.indexOf(spocToBeRemoved),
          1,
        );
      } else {
        console.error(
          `Invalid SPoC id ${selectedSpocId} found while removing SPoC.`,
        );
      }
    },
    getDashboardViewAndProjectSpocs: flow(function* (
      surveyId: string,
      projectId: string,
    ) {
      const errorStore =
        getRoot<typeof RootStore>(store).networkingStore.errorStore;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new GetDashboardViewAndProjectSpocsRPC.Request(
          new LeoUUID(projectId),
          new LeoUUID(surveyId),
          new LeoUUID(store.surveyViewId),
        );
        const {
          response,
          error,
        }: {
          response?: GetDashboardViewAndProjectSpocsRPC.Response;
          error?: GetDashboardViewAndProjectSpocsRPC.Errors.Errors;
        } =
          yield useGetDashboardViewAndProjectSpocsRPCClientImpl(
            apiClient,
          ).execute(request);
        if (response) {
          store.isAccessibleByProjectSPoCs =
            response.isAccessibleByProjectSPoCs;
          store.updatedIsAccessibleByProjectSPoCs =
            response.isAccessibleByProjectSPoCs;
          store.projectSPoCs = cast(
            response.projectSPoCUserDetails.map((spocUserDetails) => {
              return createSpocUserDetailsModel(spocUserDetails);
            }),
          );
          store.selectedSPoCs = cast(
            response.selectedSPoCUserDetails.map((spocUserDetails) => {
              return createSpocUserDetailsModel(spocUserDetails);
            }),
          );
          store.updatedSelectedSPoCs = cast(
            response.selectedSPoCUserDetails.map((spocUserDetails) => {
              return createSpocUserDetailsModel(spocUserDetails);
            }),
          );
          store.ManageSurveyViewSPoCsDialogState =
            ManageSurveyViewSPoCsDialogState.ManageSurveyViewSPoCs;
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              // These errors are caught and handled in defaultResponseInterceptors.
              return;
            case GetDashboardViewAndProjectSpocsRPC.RPCError
              .InvalidSurveyViewId: {
              store.rpcError =
                GetDashboardViewAndProjectSpocsRPC.RPCError.InvalidSurveyViewId;
              break;
            }
            case GetDashboardViewAndProjectSpocsRPC.RPCError
              .ProjectAlreadyArchived: {
              store.rpcError =
                GetDashboardViewAndProjectSpocsRPC.RPCError.ProjectAlreadyArchived;
              break;
            }
            default: {
              console.error(
                `Unhandled error ${error.code} from GetDashboardViewAndProjectSpocsRPC`,
              );
              store.rpcError = UnexpectedError.UnhandledError;
              break;
            }
          }
          store.ManageSurveyViewSPoCsDialogState =
            ManageSurveyViewSPoCsDialogState.Error;
        }
      } catch (e) {
        if (e instanceof Error) {
          errorStore.setLeoError(e);
        } else {
          console.error(
            `Unhandled error ${e} in createView action in AddViewStore`,
          );
        }
      }
    }),
    manageDashboardViewSpocsRPC: flow(function* (
      surveyId: string,
      projectId: string,
    ) {
      const errorStore =
        getRoot<typeof RootStore>(store).networkingStore.errorStore;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new ManageDashboardViewSpocsRPC.Request(
          new LeoUUID(projectId),
          new LeoUUID(surveyId),
          new LeoUUID(store.surveyViewId),
          store.viewAccessType,
        );
        const {
          response,
          error,
        }: {
          response?: ManageDashboardViewSpocsRPC.Response;
          error?: ManageDashboardViewSpocsRPC.Errors.Errors;
        } =
          yield useManageDashboardViewSpocsRPCClientImpl(apiClient).execute(
            request,
          );
        if (response) {
          return response.surveyDetailsAndDashboardViews.surveyDashboardViews;
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              // These errors are caught and handled in defaultResponseInterceptors.
              return;
            case ManageDashboardViewSpocsRPC.RPCError.InvalidSurveyViewId: {
              store.rpcError =
                ManageDashboardViewSpocsRPC.RPCError.InvalidSurveyViewId;
              break;
            }
            case ManageDashboardViewSpocsRPC.RPCError.ProjectAlreadyArchived: {
              store.rpcError =
                ManageDashboardViewSpocsRPC.RPCError.ProjectAlreadyArchived;
              break;
            }
            case ManageDashboardViewSpocsRPC.RPCError.InvalidSpocId: {
              store.rpcError =
                ManageDashboardViewSpocsRPC.RPCError.InvalidSpocId;
              break;
            }
            case ManageDashboardViewSpocsRPC.RPCError
              .MasterSurveyViewCannotBeEdited: {
              store.rpcError =
                ManageDashboardViewSpocsRPC.RPCError.MasterSurveyViewCannotBeEdited;
              break;
            }
            default: {
              console.error(
                `Unhandled error ${error.code} from GetDashboardViewAndProjectSpocsRPC`,
              );
              store.rpcError = UnexpectedError.UnhandledError;
              break;
            }
          }
          store.ManageSurveyViewSPoCsDialogState =
            ManageSurveyViewSPoCsDialogState.Error;
        }
      } catch (e) {
        if (e instanceof Error) {
          errorStore.setLeoError(e);
        } else {
          console.error(
            `Unhandled error ${e} in createView action in AddViewStore`,
          );
        }
      }
    }),
  }));

export const createManageSurveyViewSPoCsModel = (
  surveyViewId: string,
): Instance<typeof ManageSurveyViewSPoCsModel> => {
  return ManageSurveyViewSPoCsModel.create({ surveyViewId });
};
