import React from "react";
import {
  NavigateToFunctions,
  processSurveyParams,
} from "../../../../routes/RoutesHelper";
import { Instance } from "mobx-state-tree";
import { SingleSelectDropdownOptionsUploadStore } from "../../store/SingleSelectDropdownOptionsUploadStore";
import { TFunction } from "i18next";
import {
  Banner,
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
  Button,
} from "@surya-digital/leo-reactjs-material-ui";
import { Stack, Typography } from "@mui/material";
import { AlertCircle, Download } from "lucide-react";
import { FileUploadComponent } from "../../../../components/fileUpload/FileUploadComponent";
import {
  EMPTY_CHARACTER,
  getIconProps,
  ICON_SIZE,
  MAX_SINGLE_SELECT_DROPDOWN_OPTIONS_FILE_SIZE_IN_BYTES,
  MIN_SINGLE_SELECT_DROPDOWN_OPTIONS_FILE_SIZE_IN_BYTES,
} from "@pulse/shared-components";
import { getUploadErrorDescriptions } from "../../../utils/FileUploadUtils";
import { UploadValidationLoader } from "../../../../components/fileUpload/UploadValidationLoader";
import { observer } from "mobx-react";
import {
  SingleChoiceOption,
  ValidateCSVAndUpdateQuestionOptionsRPC,
} from "@pulse/pulse-rpcs";

interface OptionsUploadPaneProps {
  navigateTo: NavigateToFunctions;
  t: TFunction;
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  singleSelectDropdownOptionsUploadStore: Instance<
    typeof SingleSelectDropdownOptionsUploadStore
  >;
  questionId: string | null;
  updateOptions: (options: SingleChoiceOption[]) => void;
}

const OptionsUploadPane = observer(
  ({
    spacing,
    t,
    typography,
    singleSelectDropdownOptionsUploadStore,
    tokens,
    navigateTo,
    questionId,
    updateOptions,
  }: OptionsUploadPaneProps): React.ReactElement => {
    const fileUploadHandler = async (file: File): Promise<void> => {
      singleSelectDropdownOptionsUploadStore.setFileName(file.name);
      singleSelectDropdownOptionsUploadStore.resetRPCError();
      await processSurveyParams(async (surveyId, projectId) => {
        singleSelectDropdownOptionsUploadStore.setIsRPCLoading(true);
        await singleSelectDropdownOptionsUploadStore.uploadCSVFile(
          file,
          surveyId,
          projectId,
          questionId,
        );
        await singleSelectDropdownOptionsUploadStore.validateCSVAndUpdateQuestionOptions(
          projectId,
          surveyId,
          questionId,
          updateOptions,
        );
        singleSelectDropdownOptionsUploadStore.setIsRPCLoading(false);
      }, navigateTo);
    };
    const UploadErrors = (): React.ReactElement => {
      const getErrorDescriptions = (): String => {
        if (singleSelectDropdownOptionsUploadStore.validationError) {
          return getUploadErrorDescriptions(
            singleSelectDropdownOptionsUploadStore.validationError,
            t,
            MAX_SINGLE_SELECT_DROPDOWN_OPTIONS_FILE_SIZE_IN_BYTES,
            MIN_SINGLE_SELECT_DROPDOWN_OPTIONS_FILE_SIZE_IN_BYTES,
          );
        }
        return (
          singleSelectDropdownOptionsUploadStore.invalidFileDetails ??
          EMPTY_CHARACTER
        );
      };

      const iconProps = getIconProps(tokens.iconError, ICON_SIZE.default);
      return (
        <Stack spacing={spacing.spaceXXS}>
          <Typography sx={{ ...typography.b2, color: tokens.label }}>
            {singleSelectDropdownOptionsUploadStore.fileName}
          </Typography>
          <Stack gap={spacing.spaceXXS} direction="row">
            {!singleSelectDropdownOptionsUploadStore.invalidFileDetails && (
              <AlertCircle {...iconProps} />
            )}
            <Typography
              sx={{
                ...typography.b2,
                color: tokens.labelError,
                whiteSpace: "pre-line",
              }}
            >
              {getErrorDescriptions()}
            </Typography>
          </Stack>
        </Stack>
      );
    };

    return (
      <Stack spacing={spacing.spaceMD}>
        <FileUploadComponent fileUploadHandler={fileUploadHandler} />
        {singleSelectDropdownOptionsUploadStore.doesStoreContainValidationError && (
          <UploadErrors />
        )}
      </Stack>
    );
  },
);

interface UploadSingleSelectDropdownOptionsDialogChildProps {
  t: TFunction;
  navigateTo: NavigateToFunctions;
  singleSelectDropdownOptionsUploadStore: Instance<
    typeof SingleSelectDropdownOptionsUploadStore
  >;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  questionId: string | null;
  updateOptions: (options: SingleChoiceOption[]) => void;
}

export const UploadSingleSelectDropdownOptionsDialogChild = observer(
  ({
    singleSelectDropdownOptionsUploadStore,
    t,
    spacing,
    tokens,
    typography,
    navigateTo,
    questionId,
    updateOptions,
  }: UploadSingleSelectDropdownOptionsDialogChildProps): React.ReactElement => {
    const getErrorBannerText = (): string | undefined => {
      if (singleSelectDropdownOptionsUploadStore.validationError) {
        return t(
          "surveys.singleSelectDropdownUploadCSV.uploadErrorMessage.invalidFileFormat",
        );
      }
      switch (singleSelectDropdownOptionsUploadStore.rpcError) {
        case ValidateCSVAndUpdateQuestionOptionsRPC.RPCError.InvalidFile: {
          return t(
            "surveys.singleSelectDropdownUploadCSV.uploadErrorMessage.invalidFileFormat",
          );
        }
        default: {
          return undefined;
        }
      }
    };

    return (
      <Stack gap={spacing.spaceLG}>
        {getErrorBannerText() && (
          <Banner
            message={getErrorBannerText() ?? EMPTY_CHARACTER}
            severity="error"
            width="496px"
          />
        )}
        <Stack gap={spacing.spaceSM}>
          <Typography {...typography.b2} color={tokens.labelSubtle}>
            {t("surveys.singleSelectDropdownUploadCSV.description")}
          </Typography>
          <Typography {...typography.b2} color={tokens.labelSubtle}>
            {t("surveys.singleSelectDropdownUploadCSV.descriptionNote")}
          </Typography>
          <Stack direction={"row"} gap={spacing.spaceSM}>
            <Button
              name="primaryDownload"
              label={t("common.downloadTemplate")}
              icon={<Download />}
              iconPosition="leading"
              size="small"
              variant="outlined-neutral"
              loading={
                singleSelectDropdownOptionsUploadStore.isDownloadTemplateButtonLoading
              }
              onClick={async (): Promise<void> => {
                await singleSelectDropdownOptionsUploadStore.downloadSingleSelectOptionsTemplate();
              }}
            />
          </Stack>
        </Stack>
        {singleSelectDropdownOptionsUploadStore.isRPCLoading ? (
          <UploadValidationLoader
            fileName={singleSelectDropdownOptionsUploadStore.fileName}
            t={t}
            tokens={tokens}
            typography={typography}
            spacing={spacing}
          />
        ) : (
          <OptionsUploadPane
            navigateTo={navigateTo}
            t={t}
            spacing={spacing}
            typography={typography}
            tokens={tokens}
            singleSelectDropdownOptionsUploadStore={
              singleSelectDropdownOptionsUploadStore
            }
            questionId={questionId}
            updateOptions={updateOptions}
          />
        )}
      </Stack>
    );
  },
);
