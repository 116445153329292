import { Instance } from "mobx-state-tree";
import React from "react";
import { Stack, Typography } from "@mui/material";
import { RespondentResponseStore } from "../../store/RespondentResponseStore";
import {
  CornerRadius,
  FoundationColorTokens,
  Typography as LeoTypography,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { RespondentResponses } from "./RespondentResponses";
import { BorderStyle } from "@pulse/shared-components";
import { observer } from "mobx-react";

interface RespondentDetailComponentProps {
  title: string;
  value: string;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
}

const RespondentDetailComponent = ({
  title,
  value,
  typography,
  tokens,
}: RespondentDetailComponentProps): React.ReactElement => {
  return (
    <Stack direction="row">
      <Typography {...typography.s1} color={tokens.label} width="120px">
        {title}
      </Typography>
      <Typography {...typography.b1} color={tokens.label}>
        {value}
      </Typography>
    </Stack>
  );
};

interface RespondentResponseDialogChildProps {
  respondentResponseStore: Instance<typeof RespondentResponseStore>;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  t: TFunction;
  spacing: Spacing;
  border: BorderStyle;
  cornerRadius: CornerRadius;
}

export const RespondentResponseDialogChild = observer(
  ({
    t,
    tokens,
    typography,
    respondentResponseStore,
    spacing,
    border,
    cornerRadius,
  }: RespondentResponseDialogChildProps): React.ReactElement => {
    return (
      <Stack width="100%">
        <Stack gap={spacing.spaceXS} padding={spacing.spaceLG}>
          <RespondentDetailComponent
            title={t("projects.respondentResponseDialog.respondentId")}
            value={`#${respondentResponseStore.respondentId}`}
            tokens={tokens}
            typography={typography}
          />
          <RespondentDetailComponent
            title={t("projects.respondentResponseDialog.endTime")}
            value={`${respondentResponseStore.endTime}`}
            tokens={tokens}
            typography={typography}
          />
          <RespondentDetailComponent
            title={t("projects.respondentResponseDialog.browser")}
            value={`${respondentResponseStore.browser}`}
            tokens={tokens}
            typography={typography}
          />
          <RespondentDetailComponent
            title={t("projects.respondentResponseDialog.deviceType")}
            value={`${respondentResponseStore.deviceType}`}
            tokens={tokens}
            typography={typography}
          />
        </Stack>
        <RespondentResponses
          responses={respondentResponseStore.submittedResponses}
          border={border}
          tokens={tokens}
          cornerRadius={cornerRadius}
          spacing={spacing}
          typography={typography}
          t={t}
        />
      </Stack>
    );
  },
);
