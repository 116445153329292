import { Typography } from "@mui/material";
import {
  FoundationColorTokens,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";

interface S1TypographyLabelProps {
  text: string;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
}

export const S1TypographyLabel = ({
  text,
  tokens,
  typography,
}: S1TypographyLabelProps): React.ReactElement => {
  return (
    <Typography {...typography.s1} color={tokens.label}>
      {text}
    </Typography>
  );
};
