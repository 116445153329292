import React, { ReactElement, useRef } from "react";
import { Instance } from "mobx-state-tree";
import { NPSSingleSelectQuestionModelV1 } from "../models/NPSSingleSelectQuestionModelV1";
import {
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Typography,
  Button,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { BorderStyle } from "@pulse/shared-components";
import { SurveyQuestionViewStoreNames } from "../stores/SurveyViewDetailsStore";
import { ChartSelection, QuestionTypeGraphs } from "./ChartSelection";
import { observer } from "mobx-react";
import { getButtonProps, UpdateQuestionViewParams } from "./QuestionViews";
import { SignInStore } from "../../auth/store/SignInStore";
import { Stack } from "@mui/material";
import { UserPrivileges } from "../../store/user/UserPrivileges";
import { HIDDEN_ELEMENT_WHILE_DOWNLOADING_GRAPH_CLASS_NAME } from "../../utils/constants";
import { GraphDownloadButton } from "./GraphDownloadButton";
import { HeaderQuestionDetails } from "./HeaderQuestionDetails";
import { NPSSingleSelectSelectedGraph } from "./graphs/NPSSingleSelectSelectedGraph";
import { DataProps, DataSection } from "./DataSection";

interface NPSSingleSelectQuestionViewProps {
  surveyQuestionView: Instance<typeof NPSSingleSelectQuestionModelV1>;
  typography: Typography;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
  border: BorderStyle;
  cornerRadius: CornerRadius;
  updateQuestionViewParams?: UpdateQuestionViewParams;
  allowSPoCToSelectGraphType: boolean;
  signInStore: Instance<typeof SignInStore>;
}

export const NPSSingleSelectQuestionView = observer(
  ({
    surveyQuestionView,
    spacing,
    t,
    tokens,
    typography,
    border,
    cornerRadius,
    updateQuestionViewParams,
    allowSPoCToSelectGraphType,
    signInStore,
  }: NPSSingleSelectQuestionViewProps): ReactElement => {
    const gridElementRef = useRef<HTMLDivElement>(null);
    const isChildQuestion = updateQuestionViewParams === undefined;

    const buttonProps = getButtonProps(
      updateQuestionViewParams,
      surveyQuestionView.surveyQuestionViewId,
      surveyQuestionView.questionViewGraphType,
      surveyQuestionView.isGraphSelectionUpdated,
      surveyQuestionView.updateSelectedGraphTypeWithUpdatedGraph,
    );
    const isProcessSurveyDashboardViewsPrivilegeGranted =
      signInStore.isPrivilegeGranted(
        UserPrivileges.processSurveyDashboardViews,
      );
    const isChartSelectionVisible =
      allowSPoCToSelectGraphType ||
      isProcessSurveyDashboardViewsPrivilegeGranted;

    const barChartData: DataProps[] =
      surveyQuestionView.singleSelectNpsResultsBreakdownV1.map(
        (singleSelectNPSResult) => {
          return {
            option: singleSelectNPSResult.answer.code,
            count: singleSelectNPSResult.count,
            percentage: singleSelectNPSResult.percentage,
            id: singleSelectNPSResult.answer.code,
          };
        },
      );

    const proportionData: DataProps[] = [
      {
        option: surveyQuestionView.detractors.answer,
        count: surveyQuestionView.detractors.count,
        percentage: surveyQuestionView.detractors.percentage,
        id: surveyQuestionView.detractors.answer,
      },
      {
        option: surveyQuestionView.passives.answer,
        count: surveyQuestionView.passives.count,
        percentage: surveyQuestionView.passives.percentage,
        id: surveyQuestionView.passives.answer,
      },
      {
        option: surveyQuestionView.promoters.answer,
        count: surveyQuestionView.promoters.count,
        percentage: surveyQuestionView.promoters.percentage,
        id: surveyQuestionView.promoters.answer,
      },
    ];

    return (
      <Stack
        width={isChildQuestion ? "100%" : "1024px"}
        alignItems="center"
        border={isChildQuestion ? undefined : border.default}
        borderRadius={cornerRadius.radiusXS}
        gap={spacing.spaceXL}
        paddingBottom={spacing.space2XL}
        bgcolor={tokens.background}
        ref={gridElementRef}
      >
        <Stack
          padding={
            isChildQuestion
              ? spacing.spaceSM
              : `${spacing.spaceMD} ${spacing.spaceXL}`
          }
          gap={isChildQuestion ? "0px" : spacing.spaceSM}
          width="100%"
          borderBottom={border.default}
        >
          {
            <Stack direction="row">
              <HeaderQuestionDetails
                questionText={surveyQuestionView.question}
                sampleBreakdown={surveyQuestionView.sampleBreakdown}
                typography={typography}
                tokens={tokens}
                t={t}
                spacing={spacing}
                surveyQuestionViewType={
                  SurveyQuestionViewStoreNames.NPS_SINGLE_SELECT_QUESTION_MODEL
                }
                isQuestionTypeIconVisible={true}
              />
              {isChildQuestion && isChartSelectionVisible && (
                <ChartSelection
                  border={border}
                  cornerRadius={cornerRadius}
                  spacing={spacing}
                  surveyQuestionViewType={
                    SurveyQuestionViewStoreNames.NPS_SINGLE_SELECT_QUESTION_MODEL
                  }
                  selectedGraph={
                    QuestionTypeGraphs[
                      surveyQuestionView.updatedSingleSelectNPSGraphType
                    ]
                  }
                  onChartIconClick={surveyQuestionView.updateSelectedGraphType}
                  tokens={tokens}
                />
              )}
            </Stack>
          }
          <Stack
            direction="row"
            justifyContent={
              isChartSelectionVisible ? "space-between" : "flex-end"
            }
          >
            {!isChildQuestion && isChartSelectionVisible && (
              <ChartSelection
                border={border}
                cornerRadius={cornerRadius}
                spacing={spacing}
                surveyQuestionViewType={
                  SurveyQuestionViewStoreNames.NPS_SINGLE_SELECT_QUESTION_MODEL
                }
                selectedGraph={
                  QuestionTypeGraphs[
                    surveyQuestionView.updatedSingleSelectNPSGraphType
                  ]
                }
                onChartIconClick={surveyQuestionView.updateSelectedGraphType}
                tokens={tokens}
              />
            )}
            {buttonProps && !isChildQuestion && (
              <Stack
                gap={spacing.spaceXS}
                direction="row"
                className={HIDDEN_ELEMENT_WHILE_DOWNLOADING_GRAPH_CLASS_NAME}
              >
                <GraphDownloadButton
                  elementRef={gridElementRef}
                  fileName={
                    QuestionTypeGraphs[
                      surveyQuestionView.updatedSingleSelectNPSGraphType
                    ]
                  }
                />
                {isProcessSurveyDashboardViewsPrivilegeGranted && (
                  <Button
                    label={t("common.saveChanges")}
                    onClick={buttonProps.onSaveChangeClick}
                    name="saveChanges"
                    size="small"
                    variant="filled"
                    disabled={buttonProps.isSaveChangeDisabled}
                  />
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
        <Stack padding={spacing.space2XL} gap={spacing.spaceXL} width="100%">
          <NPSSingleSelectSelectedGraph
            selectedGraph={
              QuestionTypeGraphs[
                surveyQuestionView.updatedSingleSelectNPSGraphType
              ]
            }
            proportionData={proportionData}
            barChartData={barChartData}
            cornerRadius={cornerRadius}
            tokens={tokens}
            spacing={spacing}
            typography={typography}
            border={border}
            t={t}
          />
          <DataSection
            spacing={spacing}
            t={t}
            typography={typography}
            tokens={tokens}
            cornerRadius={cornerRadius}
            total={surveyQuestionView.sampleBreakdown.total}
            data={proportionData}
            border={border}
            isColoredSwatchRequired={false}
            isSurveyViewOptionDisplayTextSelected={false}
            isGridTotalPresent={false}
            customOptionHeaders={undefined}
          />
        </Stack>
      </Stack>
    );
  },
);
