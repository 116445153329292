import {
  EMPTY_CHARACTER,
  MAX_USERNAME_CHAR_LIMIT,
  MIN_USERNAME_CHAR_LIMIT,
  REGEX_FOR_EMAIL_VALIDATION,
} from "@pulse/shared-components";
import { Instance, flow, getRoot, types } from "mobx-state-tree";
import { getAPIClient } from "../../networking/APIClient";
import {
  AddSpocToProjectRPC,
  KeycloakEmail,
  UserName,
} from "@pulse/pulse-rpcs";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import { useAddSpocToProjectRPCClientImpl } from "../rpcs/RPC";
import { RootStore } from "../../root/store/RootStore";
import { DatePickerInput } from "@surya-digital/leo-reactjs-material-ui";
import { getFormattedLeoDate } from "../../utils/DateUtils";
import { FIRST_AND_LAST_NAME_REGEX } from "@pulse/shared-components";

export enum AddNewSPoCRPCError {
  InvalidProjectId = "INVALID_PROJECT_ID",
  ProjectAlreadyArchived = "PROJECT_ALREADY_ARCHIVED",
  MaxSPoCReached = "MAX_SPOC_REACHED",
  SPoCAlreadyExists = "SPOC_ALREADY_EXISTS",
  InvalidExpiryDate = "INVALID_EXPIRY_DATE",
  BOUCannotBeSPoC = "BOU_CANNOT_BE_SPOC",
  UnableToAddSPoC = "UNABLE_TO_ADD_SPOC",
}

export enum AddNewSPoCDialogState {
  AddNewSPoCState = "ADD_NEW_SPOC_STATE",
  ErrorState = "ERROR_STATE",
  SuccessState = "SUCCESS_STATE",
}

export enum ExpiryDateFieldError {
  InvalidDate = "INVALID_DATE",
  InvalidExpiryDate = "INVALID_EXPIRY_DATE",
}

export const AddNewSPoCStore = types
  .model("AddNewSPoCStore", {
    emailAddress: types.optional(types.string, EMPTY_CHARACTER),
    firstName: types.optional(types.string, EMPTY_CHARACTER),
    lastName: types.optional(types.string, EMPTY_CHARACTER),
    expiryDate: types.maybe(types.Date),
    rpcError: types.maybeNull(
      types.enumeration(
        "AddNewSPoCRPCError",
        Object.values(AddNewSPoCRPCError),
      ),
    ),
    isRPCLoading: types.optional(types.boolean, false),
    addNewSPoCDialogState: types.optional(
      types.enumeration(
        "AddNewSPoCDialogState",
        Object.values(AddNewSPoCDialogState),
      ),
      AddNewSPoCDialogState.AddNewSPoCState,
    ),
    expiryDateFieldError: types.maybeNull(
      types.enumeration(
        "ExpiryDateFieldError",
        Object.values(ExpiryDateFieldError),
      ),
    ),
    isAddSPoCButtonClicked: types.optional(types.boolean, false),
  })
  .views((store) => ({
    get isPrimaryButtonDisabled(): boolean {
      return (
        store.emailAddress === EMPTY_CHARACTER ||
        store.firstName === EMPTY_CHARACTER ||
        store.lastName === EMPTY_CHARACTER ||
        store.expiryDate === undefined
      );
    },
    get datePickerInput(): DatePickerInput {
      return store.expiryDate ? new Date(store.expiryDate) : null;
    },
    get isPrimaryButtonVisible(): boolean {
      return (
        (store.rpcError === null ||
          store.rpcError === AddNewSPoCRPCError.InvalidExpiryDate) &&
        store.addNewSPoCDialogState === AddNewSPoCDialogState.AddNewSPoCState
      );
    },
    get isDialogInErrorState(): boolean {
      return store.addNewSPoCDialogState === AddNewSPoCDialogState.ErrorState;
    },
    get isExpiryDateFieldErrored(): boolean {
      return store.expiryDateFieldError !== null;
    },
    get isDialogInSuccessState(): boolean {
      return store.addNewSPoCDialogState === AddNewSPoCDialogState.SuccessState;
    },
    get isEmailAddressInvalid(): boolean {
      try {
        new KeycloakEmail(store.emailAddress);
        const regex = new RegExp(REGEX_FOR_EMAIL_VALIDATION);
        if (!regex.test(store.emailAddress)) {
          return true && store.isAddSPoCButtonClicked;
        }
        return false;
      } catch (e) {
        return true && store.isAddSPoCButtonClicked;
      }
    },
    get isFirstNameInvalid(): boolean {
      const userNameRegex = new RegExp(FIRST_AND_LAST_NAME_REGEX);
      return (
        (store.firstName.length > MAX_USERNAME_CHAR_LIMIT ||
          store.firstName.length < MIN_USERNAME_CHAR_LIMIT ||
          !userNameRegex.test(store.firstName)) &&
        store.isAddSPoCButtonClicked
      );
    },
    get isLastNameInvalid(): boolean {
      const userNameRegex = new RegExp(FIRST_AND_LAST_NAME_REGEX);
      return (
        (store.lastName.length > MAX_USERNAME_CHAR_LIMIT ||
          store.lastName.length < MIN_USERNAME_CHAR_LIMIT ||
          !userNameRegex.test(store.lastName)) &&
        store.isAddSPoCButtonClicked
      );
    },
    get refreshTableOnDialogClose(): boolean {
      return (
        store.rpcError === AddNewSPoCRPCError.MaxSPoCReached ||
        store.rpcError === AddNewSPoCRPCError.SPoCAlreadyExists ||
        store.addNewSPoCDialogState === AddNewSPoCDialogState.SuccessState
      );
    },
  }))
  .actions((store) => ({
    resetStore: (): void => {
      store.emailAddress = EMPTY_CHARACTER;
      store.firstName = EMPTY_CHARACTER;
      store.lastName = EMPTY_CHARACTER;
      store.expiryDate = undefined;
      store.rpcError = null;
      store.isRPCLoading = false;
      store.addNewSPoCDialogState = AddNewSPoCDialogState.AddNewSPoCState;
      store.expiryDateFieldError = null;
      store.isAddSPoCButtonClicked = false;
    },
    validateExpiryDate: (): void => {
      if (store.expiryDate === undefined) {
        store.expiryDateFieldError = ExpiryDateFieldError.InvalidExpiryDate;
      }
    },
  }))
  .actions((store) => ({
    setIsAddSPoCButtonClicked: (isAddSPoCButtonClicked: boolean): void => {
      store.isAddSPoCButtonClicked = isAddSPoCButtonClicked;
    },
    setEmailAddress: (emailAddress: string): void => {
      store.isAddSPoCButtonClicked = false;
      store.emailAddress = emailAddress;
    },
    setFirstName: (firstName: string): void => {
      store.isAddSPoCButtonClicked = false;
      store.firstName = firstName;
    },
    setLastName: (lastName: string): void => {
      store.isAddSPoCButtonClicked = false;
      store.lastName = lastName;
    },
    setExpiryDateFieldError: (
      expiryDateFieldError: ExpiryDateFieldError | null,
    ): void => {
      store.isAddSPoCButtonClicked = false;
      store.expiryDateFieldError = expiryDateFieldError;
    },
    setExpiryDate: (expiryDate: DatePickerInput): void => {
      store.expiryDateFieldError = null;
      store.expiryDate = expiryDate
        ? new Date(expiryDate.toDateString())
        : undefined;
    },
    addNewSPoC: flow(function* (projectId: string) {
      store.validateExpiryDate();
      if (
        store.isEmailAddressInvalid ||
        store.isFirstNameInvalid ||
        store.isLastNameInvalid ||
        store.isExpiryDateFieldErrored
      ) {
        return;
      }
      store.isRPCLoading = true;
      try {
        const apiClient = getAPIClient(store);
        const request = new AddSpocToProjectRPC.Request(
          new LeoUUID(projectId),
          new KeycloakEmail(store.emailAddress),
          getFormattedLeoDate(store.expiryDate),
          new UserName(store.firstName, store.lastName),
        );
        const {
          response,
          error,
        }: {
          response?: AddSpocToProjectRPC.Response;
          error?: AddSpocToProjectRPC.Errors.Errors;
        } = yield useAddSpocToProjectRPCClientImpl(apiClient).execute(request);
        if (response) {
          store.addNewSPoCDialogState = AddNewSPoCDialogState.SuccessState;
        } else if (error) {
          switch (error.code) {
            case AddNewSPoCRPCError.InvalidProjectId: {
              break;
            }
            case AddNewSPoCRPCError.InvalidExpiryDate: {
              store.rpcError = AddNewSPoCRPCError.InvalidExpiryDate;
              store.expiryDateFieldError =
                ExpiryDateFieldError.InvalidExpiryDate;
              break;
            }
            case AddNewSPoCRPCError.MaxSPoCReached: {
              store.rpcError = AddNewSPoCRPCError.MaxSPoCReached;
              store.addNewSPoCDialogState = AddNewSPoCDialogState.ErrorState;
              break;
            }
            case AddNewSPoCRPCError.ProjectAlreadyArchived: {
              store.rpcError = AddNewSPoCRPCError.ProjectAlreadyArchived;
              store.addNewSPoCDialogState = AddNewSPoCDialogState.ErrorState;
              break;
            }
            case AddNewSPoCRPCError.SPoCAlreadyExists: {
              store.rpcError = AddNewSPoCRPCError.SPoCAlreadyExists;
              store.addNewSPoCDialogState = AddNewSPoCDialogState.ErrorState;
              break;
            }
            case AddNewSPoCRPCError.BOUCannotBeSPoC: {
              store.rpcError = AddNewSPoCRPCError.BOUCannotBeSPoC;
              store.addNewSPoCDialogState = AddNewSPoCDialogState.ErrorState;
              break;
            }
            case AddNewSPoCRPCError.UnableToAddSPoC: {
              store.rpcError = AddNewSPoCRPCError.UnableToAddSPoC;
              store.addNewSPoCDialogState = AddNewSPoCDialogState.ErrorState;
              break;
            }
            default: {
              store.addNewSPoCDialogState = AddNewSPoCDialogState.ErrorState;
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
  }));

export const createAddNewSPoCStore = (): Instance<typeof AddNewSPoCStore> => {
  return AddNewSPoCStore.create();
};
