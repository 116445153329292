import React, { ReactElement } from "react";
import {
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  BorderStyle,
  DeveloperErrorFlow,
  EMPTY_CHARACTER,
  RGG_PALETTE_COLORS_FOR_SLIDER,
  getHundredthDecimalRoundedPercentageString,
} from "@pulse/shared-components";
import { DataProps } from "../DataSection";
import { ProportionGraph } from "../graphs/ProportionGraph";
import { Stack, Typography } from "@mui/material";
import { TFunction } from "i18next";
import { QuestionTypeGraphs } from "../ChartSelection";
import { BarGraph } from "./BarGraph";

interface NPSProportionChartProps {
  spacing: Spacing;
  cornerRadius: CornerRadius;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  border: BorderStyle;
  proportionData: DataProps[];
  npsScore: string;
  customColours: string[];
  t: TFunction;
}

const NPSProportionChart = ({
  border,
  cornerRadius,
  spacing,
  typography,
  tokens,
  proportionData,
  npsScore,
  customColours,
  t,
}: NPSProportionChartProps): ReactElement => {
  return (
    <Stack>
      <Stack direction="row" spacing={spacing.spaceMD}>
        <Stack width="820px" />
        <Typography
          {...typography.b2}
          color={tokens.label}
          textAlign="center"
          width="72px"
        >
          {t("surveyDashboard.netPromoterScore")}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        width="100%"
        height="fit-content"
        justifyContent="center"
        alignItems="flex-start"
        gap={spacing.spaceSM}
      >
        <ProportionGraph
          data={proportionData}
          isNeutral={false}
          spacing={spacing}
          cornerRadius={cornerRadius}
          tokens={tokens}
          border={border}
          typography={typography}
          isLegendsVisible={false}
          graphWidth={820}
          customColours={customColours}
        />
        <Stack
          height="100%"
          width="100%"
          gap={spacing.spaceSM}
          paddingTop={spacing.space2XL}
        >
          <Stack
            justifyContent="center"
            height="106px"
            width="72px"
            padding={spacing.spaceXS}
            sx={{
              backgroundColor: tokens.backgroundSubtle,
            }}
          >
            <Typography {...typography.sh4} color={tokens.labelHighEmphasis}>
              {npsScore}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

interface GridSelectedGraphProps {
  selectedGraph: QuestionTypeGraphs;
  proportionData: DataProps[];
  barChartData: DataProps[];
  spacing: Spacing;
  cornerRadius: CornerRadius;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  border: BorderStyle;
  t: TFunction;
}

export const NPSSingleSelectSelectedGraph = ({
  selectedGraph,
  proportionData,
  barChartData,
  spacing,
  cornerRadius,
  tokens,
  typography,
  border,
  t,
}: GridSelectedGraphProps): ReactElement => {
  const npsScore = getHundredthDecimalRoundedPercentageString(
    Number(proportionData[2].percentage) - Number(proportionData[0].percentage),
  );

  const customLegendAndProportionColours: string[] = [
    RGG_PALETTE_COLORS_FOR_SLIDER.LEFT_MOST.lightMode.selected,
    RGG_PALETTE_COLORS_FOR_SLIDER.MIDDLE.lightMode.selected,
    RGG_PALETTE_COLORS_FOR_SLIDER.RIGHT_MOST.lightMode.selected,
  ];

  const customBarColours: string[] = [
    ...Array<string>(7).fill(
      RGG_PALETTE_COLORS_FOR_SLIDER.LEFT_MOST.lightMode.selected,
    ),
    ...Array(2).fill(RGG_PALETTE_COLORS_FOR_SLIDER.MIDDLE.lightMode.selected),
    ...Array(2).fill(
      RGG_PALETTE_COLORS_FOR_SLIDER.RIGHT_MOST.lightMode.selected,
    ),
  ];

  switch (selectedGraph) {
    case QuestionTypeGraphs.PROPORTION_WITH_HORIZONTAL_BAR: {
      return (
        <Stack width="100%" gap={spacing.spaceXL}>
          <NPSProportionChart
            border={border}
            cornerRadius={cornerRadius}
            spacing={spacing}
            typography={typography}
            tokens={tokens}
            proportionData={proportionData}
            npsScore={npsScore}
            t={t}
            customColours={customLegendAndProportionColours}
          />
          <BarGraph
            data={barChartData as DataProps[]}
            isHorizontal={true}
            spacing={spacing}
            cornerRadius={cornerRadius}
            tokens={tokens}
            typography={typography}
            border={border}
            isSurveyViewOptionDisplayTextSelected={false}
            legendLabels={proportionData.map(
              (item) => item.option ?? EMPTY_CHARACTER,
            )}
            legendColors={customLegendAndProportionColours}
            customBarColours={customBarColours}
            legendsTextWrap="nowrap"
          />
        </Stack>
      );
    }
    case QuestionTypeGraphs.GAUGE:
    case QuestionTypeGraphs.PROPORTION_WITH_VERTICAL_BAR: {
      return (
        <Stack width="100%" gap={spacing.spaceXL}>
          <NPSProportionChart
            border={border}
            cornerRadius={cornerRadius}
            spacing={spacing}
            typography={typography}
            tokens={tokens}
            proportionData={proportionData}
            npsScore={npsScore}
            customColours={customLegendAndProportionColours}
            t={t}
          />
          <BarGraph
            data={barChartData as DataProps[]}
            isHorizontal={false}
            spacing={spacing}
            cornerRadius={cornerRadius}
            tokens={tokens}
            typography={typography}
            border={border}
            isSurveyViewOptionDisplayTextSelected={false}
            customBarColours={customBarColours}
            legendColors={customLegendAndProportionColours}
            legendLabels={proportionData.map(
              (item) => item.option ?? EMPTY_CHARACTER,
            )}
            legendsTextWrap="nowrap"
          />
        </Stack>
      );
    }
    case QuestionTypeGraphs.NONE: {
      return <></>;
    }
    case QuestionTypeGraphs.PROPORTION:
    case QuestionTypeGraphs.WORD_CLOUD:
    case QuestionTypeGraphs.STACKED_COLUMN:
    case QuestionTypeGraphs.GROUPED_BAR:
    case QuestionTypeGraphs.DONUT:
    case QuestionTypeGraphs.HORIZONTAL_BAR:
    case QuestionTypeGraphs.LINE:
    case QuestionTypeGraphs.LINE_AREA:
    case QuestionTypeGraphs.PIE:
    case QuestionTypeGraphs.VERTICAL_BAR: {
      return <DeveloperErrorFlow />;
    }
  }
};
