import { RadialBar, RadialBarDatum, RadialBarSerie } from "@nivo/radial-bar";
import React, { ReactElement } from "react";
import {
  DASHBOARD_NPS_CHART_FOREGROUND_COLORS,
  QUESTION_VIEW_MAX_WIDTH_IN_PIXELS,
} from "../../../utils/constants";
import { DataProps } from "../DataSection";
import { Stack } from "@mui/material";
import { Legends } from "./Legends";
import {
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";

interface GaugeGraphProps {
  data: DataProps[];
  spacing: Spacing;
  cornerRadius: CornerRadius;
  typography: Typography;
  tokens: FoundationColorTokens<string>;
}

export const GaugeGraph = ({
  data,
  cornerRadius,
  spacing,
  typography,
  tokens,
}: GaugeGraphProps): ReactElement => {
  const optionsColor: string[] = [
    DASHBOARD_NPS_CHART_FOREGROUND_COLORS.DETRACTOR_COLOR,
    DASHBOARD_NPS_CHART_FOREGROUND_COLORS.PASSIVE_COLOR,
    DASHBOARD_NPS_CHART_FOREGROUND_COLORS.PROMOTER_COLOR,
  ];
  const npsScore = Number(data[2].percentage) - Number(data[0].percentage);
  const xKeys = {
    detractorsBackground: "detractorsForeground",
    passivesBackground: "passivesForeground",
    promotersBackground: "promotersForeground",
  };
  const options: RadialBarDatum[] = [
    {
      x: xKeys.detractorsBackground,
      y: 64,
    },
    {
      x: xKeys.passivesBackground,
      y: 18,
    },
    {
      x: xKeys.promotersBackground,
      y: 18,
    },
  ];

  const CustomAxisLabels = (): ReactElement => {
    const scaleValue = (value: number): number => {
      if (value < -100) value = -100;
      if (value > 100) value = 100;
      return (value / 100) * 90;
    };
    const rotateAngle = scaleValue(npsScore);

    // Below cx and cy values are used to position the gauge.
    const cx = 340;
    const cy = 175;

    const pointerWidth = 20;
    const pointerHeight = 90;
    const points = `${cx},${cy - pointerHeight} ${cx - pointerWidth / 2},${cy} ${cx + pointerWidth / 2},${cy}`;

    const thumbRadius = 10;

    return (
      <>
        <text
          x={200}
          y={cy + 30}
          style={{
            ...typography.b2,
            textAnchor: "middle",
            color: tokens.label,
          }}
        >
          {`-100`}
        </text>
        <text
          x={480}
          y={cy + 30}
          style={{
            ...typography.b2,
            textAnchor: "middle",
            color: tokens.label,
          }}
        >
          {`100`}
        </text>
        <polygon
          points={points}
          fill={tokens.label}
          transform={`rotate(${rotateAngle}, ${cx}, ${cy})`}
        />
        <circle cx={cx} cy={cy} r={thumbRadius} fill={tokens.label} />
        <text
          x={cx}
          y={cy + 40}
          style={{
            ...typography.h5,
            textAnchor: "middle",
            color: tokens.label,
          }}
        >
          {`${Math.round(npsScore)}`}
        </text>
      </>
    );
  };

  const transformedData: RadialBarSerie<RadialBarDatum>[] = [
    {
      id: "transformedData",
      data: options,
    },
  ];
  return (
    <Stack width={`${QUESTION_VIEW_MAX_WIDTH_IN_PIXELS}px`}>
      <RadialBar
        data={transformedData}
        innerRadius={0.5}
        height={250}
        width={QUESTION_VIEW_MAX_WIDTH_IN_PIXELS}
        margin={{
          bottom: -120,
          left: 120,
          right: 120,
          top: 0,
        }}
        colors={optionsColor}
        startAngle={270}
        endAngle={450}
        enableRadialGrid={false}
        radialAxisStart={null}
        circularAxisOuter={null}
        tooltip={() => {
          return <></>;
        }}
        layers={[CustomAxisLabels, "tracks", "bars"]}
      />
      <Legends
        legends={data.map((item) => item.id)}
        spacing={spacing}
        colors={Object.entries(DASHBOARD_NPS_CHART_FOREGROUND_COLORS).map(
          ([_key, value]) => value,
        )}
        cornerRadius={cornerRadius}
        isSurveyViewOptionDisplayTextSelected={true}
        tokens={tokens}
        typography={typography}
      />
    </Stack>
  );
};
