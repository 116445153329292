import {
  Button,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { DownloadDashboardViewPDFDialog } from "./DownloadDashboardViewPDFDialog";
import { Instance } from "mobx-state-tree";
import { DownloadDashboardViewPDFStore } from "../stores/DownloadDashboardViewPDFStore";
import { NavigateToFunctions } from "../../../routes/RoutesHelper";
import { TableFilter } from "@pulse/pulse-rpcs";

interface DownloadDashboardViewProps {
  t: TFunction;
  viewName: string | null;
  downloadDashboardViewPDFStore: Instance<typeof DownloadDashboardViewPDFStore>;
  navigateTo: NavigateToFunctions;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  tableFilters: TableFilter[];
  isDownloadDashboardViewPDFButtonDisabled: boolean;
}

export const DownloadDashboardViewPDF = observer(
  ({
    t,
    downloadDashboardViewPDFStore,
    navigateTo,
    viewName,
    spacing,
    tokens,
    typography,
    tableFilters,
    isDownloadDashboardViewPDFButtonDisabled,
  }: DownloadDashboardViewProps): React.ReactElement => {
    const [
      isDownloadDashboardViewPDFDialogOpen,
      setIsDownloadDashboardViewPDFDialogOpen,
    ] = useState(false);
    return (
      <>
        {isDownloadDashboardViewPDFDialogOpen && (
          <DownloadDashboardViewPDFDialog
            t={t}
            isDownloadDashboardViewPDFDialogOpen={
              isDownloadDashboardViewPDFDialogOpen
            }
            setIsDownloadDashboardViewPDFDialogOpen={
              setIsDownloadDashboardViewPDFDialogOpen
            }
            downloadDashboardViewPDFStore={downloadDashboardViewPDFStore}
            navigateTo={navigateTo}
            viewName={viewName}
            spacing={spacing}
            tokens={tokens}
            typography={typography}
            tableFilters={tableFilters}
          />
        )}
        <Button
          name="downloadPDF"
          variant="outlined-neutral"
          size="small"
          label={t("surveyDashboard.downloadPDF")}
          onClick={(): void => {
            setIsDownloadDashboardViewPDFDialogOpen(true);
          }}
          disabled={isDownloadDashboardViewPDFButtonDisabled}
        />
      </>
    );
  },
);
