import { Stack } from "@mui/material";
import React, { ReactElement, useRef } from "react";
import { DataProps, DataPropsForRanking, DataSection } from "./DataSection";
import { TFunction } from "i18next";
import {
  Button,
  CornerRadius,
  FoundationColorTokens,
  Shadow,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { BorderStyle } from "@pulse/shared-components";
import { HeaderQuestionDetails } from "./HeaderQuestionDetails";
import { QuestionTypeGraphs, ChartSelection } from "./ChartSelection";
import { Instance } from "mobx-state-tree";
import { SampleBreakdownModel } from "../models/SampleBreakdownModel";
import { SurveyQuestionViewStoreNames } from "../stores/SurveyViewDetailsStore";
import { observer } from "mobx-react";
import { SelectedGraph } from "./SelectedGraph";
import { MeanComponent } from "./MeanComponent";
import { HIDDEN_ELEMENT_WHILE_DOWNLOADING_GRAPH_CLASS_NAME } from "../../utils/constants";
import { GraphDownloadButton } from "./GraphDownloadButton";
import { OpenEndedResponsesModel } from "../models/OpenEndedResponsesModel";
import { NavigateToFunctions } from "../../../routes/RoutesHelper";
import {
  GetPaginatedOpenEndedResponsesRPC,
  TableFilter,
} from "@pulse/pulse-rpcs";
import { SignInStore } from "../../auth/store/SignInStore";
import { UserPrivileges } from "../../store/user/UserPrivileges";

export interface ButtonProps {
  onSaveChangeClick: () => void;
  isSaveChangeDisabled: boolean;
}

export interface MeanProps {
  mean: number | undefined;
  optionCodes: number[];
  singleSelectPrimitiveQuartileColor: string | null;
}

export interface TopNWordsProps {
  selectedTopNWords: number;
  setSelectedTopNWords: (topNWords: number) => void;
  openEndedResponsesStore: Instance<typeof OpenEndedResponsesModel>;
  navigateTo: NavigateToFunctions;
  surveyQuestionViewId: string;
  tableFilter: TableFilter[];
  openEndedQuestionType: GetPaginatedOpenEndedResponsesRPC.RequestEnums.OpenEndedQuestionType.OpenEndedQuestionType;
}

interface QuestionViewProps {
  t: TFunction;
  spacing: Spacing;
  typography: Typography;
  tokens: FoundationColorTokens<string>;
  cornerRadius: CornerRadius;
  border: BorderStyle;
  questionText: string | null;
  sampleBreakdown: Instance<typeof SampleBreakdownModel> | undefined;
  surveyQuestionViewType: SurveyQuestionViewStoreNames;
  data: DataProps[] | DataPropsForRanking[];
  total: number | null;
  selectedGraph: QuestionTypeGraphs;
  updateSelectedGraphType: (graphType: QuestionTypeGraphs) => void;
  buttonProps?: ButtonProps;
  meanProps?: MeanProps;
  boxShadow: Shadow;
  isChildQuestion: boolean;
  naOptionCode?: string | null;
  isSurveyViewOptionDisplayTextSelected: boolean;
  allowSPoCToSelectGraphType: boolean;
  signInStore: Instance<typeof SignInStore>;
}

export const QuestionView = observer(
  ({
    spacing,
    t,
    tokens,
    typography,
    cornerRadius,
    border,
    questionText,
    sampleBreakdown,
    surveyQuestionViewType,
    data,
    total,
    selectedGraph,
    updateSelectedGraphType,
    buttonProps,
    meanProps,
    isChildQuestion,
    isSurveyViewOptionDisplayTextSelected,
    allowSPoCToSelectGraphType,
    signInStore,
    naOptionCode = null,
  }: QuestionViewProps): ReactElement => {
    const graphElementRef = useRef<HTMLDivElement>(null);
    const isProcessSurveyDashboardViewsPrivilegeGranted =
      signInStore.isPrivilegeGranted(
        UserPrivileges.processSurveyDashboardViews,
      );
    const isChartSelectionVisible =
      allowSPoCToSelectGraphType ||
      isProcessSurveyDashboardViewsPrivilegeGranted;
    return (
      <Stack
        width={isChildQuestion ? "100%" : "1024px"}
        alignItems="center"
        border={isChildQuestion ? undefined : border.default}
        borderRadius={cornerRadius.radiusXS}
        gap={spacing.spaceXL}
        paddingBottom={spacing.space2XL}
        bgcolor={tokens.background}
        ref={graphElementRef}
      >
        <Stack
          padding={
            isChildQuestion
              ? spacing.spaceSM
              : `${spacing.spaceMD} ${spacing.spaceXL}`
          }
          gap={isChildQuestion ? "0px" : spacing.spaceSM}
          width="100%"
          borderBottom={border.default}
        >
          {
            <Stack direction="row">
              <HeaderQuestionDetails
                questionText={questionText}
                sampleBreakdown={sampleBreakdown}
                typography={typography}
                tokens={tokens}
                t={t}
                spacing={spacing}
                surveyQuestionViewType={surveyQuestionViewType}
                isQuestionTypeIconVisible={true}
              />
              {isChildQuestion && isChartSelectionVisible && (
                <ChartSelection
                  border={border}
                  cornerRadius={cornerRadius}
                  spacing={spacing}
                  surveyQuestionViewType={surveyQuestionViewType}
                  selectedGraph={selectedGraph}
                  onChartIconClick={updateSelectedGraphType}
                  tokens={tokens}
                />
              )}
            </Stack>
          }
          <Stack
            direction="row"
            justifyContent={
              isChartSelectionVisible ? "space-between" : "flex-end"
            }
          >
            {!isChildQuestion && isChartSelectionVisible && (
              <ChartSelection
                border={border}
                cornerRadius={cornerRadius}
                spacing={spacing}
                surveyQuestionViewType={surveyQuestionViewType}
                selectedGraph={selectedGraph}
                onChartIconClick={updateSelectedGraphType}
                tokens={tokens}
              />
            )}
            {buttonProps && !isChildQuestion && (
              <Stack
                gap={spacing.spaceXS}
                direction="row"
                className={HIDDEN_ELEMENT_WHILE_DOWNLOADING_GRAPH_CLASS_NAME}
              >
                <GraphDownloadButton
                  elementRef={graphElementRef}
                  fileName={selectedGraph}
                />
                {isProcessSurveyDashboardViewsPrivilegeGranted && (
                  <Button
                    label={t("common.saveChanges")}
                    onClick={buttonProps.onSaveChangeClick}
                    name="saveChanges"
                    size="small"
                    variant="filled"
                    disabled={buttonProps.isSaveChangeDisabled}
                  />
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
        <Stack
          padding={spacing.space2XL}
          gap={spacing.spaceXL}
          width="100%"
          alignItems="center"
        >
          <SelectedGraph
            selectedGraph={selectedGraph}
            data={data}
            isNeutral={meanProps?.mean === undefined}
            cornerRadius={cornerRadius}
            tokens={tokens}
            spacing={spacing}
            typography={typography}
            border={border}
            naOptionCode={naOptionCode}
            t={t}
            isSurveyViewOptionDisplayTextSelected={
              isSurveyViewOptionDisplayTextSelected
            }
          />
          {meanProps?.mean && (
            <MeanComponent
              spacing={spacing}
              t={t}
              tokens={tokens}
              cornerRadius={cornerRadius}
              typography={typography}
              meanProps={meanProps}
            />
          )}
          <DataSection
            spacing={spacing}
            t={t}
            typography={typography}
            tokens={tokens}
            cornerRadius={cornerRadius}
            total={total}
            data={data as DataProps[]}
            border={border}
            isColoredSwatchRequired={
              meanProps === undefined &&
              surveyQuestionViewType !==
                SurveyQuestionViewStoreNames.OPEN_ENDED_QUESTION_MODEL &&
              surveyQuestionViewType !==
                SurveyQuestionViewStoreNames.NPS_SINGLE_SELECT_QUESTION_MODEL &&
              surveyQuestionViewType !==
                SurveyQuestionViewStoreNames.RANKING_QUESTION_MODEL
            }
            customOptionHeaders={undefined}
            isSurveyViewOptionDisplayTextSelected={
              isSurveyViewOptionDisplayTextSelected
            }
          />
        </Stack>
      </Stack>
    );
  },
);
