import { Checkbox, Divider, Stack, Typography } from "@mui/material";
import {
  BorderStyle,
  checkBoxRadioProps,
  EMPTY_CHARACTER,
  getIconProps,
  ICON_SIZE,
} from "@pulse/shared-components";
import {
  AutoCompleteInputField,
  ListX,
  Spacing,
  Typography as LeoTypography,
  FoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import React, { ReactElement, useRef, useState } from "react";
import { ManageSurveyViewSPoCsModel } from "../../models/ManageSurveyViewSPoCsModel";
import { SelectedProjectSPoCList } from "../../../projects/components/spocManagement/SelectedProjectSPoCList";
import { observer } from "mobx-react";

interface AccessibleByProjectSPoCsProps {
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  t: TFunction;
  typography: LeoTypography;
  manageSurveyViewSPoCs: Instance<typeof ManageSurveyViewSPoCsModel>;
}

const AccessibleByProjectSPoCs = observer(
  ({
    manageSurveyViewSPoCs,
    spacing,
    tokens,
    t,
    typography,
  }: AccessibleByProjectSPoCsProps): ReactElement => {
    return (
      <Stack
        direction="row"
        gap={spacing.spaceSM}
        alignItems="center"
        onClick={manageSurveyViewSPoCs.setIsAccessibleByProjectSPoCs}
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <Checkbox
          checked={manageSurveyViewSPoCs.updatedIsAccessibleByProjectSPoCs}
          sx={{
            ...checkBoxRadioProps(tokens.iconPrimary, tokens.iconPrimary),
          }}
          disableRipple={true}
        />
        <Stack>
          <Typography {...typography.b1} color={tokens.label}>
            {t("surveyDashboard.accessibleByProjectSPoCs")}
          </Typography>
          <Typography {...typography.b3} color={tokens.labelSubtle}>
            {t("surveyDashboard.accessibleByProjectSPoCsDescription")}
          </Typography>
        </Stack>
      </Stack>
    );
  },
);

interface SPoCSelectionProps {
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  t: TFunction;
  typography: LeoTypography;
  manageSurveyViewSPoCs: Instance<typeof ManageSurveyViewSPoCsModel>;
  border: BorderStyle;
  isRPCLoading: boolean;
}

const SPoCSelection = observer(
  ({
    manageSurveyViewSPoCs,
    border,
    t,
    spacing,
    tokens,
    typography,
    isRPCLoading,
  }: SPoCSelectionProps): ReactElement => {
    const [searchSpocText, setSearchSpocText] =
      useState<string>(EMPTY_CHARACTER);
    const isSearchSpocFieldCleared = useRef(false);
    return (
      <>
        <AutoCompleteInputField
          inputValue={searchSpocText}
          placeholder={t("common.searchSpocPlaceholderText")}
          label={EMPTY_CHARACTER}
          options={manageSurveyViewSPoCs.nonSelectedProjectSpocs.map(
            (projectSpoc) => {
              return {
                id: projectSpoc.spocId,
                label: projectSpoc.searchSpocDropdownText,
              };
            },
          )}
          onInputChange={(inputText) => {
            if (isSearchSpocFieldCleared.current) {
              isSearchSpocFieldCleared.current = false;
              setSearchSpocText(EMPTY_CHARACTER);
              return;
            }
            setSearchSpocText(inputText ? inputText : EMPTY_CHARACTER);
          }}
          onChange={(selectedItem) => {
            isSearchSpocFieldCleared.current = true;
            setSearchSpocText(EMPTY_CHARACTER);
            // This gets triggered when the user unselects an option, as in this case `selectedItemType` is undefined.
            if (selectedItem === null || selectedItem?.id === null) {
              console.warn(`SPoC could not be found for ${selectedItem}`);
              return;
            }
            manageSurveyViewSPoCs.addSelectedSpoc(selectedItem.id);
          }}
          noOptionsText={t("common.noSPoCFoundText")}
          disabled={manageSurveyViewSPoCs.updatedIsAccessibleByProjectSPoCs}
        />
        <Typography
          {...typography.sh3}
          color={tokens.label}
          borderBottom={border.default}
          paddingY={spacing.spaceXS}
        >
          {t("common.selectedSpocsTitleText")}
        </Typography>
        {manageSurveyViewSPoCs.isNoSPoCSelected ? (
          <Stack gap={spacing.spaceSM}>
            <Stack
              padding={spacing.spaceXL}
              gap={spacing.spaceXS}
              alignItems="center"
            >
              <ListX
                {...getIconProps(tokens.iconLowEmphasis, ICON_SIZE.large)}
              />
              <Typography {...typography.b1} color={tokens.labelLowEmphasis}>
                {t("common.noSpocSelectedText")}
              </Typography>
            </Stack>
          </Stack>
        ) : (
          manageSurveyViewSPoCs.updatedSelectedSPoCs.map((selectedSpoc) => {
            return (
              <SelectedProjectSPoCList
                key={selectedSpoc.spocId}
                spocName={selectedSpoc.userName.firstAndLastName}
                spocEmailId={selectedSpoc.emailId}
                spocId={selectedSpoc.spocId}
                isRemoveSpocButtonVisible={true}
                spacing={spacing}
                typography={typography}
                tokens={tokens}
                isRPCLoading={isRPCLoading}
                removeSelectedSpocId={manageSurveyViewSPoCs.removeSelectedSPoC}
                isDisabled={
                  manageSurveyViewSPoCs.updatedIsAccessibleByProjectSPoCs
                }
              />
            );
          })
        )}
      </>
    );
  },
);

interface ManageSurveyViewSPoCsDialogChildProps {
  t: TFunction;
  manageSurveyViewSPoCs: Instance<typeof ManageSurveyViewSPoCsModel>;
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  isRPCLoading: boolean;
  border: BorderStyle;
}

export const ManageSurveyViewSPoCsDialogChild = observer(
  ({
    t,
    manageSurveyViewSPoCs,
    spacing,
    typography,
    tokens,
    isRPCLoading,
    border,
  }: ManageSurveyViewSPoCsDialogChildProps): ReactElement => {
    return (
      <Stack width="100%" gap={spacing.spaceLG}>
        <AccessibleByProjectSPoCs
          spacing={spacing}
          tokens={tokens}
          t={t}
          typography={typography}
          manageSurveyViewSPoCs={manageSurveyViewSPoCs}
        />
        <Divider
          sx={{
            width: "100%",
            alignSelf: "center",
          }}
          orientation="horizontal"
          flexItem
        >
          <Typography {...typography.b1} color={tokens.labelLowEmphasis}>
            {t("surveyDashboard.or")}
          </Typography>
        </Divider>
        <SPoCSelection
          spacing={spacing}
          tokens={tokens}
          t={t}
          typography={typography}
          manageSurveyViewSPoCs={manageSurveyViewSPoCs}
          border={border}
          isRPCLoading={isRPCLoading}
        />
      </Stack>
    );
  },
);
