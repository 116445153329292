import { Skeleton, Stack } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { SingleSelectChoiceOptions } from "./SingleSelectChoiceOptions";
import {
  useFoundationColorTokens,
  useRadioButtonColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import {
  useQuestionDetailsStore,
  useSingleChoiceQuestionV1Store,
} from "../store/hooks";
import { ResetFieldsButton } from "./commonQuestionConfigurationDetailsComponents/ResetFieldsButton";
import { useBorder } from "@pulse/shared-components";
import { SingleSelectPresetPane } from "./singleSelectOptionComponents/SingleSelectPresetPane";
import { List, ListRowProps } from "react-virtualized";

interface ChoiceOptionsPaneProps {
  setIsResetDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsChoicePresetDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ChoiceOptionsPane = observer(
  ({
    setIsResetDialogOpen,
    setIsChoicePresetDialogOpen,
  }: ChoiceOptionsPaneProps): React.ReactElement => {
    const tokens = useFoundationColorTokens();
    const radioButtonTokens = useRadioButtonColorTokens();
    const singleChoiceStore = useSingleChoiceQuestionV1Store();
    const typography = useTypography();
    const spacing = useSpacing();
    const { t } = useTranslation();
    const questionDetailsStore = useQuestionDetailsStore();
    const border = useBorder();
    const renderRow = ({
      index,
      key,
      style,
      isScrolling,
    }: ListRowProps): React.ReactElement => {
      if (isScrolling) {
        return (
          <Stack style={style}>
            <Skeleton
              variant="rounded"
              animation="wave"
              sx={{
                animationDuration: "0.001s",
                width: "892px",
                height: "56px",
                margin: spacing.spaceSM,
              }}
            />
          </Stack>
        );
      }
      return (
        <SingleSelectChoiceOptions
          key={key}
          index={index}
          tokens={tokens}
          singleChoiceStore={singleChoiceStore}
          questionDetailsStore={questionDetailsStore}
          t={t}
          radioButtonTokens={radioButtonTokens}
          spacing={spacing}
          style={style}
          typography={typography}
        />
      );
    };
    const rowCount =
      singleChoiceStore.singleSelectQuestionV1Model.options.length;
    const height = rowCount > 10 ? 600 : rowCount * 56 + 12;
    return (
      <Stack width="fit-content">
        <SingleSelectPresetPane
          spacing={spacing}
          border={border}
          t={t}
          typography={typography}
          setIsChoicePresetDialogOpen={setIsChoicePresetDialogOpen}
          isChoosePresetButtonDisabled={
            questionDetailsStore.updatedQuestionDetails
              .singleChoiceQuestionV1Store.singleSelectQuestionV1Model.isNPS
          }
        />
        <List
          width={892}
          height={height}
          rowHeight={56}
          rowRenderer={renderRow}
          rowCount={rowCount}
          overscanRowCount={20}
          style={{
            marginTop: spacing.spaceSM,
            borderBottom: border.default,
            marginBottom: spacing.spaceSM,
          }}
        />
        <ResetFieldsButton
          isResetDisabled={singleChoiceStore.isResetDisabled}
          setIsResetDialogOpen={setIsResetDialogOpen}
          t={t}
          spacing={spacing}
        />
      </Stack>
    );
  },
);
