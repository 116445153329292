import { SubmittedResponseEnums } from "@pulse/pulse-rpcs";
import { Instance, types } from "mobx-state-tree";

export const SingleSelectResponseV1Model = types.model(
  "SingleSelectResponseV1Model",
  {
    singleSelectAnswer: types.string,
  },
);

export const createSingleSelectResponseV1Model = (
  singleSelectResponse: SubmittedResponseEnums.Response.Version1Enums.ResponseTypeV1.SingleSelect,
): Instance<typeof SingleSelectResponseV1Model> => {
  return SingleSelectResponseV1Model.create({
    singleSelectAnswer: singleSelectResponse.answer,
  });
};
