import { APIClient } from "@surya-digital/tedwig";
import {
  cast,
  clone,
  flow,
  getParentOfType,
  getRoot,
  Instance,
  types,
} from "mobx-state-tree";
import { getAPIClient } from "../../networking/APIClient";
import {
  Code,
  GetSurveyQuestionViewsRPC,
  GroupChildVisibility,
  ManageDashboardViewRPC,
  Order,
  SurveyQuestionViewOrder,
} from "@pulse/pulse-rpcs";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import {
  useGetSurveyQuestionViewsRPCClientImpl,
  useManageDashboardViewRPCClientImpl,
} from "../rpcs/RPC";
import { CommonErrors, NetworkingError } from "@pulse/shared-components";
import { RootStore } from "../../root/store/RootStore";
import { SurveyViewDetailsStore } from "./SurveyViewDetailsStore";
import {
  createSurveyQuestionViewsModel,
  SurveyQuestionViewsModel,
} from "../models/SurveyQuestionViewsModel";

export enum ManageQuestionViewsDialogState {
  Loading = "LOADING",
  ManageQuestionViews = "MANAGE_QUESTION_VIEWS",
  Error = "ERROR",
}

enum UnhandledError {
  UnhandledError = "UNHANDLED_ERROR",
}

export const ManageQuestionViewsStore = types
  .model("ManageQuestionViewsStore", {
    manageQuestionViewsDialogState: types.optional(
      types.enumeration(Object.values(ManageQuestionViewsDialogState)),
      ManageQuestionViewsDialogState.Loading,
    ),
    surveyQuestionViews: types.array(SurveyQuestionViewsModel),
    updatedSurveyQuestionViews: types.array(SurveyQuestionViewsModel),
    rpcError: types.maybeNull(
      types.union(
        types.enumeration(Object.values(GetSurveyQuestionViewsRPC.RPCError)),
        types.enumeration(Object.values(ManageDashboardViewRPC.RPCError)),
        types.enumeration(Object.values(UnhandledError)),
      ),
    ),
    isRPCLoading: types.optional(types.boolean, false),
  })
  .views((store) => ({
    get isManageQuestionViewsDialogLoading(): boolean {
      return (
        store.manageQuestionViewsDialogState ===
        ManageQuestionViewsDialogState.Loading
      );
    },
    get isUpdatedSurveyQuestionViewsEmpty(): boolean {
      return store.updatedSurveyQuestionViews.length === 0;
    },
    get doesStoreContainError(): boolean {
      return store.rpcError !== null;
    },
    get isManageQuestionViewsDialogPrimaryButtonDisabled(): boolean {
      return (
        JSON.stringify(store.updatedSurveyQuestionViews) ===
          JSON.stringify(store.surveyQuestionViews) ||
        store.manageQuestionViewsDialogState ===
          ManageQuestionViewsDialogState.Loading
      );
    },
  }))
  .actions((store) => ({
    resetStore: (): void => {
      store.isRPCLoading = false;
      store.manageQuestionViewsDialogState =
        ManageQuestionViewsDialogState.Loading;
      store.surveyQuestionViews.clear();
      store.updatedSurveyQuestionViews.clear();
      store.rpcError = null;
    },
    moveQuestionAfter: (
      draggingQuestionId: string,
      nextToQuestionId: string,
    ): void => {
      const draggingQuestionIndex = store.updatedSurveyQuestionViews.findIndex(
        (updatedSurveyQuestionView) =>
          updatedSurveyQuestionView.surveyQuestionViewId === draggingQuestionId,
      );

      if (draggingQuestionIndex === -1) {
        console.error(`Question with id: ${draggingQuestionId} not found.`);
        return;
      }

      const draggingQuestion = clone(
        store.updatedSurveyQuestionViews[draggingQuestionIndex],
      );

      store.updatedSurveyQuestionViews.splice(draggingQuestionIndex, 1);

      const nextToQuestionIndex = store.updatedSurveyQuestionViews.findIndex(
        (updatedSurveyQuestionView) =>
          updatedSurveyQuestionView.surveyQuestionViewId === nextToQuestionId,
      );

      if (nextToQuestionIndex === -1) {
        console.error(`Question with id: ${nextToQuestionId} not found.`);
        store.updatedSurveyQuestionViews.push(draggingQuestion);
      } else if (
        nextToQuestionIndex ===
        store.updatedSurveyQuestionViews.length - 1
      ) {
        store.updatedSurveyQuestionViews.push(draggingQuestion);
      } else {
        store.updatedSurveyQuestionViews.splice(
          nextToQuestionIndex + 1,
          0,
          draggingQuestion,
        );
      }
    },
    moveQuestionBefore: (
      draggingQuestionId: string,
      beforeQuestionId: string,
    ): void => {
      const draggingQuestionIndex = store.updatedSurveyQuestionViews.findIndex(
        (updatedSurveyQuestionView) =>
          updatedSurveyQuestionView.surveyQuestionViewId === draggingQuestionId,
      );

      if (draggingQuestionIndex === -1) {
        console.error(`Question with id: ${draggingQuestionId} not found.`);
        return;
      }

      const draggingQuestion = clone(
        store.updatedSurveyQuestionViews[draggingQuestionIndex],
      );

      store.updatedSurveyQuestionViews.splice(draggingQuestionIndex, 1);

      const beforeQuestionIndex = store.updatedSurveyQuestionViews.findIndex(
        (updatedSurveyQuestionView) =>
          updatedSurveyQuestionView.surveyQuestionViewId === beforeQuestionId,
      );

      if (beforeQuestionIndex === -1) {
        console.error(`Question with id: ${beforeQuestionId} not found.`);
        store.updatedSurveyQuestionViews.push(draggingQuestion);
      } else if (beforeQuestionIndex === 0) {
        store.updatedSurveyQuestionViews.unshift(draggingQuestion);
      } else {
        store.updatedSurveyQuestionViews.splice(
          beforeQuestionIndex,
          0,
          draggingQuestion,
        );
      }
    },
    getSurveyQuestionViews: flow(function* (
      surveyViewId: string,
      surveyId: string,
      projectId: string,
    ) {
      const errorStore =
        getRoot<typeof RootStore>(store).networkingStore.errorStore;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new GetSurveyQuestionViewsRPC.Request(
          new LeoUUID(surveyId),
          new LeoUUID(surveyViewId),
          new LeoUUID(projectId),
        );
        const {
          response,
          error,
        }: {
          response?: GetSurveyQuestionViewsRPC.Response;
          error?: GetSurveyQuestionViewsRPC.Errors.Errors;
        } =
          yield useGetSurveyQuestionViewsRPCClientImpl(apiClient).execute(
            request,
          );
        if (response) {
          store.surveyQuestionViews = cast(
            response.surveyQuestionViews.map((surveyQuestionView) => {
              return createSurveyQuestionViewsModel(surveyQuestionView);
            }),
          );
          store.updatedSurveyQuestionViews = cast(
            response.surveyQuestionViews.map((surveyQuestionView) => {
              return createSurveyQuestionViewsModel(surveyQuestionView);
            }),
          );
          store.manageQuestionViewsDialogState =
            ManageQuestionViewsDialogState.ManageQuestionViews;
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              // This error is already handled in default response interceptor.
              break;
            case GetSurveyQuestionViewsRPC.RPCError.InvalidSurveyViewId: {
              errorStore.setError(NetworkingError.PageNotFound);
              break;
            }
            default: {
              console.error(`Unhandled error occured: ${error}`);
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      }
    }),
    manageSurveyQuestionViews: flow(function* (
      surveyViewId: string,
      surveyId: string,
      projectId: string,
    ) {
      const errorStore =
        getRoot<typeof RootStore>(store).networkingStore.errorStore;
      try {
        store.isRPCLoading = true;
        const apiClient: APIClient = getAPIClient(store);
        const request = new ManageDashboardViewRPC.Request(
          new LeoUUID(projectId),
          new LeoUUID(surveyId),
          new LeoUUID(surveyViewId),
          store.updatedSurveyQuestionViews.map((surveyQuestionView, index) => {
            return new SurveyQuestionViewOrder(
              new LeoUUID(surveyQuestionView.surveyQuestionViewId),
              new Order(index + 1),
              surveyQuestionView.isViewVisible,
              surveyQuestionView.groupChildQuestions.map(
                (groupChildQuestion) => {
                  return new GroupChildVisibility(
                    new Code(groupChildQuestion.questionCode),
                    groupChildQuestion.isViewVisible,
                  );
                },
              ),
            );
          }),
        );
        const {
          response,
          error,
        }: {
          response?: ManageDashboardViewRPC.Response;
          error?: ManageDashboardViewRPC.Errors.Errors;
        } =
          yield useManageDashboardViewRPCClientImpl(apiClient).execute(request);
        if (response) {
          const surveyViewDetailsStore = getParentOfType(
            store,
            SurveyViewDetailsStore,
          );
          surveyViewDetailsStore.resetCurrentQuestionViewIds();
          surveyViewDetailsStore.updateSurveyQuestionViews(
            response.surveyViewResults,
            false,
            surveyViewDetailsStore.isMasterView,
          );
          surveyViewDetailsStore.filterStore.clearStore();
          surveyViewDetailsStore.setIsLastElementReached(false);
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              // This error is already handled in default response interceptor.
              return;
            case ManageDashboardViewRPC.RPCError.InvalidSurveyViewId: {
              errorStore.setError(NetworkingError.PageNotFound);
              return;
            }
            case ManageDashboardViewRPC.RPCError.InvalidQuestionViewOrders: {
              store.rpcError =
                ManageDashboardViewRPC.RPCError.InvalidQuestionViewOrders;
              break;
            }
            case ManageDashboardViewRPC.RPCError
              .MasterSurveyViewCannotBeEdited: {
              store.rpcError =
                ManageDashboardViewRPC.RPCError.MasterSurveyViewCannotBeEdited;
              break;
            }
            case ManageDashboardViewRPC.RPCError.OutdatedQuestionViewsFound: {
              store.rpcError =
                ManageDashboardViewRPC.RPCError.OutdatedQuestionViewsFound;
              break;
            }
            case ManageDashboardViewRPC.RPCError.ProjectAlreadyArchived: {
              store.rpcError =
                ManageDashboardViewRPC.RPCError.ProjectAlreadyArchived;
              break;
            }
            default: {
              console.error(`Unhandled error occured: ${error}`);
              store.rpcError = UnhandledError.UnhandledError;
              break;
            }
          }
          store.manageQuestionViewsDialogState =
            ManageQuestionViewsDialogState.Error;
        }
      } catch (e) {
        if (e instanceof Error) {
          errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
  }))
  .actions((store) => ({
    reorderQuestionnaire: (activeId: string, overId: string): void => {
      const activeIndex = store.updatedSurveyQuestionViews.findIndex(
        (question) => question.surveyQuestionViewId === activeId,
      );

      const overIndex = store.updatedSurveyQuestionViews.findIndex(
        (question) => question.surveyQuestionViewId === overId,
      );

      const isMovingAbove = overIndex < activeIndex;
      if (isMovingAbove) {
        store.moveQuestionBefore(activeId, overId);
      } else {
        store.moveQuestionAfter(activeId, overId);
      }
    },
  }));

export const createManageQuestionViewsStore = (): Instance<
  typeof ManageQuestionViewsStore
> => {
  return ManageQuestionViewsStore.create();
};
