import React from "react";
import { GroupResponsesV1Model } from "../../models/RespondentSubmittedResponseModels/GroupResponseV1Model";
import { getType, Instance } from "mobx-state-tree";
import { TFunction } from "i18next";
import {
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { BorderStyle, DeveloperErrorFlow } from "@pulse/shared-components";
import { Stack } from "@mui/material";
import { S1TypographyLabel } from "./S1TypographyLabel";
import { RespondentResponseQuestionTypeStoreName } from "../../models/RespondentSubmittedResponseModels/SubmittedResponseModel";
import { QuestionContainer } from "./QuestionContainer";
import { OpenEndedResponse } from "./OpenEndedResponse";
import { OpenEndedResponseV1Model } from "../../models/RespondentSubmittedResponseModels/OpenEndedResponseV1Model";
import { SingleSelectResponse } from "./SingleSelectResponse";
import { SingleSelectResponseV1Model } from "../../models/RespondentSubmittedResponseModels/SingleSelectResponseV1Model";
import { MultiSelectResponse } from "./MultiSelectResponse";
import { MultiSelectResponseV1Model } from "../../models/RespondentSubmittedResponseModels/MultiSelectResponseV1Model";
import { RankingResponse } from "./RankingResponse";
import { RankingResponseV1Model } from "../../models/RespondentSubmittedResponseModels/RankingResponseV1Model";
import { GridResponse } from "./GridResponse";
import { GridResponseV1Model } from "../../models/RespondentSubmittedResponseModels/GridResponseV1Model";
import { observer } from "mobx-react";

interface GroupResponseProps {
  question: string | null;
  groupResponse: Instance<typeof GroupResponsesV1Model>;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  spacing: Spacing;
  border: BorderStyle;
}

export const GroupResponse = observer(
  ({
    question,
    groupResponse,
    t,
    tokens,
    typography,
    spacing,
    border,
  }: GroupResponseProps): React.ReactElement => {
    return (
      <Stack>
        {question && (
          <Stack paddingBottom={spacing.spaceLG}>
            <S1TypographyLabel
              text={t("projects.respondentResponseDialog.questionPrefix", {
                question,
              })}
              tokens={tokens}
              typography={typography}
            />
          </Stack>
        )}
        {groupResponse.groupResponses.map((response, index) => {
          const paddingBottom =
            index === groupResponse.groupResponses.length - 1
              ? "0px"
              : spacing.spaceLG;
          switch (getType(response.response).name) {
            case RespondentResponseQuestionTypeStoreName.OPEN_ENDED_RESPONSE_V1_MODEL: {
              return (
                <QuestionContainer
                  key={index}
                  border={border}
                  tokens={tokens}
                  spacing={spacing}
                  paddingBottom={paddingBottom}
                >
                  <OpenEndedResponse
                    key={index}
                    question={response.question}
                    openEndedResponse={
                      response.response as Instance<
                        typeof OpenEndedResponseV1Model
                      >
                    }
                    t={t}
                    tokens={tokens}
                    typography={typography}
                    spacing={spacing}
                    border={border}
                  />
                </QuestionContainer>
              );
            }
            case RespondentResponseQuestionTypeStoreName.SINGLE_SELECT_RESPONSE_V1_MODEL: {
              return (
                <QuestionContainer
                  key={index}
                  border={border}
                  tokens={tokens}
                  spacing={spacing}
                  paddingBottom={paddingBottom}
                >
                  <SingleSelectResponse
                    singleSelectResponse={
                      response.response as Instance<
                        typeof SingleSelectResponseV1Model
                      >
                    }
                    question={response.question}
                    t={t}
                    tokens={tokens}
                    typography={typography}
                  />
                </QuestionContainer>
              );
            }
            case RespondentResponseQuestionTypeStoreName.MULTI_SELECT_RESPONSE_V1_MODEL: {
              return (
                <QuestionContainer
                  key={index}
                  border={border}
                  tokens={tokens}
                  spacing={spacing}
                  paddingBottom={paddingBottom}
                >
                  <MultiSelectResponse
                    multiSelectResponse={
                      response.response as Instance<
                        typeof MultiSelectResponseV1Model
                      >
                    }
                    question={response.question}
                    t={t}
                    tokens={tokens}
                    typography={typography}
                  />
                </QuestionContainer>
              );
            }
            case RespondentResponseQuestionTypeStoreName.RANKING_RESPONSE_V1_MODEL: {
              return (
                <QuestionContainer
                  key={index}
                  border={border}
                  tokens={tokens}
                  spacing={spacing}
                  paddingBottom={paddingBottom}
                >
                  <RankingResponse
                    rankingResponse={
                      response.response as Instance<
                        typeof RankingResponseV1Model
                      >
                    }
                    question={response.question}
                    t={t}
                    tokens={tokens}
                    typography={typography}
                  />
                </QuestionContainer>
              );
            }
            case RespondentResponseQuestionTypeStoreName.GRID_RESPONSE_V1_MODEL: {
              return (
                <QuestionContainer
                  key={index}
                  border={border}
                  tokens={tokens}
                  spacing={spacing}
                  paddingBottom={paddingBottom}
                >
                  <GridResponse
                    gridResponse={
                      response.response as Instance<typeof GridResponseV1Model>
                    }
                    question={response.question}
                    t={t}
                    tokens={tokens}
                    typography={typography}
                    spacing={spacing}
                    border={border}
                  />
                </QuestionContainer>
              );
            }
            default: {
              return <DeveloperErrorFlow />;
            }
          }
        })}
      </Stack>
    );
  },
);
