import { flow, getRoot, Instance, types } from "mobx-state-tree";
import { UnexpectedError } from "../../utils/constants";
import { RootStore } from "../../root/store/RootStore";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "../../networking/APIClient";
import { DownloadDashboardViewPDFRPC, TableFilter } from "@pulse/pulse-rpcs";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import { CommonErrors, NetworkingError } from "@pulse/shared-components";
import { useDownloadDashboardViewPDFRPCClientImpl } from "../rpcs/RPC";

export enum DownloadDashboardViewPDFDialogState {
  DownloadDashboardViewPDF = "DOWNLOAD_DASHBOARD_VIEW_PDF",
  Error = "ERROR",
  Success = "SUCCESS",
}

export const DownloadDashboardViewPDFStore = types
  .model("DownloadDashboardViewPDFStore", {
    isRPCLoading: types.optional(types.boolean, false),
    downloadDashboardViewPDFDialogState: types.optional(
      types.enumeration(Object.values(DownloadDashboardViewPDFDialogState)),
      DownloadDashboardViewPDFDialogState.DownloadDashboardViewPDF,
    ),
    rpcError: types.maybeNull(
      types.union(
        types.enumeration("UnexpectedError", Object.values(UnexpectedError)),
        types.enumeration(
          "DownloadDashboardViewPDFRPCError",
          Object.values(DownloadDashboardViewPDFRPC.RPCError),
        ),
      ),
    ),
  })
  .actions((store) => ({
    resetStoreData: (): void => {
      store.isRPCLoading = false;
      store.downloadDashboardViewPDFDialogState =
        DownloadDashboardViewPDFDialogState.DownloadDashboardViewPDF;
      store.rpcError = null;
    },
    downloadDashboardViewPDF: flow(function* (
      surveyViewId: string,
      surveyId: string,
      projectId: string,
      tableFilters: TableFilter[],
    ) {
      const errorStore =
        getRoot<typeof RootStore>(store).networkingStore.errorStore;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new DownloadDashboardViewPDFRPC.Request(
          new LeoUUID(projectId),
          new LeoUUID(surveyId),
          new LeoUUID(surveyViewId),
          tableFilters,
        );
        const {
          response,
          error,
        }: {
          response?: DownloadDashboardViewPDFRPC.Response;
          error?: DownloadDashboardViewPDFRPC.Errors.Errors;
        } =
          yield useDownloadDashboardViewPDFRPCClientImpl(apiClient).execute(
            request,
          );
        if (response) {
          store.downloadDashboardViewPDFDialogState =
            DownloadDashboardViewPDFDialogState.Success;
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              // This error is already handled in default response interceptor.
              break;
            case DownloadDashboardViewPDFRPC.RPCError.InvalidSurveyViewId: {
              errorStore.setError(NetworkingError.PageNotFound);
              break;
            }
            case DownloadDashboardViewPDFRPC.RPCError.DeletedColumnFilter: {
              store.rpcError =
                DownloadDashboardViewPDFRPC.RPCError.DeletedColumnFilter;
              store.downloadDashboardViewPDFDialogState =
                DownloadDashboardViewPDFDialogState.Error;
              break;
            }
            default: {
              store.downloadDashboardViewPDFDialogState =
                DownloadDashboardViewPDFDialogState.Error;
              store.rpcError = UnexpectedError.UnhandledError;
              console.error(`Unhandled error occured: ${error}`);
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      }
    }),
  }));

export const createDownloadDashboardViewPDFStore = (): Instance<
  typeof DownloadDashboardViewPDFStore
> => {
  return DownloadDashboardViewPDFStore.create();
};
