import {
  GridResultV1Enums,
  GridSingleSelectGraphTypeV1,
  GridSingleSelectResultV1,
  GridSingleSelectRowResultV1,
} from "@pulse/pulse-rpcs";
import { Instance, types } from "mobx-state-tree";
import { SingleSelectResultsModelV1 } from "./SingleSelectResultsModelV1";
import {
  createSingleSelectAnswerV1Model,
  getHundredthDecimalRoundedPercentageString,
} from "@pulse/shared-components";
import { QuestionTypeGraphs } from "../components/ChartSelection";

export const createGridSingleSelectResultsModelV1 = (
  singleSelectResultV1: GridSingleSelectResultV1[],
): Instance<typeof SingleSelectResultsModelV1>[] => {
  return singleSelectResultV1.map((singleSelectResult) => {
    return SingleSelectResultsModelV1.create({
      answer: createSingleSelectAnswerV1Model(
        singleSelectResult.answer.code.code,
        singleSelectResult.answer.answer,
      ),
      percentage: getHundredthDecimalRoundedPercentageString(
        singleSelectResult.commonResultBreakdownDetails.percentage,
      ),
      count: singleSelectResult.commonResultBreakdownDetails.count.count,
    });
  });
};

const GridSingleSelectRowResultV1Model = types.model(
  "GridSingleSelectRowResultV1Model",
  {
    code: types.string,
    option: types.maybeNull(types.string),
    singleSelectResults: types.array(SingleSelectResultsModelV1),
    totalResponses: types.number,
  },
);

const createGridSingleSelectRowResultV1Model = (
  gridSingleSelectRowResult: GridSingleSelectRowResultV1,
): Instance<typeof GridSingleSelectRowResultV1Model> => {
  return GridSingleSelectRowResultV1Model.create({
    code: gridSingleSelectRowResult.gridOptionV1.code.code,
    option: gridSingleSelectRowResult.gridOptionV1.option?.text,
    singleSelectResults: createGridSingleSelectResultsModelV1(
      gridSingleSelectRowResult.singleSelectResults,
    ),
    totalResponses: gridSingleSelectRowResult.totalResponses,
  });
};

export const GridSingleSelectModel = types
  .model("GridSingleSelectModel", {
    gridSingleSelectRowResults: types.array(GridSingleSelectRowResultV1Model),
    selectedGraphType: types.enumeration(
      Object.values(GridSingleSelectGraphTypeV1.GridSingleSelectGraphTypeV1),
    ),
    updatedGraphType: types.enumeration(
      Object.values(GridSingleSelectGraphTypeV1.GridSingleSelectGraphTypeV1),
    ),
    columnHeader: types.maybeNull(types.string),
  })
  .views((store) => ({
    get isGraphSelectionUpdated(): boolean {
      return store.selectedGraphType !== store.updatedGraphType;
    },
  }))
  .actions((store) => ({
    updateSelectedGraphType: (
      updatedSingleSelectGraphType: QuestionTypeGraphs,
    ): void => {
      store.updatedGraphType =
        GridSingleSelectGraphTypeV1.GridSingleSelectGraphTypeV1[
          updatedSingleSelectGraphType as keyof typeof GridSingleSelectGraphTypeV1.GridSingleSelectGraphTypeV1
        ];
    },
    updateSelectedGraphTypeWithUpdatedGraph: (): void => {
      store.selectedGraphType = store.updatedGraphType;
    },
  }));

export const createGridSingleSelectModel = (
  gridSingleSelect: GridResultV1Enums.ColumnQuestionType.SingleSelect,
  columnHeader: string | null = null,
): Instance<typeof GridSingleSelectModel> => {
  return GridSingleSelectModel.create({
    gridSingleSelectRowResults: gridSingleSelect.gridSingleSelectRowResults.map(
      (gridSingleSelectRowResult) => {
        return createGridSingleSelectRowResultV1Model(
          gridSingleSelectRowResult,
        );
      },
    ),
    selectedGraphType: gridSingleSelect.singleSelectGraphType,
    updatedGraphType: gridSingleSelect.singleSelectGraphType,
    columnHeader,
  });
};
