import {
  AddSurveyQuestionRPC,
  AddSurveyQuestionRPCClientImpl,
  CloseSurveyRPC,
  CloseSurveyRPCClientImpl,
  DeleteQuestionRPC,
  DeleteQuestionRPCClientImpl,
  GenerateSurveyPreviewLinkRPC,
  GenerateSurveyPreviewLinkRPCClientImpl,
  GetAllTranslationsRPC,
  GetAllTranslationsRPCClientImpl,
  GetPresignedDownloadURLForQuestionOptionsTemplateRPC,
  GetPresignedDownloadURLForQuestionOptionsTemplateRPCClientImpl,
  GetPresignedDownloadURLForSurveyRulesRPC,
  GetPresignedDownloadURLForSurveyRulesRPCClientImpl,
  GetPresignedDownloadURLForSurveyRulesTemplateRPC,
  GetPresignedDownloadURLForSurveyRulesTemplateRPCClientImpl,
  GetPresignedDownloadURLForTranslationsRPC,
  GetPresignedDownloadURLForTranslationsRPCClientImpl,
  GetPresignedUploadURLForClientLogoRPC,
  GetPresignedUploadURLForClientLogoRPCClientImpl,
  GetPresignedUploadURLForQuestionOptionsRPC,
  GetPresignedUploadURLForQuestionOptionsRPCClientImpl,
  GetPresignedUploadURLForSurveyRulesRPC,
  GetPresignedUploadURLForSurveyRulesRPCClientImpl,
  GetPresignedUploadURLForTranslationsRPC,
  GetPresignedUploadURLForTranslationsRPCClientImpl,
  GetProjectSurveyDetailsRPC,
  GetProjectSurveyDetailsRPCClientImpl,
  GetQuestionDetailsRPC,
  GetQuestionDetailsRPCClientImpl,
  GetQuestionsOfSurveyRPC,
  GetQuestionsOfSurveyRPCClientImpl,
  GetSurveySettingsRPC,
  GetSurveySettingsRPCClientImpl,
  ManageSurveyQuestionsRPC,
  ManageSurveyQuestionsRPCClientImpl,
  ReOpenSurveyRPC,
  ReOpenSurveyRPCClientImpl,
  ResetClientLogoRPC,
  ResetClientLogoRPCClientImpl,
  UpdateSurveyQuestionRPC,
  UpdateSurveyQuestionRPCClientImpl,
  UpdateSurveySettingsRPC,
  UpdateSurveySettingsRPCClientImpl,
  ValidateAndUploadSurveyRulesRPC,
  ValidateAndUploadSurveyRulesRPCClientImpl,
  ValidateAndUploadTranslationsRPC,
  ValidateAndUploadTranslationsRPCClientImpl,
  ValidateCSVAndUpdateQuestionOptionsRPC,
  ValidateCSVAndUpdateQuestionOptionsRPCClientImpl,
} from "@pulse/pulse-rpcs";
import { APIClient } from "@surya-digital/tedwig";
import { MockGetQuestionsOfSurveyClientImpl } from "./MockGetQuestionsOfSurvey";
import { MOCK } from "@pulse/shared-components";
import { MockGetQuestionDetailsClientImpl } from "./MockGetQuestionDetails";
import { MockAddSurveyQuestionClientImpl } from "./MockAddSurveyQuestion";
import { MockUpdateSurveyQuestionClientImpl } from "./MockUpdateSurveyQuestion";
import { MockGetPresignedDownloadURLForSurveyRulesTemplateClientImpl } from "./MockGetPresignedDownloadURLForSurveyRulesTemplate";
import { MockGetPresignedUploadURLForSurveyRulesClientImpl } from "./MockGetPresignedUploadURLForSurveyRulesRPC";
import { MockGetPresignedDownloadURLForSurveyRulesRPCClientImpl } from "./MockGetPresignedDownloadURLForSurveyRule";
import { MockValidateAndUploadSurveyRulesRPCClientImpl } from "./MockValidateAndUploadSurveyRulesRPCClientImpl";
import { MockGenerateSurveyPreviewLinkRPCClientImpl } from "./MockGenerateSurveyPreviewLinkRPCClientImpl";
import { MockDeleteQuestionClientImpl } from "./MockDeleteQuestion";
import { MockManageSurveyQuestionsRPCClientImpl } from "./MockMangeSurveyQuestionsRPCClientImpl";
import { MockGetProjectSurveyDetails } from "./MockGetProjectSurveyDetails";
import { MockGetSurveySettingsRPCClientImpl } from "./MockGetSurveySettingsRPCClientImpl";
import { MockUpdateSurveySettingsRPCClientImpl } from "./MockUpdateSurveySettingsRPCClientImpl";
import { MockCloseSurveyRPCClientImpl } from "./MockCloseSurveyRPCClientImpl";
import { MockReOpenSurveyRPCClientImpl } from "./MockReOpenSurveyRPCClientImpl";
import { MockGetPresignedUploadURLForClientLogoRPCClientImpl } from "./MockGetPresignedUploadURLForClientLogoRPCClientImpl";
import { MockResetClientLogoRPCClientImpl } from "./MockResetClientLogoRPCClientImpl";
import { MockGetPresignedDownloadURLForTranslationsRPCClientImpl } from "./MockGetPresignedDownloadURLForTranslationsRPCClient";
import { MockGetPresignedUploadURLForTranslationsRPCClientImpl } from "./MockGetPresignedUploadURLForTranslationsRPCClient";
import { MockValidateAndUploadTranslationsRPCClientImpl } from "./MockValidateAndUploadTranslationsRPCClient";
import { MockGetAllTranslationsRPCClientImpl } from "./MockGetAllTranslationsRPCClient";
import { MockGetPresignedDownloadURLForQuestionOptionsTemplateRPCClientImpl } from "./MockGetPresignedDownloadURLForQuestionOptionsTemplateRPCClientImpl";
import { MockValidateCSVAndUpdateQuestionOptionsRPCClientImpl } from "./MockValidateCSVAndUpdateQuestionOptionsRPCClientImpl";
import { MockGetPresignedUploadURLForQuestionOptionsRPCClientImpl } from "./MockGetPresignedUploadURLForQuestionOptionsRPCClientImpl";

export const useGetQuestionsOfSurveyRPCClientImpl = (
  apiClient: APIClient,
): GetQuestionsOfSurveyRPC => {
  if (MOCK.surveyQuestions) {
    return new MockGetQuestionsOfSurveyClientImpl();
  } else {
    return new GetQuestionsOfSurveyRPCClientImpl(apiClient);
  }
};

export const useGetQuestionDetailsRPCClientImpl = (
  apiClient: APIClient,
): GetQuestionDetailsRPC => {
  if (MOCK.surveyQuestions) {
    return new MockGetQuestionDetailsClientImpl();
  } else {
    return new GetQuestionDetailsRPCClientImpl(apiClient);
  }
};

export const useAddSurveyQuestionClientImpl = (
  apiClient: APIClient,
): AddSurveyQuestionRPC => {
  if (MOCK.surveyQuestions) {
    return new MockAddSurveyQuestionClientImpl();
  } else {
    return new AddSurveyQuestionRPCClientImpl(apiClient);
  }
};

export const useUpdateSurveyQuestionClientImpl = (
  apiClient: APIClient,
): UpdateSurveyQuestionRPC => {
  if (MOCK.surveyQuestions) {
    return new MockUpdateSurveyQuestionClientImpl();
  } else {
    return new UpdateSurveyQuestionRPCClientImpl(apiClient);
  }
};

export const useGetPresignedDownloadURLForSurveyRulesTemplateClientImpl = (
  apiClient: APIClient,
): GetPresignedDownloadURLForSurveyRulesTemplateRPC => {
  if (MOCK.surveyQuestions) {
    return new MockGetPresignedDownloadURLForSurveyRulesTemplateClientImpl();
  } else {
    return new GetPresignedDownloadURLForSurveyRulesTemplateRPCClientImpl(
      apiClient,
    );
  }
};

export const useGetPresignedUploadURLForSurveyRulesClientImpl = (
  apiClient: APIClient,
): GetPresignedUploadURLForSurveyRulesRPC => {
  if (MOCK.surveyQuestions) {
    return new MockGetPresignedUploadURLForSurveyRulesClientImpl();
  } else {
    return new GetPresignedUploadURLForSurveyRulesRPCClientImpl(apiClient);
  }
};

export const useGetPresignedDownloadURLForSurveyRulesClientImpl = (
  apiClient: APIClient,
): GetPresignedDownloadURLForSurveyRulesRPC => {
  if (MOCK.surveyQuestions) {
    return new MockGetPresignedDownloadURLForSurveyRulesRPCClientImpl();
  } else {
    return new GetPresignedDownloadURLForSurveyRulesRPCClientImpl(apiClient);
  }
};

export const useValidateAndUploadSurveyRulesClientImpl = (
  apiClient: APIClient,
): ValidateAndUploadSurveyRulesRPC => {
  if (MOCK.surveyQuestions) {
    return new MockValidateAndUploadSurveyRulesRPCClientImpl();
  } else {
    return new ValidateAndUploadSurveyRulesRPCClientImpl(apiClient);
  }
};

export const useGenerateSurveyPreviewLinkClientImpl = (
  apiClient: APIClient,
): GenerateSurveyPreviewLinkRPC => {
  if (MOCK.surveyQuestions) {
    return new MockGenerateSurveyPreviewLinkRPCClientImpl();
  } else {
    return new GenerateSurveyPreviewLinkRPCClientImpl(apiClient);
  }
};

export const useDeleteQuestionClientImpl = (
  apiClient: APIClient,
): DeleteQuestionRPC => {
  if (MOCK.surveyQuestions) {
    return new MockDeleteQuestionClientImpl();
  } else {
    return new DeleteQuestionRPCClientImpl(apiClient);
  }
};

export const useManageSurveyQuestionsRPCClientImpl = (
  apiClient: APIClient,
): ManageSurveyQuestionsRPC => {
  if (MOCK.surveyQuestions) {
    return new MockManageSurveyQuestionsRPCClientImpl();
  } else {
    return new ManageSurveyQuestionsRPCClientImpl(apiClient);
  }
};

export const useGetProjectSurveyDetailsClientImpl = (
  apiClient: APIClient,
): GetProjectSurveyDetailsRPC => {
  if (MOCK.surveyDetails) {
    return new MockGetProjectSurveyDetails();
  } else {
    return new GetProjectSurveyDetailsRPCClientImpl(apiClient);
  }
};

export const useGetSurveySettingsClientImpl = (
  apiClient: APIClient,
): GetSurveySettingsRPC => {
  if (MOCK.surveySettings) {
    return new MockGetSurveySettingsRPCClientImpl();
  }
  return new GetSurveySettingsRPCClientImpl(apiClient);
};

export const useUpdateSurveySettingsClientImpl = (
  apiClient: APIClient,
): UpdateSurveySettingsRPC => {
  if (MOCK.surveySettings) {
    return new MockUpdateSurveySettingsRPCClientImpl();
  }
  return new UpdateSurveySettingsRPCClientImpl(apiClient);
};

export const useCloseSurveyClientImpl = (
  apiClient: APIClient,
): CloseSurveyRPC => {
  if (MOCK.surveySettings) {
    return new MockCloseSurveyRPCClientImpl();
  }
  return new CloseSurveyRPCClientImpl(apiClient);
};

export const useReOpenSurveyClientImpl = (
  apiClient: APIClient,
): ReOpenSurveyRPC => {
  if (MOCK.surveySettings) {
    return new MockReOpenSurveyRPCClientImpl();
  }
  return new ReOpenSurveyRPCClientImpl(apiClient);
};

export const useGetPresignedUploadURLForClientLogoRPCClientImpl = (
  apiClient: APIClient,
): GetPresignedUploadURLForClientLogoRPC => {
  if (MOCK.surveySettings) {
    return new MockGetPresignedUploadURLForClientLogoRPCClientImpl();
  }
  return new GetPresignedUploadURLForClientLogoRPCClientImpl(apiClient);
};

export const useResetClientLogoRPCClientImpl = (
  apiClient: APIClient,
): ResetClientLogoRPC => {
  if (MOCK.surveySettings) {
    return new MockResetClientLogoRPCClientImpl();
  }
  return new ResetClientLogoRPCClientImpl(apiClient);
};

export const useGetPresignedDownloadURLForTranslationsRPCClientImpl = (
  apiClient: APIClient,
): GetPresignedDownloadURLForTranslationsRPC => {
  if (MOCK.surveyTranslations) {
    return new MockGetPresignedDownloadURLForTranslationsRPCClientImpl();
  }
  return new GetPresignedDownloadURLForTranslationsRPCClientImpl(apiClient);
};

export const useGetPresignedUploadURLForTranslationsRPCClientImpl = (
  apiClient: APIClient,
): GetPresignedUploadURLForTranslationsRPC => {
  if (MOCK.surveyTranslations) {
    return new MockGetPresignedUploadURLForTranslationsRPCClientImpl();
  }
  return new GetPresignedUploadURLForTranslationsRPCClientImpl(apiClient);
};

export const useValidateAndUploadTranslationsRPCClientImpl = (
  apiClient: APIClient,
): ValidateAndUploadTranslationsRPC => {
  if (MOCK.surveyTranslations) {
    return new MockValidateAndUploadTranslationsRPCClientImpl();
  }
  return new ValidateAndUploadTranslationsRPCClientImpl(apiClient);
};

export const useGetAllTranslationsRPCClientImpl = (
  apiClient: APIClient,
): GetAllTranslationsRPC => {
  if (MOCK.surveyTranslations) {
    return new MockGetAllTranslationsRPCClientImpl();
  }
  return new GetAllTranslationsRPCClientImpl(apiClient);
};

export const useGetPresignedDownloadURLForQuestionOptionsTemplateRPCClient = (
  apiClient: APIClient,
): GetPresignedDownloadURLForQuestionOptionsTemplateRPC => {
  if (MOCK.surveyQuestions) {
    return new MockGetPresignedDownloadURLForQuestionOptionsTemplateRPCClientImpl();
  }
  return new GetPresignedDownloadURLForQuestionOptionsTemplateRPCClientImpl(
    apiClient,
  );
};

export const useValidateCSVAndUpdateQuestionOptionsRPCClient = (
  apiClient: APIClient,
): ValidateCSVAndUpdateQuestionOptionsRPC => {
  if (MOCK.surveyQuestions) {
    return new MockValidateCSVAndUpdateQuestionOptionsRPCClientImpl();
  }
  return new ValidateCSVAndUpdateQuestionOptionsRPCClientImpl(apiClient);
};

export const useGetPresignedUploadURLForQuestionOptionsRPCClientImpl = (
  apiClient: APIClient,
): GetPresignedUploadURLForQuestionOptionsRPC => {
  if (MOCK.surveyQuestions) {
    return new MockGetPresignedUploadURLForQuestionOptionsRPCClientImpl();
  }
  return new GetPresignedUploadURLForQuestionOptionsRPCClientImpl(apiClient);
};
