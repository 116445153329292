import {
  CommonErrors,
  DEFAULT_ITEMS_PER_PAGE_IN_PG_TABLE,
  EMPTY_CHARACTER,
  NetworkingError,
  ZERO_VALUE,
} from "@pulse/shared-components";
import {
  Instance,
  cast,
  flow,
  getParentOfType,
  getRoot,
  types,
} from "mobx-state-tree";
import {
  RespondentColumnDetailsModel,
  createRespondentColumnDetailsModel,
} from "../models/RespondentColumnDetailsModel";
import {
  MultiSelectTableStore,
  TableSortOption,
  createMultiSelectTableStore,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  GetRespondentDetailsRPC,
  RespondentSelection,
  TableFilter,
  TableFilterEnums,
  UnassignRespondentFromSpocRPC,
} from "@pulse/pulse-rpcs";
import { RespondentFilters } from "../components/RespondentsPane";
import {
  FilterStore,
  createFilterStore,
} from "../../../components/filter/store/FilterStore";
import { FilterType } from "../../../components/filter/model/FilterModel";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import { getSortOrder } from "../utils/TableUtils";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "../../networking/APIClient";
import {
  useGetRespondentDetailsRPCClientImpl,
  useUnassignRespondentFromSpocRPCClientImpl,
} from "../rpcs/RPC";
import {
  RespondentWithSurveyDetailsModel,
  createRespondentWithSurveyDetailsModel,
} from "../models/RespondentWithSurveyDetailsModel";
import {
  GetRespondentDetailsError,
  RespondentListType,
} from "./RespondentStore";
import {
  SurveyIdAndNameModel,
  createSurveyIdAndNameModel,
} from "../models/SurveyIdAndNameModel";
import { RootStore } from "../../root/store/RootStore";
import { UserAccessStore } from "./UserAccessStore";
import { getFormattedLeoDate } from "../../utils/DateUtils";
import { filterList } from "../../utils/RespondentTableUtils";

export enum SPoCRespondentTabs {
  Respondents,
  Settings,
}

export enum UnassignRespondentFromSpocDialogState {
  UnassignSPoCRespondentState = "UNASSIGN_SPOC_RESPONDENT_STATE",
  ErrorState = "ERROR_STATE",
}

export enum UnassignRespondentFromSpocRPCErrors {
  InvalidSPoCId = "INVALID_SPOC_ID",
  ProjectAlreadyArchived = "PROJECT_ALREADY_ARCHIVED",
  DeleteColumnFilter = "DELETED_COLUMN_FILTER",
  InvalidRespondentId = "INVALID_RESPONDENT_ID_FOUND",
}

export const SPoCRespondentStore = types
  .model("SPoCRespondentStore", {
    projectName: types.optional(types.string, EMPTY_CHARACTER),
    isRPCLoading: types.optional(types.boolean, false),
    selectedTab: types.optional(types.number, ZERO_VALUE),
    respondentColumns: types.array(RespondentColumnDetailsModel),
    isGetRespondentDetailsRPCCalled: types.optional(types.boolean, false),
    filterStore: FilterStore,
    sortColumn: types.enumeration(
      "SortColumn",
      Object.values(GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn),
    ),
    sortOrder: types.enumeration(
      "SortOrder",
      Object.values(GetRespondentDetailsRPC.RequestEnums.SortOrder.SortOrder),
    ),
    respondentWithSurveyDetailsList: types.array(
      RespondentWithSurveyDetailsModel,
    ),
    respondentListType: types.optional(
      types.enumeration(Object.values(RespondentListType)),
      RespondentListType.NoRespondentsFound,
    ),
    totalItems: types.number,
    surveyList: types.array(SurveyIdAndNameModel),
    isRespondentUpdateStatusInProgress: types.boolean,
    rpcError: types.maybeNull(
      types.union(
        types.enumeration(
          "GetRespondentDetailsError",
          Object.values(GetRespondentDetailsError),
        ),
        types.enumeration("NetworkingError", Object.values(NetworkingError)),
        types.enumeration(
          "UnassignRespondentFromSpocRPCErrors",
          Object.values(UnassignRespondentFromSpocRPCErrors),
        ),
      ),
    ),
    multiSelectTableStore: MultiSelectTableStore,
    unassignRespondentFromSpocDialogState: types.optional(
      types.enumeration(
        "UnassignRespondentFromSpocDialogState",
        Object.values(UnassignRespondentFromSpocDialogState),
      ),
      UnassignRespondentFromSpocDialogState.UnassignSPoCRespondentState,
    ),
  })
  .views((store) => ({
    get spocRespondentFilterList(): { key: string; label: string }[] {
      const respondentFilterList = [...filterList];
      store.respondentColumns.map((respondentColumn) => {
        respondentFilterList.push({
          key: respondentColumn.name,
          label: respondentColumn.name,
        });
      });
      return respondentFilterList;
    },
    get itemsPerPage(): number {
      return DEFAULT_ITEMS_PER_PAGE_IN_PG_TABLE;
    },
    get doesRespondentStoreContainError(): boolean {
      return store.rpcError !== null;
    },
    get isGetRespondentErrorDialogOpen(): boolean {
      return (
        store.rpcError === GetRespondentDetailsError.InvalidPageIndex ||
        store.rpcError === GetRespondentDetailsError.DeletedColumnFilter
      );
    },
    get doesStoreContainError(): boolean {
      return store.rpcError !== null;
    },
    get isSingleRespondentSelected(): boolean {
      return (
        store.multiSelectTableStore.totalSelectedItems(store.totalItems) === 1
      );
    },
  }))
  .actions((store) => ({
    setUnassignRespondentFromSpocDialogState: (
      unassignRespondentFromSpocDialogState: UnassignRespondentFromSpocDialogState,
    ): void => {
      store.unassignRespondentFromSpocDialogState =
        unassignRespondentFromSpocDialogState;
    },
    applyFilters: (requestFilters: TableFilter[]): void => {
      try {
        if (!store.filterStore.areNoFiltersAdded) {
          store.filterStore.appliedFilters.map((filter) => {
            if (filter.key === undefined) {
              console.error("The filter key cannot be null");
              return;
            }
            let tableFilters:
              | TableFilterEnums.Input.DateRange
              | TableFilterEnums.Input.OpenEnded
              | TableFilterEnums.Input.PresetMultiSelect;
            if (filter.filterType === FilterType.OpenEnded) {
              if (filter.openEndedFilterValue === undefined) {
                console.error(
                  "The filter value for Open Ended Filter cannot be null",
                );
                return;
              }
              tableFilters = new TableFilterEnums.Input.OpenEnded(
                filter.openEndedFilterValue,
              );
            } else if (filter.filterType === FilterType.MultiSelect) {
              tableFilters = new TableFilterEnums.Input.PresetMultiSelect(
                filter.multiSelectFilterValue.map((filterValue) => {
                  if (filterValue.id === undefined) {
                    console.error(
                      "The filter value for Multi Select Filter cannot be null",
                    );
                  }
                  return filterValue.id ?? EMPTY_CHARACTER;
                }),
              );
            } else {
              if (
                filter.dateRangeFilterValue &&
                filter.dateRangeFilterValue.startDate &&
                filter.dateRangeFilterValue.endDate
              ) {
                tableFilters = new TableFilterEnums.Input.DateRange(
                  getFormattedLeoDate(filter.dateRangeFilterValue.startDate),
                  getFormattedLeoDate(filter.dateRangeFilterValue.endDate),
                );
              } else {
                console.error("The filter value for date range cannot be null");
                return;
              }
            }
            requestFilters.push(
              new TableFilter(filter.key ?? EMPTY_CHARACTER, tableFilters),
            );
          });
        }
      } catch (e) {
        console.error(
          `Unexpected error ${e} at applyFilters action in RespondentStore.`,
        );
      }
    },
  }))
  .actions((store) => ({
    setSelectedTab: (selectedTab: number): void => {
      store.selectedTab = selectedTab;
    },
    resetError: (): void => {
      store.rpcError = null;
    },
    getRespondentDetails: flow(function* (
      pageIndex: number,
      projectId: string,
      spocId: string,
      sort?: TableSortOption,
      surveyFilterName?: string,
    ) {
      store.sortColumn =
        GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn.ID;
      store.sortOrder =
        GetRespondentDetailsRPC.RequestEnums.SortOrder.SortOrder.ASCENDING;
      store.isRPCLoading = true;
      try {
        const requestFilters: TableFilter[] = surveyFilterName
          ? [
              new TableFilter(
                RespondentFilters.Survey,
                new TableFilterEnums.Input.PresetMultiSelect([
                  surveyFilterName,
                ]),
              ),
            ]
          : [];

        store.applyFilters(requestFilters);

        if (sort) {
          store.sortOrder = getSortOrder(sort.order);
          store.sortColumn =
            sort.id as GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn;
        }

        const apiClient: APIClient = getAPIClient(store);
        const request = new GetRespondentDetailsRPC.Request(
          new LeoUUID(projectId),
          requestFilters,
          pageIndex,
          store.itemsPerPage,
          store.sortOrder,
          store.sortColumn,
          true,
          new LeoUUID(spocId),
        );
        const {
          response,
          error,
        }: {
          response?: GetRespondentDetailsRPC.Response;
          error?: GetRespondentDetailsRPC.Errors.Errors;
        } =
          yield useGetRespondentDetailsRPCClientImpl(apiClient).execute(
            request,
          );
        if (response) {
          store.projectName = response.projectDetails.projectName.name;
          if (
            response.respondentUpdateStatus instanceof
            GetRespondentDetailsRPC.ResponseEnums.RespondentUpdateStatus
              .Completed
          ) {
            if (
              response.respondentUpdateStatus.respondents instanceof
              GetRespondentDetailsRPC.ResponseEnums.RespondentUpdateStatus
                .CompletedEnums.Respondents.NoRespondentsFound
            ) {
              store.respondentWithSurveyDetailsList.clear();
              store.respondentListType = RespondentListType.NoRespondentsFound;
            } else if (
              response.respondentUpdateStatus.respondents instanceof
              GetRespondentDetailsRPC.ResponseEnums.RespondentUpdateStatus
                .CompletedEnums.Respondents.NoMatchingRespondentsFound
            ) {
              store.respondentWithSurveyDetailsList.clear();
              store.respondentListType =
                RespondentListType.NoMatchingRespondentsFound;
            } else if (
              response.respondentUpdateStatus.respondents instanceof
              GetRespondentDetailsRPC.ResponseEnums.RespondentUpdateStatus
                .CompletedEnums.Respondents.RespondentsFound
            ) {
              store.respondentListType = RespondentListType.RespondentsFound;
              store.respondentWithSurveyDetailsList = cast(
                response.respondentUpdateStatus.respondents.respondents.map(
                  (respondent) => {
                    return createRespondentWithSurveyDetailsModel(respondent);
                  },
                ),
              );
            }

            store.respondentColumns = cast(
              response.respondentUpdateStatus.respondentColumnDetails.map(
                (column) => {
                  return createRespondentColumnDetailsModel(column);
                },
              ),
            );
            store.totalItems = response.respondentUpdateStatus.totalItems;
            store.surveyList = cast(
              response.respondentUpdateStatus.surveyDetails.map((survey) => {
                return createSurveyIdAndNameModel(
                  survey.surveyId.uuid,
                  survey.surveyName.name,
                );
              }),
            );
          } else if (
            response.respondentUpdateStatus instanceof
            GetRespondentDetailsRPC.ResponseEnums.RespondentUpdateStatus
              .InProgress
          ) {
            store.isRespondentUpdateStatusInProgress = true;
          }
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
              // This error is handled in default response interceptor.
              break;
            case GetRespondentDetailsError.InvalidPageIndex:
              store.rpcError = GetRespondentDetailsError.InvalidPageIndex;
              break;
            case GetRespondentDetailsError.DeletedColumnFilter:
              store.rpcError = GetRespondentDetailsError.DeletedColumnFilter;
              break;
            default:
              console.error(`Unhandled error ${error.code}.`);
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
        store.isGetRespondentDetailsRPCCalled = true;
      }
    }),
    unassignRespondentFromSpoc: flow(function* (
      projectId: string,
      spocId: string,
      selectedRespondentId: number | undefined,
    ) {
      try {
        store.isRPCLoading = true;
        store.rpcError = null;
        const apiClient = getAPIClient(store);
        const requestFilters: TableFilter[] = [];
        store.applyFilters(requestFilters);
        let respondentSelection;
        if (selectedRespondentId) {
          respondentSelection = new RespondentSelection.SomeRowsSelected([
            selectedRespondentId,
          ]);
        } else {
          respondentSelection = store.multiSelectTableStore.areAllRowsSelected
            ? new RespondentSelection.AllRowsSelected(true, [...requestFilters])
            : new RespondentSelection.SomeRowsSelected(
                store.multiSelectTableStore.selectedRowIds.map((id) =>
                  Number(id),
                ),
              );
        }
        const request = new UnassignRespondentFromSpocRPC.Request(
          respondentSelection,
          new LeoUUID(spocId),
          new LeoUUID(projectId),
        );
        const {
          response,
          error,
        }: {
          response?: UnassignRespondentFromSpocRPC.Response;
          error?: UnassignRespondentFromSpocRPC.Errors.Errors;
        } =
          yield useUnassignRespondentFromSpocRPCClientImpl(apiClient).execute(
            request,
          );
        store.multiSelectTableStore.unselectAllRows();
        store.filterStore.clearStore();
        if (response) {
          // We don't need to do anything for positive response.
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId: {
              // This error is handled in default response interceptor.
              break;
            }
            case UnassignRespondentFromSpocRPCErrors.InvalidSPoCId: {
              // The `isURLInvalid` state in `UserAccessStore` has a listener which redirects user to 404 page.
              getParentOfType(store, UserAccessStore).setIsURLInvalid(true);
              break;
            }
            case UnassignRespondentFromSpocRPCErrors.InvalidRespondentId: {
              store.unassignRespondentFromSpocDialogState =
                UnassignRespondentFromSpocDialogState.ErrorState;
              store.rpcError =
                UnassignRespondentFromSpocRPCErrors.InvalidRespondentId;
              break;
            }
            case UnassignRespondentFromSpocRPCErrors.DeleteColumnFilter: {
              // We are not setting unassignRespondentFromSpocDialogState to error here as this error is already handled.
              store.rpcError =
                UnassignRespondentFromSpocRPCErrors.DeleteColumnFilter;
              break;
            }
            case UnassignRespondentFromSpocRPCErrors.ProjectAlreadyArchived: {
              store.unassignRespondentFromSpocDialogState =
                UnassignRespondentFromSpocDialogState.ErrorState;
              store.rpcError =
                UnassignRespondentFromSpocRPCErrors.ProjectAlreadyArchived;
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(
            `Unhandled error ${e} in assignSurvey action in unassignRespondentFromSpoc action.`,
          );
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
    mapRespondentColumnsToColumnWithIndex: (): string[] => {
      return store.respondentColumns.map((column) => {
        return `column${column.index}`;
      });
    },
  }));

export const createSPoCRespondentStore = (): Instance<
  typeof SPoCRespondentStore
> => {
  return SPoCRespondentStore.create({
    multiSelectTableStore: createMultiSelectTableStore(),
    sortColumn: GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn.ID,
    sortOrder:
      GetRespondentDetailsRPC.RequestEnums.SortOrder.SortOrder.ASCENDING,
    totalItems: 0,
    isGetRespondentDetailsRPCCalled: false,
    isRespondentUpdateStatusInProgress: false,
    filterStore: createFilterStore(),
  });
};
