import { Instance, cast, flow, getRoot, types } from "mobx-state-tree";
import {
  CommonErrors,
  EMPTY_CHARACTER,
  EMPTY_LIST_LENGTH,
  projectAndSurveyNameRegex,
} from "@pulse/shared-components";
import {
  AssignSpocsToRespondentsDialogState,
  AssignSurveyDialogState,
  RespondentStore,
  createRespondentStore,
} from "./RespondentStore";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "../../networking/APIClient";
import {
  CloneSurveyRPC,
  CreateNewSurveyRPC,
  GetProjectSpocDetailsRPC,
  GetProjectDetailsRPC,
  GetProjectSurveyNamesRPC,
  ProjectDetails,
  SpocUserDetails,
  SurveyDetails,
  SurveyName,
} from "@pulse/pulse-rpcs";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import {
  useCloneSurveyRPCClientImpl,
  useCreateNewSurveyRPCClientImpl,
  useGetProjectDetailsClientImpl,
  useGetProjectSpocDetailsRPCClientImpl,
  useGetProjectSurveyNamesRPCClientImpl,
} from "../rpcs/RPC";
import {
  SurveyStore,
  createSurveyStore,
} from "../../surveys/store/SurveyStore";
import {
  DownloadReportsDialogState,
  ReportStore,
  createReportStore,
} from "./ReportStore";
import {
  SurveyIdAndNameModel,
  createSurveyIdAndNameModel,
} from "../models/SurveyIdAndNameModel";
import { DashboardStore, createDashboardStore } from "./DashboardStore";
import { useGetProjectSurveyDetailsClientImpl } from "../../surveys/rpcs/RPC";
import { createSurveyDetailsModel } from "../../surveys/models/SurveyDetailsModel";
import { RootStore } from "../../root/store/RootStore";
import { CreateSurveyDialogState } from "../components/CreateSurveyDialog";
import { DateRangePickerInput } from "@surya-digital/leo-reactjs-material-ui";
import { UserAccessStore, createUserAccessStore } from "./UserAccessStore";
import {
  SpocUserDetailsModel,
  createSpocUserDetailsModel,
} from "../../models/SpocUserDetailsModel";
import { NetworkingError } from "@pulse/shared-components";
import { AddNewEmbedDialogState } from "./EmbedStore";
import {
  UpdateProjectDetailsStore,
  createUpdateProjectDetailsStore,
} from "./UpdateProjectDetailsStore";
import { CloneSurveyDialogState } from "../components/surveyDetails/CloneSurveyDialog";
import { GetProjectSurveyDetailsRPC } from "@pulse/pulse-rpcs";

export enum CreateNewSurveyRPCErrors {
  InvalidSurveyName = "INVALID_SURVEY_NAME",
  MaximumSurveyCountExceeded = "MAXIMUM_SURVEY_COUNT_EXCEEDED",
  ProjectAlreadyArchived = "PROJECT_ALREADY_ARCHIVED",
  SurveyNameNotUnique = "SURVEY_NAME_NOT_UNIQUE",
  InvalidSurveyNameLength = "INVALID_SURVEY_NAME_LENGTH",
}

export const ProjectDetailsStore = types
  .model("ProjectDetailsStore", {
    projectName: types.optional(types.string, EMPTY_CHARACTER),
    projectStartDate: types.maybeNull(types.Date),
    projectEndDate: types.maybeNull(types.Date),
    respondentStore: RespondentStore,
    dashboardStore: DashboardStore,
    reportStore: ReportStore,
    // As mentioned here: https://mobx-state-tree.js.org/intro/getting-started#how-to-define-the-reference,
    // Sometimes initializing can lead to circular references that may use a model before it's declared.
    // To overcome this we can use types.late as shown below.
    surveyStore: types.late(() => SurveyStore),
    isRPCLoading: types.optional(types.boolean, false),
    surveyIdAndNames: types.array(SurveyIdAndNameModel),
    projectSpocs: types.array(SpocUserDetailsModel),
    selectedSpocIds: types.array(types.string),
    rpcError: types.maybeNull(
      types.union(
        types.enumeration(
          "CreateNewSurveyRPCErrors",
          Object.values(CreateNewSurveyRPCErrors),
        ),
        types.enumeration("NetworkingError", Object.values(NetworkingError)),
      ),
    ),
    createSurveyDialogState: types.optional(
      types.enumeration(
        "CreateSurveyDialogState",
        Object.values(CreateSurveyDialogState),
      ),
      CreateSurveyDialogState.CreateSurvey,
    ),
    cloneSurveyDialogState: types.optional(
      types.enumeration(
        "CloneSurveyDialogState",
        Object.values(CloneSurveyDialogState),
      ),
      CloneSurveyDialogState.CloneSurvey,
    ),
    userAccessStore: UserAccessStore,
    updateProjectDetailsStore: UpdateProjectDetailsStore,
    cloneProjectId: types.maybeNull(types.string),
  })
  .views((store) => ({
    get areProjectDetailsEmpty(): boolean {
      return (
        store.projectName === EMPTY_CHARACTER ||
        store.projectStartDate === null ||
        store.projectEndDate === null
      );
    },
    get doesStoreContainError(): boolean {
      return store.rpcError !== null;
    },
    get isUpdateProjectDetailsSaveChangesButtonDisabled(): boolean {
      return (
        store.projectName === store.updateProjectDetailsStore.projectName &&
        store.projectStartDate === store.updateProjectDetailsStore.startDate &&
        store.projectEndDate === store.updateProjectDetailsStore.endDate
      );
    },
    get getDateRangePickerInput(): DateRangePickerInput {
      return {
        startDate: store.projectStartDate ?? null,
        endDate: store.projectEndDate ?? null,
      };
    },
    get isCreateSurveyDialogErrorState(): boolean {
      return store.createSurveyDialogState === CreateSurveyDialogState.ERROR;
    },
    get isCloneSurveyDialogErrorState(): boolean {
      return store.cloneSurveyDialogState === CloneSurveyDialogState.ERROR;
    },
    get isCreateSurveySecondaryButtonDisabled(): boolean {
      return store.isRPCLoading;
    },
    get isCreateSurveyPrimaryButtonDisabled(): boolean {
      return store.surveyStore.surveyName.trim() === EMPTY_CHARACTER;
    },
    get isClonedSurveyPrimaryButtonDisabled(): boolean {
      return (
        store.surveyStore.clonedSurveyName.trim() === EMPTY_CHARACTER ||
        store.rpcError !== null
      );
    },
    get doesStoreContainSurveyNameErrors(): boolean {
      return (
        store.rpcError === CreateNewSurveyRPCErrors.InvalidSurveyName ||
        store.rpcError === CreateNewSurveyRPCErrors.SurveyNameNotUnique ||
        store.rpcError === CreateNewSurveyRPCErrors.InvalidSurveyNameLength
      );
    },
    getSurveyIdAndNameByName(
      surveyName: string,
    ): Instance<typeof SurveyIdAndNameModel> | undefined {
      return store.respondentStore.surveyList.find(
        (surveyIdName) => surveyIdName.surveyName === surveyName,
      );
    },
    get isProjectSpocListEmpty(): boolean {
      return store.projectSpocs.length === EMPTY_LIST_LENGTH;
    },
    get isSelectedSpocListEmpty(): boolean {
      return store.selectedSpocIds.length === EMPTY_LIST_LENGTH;
    },
    isSelectedSpocTextInSpocList(selectedSpocText: string | null): boolean {
      return (
        store.projectSpocs.find((projectSpoc) => {
          return projectSpoc.searchSpocDropdownText === selectedSpocText;
        }) !== undefined
      );
    },
    get selectedSpocs(): Instance<typeof SpocUserDetailsModel>[] {
      // Filters out all the project SPoCs that have not been selected by the user.
      return store.projectSpocs.filter((projectSpoc) => {
        return (
          store.selectedSpocIds.find((selectedSpocId) => {
            return projectSpoc.spocId === selectedSpocId;
          }) !== undefined
        );
      });
    },
    get nonSelectedProjectSpocs(): Instance<typeof SpocUserDetailsModel>[] {
      // Filters out all the project SPoCs that have already been selected by the user.
      return store.projectSpocs.filter((projectSpoc) => {
        return (
          store.selectedSpocIds.find((selectedSpocId) => {
            return projectSpoc.spocId === selectedSpocId;
          }) === undefined
        );
      });
    },
  }))
  .views((store) => ({
    get isSearchProjectSpocFieldDisabled(): boolean {
      return store.isRPCLoading || store.isProjectSpocListEmpty;
    },
  }))
  .actions((store) => ({
    setCloneProjectId: (cloneProjectId: string): void => {
      store.cloneProjectId = cloneProjectId;
    },
    getProjectSurveyDetails: flow(function* (projectId: string) {
      store.surveyStore.isRPCLoading = true;
      store.surveyStore.projectId = projectId;
      try {
        const apiClient = getAPIClient(store);
        const request = new GetProjectSurveyDetailsRPC.Request(
          new LeoUUID(projectId),
        );
        const {
          response,
          error,
        }: {
          response?: GetProjectSurveyDetailsRPC.Response;
          error?: GetProjectSurveyDetailsRPC.Errors.Errors;
        } =
          yield useGetProjectSurveyDetailsClientImpl(apiClient).execute(
            request,
          );
        if (response) {
          const surveyDetailsList = response;
          const projectDetails = response.projectDetails;
          store.projectName = projectDetails.projectName.name;
          store.projectStartDate = new Date(projectDetails.startDate.date);
          store.projectEndDate = new Date(projectDetails.endDate.date);
          store.surveyStore.surveyDetailsList = cast(
            surveyDetailsList.surveyDetails.map(
              (surveyDetails: SurveyDetails) => {
                return createSurveyDetailsModel(surveyDetails);
              },
            ),
          );
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
              break;
            default: {
              console.error(`Unhandled error occured: ${error}`);
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.surveyStore.isRPCLoading = false;
      }
    }),
    getProjectDetails: flow(function* (projectId: string) {
      store.isRPCLoading = true;
      store.updateProjectDetailsStore.isRPCLoading = true;
      try {
        const apiClient = getAPIClient(store);
        const request = new GetProjectDetailsRPC.Request(
          new LeoUUID(projectId),
        );
        const {
          response,
          error,
        }: {
          response?: GetProjectDetailsRPC.Response;
          error?: GetProjectDetailsRPC.Errors.Errors;
        } = yield useGetProjectDetailsClientImpl(apiClient).execute(request);
        if (response) {
          const projectDetails = response.projectDetails;
          store.projectName = projectDetails.projectName.name;
          store.projectStartDate = new Date(projectDetails.startDate.date);
          store.projectEndDate = new Date(projectDetails.endDate.date);
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
              break;
            default: {
              console.error(`Unhandled error occured: ${error}`);
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.updateProjectDetailsStore.isRPCLoading = false;
        store.isRPCLoading = false;
      }
    }),
    getProjectSurveyNames: flow(function* (projectId: string) {
      try {
        const apiClient = getAPIClient(store);
        const request = new GetProjectSurveyNamesRPC.Request(
          new LeoUUID(projectId),
        );
        const {
          response,
          error,
        }: {
          response?: GetProjectSurveyNamesRPC.Response;
          error?: GetProjectSurveyNamesRPC.Errors.Errors;
        } =
          yield useGetProjectSurveyNamesRPCClientImpl(apiClient).execute(
            request,
          );
        if (response) {
          if (response.surveyIdAndNames.length !== EMPTY_LIST_LENGTH) {
            store.surveyIdAndNames = cast(
              response.surveyIdAndNames.map((surveyIdAndName) => {
                return createSurveyIdAndNameModel(
                  surveyIdAndName.surveyId.uuid,
                  surveyIdAndName.surveyName.name,
                );
              }),
            );
            store.respondentStore.assignRespondentDialogState =
              AssignSurveyDialogState.ChooseFromSurveyList;
            // If there is only 1 survey present for the project, we set that survey as the selected survey in the dashboard tab.
            if (response.surveyIdAndNames.length === 1) {
              const selectedSurvey = response.surveyIdAndNames[0];
              store.dashboardStore.setSelectedSurvey({
                id: selectedSurvey.surveyId.uuid,
                label: selectedSurvey.surveyName.name,
              });
            }
          } else {
            store.respondentStore.assignRespondentDialogState =
              AssignSurveyDialogState.NoSurveyCreated;
            store.reportStore.downloadReportsDialogState =
              DownloadReportsDialogState.NoSurveyCreated;
          }
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
              break;
            default:
              console.error(
                `Unhandled RPCError ${error.code} from getProjectSurveyNames`,
              );
              break;
          }
          store.respondentStore.assignRespondentDialogState =
            AssignSurveyDialogState.FetchingSurveyListError;
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
          store.respondentStore.assignRespondentDialogState =
            AssignSurveyDialogState.FetchingSurveyListError;
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      }
    }),
    getProjectSpocDetails: flow(function* (projectId: string) {
      try {
        store.reportStore.embedStore.setAddNewEmbedDialogState(
          AddNewEmbedDialogState.FetchingSPoCDetails,
        );
        store.respondentStore.assignAndViewSpocsToRespondentsDialogState =
          AssignSpocsToRespondentsDialogState.FetchingSpocDetails;
        store.isRPCLoading = true;
        const apiClient: APIClient = getAPIClient(store);

        const request = new GetProjectSpocDetailsRPC.Request(
          new LeoUUID(projectId),
        );
        const {
          response,
          error,
        }: {
          response?: GetProjectSpocDetailsRPC.Response;
          error?: GetProjectSpocDetailsRPC.Errors.Errors;
        } =
          yield useGetProjectSpocDetailsRPCClientImpl(apiClient).execute(
            request,
          );
        if (response) {
          store.projectSpocs = cast(
            response.spocUserDetails.map((spocUserDetail: SpocUserDetails) => {
              return createSpocUserDetailsModel(spocUserDetail);
            }),
          );
          store.respondentStore.assignAndViewSpocsToRespondentsDialogState =
            AssignSpocsToRespondentsDialogState.SelectingSpocs;
          store.reportStore.embedStore.setAddNewEmbedDialogState(
            AddNewEmbedDialogState.FetchingSPoCDetailsSuccess,
          );
        } else if (error) {
          store.respondentStore.assignAndViewSpocsToRespondentsDialogState =
            AssignSpocsToRespondentsDialogState.AssignFailed;
          store.reportStore.embedStore.setAddNewEmbedDialogState(
            AddNewEmbedDialogState.FetchingSPoCDetailsError,
          );
          switch (error.code) {
            case CommonErrors.InvalidProjectId: {
              break;
            }
            default: {
              console.error(`Unhandled error ${error.code}`);
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
    validateSurveyName: (): boolean => {
      try {
        new SurveyName(store.surveyStore.surveyName.trim());
        const regex = new RegExp(projectAndSurveyNameRegex);
        if (!regex.test(store.surveyStore.surveyName)) {
          store.rpcError = CreateNewSurveyRPCErrors.InvalidSurveyName;
          return false;
        }
        return true;
      } catch (e) {
        store.rpcError = CreateNewSurveyRPCErrors.InvalidSurveyNameLength;
        return false;
      }
    },
    validateClonedSurveyName: (surveyName: string): boolean => {
      if (surveyName.trim() === store.surveyStore.clonedSurveyName.trim()) {
        store.rpcError = CreateNewSurveyRPCErrors.SurveyNameNotUnique;
        return false;
      }
      try {
        new SurveyName(store.surveyStore.clonedSurveyName.trim());
        const regex = new RegExp(projectAndSurveyNameRegex);
        if (!regex.test(store.surveyStore.clonedSurveyName)) {
          store.rpcError = CreateNewSurveyRPCErrors.InvalidSurveyName;
          return false;
        }
        return true;
      } catch (e) {
        store.rpcError = CreateNewSurveyRPCErrors.InvalidSurveyNameLength;
        return false;
      }
    },
    clearRespondentStore: (): void => {
      store.respondentStore = createRespondentStore(
        store.respondentStore.isLiveRespondentsSelected,
      );
    },
    resetSurveyStore: (): void => {
      store.surveyStore = createSurveyStore();
    },
    resetProjectDetails: (): void => {
      store.projectName = EMPTY_CHARACTER;
      store.projectStartDate = null;
      store.projectEndDate = null;
    },
    clearRPCError: (): void => {
      store.rpcError = null;
    },
    clearProjectSpocs: (): void => {
      store.projectSpocs.clear();
    },
    clearSelectedSpocs: (): void => {
      store.selectedSpocIds.clear();
    },
    removeSelectedSpocId: (selectedSpocId: string): void => {
      if (store.selectedSpocIds.includes(selectedSpocId)) {
        store.selectedSpocIds.splice(
          store.selectedSpocIds.indexOf(selectedSpocId),
          1,
        );
      }
    },
    addSelectedSpocId: (selectedSpocId: string): void => {
      if (!store.selectedSpocIds.includes(selectedSpocId)) {
        store.selectedSpocIds.push(selectedSpocId);
      } else {
        console.error(`${selectedSpocId} is already present in the list.`);
      }
    },
  }))
  .actions((store) => ({
    setProjectDetails: (projectDetails: ProjectDetails): void => {
      store.projectName = projectDetails.projectName.name;
      store.projectEndDate = new Date(projectDetails.endDate.date);
      store.projectStartDate = new Date(projectDetails.startDate.date);
    },
    clearSurveyNameAndRPCError: (): void => {
      store.createSurveyDialogState = CreateSurveyDialogState.CreateSurvey;
      store.cloneSurveyDialogState = CloneSurveyDialogState.CloneSurvey;
      store.clearRPCError();
      store.surveyStore.surveyName = EMPTY_CHARACTER;
      store.surveyStore.clonedSurveyName = EMPTY_CHARACTER;
    },
    createSurvey: flow(function* (projectId: string) {
      store.isRPCLoading = true;
      store.surveyStore.projectId = projectId;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new CreateNewSurveyRPC.Request(
          new LeoUUID(projectId),
          new SurveyName(store.surveyStore.surveyName),
        );
        const {
          response,
          error,
        }: {
          response?: CreateNewSurveyRPC.Response;
          error?: CreateNewSurveyRPC.Errors.Errors;
        } = yield useCreateNewSurveyRPCClientImpl(apiClient).execute(request);
        if (response) {
          store.surveyStore.surveyId = response.surveyId.uuid;
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
              break;
            case CreateNewSurveyRPCErrors.InvalidSurveyName:
              store.rpcError = CreateNewSurveyRPCErrors.InvalidSurveyName;
              // We are returning here and not setting createSurveyDialogState to CreateSurveyDialogState.ERROR.
              // This is done because this error is handled through helper text.
              // Setting createSurveyDialogState to CreateSurveyDialogState.ERROR will render the error dialog.
              return;
            case CreateNewSurveyRPCErrors.MaximumSurveyCountExceeded:
              store.rpcError =
                CreateNewSurveyRPCErrors.MaximumSurveyCountExceeded;
              store.createSurveyDialogState = CreateSurveyDialogState.ERROR;
              break;
            case CreateNewSurveyRPCErrors.ProjectAlreadyArchived:
              store.rpcError = CreateNewSurveyRPCErrors.ProjectAlreadyArchived;
              store.createSurveyDialogState = CreateSurveyDialogState.ERROR;
              break;
            case CreateNewSurveyRPCErrors.SurveyNameNotUnique:
              store.rpcError = CreateNewSurveyRPCErrors.SurveyNameNotUnique;
              // We are returning here and not setting createSurveyDialogState to CreateSurveyDialogState.ERROR.
              // This is done because this error is handled through helper text.
              // Setting createSurveyDialogState to CreateSurveyDialogState.ERROR will render the error dialog.
              return;
            default:
              store.rpcError = null;
              console.error(
                `Unhandled error ${error.code} from CreateNewSurveyRPC`,
              );
              break;
          }
          store.createSurveyDialogState = CreateSurveyDialogState.ERROR;
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(
            `Unhandled error ${e} in createSurvey action in ProjectStore`,
          );
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
    cloneSurvey: flow(function* (projectId: string, surveyId: string) {
      store.surveyStore.surveyId = undefined;
      store.isRPCLoading = true;
      store.surveyStore.projectId = projectId;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new CloneSurveyRPC.Request(
          new LeoUUID(projectId),
          new LeoUUID(surveyId),
          new SurveyName(store.surveyStore.clonedSurveyName),
        );
        const {
          response,
          error,
        }: {
          response?: CloneSurveyRPC.Response;
          error?: CloneSurveyRPC.Errors.Errors;
        } = yield useCloneSurveyRPCClientImpl(apiClient).execute(request);
        if (response) {
          store.surveyStore.surveyId = response.surveyId.uuid;
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
              break;
            // CreateNewSurveyRPCErrors are being used as the same errors are thrown for CloneSurveyRPC.
            case CreateNewSurveyRPCErrors.InvalidSurveyName:
              store.rpcError = CreateNewSurveyRPCErrors.InvalidSurveyName;
              // We are returning here and not setting cloneSurveyDialogState to CloneSurveyDialogState.ERROR.
              // This is done because this error is handled through helper text.
              // Setting cloneSurveyDialogState to CloneSurveyDialogState.ERROR will render the error dialog.
              return;
            case CreateNewSurveyRPCErrors.MaximumSurveyCountExceeded:
              store.rpcError =
                CreateNewSurveyRPCErrors.MaximumSurveyCountExceeded;
              store.cloneSurveyDialogState = CloneSurveyDialogState.ERROR;
              break;
            case CreateNewSurveyRPCErrors.ProjectAlreadyArchived:
              store.rpcError = CreateNewSurveyRPCErrors.ProjectAlreadyArchived;
              store.cloneSurveyDialogState = CloneSurveyDialogState.ERROR;
              break;
            case CreateNewSurveyRPCErrors.SurveyNameNotUnique:
              store.rpcError = CreateNewSurveyRPCErrors.SurveyNameNotUnique;
              // We are returning here and not setting cloneSurveyDialogState to CloneSurveyDialogState.ERROR.
              // This is done because this error is handled through helper text.
              // Setting cloneSurveyDialogState to CloneSurveyDialogState.ERROR will render the error dialog.
              return;
            default:
              store.cloneSurveyDialogState = CloneSurveyDialogState.ERROR;
              console.error(
                `Unhandled error ${error.code} from CloneNewSurveyRPC`,
              );
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(
            `Unhandled error ${e} in cloneSurvey action in ProjectStore`,
          );
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
    setProjectName(newProjectName: string): void {
      store.projectName = newProjectName;
    },
    setProjectStartAndEndDates(
      startDate: Date | null,
      endDate: Date | null,
    ): void {
      store.projectStartDate = startDate;
      store.projectEndDate = endDate;
    },
    setProjectDateRangeValue: (
      newDateRangePickerInput: DateRangePickerInput,
    ): void => {
      store.projectStartDate = newDateRangePickerInput.startDate;
      store.projectEndDate = newDateRangePickerInput.endDate;
    },
  }));

export const createProjectDetailsStore = (): Instance<
  typeof ProjectDetailsStore
> => {
  return ProjectDetailsStore.create({
    respondentStore: createRespondentStore(),
    dashboardStore: createDashboardStore(),
    reportStore: createReportStore(),
    surveyStore: createSurveyStore(),
    userAccessStore: createUserAccessStore(),
    updateProjectDetailsStore: createUpdateProjectDetailsStore(),
  });
};
