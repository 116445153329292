import React, { useRef } from "react";
import { Instance } from "mobx-state-tree";
import {
  Button,
  CornerRadius,
  FoundationColorTokens,
  Shadow,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { BorderStyle } from "@pulse/shared-components";
import { SurveyQuestionViewStoreNames } from "../stores/SurveyViewDetailsStore";
import { ChartSelection, QuestionTypeGraphs } from "./ChartSelection";
import { observer } from "mobx-react";
import { OpenEndedQuestionViewModelV1 } from "../models/OpenEndedQuestionViewModelV1";
import { getButtonProps, UpdateQuestionViewParams } from "./QuestionViews";
import { NavigateToFunctions } from "../../../routes/RoutesHelper";
import {
  GetPaginatedOpenEndedResponsesRPC,
  TableFilter,
} from "@pulse/pulse-rpcs";
import { SignInStore } from "../../auth/store/SignInStore";
import { Stack, Typography } from "@mui/material";
import { HeaderQuestionDetails } from "./HeaderQuestionDetails";
import { UserPrivileges } from "../../store/user/UserPrivileges";
import {
  HIDDEN_ELEMENT_WHILE_DOWNLOADING_GRAPH_CLASS_NAME,
  QUESTION_VIEW_MAX_WIDTH_IN_PIXELS,
} from "../../utils/constants";
import { GraphDownloadButton } from "./GraphDownloadButton";
import { SelectedGraph } from "./SelectedGraph";
import { DataProps, DataSection } from "./DataSection";
import { TopNWordsDropDown } from "./TopNWordsDropDown";
import { ViewOpenEndedResponses } from "./ViewOpenEndedResponses";

interface OpenEndedQuestionViewProps {
  surveyQuestionView: Instance<typeof OpenEndedQuestionViewModelV1>;
  typography: LeoTypography;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
  border: BorderStyle;
  cornerRadius: CornerRadius;
  boxShadow: Shadow;
  updateQuestionViewParams?: UpdateQuestionViewParams;
  navigateTo: NavigateToFunctions;
  tableFilter: TableFilter[];
  openEndedQuestionType: GetPaginatedOpenEndedResponsesRPC.RequestEnums.OpenEndedQuestionType.OpenEndedQuestionType;
  groupChildSurveyQuestionViewId?: string;
  allowSPoCToSelectGraphType: boolean;
  signInStore: Instance<typeof SignInStore>;
}

export const OpenEndedQuestionView = observer(
  ({
    surveyQuestionView,
    spacing,
    t,
    tokens,
    typography,
    border,
    cornerRadius,
    boxShadow,
    updateQuestionViewParams,
    navigateTo,
    tableFilter,
    openEndedQuestionType,
    groupChildSurveyQuestionViewId,
    allowSPoCToSelectGraphType,
    signInStore,
  }: OpenEndedQuestionViewProps): React.ReactElement => {
    const graphElementRef = useRef<HTMLDivElement>(null);
    const surveyQuestionViewType =
      SurveyQuestionViewStoreNames.OPEN_ENDED_QUESTION_MODEL;
    const buttonProps = getButtonProps(
      updateQuestionViewParams,
      surveyQuestionView.surveyQuestionViewId,
      surveyQuestionView.questionViewGraphType,
      surveyQuestionView.isGraphSelectionUpdated,
      surveyQuestionView.updateSelectedGraphTypeWithUpdatedGraph,
    );
    const topNWordsProps = {
      selectedTopNWords: surveyQuestionView.topNWords,
      setSelectedTopNWords: surveyQuestionView.setTopNWords,
      openEndedResponsesStore: surveyQuestionView.openEndedResponsesStore,
      navigateTo,
      surveyQuestionViewId:
        groupChildSurveyQuestionViewId ??
        surveyQuestionView.surveyQuestionViewId,
      tableFilter,
      openEndedQuestionType,
    };
    const isProcessSurveyDashboardViewsPrivilegeGranted =
      signInStore.isPrivilegeGranted(
        UserPrivileges.processSurveyDashboardViews,
      );
    const isChartSelectionVisible =
      allowSPoCToSelectGraphType ||
      isProcessSurveyDashboardViewsPrivilegeGranted;
    const isChildQuestion = updateQuestionViewParams === undefined;
    return (
      <Stack
        width={isChildQuestion ? "100%" : "1024px"}
        alignItems="center"
        border={isChildQuestion ? undefined : border.default}
        borderRadius={cornerRadius.radiusXS}
        gap={spacing.spaceXL}
        paddingBottom={spacing.space2XL}
        bgcolor={tokens.background}
        ref={graphElementRef}
      >
        <Stack
          padding={
            isChildQuestion
              ? spacing.spaceSM
              : `${spacing.spaceMD} ${spacing.spaceXL}`
          }
          gap={isChildQuestion ? "0px" : spacing.spaceSM}
          width="100%"
          borderBottom={border.default}
        >
          {
            <Stack direction="row">
              <HeaderQuestionDetails
                questionText={surveyQuestionView.question}
                sampleBreakdown={surveyQuestionView.sampleBreakdown}
                typography={typography}
                tokens={tokens}
                t={t}
                spacing={spacing}
                surveyQuestionViewType={surveyQuestionViewType}
                isQuestionTypeIconVisible={true}
              />
              {isChildQuestion && isChartSelectionVisible && (
                <ChartSelection
                  border={border}
                  cornerRadius={cornerRadius}
                  spacing={spacing}
                  surveyQuestionViewType={surveyQuestionViewType}
                  selectedGraph={
                    QuestionTypeGraphs[
                      surveyQuestionView.updatedOpenEndedGraphType
                    ]
                  }
                  onChartIconClick={surveyQuestionView.updateSelectedGraphType}
                  tokens={tokens}
                />
              )}
            </Stack>
          }
          <Stack
            direction="row"
            justifyContent={
              isChartSelectionVisible ? "space-between" : "flex-end"
            }
          >
            {!isChildQuestion && isChartSelectionVisible && (
              <ChartSelection
                border={border}
                cornerRadius={cornerRadius}
                spacing={spacing}
                surveyQuestionViewType={surveyQuestionViewType}
                selectedGraph={
                  QuestionTypeGraphs[
                    surveyQuestionView.updatedOpenEndedGraphType
                  ]
                }
                onChartIconClick={surveyQuestionView.updateSelectedGraphType}
                tokens={tokens}
              />
            )}
            {buttonProps && !isChildQuestion && (
              <Stack
                gap={spacing.spaceXS}
                direction="row"
                className={HIDDEN_ELEMENT_WHILE_DOWNLOADING_GRAPH_CLASS_NAME}
              >
                <GraphDownloadButton
                  elementRef={graphElementRef}
                  fileName={
                    QuestionTypeGraphs[
                      surveyQuestionView.updatedOpenEndedGraphType
                    ]
                  }
                />
                {isProcessSurveyDashboardViewsPrivilegeGranted && (
                  <Button
                    label={t("common.saveChanges")}
                    onClick={buttonProps.onSaveChangeClick}
                    name="saveChanges"
                    size="small"
                    variant="filled"
                    disabled={buttonProps.isSaveChangeDisabled}
                  />
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
        {surveyQuestionView.openEndedResultsV1.map((openEndedResult, index) => {
          const data = surveyQuestionView.openEndedResultData(index);
          return (
            <Stack
              key={index}
              padding={spacing.space2XL}
              gap={spacing.spaceXL}
              width="100%"
              alignItems="center"
            >
              {openEndedResult.title && (
                <Typography
                  {...typography.sh2}
                  color={tokens.label}
                  borderBottom={border.default}
                  paddingBottom={spacing.space2XL}
                  width="100%"
                >
                  {openEndedResult.title}
                </Typography>
              )}
              <SelectedGraph
                selectedGraph={
                  QuestionTypeGraphs[
                    surveyQuestionView.updatedOpenEndedGraphType
                  ]
                }
                data={data}
                isNeutral={undefined}
                cornerRadius={cornerRadius}
                tokens={tokens}
                spacing={spacing}
                typography={typography}
                border={border}
                naOptionCode={null}
                t={t}
                isSurveyViewOptionDisplayTextSelected={true}
              />
              <Stack
                width="100%"
                maxWidth={`${QUESTION_VIEW_MAX_WIDTH_IN_PIXELS}px`}
                justifyContent="space-between"
                direction="row"
                className={HIDDEN_ELEMENT_WHILE_DOWNLOADING_GRAPH_CLASS_NAME}
              >
                <TopNWordsDropDown
                  spacing={spacing}
                  tokens={tokens}
                  cornerRadius={cornerRadius}
                  boxShadow={boxShadow}
                  border={border}
                  selectedTopNWords={topNWordsProps.selectedTopNWords}
                  setSelectedTopNWords={topNWordsProps.setSelectedTopNWords}
                  typography={typography}
                  t={t}
                />
                <ViewOpenEndedResponses
                  t={t}
                  openEndedResponsesStore={
                    topNWordsProps.openEndedResponsesStore
                  }
                  spacing={spacing}
                  navigateTo={navigateTo}
                  surveyQuestionViewId={topNWordsProps.surveyQuestionViewId}
                  tokens={tokens}
                  typography={typography}
                  tableFilter={topNWordsProps.tableFilter}
                  openEndedQuestionType={topNWordsProps.openEndedQuestionType}
                  fieldCode={openEndedResult.code}
                />
              </Stack>
              <DataSection
                spacing={spacing}
                t={t}
                typography={typography}
                tokens={tokens}
                cornerRadius={cornerRadius}
                total={null}
                data={data as DataProps[]}
                border={border}
                isColoredSwatchRequired={false}
                customOptionHeaders={undefined}
                isSurveyViewOptionDisplayTextSelected={true}
              />
            </Stack>
          );
        })}
      </Stack>
    );
  },
);
