import {
  GetSurveyDashboardPreferencesRPC,
  HexCode,
  QuartileColor,
  QuartileColorWithHexCodes,
  QuartileConfiguration,
  QuartileRange,
  UpdateSurveyDashboardPreferencesRPC,
} from "@pulse/pulse-rpcs";
import {
  CommonErrors,
  EMPTY_CHARACTER,
  EMPTY_LIST_LENGTH,
  NetworkingError,
  ZERO_VALUE,
} from "@pulse/shared-components";
import { cast, clone, flow, getRoot, Instance, types } from "mobx-state-tree";
import { RootStore } from "../../root/store/RootStore";
import { getAPIClient } from "../../networking/APIClient";
import { APIClient } from "@surya-digital/tedwig";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import {
  useGetSurveyDashboardPreferencesRPCClient,
  useUpdateSurveyDashboardPreferencesRPCClient,
} from "../rpcs/RPC";
import {
  createQuartileConfigurationModel,
  QuartileFieldError,
  QuartileConfigurationModel,
  QuartileRanges,
} from "../models/QuartileConfigurationModel";
import { DropdownItem } from "@surya-digital/leo-reactjs-material-ui";
import { MAXIMUM_QUARTILE_CONFIGURATIONS } from "../../utils/constants";

const QuartileColorModel = types.model("QuartileColorModel", {
  quartileColor: types.enumeration(Object.values(QuartileColor.QuartileColor)),
  primitiveQuartileColor: types.string,
  defaultSingleSelectQuartileColor: types.maybeNull(types.string),
});

const createQuartileColorModel = (
  quartileColor: QuartileColorWithHexCodes,
): Instance<typeof QuartileColorModel> => {
  return QuartileColorModel.create({
    quartileColor: quartileColor.quartileColor,
    primitiveQuartileColor: quartileColor.primitiveQuartileColor.code,
    defaultSingleSelectQuartileColor:
      quartileColor.defaultSingleSelectQuartileColor?.code,
  });
};

export enum OptionDisplayEnum {
  OptionText = "OPTION_TEXT",
  OptionCode = "OPTION_CODE",
}

export enum QuartileFieldInvalidValueError {
  QuartileFieldInvalidValueError = "QUARTILE_FIELD_INVALID_VALUE_ERROR",
}

export const DashboardPreferencesStore = types
  .model("DashboardPreferencesStore", {
    isSurveyViewOptionDisplayTextSelected: types.optional(types.boolean, true),
    updatedIsSurveyViewOptionDisplayTextSelected: types.optional(
      types.boolean,
      true,
    ),
    quartileColors: types.array(QuartileColorModel),
    questionConfigurations: types.array(QuartileConfigurationModel),
    updatedQuestionConfigurations: types.array(QuartileConfigurationModel),
    isRPCLoading: types.optional(types.boolean, false),
    rpcError: types.maybeNull(
      types.union(
        types.enumeration(
          Object.values(GetSurveyDashboardPreferencesRPC.RPCError),
        ),
        types.enumeration(
          Object.values(UpdateSurveyDashboardPreferencesRPC.RPCError),
        ),
        types.enumeration(Object.values(QuartileFieldInvalidValueError)),
      ),
    ),
    isSaveButtonClicked: types.optional(types.boolean, false),
    isUpdateSurveyDashboardPreferencesRPCLoading: types.optional(
      types.boolean,
      false,
    ),
  })
  .views((store) => ({
    get isSaveButtonDisabled(): boolean {
      return (
        store.isSurveyViewOptionDisplayTextSelected ===
          store.updatedIsSurveyViewOptionDisplayTextSelected &&
        JSON.stringify(store.updatedQuestionConfigurations) ===
          JSON.stringify(store.questionConfigurations)
      );
    },
    get updatedOptionDisplaySelectedOption(): OptionDisplayEnum {
      return store.updatedIsSurveyViewOptionDisplayTextSelected
        ? OptionDisplayEnum.OptionText
        : OptionDisplayEnum.OptionCode;
    },
    get quartileRangeOptions(): DropdownItem[] {
      return [
        {
          value: QuartileRanges.BetweenValues,
          name: QuartileRanges.BetweenValues,
        },
        { name: QuartileRanges.LesserThan, value: QuartileRanges.LesserThan },
        { name: QuartileRanges.GreaterThan, value: QuartileRanges.GreaterThan },
      ];
    },
    get isRemoveRowButtonDisabled(): boolean {
      return store.updatedQuestionConfigurations.length === 1;
    },
    get isAddNewConfigurationButtonDisabled(): boolean {
      return (
        store.updatedQuestionConfigurations.length ===
        MAXIMUM_QUARTILE_CONFIGURATIONS
      );
    },
    get isRPCErrorPresent(): boolean {
      return store.rpcError !== null;
    },
    getQuartileRange(
      quartileConfiguration: Instance<typeof QuartileConfigurationModel>,
    ): QuartileRange.QuartileRange {
      switch (quartileConfiguration.quartileRange) {
        case QuartileRanges.BetweenValues: {
          return new QuartileRange.BetweenValues(
            Number(quartileConfiguration.startValue),
            Number(quartileConfiguration.endValue),
          );
        }
        case QuartileRanges.LesserThan: {
          return new QuartileRange.LesserThan(
            Number(quartileConfiguration.endValue),
          );
        }
        case QuartileRanges.GreaterThan: {
          return new QuartileRange.GreaterThan(
            Number(quartileConfiguration.startValue),
          );
        }
        default: {
          console.error("Quartile range cannot be undefined here.");
          return new QuartileRange.BetweenValues(
            Number(quartileConfiguration.startValue),
            Number(quartileConfiguration.endValue),
          );
        }
      }
    },
    getSelectedQuartileColor(
      quartileColor: QuartileColor.QuartileColor,
    ): Instance<typeof QuartileColorModel> | undefined {
      return store.quartileColors.find(
        (quartileColorModel) =>
          quartileColorModel.quartileColor === quartileColor,
      );
    },
  }))
  .actions((store) => ({
    clearQuartileRangeErrors: (): void => {
      store.updatedQuestionConfigurations.map((questionConfiguration) => {
        questionConfiguration.quartileConfigurationUIStore.setIsQuartileRangeErrored(
          false,
        );
        questionConfiguration.quartileConfigurationUIStore.setIsStartValueErrored(
          false,
        );
        questionConfiguration.quartileConfigurationUIStore.setIsEndValueErrored(
          false,
        );
      });
    },
    clearQuartileColorErrors: (): void => {
      store.updatedQuestionConfigurations.map((questionConfiguration) => {
        questionConfiguration.quartileConfigurationUIStore.setIsQuartileColorErrored(
          false,
        );
      });
    },
  }))
  .actions((store) => ({
    clearRPCError: (): void => {
      store.rpcError = null;
    },
    clearAllErrors: (): void => {
      store.rpcError = null;
      store.updatedQuestionConfigurations.map((questionConfiguration) => {
        questionConfiguration.quartileConfigurationUIStore.setIsQuartileRangeErrored(
          false,
        );
        questionConfiguration.quartileConfigurationUIStore.setIsStartValueErrored(
          false,
        );
        questionConfiguration.quartileConfigurationUIStore.setIsEndValueErrored(
          false,
        );
        questionConfiguration.quartileConfigurationUIStore.setIsQuartileColorErrored(
          false,
        );
      });
    },
    setIsSaveButtonClicked: (isSaveButtonClicked: boolean): void => {
      store.isSaveButtonClicked = isSaveButtonClicked;
    },
    validateFields: (): boolean => {
      store.isSaveButtonClicked = true;
      let doesAnyFieldContainError = false;
      let lesserThanQuartileRangeCount = 0;
      let greaterThanQuartileRangeCount = 0;
      const colorCount: { [key: string]: number } = {};
      store.updatedQuestionConfigurations.map(
        (updatedQuestionConfiguration) => {
          if (updatedQuestionConfiguration.quartileRange === undefined) {
            doesAnyFieldContainError = true;
            updatedQuestionConfiguration.quartileConfigurationUIStore.setIsQuartileRangeErrored(
              true,
            );
            store.rpcError =
              QuartileFieldInvalidValueError.QuartileFieldInvalidValueError;
          }
          if (updatedQuestionConfiguration.quartileColor === undefined) {
            doesAnyFieldContainError = true;
            updatedQuestionConfiguration.quartileConfigurationUIStore.setIsQuartileColorErrored(
              true,
            );
            store.rpcError =
              QuartileFieldInvalidValueError.QuartileFieldInvalidValueError;
          }
          if (
            updatedQuestionConfiguration.quartileRange ===
            QuartileRanges.BetweenValues
          ) {
            if (
              updatedQuestionConfiguration.startValue === undefined ||
              updatedQuestionConfiguration.startValue === EMPTY_CHARACTER
            ) {
              store.rpcError =
                QuartileFieldInvalidValueError.QuartileFieldInvalidValueError;
              doesAnyFieldContainError = true;
              updatedQuestionConfiguration.quartileConfigurationUIStore.setIsStartValueErrored(
                true,
              );
            }
            if (
              updatedQuestionConfiguration.endValue === undefined ||
              updatedQuestionConfiguration.endValue === EMPTY_CHARACTER
            ) {
              store.rpcError =
                QuartileFieldInvalidValueError.QuartileFieldInvalidValueError;
              doesAnyFieldContainError = true;
              updatedQuestionConfiguration.quartileConfigurationUIStore.setIsEndValueErrored(
                true,
              );
            }
          }
          if (
            updatedQuestionConfiguration.quartileRange ===
              QuartileRanges.LesserThan &&
            (updatedQuestionConfiguration.endValue === undefined ||
              updatedQuestionConfiguration.endValue === EMPTY_CHARACTER)
          ) {
            store.rpcError =
              QuartileFieldInvalidValueError.QuartileFieldInvalidValueError;
            doesAnyFieldContainError = true;
            updatedQuestionConfiguration.quartileConfigurationUIStore.setIsEndValueErrored(
              true,
            );
          }
          if (
            updatedQuestionConfiguration.quartileRange ===
              QuartileRanges.GreaterThan &&
            (updatedQuestionConfiguration.startValue === undefined ||
              updatedQuestionConfiguration.startValue === EMPTY_CHARACTER)
          ) {
            store.rpcError =
              QuartileFieldInvalidValueError.QuartileFieldInvalidValueError;
            doesAnyFieldContainError = true;
            updatedQuestionConfiguration.quartileConfigurationUIStore.setIsStartValueErrored(
              true,
            );
          }
          if (
            updatedQuestionConfiguration.quartileRange ===
            QuartileRanges.LesserThan
          ) {
            lesserThanQuartileRangeCount++;
            if (lesserThanQuartileRangeCount > 1) {
              store.rpcError =
                UpdateSurveyDashboardPreferencesRPC.RPCError.FoundDuplicateLesserThanQuartileRange;
              doesAnyFieldContainError = true;
              store.updatedQuestionConfigurations.map(
                (questionConfiguration) => {
                  questionConfiguration.setQuartileRangeFieldError(
                    QuartileFieldError.RepeatedLesserThanQuartileRange,
                  );
                  questionConfiguration.quartileConfigurationUIStore.setIsQuartileRangeErrored(
                    true,
                  );
                },
              );
            }
          } else if (
            updatedQuestionConfiguration.quartileRange ===
            QuartileRanges.GreaterThan
          ) {
            greaterThanQuartileRangeCount++;
            if (greaterThanQuartileRangeCount > 1) {
              store.rpcError =
                UpdateSurveyDashboardPreferencesRPC.RPCError.FoundDuplicateGreaterThanQuartileRange;
              doesAnyFieldContainError = true;
              store.updatedQuestionConfigurations.map(
                (questionConfiguration) => {
                  questionConfiguration.setQuartileRangeFieldError(
                    QuartileFieldError.RepeatedGreaterThanQuartileRange,
                  );
                  questionConfiguration.quartileConfigurationUIStore.setIsQuartileRangeErrored(
                    true,
                  );
                },
              );
            }
          } else if (
            Number(updatedQuestionConfiguration.startValue) >
            Number(updatedQuestionConfiguration.endValue)
          ) {
            store.rpcError =
              UpdateSurveyDashboardPreferencesRPC.RPCError.StartValueGreaterThanEndValue;
            doesAnyFieldContainError = true;
            updatedQuestionConfiguration.quartileConfigurationUIStore.setIsStartValueErrored(
              true,
            );
            updatedQuestionConfiguration.quartileConfigurationUIStore.setIsEndValueErrored(
              true,
            );
          }
          if (updatedQuestionConfiguration.quartileColor) {
            colorCount[updatedQuestionConfiguration.quartileColor] =
              (colorCount[updatedQuestionConfiguration.quartileColor] || 0) + 1;
          }
        },
      );
      Object.keys(colorCount).forEach((color) => {
        if (colorCount[color] > 1) {
          doesAnyFieldContainError = true;
          store.updatedQuestionConfigurations.forEach(
            (questionConfiguration) => {
              if (
                questionConfiguration.quartileColor ===
                QuartileColor.QuartileColor[
                  color as keyof typeof QuartileColor.QuartileColor
                ]
              ) {
                store.rpcError =
                  UpdateSurveyDashboardPreferencesRPC.RPCError.QuartileColorNotUnique;
                questionConfiguration.quartileConfigurationUIStore.setIsQuartileColorErrored(
                  true,
                );
              }
            },
          );
        }
      });
      return !doesAnyFieldContainError;
    },
    addNewQuartileRow: (): void => {
      store.updatedQuestionConfigurations.push(
        createQuartileConfigurationModel(undefined),
      );
      store.updatedQuestionConfigurations[
        store.updatedQuestionConfigurations.length - 1
      ].serialNumber = store.updatedQuestionConfigurations.length;
    },
    removeRow: (rowIndex: number): void => {
      store.updatedQuestionConfigurations.splice(rowIndex, 1);
      store.updatedQuestionConfigurations.map((row, index) => {
        row.serialNumber = index + 1;
      });
    },
    setUpdatedIsSurveyViewOptionDisplayTextSelected: (
      optionDisplay: OptionDisplayEnum,
    ): void => {
      store.updatedIsSurveyViewOptionDisplayTextSelected =
        optionDisplay === OptionDisplayEnum.OptionText;
    },
    getSurveyDashboardPreferences: flow(function* (
      surveyId: string,
      projectId: string,
    ) {
      const errorStore =
        getRoot<typeof RootStore>(store).networkingStore.errorStore;
      store.isRPCLoading = true;
      store.rpcError = null;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new GetSurveyDashboardPreferencesRPC.Request(
          new LeoUUID(projectId),
          new LeoUUID(surveyId),
        );
        const {
          response,
          error,
        }: {
          response?: GetSurveyDashboardPreferencesRPC.Response;
          error?: GetSurveyDashboardPreferencesRPC.Errors.Errors;
        } =
          yield useGetSurveyDashboardPreferencesRPCClient(apiClient).execute(
            request,
          );
        if (response) {
          store.isSurveyViewOptionDisplayTextSelected =
            response.isSurveyViewOptionDisplayTextSelected;
          store.updatedIsSurveyViewOptionDisplayTextSelected =
            response.isSurveyViewOptionDisplayTextSelected;
          if (response.quartileConfigurations.length > EMPTY_LIST_LENGTH) {
            store.questionConfigurations = cast(
              response.quartileConfigurations.map((quartileConfiguration) => {
                return createQuartileConfigurationModel(quartileConfiguration);
              }),
            );
            store.updatedQuestionConfigurations = cast(
              response.quartileConfigurations.map((quartileConfiguration) => {
                return createQuartileConfigurationModel(quartileConfiguration);
              }),
            );
          } else {
            store.questionConfigurations.replace([
              createQuartileConfigurationModel(undefined),
            ]);
            store.updatedQuestionConfigurations.replace([
              createQuartileConfigurationModel(undefined),
            ]);
          }
          store.quartileColors = cast(
            response.quartileColors.map((quartileColor) => {
              return createQuartileColorModel(quartileColor);
            }),
          );
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              break;
            default: {
              errorStore.setError(NetworkingError.InternalServerError);
              console.error(`Unhandled error occured: ${error}`);
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
    updateSurveyDashboardPreferences: flow(function* (
      surveyId: string,
      projectId: string,
    ) {
      const errorStore =
        getRoot<typeof RootStore>(store).networkingStore.errorStore;
      store.isUpdateSurveyDashboardPreferencesRPCLoading = true;
      store.rpcError = null;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const quartileConfigurations: (QuartileConfiguration | undefined)[] =
          store.updatedQuestionConfigurations.map(
            (updatedQuestionConfiguration) => {
              if (
                updatedQuestionConfiguration.quartileColor &&
                updatedQuestionConfiguration.primitiveQuartileColor
              ) {
                return new QuartileConfiguration(
                  new QuartileColorWithHexCodes(
                    updatedQuestionConfiguration.quartileColor,
                    new HexCode(
                      updatedQuestionConfiguration.primitiveQuartileColor,
                    ),
                    updatedQuestionConfiguration.defaultSingleSelectQuartileColor
                      ? new HexCode(
                          updatedQuestionConfiguration.defaultSingleSelectQuartileColor,
                        )
                      : null,
                  ),
                  store.getQuartileRange(updatedQuestionConfiguration),
                  updatedQuestionConfiguration.serialNumber ?? ZERO_VALUE,
                );
              } else {
                console.error("Color and Hex code cannot be undefined here.");
              }
            },
          );
        if (
          quartileConfigurations.filter(
            (configuration) => configuration === undefined,
          ).length > 0
        ) {
          console.error("Configuration cannot be undefined here.");
          return;
        }
        const request = new UpdateSurveyDashboardPreferencesRPC.Request(
          new LeoUUID(projectId),
          new LeoUUID(surveyId),
          quartileConfigurations.filter(
            (quartileConfiguration) => quartileConfiguration !== undefined,
          ),
          store.updatedIsSurveyViewOptionDisplayTextSelected,
        );
        const {
          response,
          error,
        }: {
          response?: UpdateSurveyDashboardPreferencesRPC.Response;
          error?: UpdateSurveyDashboardPreferencesRPC.Errors.Errors;
        } =
          yield useUpdateSurveyDashboardPreferencesRPCClient(apiClient).execute(
            request,
          );
        if (response) {
          store.isSaveButtonClicked = false;
          store.questionConfigurations = cast(
            store.updatedQuestionConfigurations.map((questionConfiguration) => {
              return clone(questionConfiguration);
            }),
          );
          store.isSurveyViewOptionDisplayTextSelected =
            store.updatedIsSurveyViewOptionDisplayTextSelected;
          store.clearQuartileRangeErrors();
          store.clearQuartileColorErrors();
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              break;
            case UpdateSurveyDashboardPreferencesRPC.RPCError
              .FoundDuplicateGreaterThanQuartileRange: {
              store.updatedQuestionConfigurations.map(
                (updatedQuestionConfiguration) => {
                  if (
                    updatedQuestionConfiguration.quartileRange ===
                    QuartileRanges.GreaterThan
                  ) {
                    updatedQuestionConfiguration.quartileConfigurationUIStore.setIsQuartileRangeErrored(
                      true,
                    );
                  }
                },
              );
              store.rpcError =
                UpdateSurveyDashboardPreferencesRPC.RPCError.FoundDuplicateGreaterThanQuartileRange;
              break;
            }
            case UpdateSurveyDashboardPreferencesRPC.RPCError
              .FoundDuplicateLesserThanQuartileRange: {
              store.updatedQuestionConfigurations.map(
                (updatedQuestionConfiguration) => {
                  if (
                    updatedQuestionConfiguration.quartileRange ===
                    QuartileRanges.LesserThan
                  ) {
                    updatedQuestionConfiguration.quartileConfigurationUIStore.setIsQuartileRangeErrored(
                      true,
                    );
                  }
                },
              );
              store.rpcError =
                UpdateSurveyDashboardPreferencesRPC.RPCError.FoundDuplicateLesserThanQuartileRange;
              break;
            }
            case UpdateSurveyDashboardPreferencesRPC.RPCError
              .StartValueGreaterThanEndValue: {
              if (
                error instanceof
                UpdateSurveyDashboardPreferencesRPC.Errors
                  .StartValueGreaterThanEndValue
              ) {
                const configuration = store.updatedQuestionConfigurations.find(
                  (questionConfiguration) => {
                    return (
                      questionConfiguration.serialNumber === error.serialNumber
                    );
                  },
                );
                configuration?.quartileConfigurationUIStore.setIsStartValueErrored(
                  true,
                );
                configuration?.quartileConfigurationUIStore.setIsEndValueErrored(
                  true,
                );
                configuration?.setIsStartValueGreaterThanEndValue(true);
              }
              store.rpcError =
                UpdateSurveyDashboardPreferencesRPC.RPCError.StartValueGreaterThanEndValue;
              break;
            }
            case UpdateSurveyDashboardPreferencesRPC.RPCError
              .InvalidSerialNumber: {
              store.rpcError =
                UpdateSurveyDashboardPreferencesRPC.RPCError.InvalidSerialNumber;
              break;
            }
            case UpdateSurveyDashboardPreferencesRPC.RPCError
              .MaximumQuartileConfigurationsReached: {
              store.rpcError =
                UpdateSurveyDashboardPreferencesRPC.RPCError.MaximumQuartileConfigurationsReached;
              break;
            }
            case UpdateSurveyDashboardPreferencesRPC.RPCError
              .ProjectAlreadyArchived: {
              store.rpcError =
                UpdateSurveyDashboardPreferencesRPC.RPCError.ProjectAlreadyArchived;
              break;
            }
            case UpdateSurveyDashboardPreferencesRPC.RPCError
              .QuartileColorNotUnique: {
              if (
                error instanceof
                UpdateSurveyDashboardPreferencesRPC.Errors
                  .QuartileColorNotUnique
              ) {
                store.updatedQuestionConfigurations.forEach(
                  (questionConfiguration) => {
                    if (
                      questionConfiguration.quartileColor ===
                      error.quartileColor.quartileColor
                    ) {
                      questionConfiguration.quartileConfigurationUIStore.setIsQuartileColorErrored(
                        true,
                      );
                    }
                  },
                );
              }
              store.rpcError =
                UpdateSurveyDashboardPreferencesRPC.RPCError.QuartileColorNotUnique;
              break;
            }
            default: {
              errorStore.setError(NetworkingError.InternalServerError);
              console.error(`Unhandled error occured: ${error}`);
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isUpdateSurveyDashboardPreferencesRPCLoading = false;
      }
    }),
  }));

export const createDashboardPreferencesStore = (): Instance<
  typeof DashboardPreferencesStore
> => {
  return DashboardPreferencesStore.create();
};
